import React from 'react'
import PropTypes from 'prop-types'

import DropDown from '../../../dropDown/dropDown'

import i18n from '../../../../../helpers/i18n'
import { subjectShape } from '../../../../../helpers/propShapes/common'
import { sortSubjects } from '../../../../../helpers/subjects'

import CloseIcon from '../../../../../images/icons/close.svg'

import './subjectSelect.scss'

class SubjectSelectPresenter extends React.Component {
  constructor(props) {
    super(props)

    this.onSelectSubject = this.onSelectSubject.bind(this)
  }

  onSelectSubject(subjectID) {
    const { onClickSubject, postSelect } = this.props

    onClickSubject(subjectID)

    if (postSelect) {
      postSelect()
    }
  }

  render() {
    const { value, subjects } = this.props

    const text = value ? value.name : i18n.t('userActions.selectSubject')
    const content = (
      <div className="subject-trigger">
        {text}
        <button
          className="subject-cancel"
          data-spec="cancel-selection"
          onClick={() => this.onSelectSubject(null)}
        >
          <CloseIcon className="close" title={i18n.t('labels.close')} />
        </button>
      </div>
    )

    const options = sortSubjects([...subjects])

    return (
      <div className="subject-select">
        <DropDown
          defaultIndex={
            value ? options.findIndex((s) => s.id === value.id) : -1
          }
          initialState="open"
          options={options.map((s) => ({ text: s.name }))}
          trigger={content}
          onChange={(idx) => this.onSelectSubject(options[idx].id)}
        />
      </div>
    )
  }
}

SubjectSelectPresenter.propTypes = {
  subjects: PropTypes.arrayOf(subjectShape).isRequired,
  value: subjectShape,
  postSelect: PropTypes.func,
  onClickSubject: PropTypes.func.isRequired
}

export default SubjectSelectPresenter
