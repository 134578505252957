import * as actionIDs from '../actions/actionIDs'

const defaultInitialize = (state, action, options) => {
  let newState = state
  const {
    fetchingLocation,
    creating,
    temporaryPlacement,
    placementFunction,
    template
  } = options
  // Set fetching status
  if (fetchingLocation) {
    newState = fetchingLocation(newState, action, true)
  } else {
    newState = newState.set('fetching', true)
  }
  // if creating new entities, use temporary placement
  if (creating) {
    if (temporaryPlacement) {
      newState = temporaryPlacement(newState, action)
    } else {
      const tempObject = template ? template(newState, action) : null
      newState = placementFunction(newState, action, tempObject)
    }
  }
  return newState
}

const defaultSuccess = (state, action, options) => {
  const { fetchingLocation, errorLocation, placementFunction } = options
  let newState = state
  if (fetchingLocation) {
    newState = fetchingLocation(newState, action, false)
  } else {
    // backwards compatibility
    newState = newState.set('fetching', false)
  }
  if (errorLocation) {
    newState = errorLocation(newState, action, null)
  }
  if (placementFunction) {
    return placementFunction(newState, action)
  }
  return newState
}

const defaultError = (state, action, options) => {
  const { fetchingLocation, errorLocation } = options
  if (!fetchingLocation && !errorLocation) {
    return state.merge({ fetching: false, error: action.error })
  }
  let newState = state
  if (fetchingLocation) {
    newState = fetchingLocation(state, action, false)
  }
  if (errorLocation) {
    newState = errorLocation(newState, action, action.error)
  }
  return newState
}
// Default GET request handlers for reducers
// (param) resource: constant from resources.js that is the resource name
// (param) handlers: Object mapping actionID to an action handler.  Handlers
// passed here are to be run after setting fetching state.
// (param, optional) options:
//    - fetchingLocation: function to set fetching state
//      (state, action, fetching) => state.setIn([a, b, c], fetching)
//    - errorLocation: function to set error
//      (state, action, error) => state.setIn([a, b, c], error)
//    - placementFunction: function to where to set the model
//      (state, action) => set model in state here
// (returns) reducerHandlers: default handlers + custom handlers
export const getHandlers = (resource, handlers = {}, options = {}) => {
  const handleActionIDs = actionIDs.resourceActionIDCreator(
    actionIDs.readPrefix,
    resource
  )

  return handleCreator(handleActionIDs, handlers, options)
}

// Default CREATE request handlers for reducers
// (param) resource: constant from resources.js that is the resource name
// (param) handlers: Object mapping actionID to an action handler.  Handlers
// passed here are to be run after setting fetching state.
// (param, optional) options:
//    - same as GET getHandlers above ^^^
//    - placementFunction: should handle both temporary and real objects passed in
//      based on the temporaryPlacement in defaultInitialize.
//   - creating: flag to denote a create type action for shared defaultHandlers
//   - temporaryPlacement: function to set a temporary template model
//      (state, action) => set temporary model in state here
//   - template: function to generate a template for temporary placement
//      (state, action) => generate model based on template/actions
// (returns) reducerHandlers: default handlers + custom handlers
export const createHandlers = (resource, handlers = {}, options = {}) => {
  const createOptions = Object.assign({}, options, { creating: true })
  const handleActionIDs = actionIDs.resourceActionIDCreator(
    actionIDs.createPrefix,
    resource
  )
  return handleCreator(handleActionIDs, handlers, createOptions)
}

export const updateHandlers = (resource, handlers = {}, options = {}) => {
  const updateOptions = Object.assign({}, options, {})
  const handleActionIDs = actionIDs.resourceActionIDCreator(
    actionIDs.updatePrefix,
    resource
  )

  return handleCreator(handleActionIDs, handlers, updateOptions)
}

export function handleCreator(handleActionIDs, handlers = {}, options = {}) {
  const defaultHandlers = {
    [handleActionIDs.initialize]: (state, action) =>
      defaultInitialize(state, action, options),
    [handleActionIDs.success]: (state, action) =>
      defaultSuccess(state, action, options),
    [handleActionIDs.error]: (state, action) =>
      defaultError(state, action, options)
  }
  return {
    ...defaultHandlers,
    ...handlers
  }
}

// reducerCreator creats a reducer function with given handlers
// (param) initialState: initialState of the reducer
// (param) handlers: Object mapping actionID to an action handler.
//
// (returns) a reducer
export const reducerCreator = (initialState, handlers) => {
  const reducer = (state = initialState, action) => {
    if (handlers[action.type]) {
      return handlers[action.type](state, action)
    }
    return state
  }
  return reducer
}

export default {
  createHandlers,
  getHandlers,
  updateHandlers,
  reducerCreator,
  handleCreator
}
