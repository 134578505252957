import { NOT_APPLICABLE } from '../../helpers/constants'

export const transformSubjectGroup = (data) => ({
  id: data.id.toString(),
  type: data.type,
  subjectIDs: data.subject_ids.map((subjectId) => subjectId.toString())
})

export const subjectGroups = (data) => data.map(transformSubjectGroup)

export const transformCourse = (data) => {
  return {
    id: data.id.toString(),
    name: data.name,
    curriculumID: data.curriculum_id.toString(),
    grades: data.grade_level_ids
      ? data.grade_level_ids.map((x) => x.toString())
      : [NOT_APPLICABLE],
    isNew: Boolean(data.is_new),
    schoolSubjectGroupID: data.school_subject_group_id.toString()
  }
}

export const transformSubjectsAndCourses = (data) => {
  return {
    subject: transformSubjectGroup(data.subject),
    courses: data.courses && data.courses.map(transformCourse)
  }
}

export const courses = (data) => data.map(transformCourse)
export const subjectsAndCourses = (data) =>
  (data && data.map(transformSubjectsAndCourses)) || []

export const getUnitsFromCurriculumTree = (data) => ({
  id: data.id.toString(),
  permissions: data.permissions,
  subjectGroupID: data.subject_group_id,
  sharedWith: (data.shared_with || []).map((collab) => ({
    id: collab.user_id.toString(),
    role: collab.role
  })),
  treeID: data.tree_id
})

export const transformSkill = (standard) => ({
  id: standard.standard_id.toString(),
  name: standard.standard_name,
  description: standard.standard_description,
  groupID: (standard.standard_group_id || 0).toString(),
  groupName: standard.standard_group_name,
  category: standard.standard_category,
  subCategory: standard.standard_sub_category,
  gradeID: (standard.grade_id || 0).toString(), // custom standards don't have grade_ids
  subjectID: (standard.subject_id || 0).toString(), // custom standards don't have subject_ids
  correlatedOriginalID: (
    standard.original_correlated_standard_id || 0
  ).toString()
})

export const transformSkills = (data) => data.map(transformSkill)

export const transformIndividualSkills = (data) =>
  (data.standards || []).map(transformSkill)

export const transformSkillGroups = (data) =>
  data.standard_groups.map((standardGroup) => {
    return {
      id: standardGroup.standard_group_id.toString(),
      source: standardGroup.source,
      groupName: standardGroup.group_name,
      country: standardGroup.country,
      gradeName: standardGroup.grade_name,
      subjectName: standardGroup.subject_name,
      subjectID: standardGroup.subject_id
        ? standardGroup.subject_id.toString()
        : null,
      gradeID: standardGroup.grade_id
        ? standardGroup.grade_id.toString()
        : null,
      gradeIDs: (standardGroup.grade_ids || []).map((gradeID) =>
        gradeID.toString()
      ),
      subjectIDs: (standardGroup.subject_ids || []).map((subjectID) =>
        subjectID.toString()
      ),
      isCustom: standardGroup.is_custom,
      state: standardGroup.state
    }
  })
