import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { CLASS_STUDENT_URL } from '../../routes/routes'
import {
  STUDENT_PROFILE_VIEWED,
  STUDENT_VIEWED_IN_CLASSROOM
} from '../../store/userPreferences'
import * as resources from '../../store/models/resources'
import * as fromState from '../../store/fromState'
import { fetchStudentClasses } from '../../store/actions/adminActions'
import * as coreActions from '../../store/actions/coreActions'
import Loading from '../kiddom/loading/loading'
import StudentClassCard from '../common/cards/studentClassCard'
import StudentOverviewPresenter from './studentOverviewPresenter'
import withDataLoading from '../hoc/withDataLoading'

class StudentOverviewContainer extends React.Component {
  cardClick = (classID) => {
    window.open(CLASS_STUDENT_URL(classID, this.props.student.get('childID')))
    if (this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(
        STUDENT_VIEWED_IN_CLASSROOM,
        this.props.shouldSetUserPref
      )
    }
  }

  generateCards = () => {
    const cards = []
    const classes = this.props.student.get('classStatisticsByID')
    classes.forEach((classObj, index) => {
      cards.push(
        <StudentClassCard
          classID={classObj.get('id')}
          studentID={this.props.student.get('id')}
          onClick={this.cardClick.bind(this, classObj.get('id'))}
          reportingMethod={this.props.reportingMethod}
          key={`class-card-${index}`}
        />
      )
    })
    return cards
  }

  componentDidMount() {
    this.tryToSetUserPref()
  }

  componentDidUpdate() {
    this.tryToSetUserPref()
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(
        STUDENT_PROFILE_VIEWED,
        this.props.shouldSetUserPref
      )
      this.userPrefSet = true
    }
  }

  render() {
    if (this.props.isLoading) {
      return <Loading type="academy" />
    }
    return (
      <StudentOverviewPresenter
        {...this.props}
        classCards={this.generateCards()}
      />
    )
  }
}

// Do we have the student? the student's statistics in a class? the class?
// the teacher in the class? if not, fetch it!
const isStudentFetchedWithStats = (state, studentID) => {
  const student = fromState.getResourceByID(
    state,
    resources.STUDENTS,
    studentID
  )
  let needToFetchData = !student || !student.get('classStatisticsByID')
  if (!needToFetchData && student.get('classStatisticsByID')) {
    needToFetchData = student
      .get('classStatisticsByID')
      .some((clazz, clazzID) => {
        const clazzObj = fromState.getResourceByID(
          state,
          resources.CLASSES,
          clazzID
        )
        const teacher =
          clazzObj &&
          fromState.getResourceByID(
            state,
            resources.TEACHERS,
            clazzObj.get('teacherID')
          )
        return !teacher || !clazzObj || clazz.get('performance') === undefined
      })
  }
  return !needToFetchData
}

export const mapStateToProps = (state, ownProps) => {
  const { isFetching, resourcesLoaded } = ownProps
  if (isFetching || !resourcesLoaded) {
    return { isLoading: true }
  }
  const { schoolID, studentID } = ownProps.match.params
  const student = fromState.getResourceByID(
    state,
    resources.STUDENTS,
    studentID
  )
  const renderSidebar =
    fromState.isUserFreeTrial(state) &&
    fromState.getSchoolIsDemo(state, schoolID)
  const shouldSetUserPref = fromState.isSchoolFreeTrialAndDemo(state, schoolID)
  return {
    student,
    reportingMethod: fromState.getSchoolSetReportingMethod(state, schoolID),
    renderSidebar,
    shouldSetUserPref,
    userPrefDataLoaded: fromState.userPrefDataLoaded(state)
  }
}

export const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    checkAndSetUserPref: (userPref, shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: userPref,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    }
  }
}

const generateLoadingProps = (props) => ({
  isFetching: [
    [
      fromState.isFetchingResources,
      [
        [
          resources.STUDENTS,
          resources.CLASSES,
          resources.TEACHERS,
          resources.REPORTING_METHODS,
          resources.SUBJECTS
        ]
      ]
    ]
  ],
  resourcesLoaded: [
    [
      fromState.resourcesLoaded,
      [
        [
          resources.STUDENTS,
          resources.CLASSES,
          resources.TEACHERS,
          resources.REPORTING_METHODS,
          resources.SUBJECTS
        ],
        props.match.params.schoolID
      ]
    ],
    [isStudentFetchedWithStats, [props.match.params.studentID]]
  ]
})
const generateDataLoaders = (props) => [
  coreActions.subjects.read.begin(),
  fetchStudentClasses(
    props.match.params.schoolID,
    props.match.params.studentID
  ),
  coreActions.reportingMethods.read.begin(props.match.params.schoolID)
]

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(StudentOverviewContainer),
    generateDataLoaders,
    generateLoadingProps,
    []
  )
)
