import React, { useEffect } from 'react'

import i18n from '../../helpers/i18n'
import { ONBOARDING_LOADING } from '../../routes/routes'
// import FreeTrialHero from '../../images../../../../../../../images/illustrations/freetrial-hero.svg';
import KiddomIconText from '../../images/logos/icon-text.svg'
import CurriculumIcon from '../../images/logos/planner.svg'
import DashboardIcon from '../../images/logos/chart.svg'
import ArrowNext from '../../images/icons/arrow-next.svg'
import Card from '../common/cards/card'
import './onboardingLanding.scss'

const FreeTrialHero = '/images/illustrations/freetrial-hero.svg'
const headerLabel = i18n.t('titles.customizeExperience')
const questionLabel = i18n.t('titles.whatOption')
const curriculumLabel = i18n.t('userActions.createCurriculum')
const dashboardLabel = i18n.t('userActions.exploreDemoData')
const timeLabel = (count) => `(${i18n.t('labels.minutes', { count })})`
const curriculumTitle = i18n.t('titles.curriculum')
const dashboardTitle = i18n.t('titles.dashboard')
const nextTitle = i18n.t('labels.next')

const Option = (props) => {
  return (
    <Card
      className="option"
      onClick={props.onClick}
      data-spec={props['data-spec']}
    >
      {props.icon}
      <div className="blurb">
        <h3 className="action">{props.label}</h3>
        <span className="timespan">{props.timeLimit}</span>
      </div>
      <ArrowNext title={nextTitle} className="arrow" />
    </Card>
  )
}

const OnboardingLanding = (props) => {
  useEffect(() => {
    if (window.Intercom) {
      // Sometimes Intercom is failing to see the user as active,
      // and wont trigger new incoming messages, calling update should ping
      // intercom again with current user data, if not, we'll listen for a
      // change to unread messages. If Free Trial onboarding campign is over,
      // this could be removed, check with Support/Success first.
      window.Intercom('update')
      window.Intercom('onUnreadCountChange', (unreadCount) => {
        if (unreadCount) {
          window.Intercom('show')
        }
      })
    }
  }, [])

  const options = [
    {
      icon: <CurriculumIcon title={curriculumTitle} className="option-icon" />,
      label: curriculumLabel,
      timeLimit: timeLabel(7),
      onClick: () => {
        props.trackCurriculumSelected()
        props.history.push(ONBOARDING_LOADING)
      },
      'data-spec': 'curriculum-option',
      key: 'curriculum'
    },
    {
      icon: <DashboardIcon title={dashboardTitle} className="option-icon" />,
      label: dashboardLabel,
      timeLimit: timeLabel(10),
      onClick: () => {
        props.trackDashboardSelected()
        props.history.push(ONBOARDING_LOADING)
      },
      'data-spec': 'dashboard-option',
      key: 'dashboard'
    }
  ]

  return (
    <div className="onboarding-landing">
      <div className="content-container">
        <KiddomIconText title="Kiddom" className="kiddom-icon" />
        <div className="header">
          <h1 className="label">{headerLabel}</h1>
          <div className="underline" />
        </div>
        <div className="question-container">
          <h2 className="question">{questionLabel}</h2>
          <div className="options-container">
            {options.map((optionProps) => (
              <Option {...optionProps} key={optionProps.key} />
            ))}
          </div>
        </div>
      </div>
      <div className="background-container">
        <img className="background" src={FreeTrialHero} alt="foobar" />
      </div>
    </div>
  )
}

export default OnboardingLanding
