import React, { useEffect } from 'react'
import useFeatureEnabled from '../../hooks/useFeatureEnabled'
import { ExternalFeature } from '../../hooks/useFeatures'

export const HelpScoutBeacon = () => {
  const featureEnabled = useFeatureEnabled()
  const beaconEnabled = featureEnabled(ExternalFeature.HelpScoutBeacon)

  useEffect(() => {
    if (beaconEnabled !== undefined) {
      const body = document.querySelector<HTMLBodyElement>('body')
      const classToAdd = beaconEnabled ? 'show-beacon' : 'show-intercom'
      const classToRemove = !beaconEnabled ? 'show-beacon' : 'show-intercom'
      if (!body?.classList.contains(classToAdd)) {
        body?.classList.add(classToAdd)
      }
      if (body?.classList.contains(classToRemove)) {
        body?.classList.remove(classToRemove)
      }
    }
  }, [beaconEnabled])
  return <></>
}
