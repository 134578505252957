import PropTypes from 'prop-types'

const studentMasteryShape = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired
    })
  ),
  PropTypes.number
])

export const teacherItemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  studentMastery: studentMasteryShape,
  classCount: PropTypes.number.isRequired,
  numStudents: PropTypes.number.isRequired,
  assignmentsAssigned: PropTypes.number.isRequired,
  assignmentsScored: PropTypes.number.isRequired
})

export const studentItemShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  studentMastery: studentMasteryShape,
  recentGrowth: PropTypes.number,
  assignmentsSubmitted: PropTypes.number,
  assignmentsDue: PropTypes.number,
  assignmentsMissing: PropTypes.number
})

export const listItemsShape = PropTypes.arrayOf(
  PropTypes.oneOfType([teacherItemShape, studentItemShape])
)

export const listHeadingsShape = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
  })
)
