import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Kiddoms
import { SCHOOL_PATH_PREFIX } from './routes'
import TeachersOverviewContainer from '../components/teacher/teachersOverviewContainer'
import TeacherOverviewContainer from '../components/teacher/teacherOverviewContainer'
import TeacherClassOverviewContainer from '../components/teacher/teacherClassOverviewContainer'
import InactiveTeachers from '../components/teacher/inactiveTeachers'
import UnactivatedTeachers from '../components/teacher/unactivatedTeachers'
import { withSchoolOrDistrictAdminRoleCheck } from '../components/hoc/withRoleCheckFor'

const teachersPath = `${SCHOOL_PATH_PREFIX}/teachers`
const inactiveTeachersPath = `${SCHOOL_PATH_PREFIX}/teachers/inactive`
const unactivatedTeachersPath = `${SCHOOL_PATH_PREFIX}/teachers/unactivated`
const teacherPath = `${SCHOOL_PATH_PREFIX}/teachers/:teacherID`
const teacherClassPath = `${teacherPath}/classes/:classID`

const TeacherRouter = () => (
  <Switch>
    <Route exact path={inactiveTeachersPath} component={InactiveTeachers} />
    <Route
      exact
      path={unactivatedTeachersPath}
      component={UnactivatedTeachers}
    />
    <Route exact path={teachersPath} component={TeachersOverviewContainer} />
    <Route exact path={teacherPath} component={TeacherOverviewContainer} />
    <Route
      exact
      path={teacherClassPath}
      component={TeacherClassOverviewContainer}
    />
  </Switch>
)

export default withSchoolOrDistrictAdminRoleCheck(TeacherRouter)
