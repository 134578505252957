import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import NavigationMenu from './navigationMenu'

const SchoolSubheader = (props) => {
  return (
    <div
      className={classnames('subheader', {
        'subheader--minimized': props.minimized
      })}
      data-spec="school-subheader"
    >
      <h2 className="subtitle">{props.schoolName}</h2>
      <NavigationMenu
        schoolID={props.schoolID}
        disableDashboardLinks={props.disableDashboardLinks}
      />
    </div>
  )
}

const DistrictSubheader = (props) => {
  const subtitle = props.subheaderTitle || i18n.t('titles.schoolDirectory')
  return (
    <div className="subheader district" data-spec="district-subheader">
      <h2 className="subtitle">{subtitle}</h2>
      <NavigationMenu districtID={props.districtID} />
    </div>
  )
}

const Subheader = (props) =>
  props.schoolID && props.useSchoolSubheader ? (
    <SchoolSubheader {...props} />
  ) : (
    <DistrictSubheader {...props} />
  )

Subheader.propTypes = {
  districtID: PropTypes.number,
  schoolID: PropTypes.string,
  schoolName: PropTypes.string,
  minimized: PropTypes.bool,
  onClickButton: PropTypes.func,
  subheaderTitle: PropTypes.string,
  useSchoolSubheader: PropTypes.bool
}

export default Subheader
