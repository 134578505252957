import React from 'react'
import AlgebraIcon from '../../images/subjects/subject-algebra.png'
import ArtsIcon from '../../images/subjects/subject-arts.png'
import AstronomyIcon from '../../images/subjects/subject-astronomy.png'
import BiologyIcon from '../../images/subjects/subject-biology.png'
import CalculusIcon from '../../images/subjects/subject-calculus.png'
import CareerReadinessIcon from '../../images/subjects/subject-careerreadiness.svg'
import ChemistryIcon from '../../images/subjects/subject-chemistry.png'
import DanceIcon from '../../images/subjects/subject-dance.svg'
import DramaIcon from '../../images/subjects/subject-drama.png'
import EconomicsIcon from '../../images/subjects/subject-economics.png'
import EnglishIcon from '../../images/subjects/subject-english.svg'
import EnvironmentalSciencesIcon from '../../images/subjects/subject-environmentalsciences.png'
import EuropeanHistoryIcon from '../../images/subjects/subject-european-history.png'
import GeographyIcon from '../../images/subjects/subject-geography.png'
import GeometryIcon from '../../images/subjects/subject-geometry.png'
import GrammerIcon from '../../images/subjects/subject-grammar.png'
import HealthIcon from '../../images/subjects/subject-health.png'
import LanguageArtsIcon from '../../images/subjects/subject-languagearts.svg'
import LanguageNotEnglishIcon from '../../images/subjects/subject-languagenotenglish.png'
import LibraryIcon from '../../images/subjects/subject-library.png'
import MathIcon from '../../images/subjects/subject-math.png'
import MultidisciplinaryIcon from '../../images/subjects/subject-multi.svg'
import MusicIcon from '../../images/subjects/subject-music.png'
import NutritionIcon from '../../images/subjects/subject-nutrition.svg'
import OtherIcon from '../../images/subjects/subject-other.svg'
import PhotographyIcon from '../../images/subjects/subject-photography.png'
import PEIcon from '../../images/subjects/subject-physicaleducation.svg'
import PhysicsIcon from '../../images/subjects/subject-physics.png'
import PreAlgebraIcon from '../../images/subjects/subject-prealgebra.png'
import PreCalculusIcon from '../../images/subjects/subject-precalculus.png'
import PsychologyIcon from '../../images/subjects/subject-psychology.png'
import ReadingIcon from '../../images/subjects/subject-reading.png'
import ScienceIcon from '../../images/subjects/subject-science.png'
import SocialEmotionalLearningIcon from '../../images/subjects/subject-socialemotionallearning.png'
import SocialStudiesIcon from '../../images/subjects/subject-socialstudies.png'
import SociologyIcon from '../../images/subjects/subject-sociology.png'
import SpeakingAndListeningIcon from '../../images/subjects/subject-speakingandlistening.svg'
import StatisticsIcon from '../../images/subjects/subject-statistics.png'
import TrigonometryIcon from '../../images/subjects/subject-trigonometry.png'
import USHistoryIcon from '../../images/subjects/subject-ushistory.png'
import WorldLanguagesIcon from '../../images/subjects/subject-wli.png'
import WorldHistoryIcon from '../../images/subjects/subject-worldhistory.png'
import WritingIcon from '../../images/subjects/subject-writing.png'

const subjects = {
  Algebra: <img src={AlgebraIcon} className="icon" alt="Algebra" />,
  'Fine Arts': <img src={ArtsIcon} className="icon" alt="Fine Arts" />,
  Arts: <img src={ArtsIcon} className="icon" alt="Art" />,
  Astronomy: <img src={AstronomyIcon} className="icon" alt="Astronomy" />,
  Biology: <img src={BiologyIcon} className="icon" alt="Biology" />,
  Calculus: <img src={CalculusIcon} className="icon" alt="Calculus" />,
  'Career Readiness': <CareerReadinessIcon title="Career Readiness" />,
  Chemistry: <img src={ChemistryIcon} className="icon" alt="Chemistry" />,
  Dance: <DanceIcon title="Dance" />,
  Drama: <img src={DramaIcon} className="icon" alt="Drama" />,
  Economics: <img src={EconomicsIcon} className="icon" alt="Economics" />,
  English: <EnglishIcon title="English" />,
  'Environmental Sciences': (
    <img
      src={EnvironmentalSciencesIcon}
      className="icon"
      alt="Environmental Sciences"
    />
  ),
  'European History': (
    <img src={EuropeanHistoryIcon} className="icon" alt="European History" />
  ),
  Geography: <img src={GeographyIcon} className="icon" alt="Geography" />,
  Geometry: <img src={GeometryIcon} className="icon" alt="Geometry" />,
  Grammar: <img src={GrammerIcon} className="icon" alt="Grammar" />,
  Health: <img src={HealthIcon} className="icon" alt="Health" />,
  'Language Arts': <LanguageArtsIcon title="Language Arts" />,
  'Languages Other Than English': (
    <img
      src={LanguageNotEnglishIcon}
      className="icon"
      alt="Languages Other Than English"
    />
  ),
  'Library Media': (
    <img src={LibraryIcon} className="icon" alt="Library Media" />
  ),
  Math: <img src={MathIcon} className="icon" alt="Math" />,
  Multidisciplinary: <MultidisciplinaryIcon title="Multidisciplinary" />,
  Music: <img src={MusicIcon} className="icon" alt="Music" />,
  Nutrition: <NutritionIcon title="Nutrition" />,
  Other: <OtherIcon title="Other" />,
  Photography: <img src={PhotographyIcon} className="icon" alt="Photography" />,
  'Physical Education': <PEIcon title="Physical Education" />,
  Physics: <img src={PhysicsIcon} className="icon" alt="Physics" />,
  'Pre Algebra': (
    <img src={PreAlgebraIcon} className="icon" alt="Pre Algebra" />
  ),
  'Pre Calculus': (
    <img src={PreCalculusIcon} className="icon" alt="Pre Calculus" />
  ),
  Psychology: <img src={PsychologyIcon} className="icon" alt="Psychology" />,
  Reading: <img src={ReadingIcon} className="icon" alt="Reading" />,
  Science: <img src={ScienceIcon} className="icon" alt="Science" />,
  'Social Emotional Learning': (
    <img
      src={SocialEmotionalLearningIcon}
      className="icon"
      alt="Social Emotional Learning"
    />
  ),
  'Social Studies': (
    <img src={SocialStudiesIcon} className="icon" alt="Social Studies" />
  ),
  Sociology: <img src={SociologyIcon} className="icon" alt="Sociology" />,
  'Speaking & Listening': (
    <img
      src={SpeakingAndListeningIcon}
      className="icon"
      alt="Speaking & Listening"
    />
  ),
  Statistics: <img src={StatisticsIcon} className="icon" alt="Statistics" />,
  Trigonometry: (
    <img src={TrigonometryIcon} className="icon" alt="Trigonometry" />
  ),
  'US History': <img src={USHistoryIcon} className="icon" alt="US History" />,
  'World Languages and International Education': (
    <img
      src={WorldLanguagesIcon}
      alt="World Languages and International Education"
    />
  ),
  'World History': (
    <img src={WorldHistoryIcon} className="icon" alt="World History" />
  ),
  Writing: <img src={WritingIcon} className="icon" alt="Writing" />
}
const SubjectIcon = (props) => {
  const { names } = props
  let name = names[0]
  if (names.length > 1) {
    name = 'Multidisciplinary'
  }
  if (!Object.keys(subjects).includes(name)) {
    name = 'Other'
  }
  const icon = subjects[name]
  return (
    <div data-spec={`subject-${name}`} className="subject-icon">
      {icon}
    </div>
  )
}

export default SubjectIcon
