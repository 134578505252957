import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './buttonGroup.scss'

class ButtonGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null
    }
  }

  buttonClick(onClick, index) {
    this.setState({ selected: index })
    onClick()
  }

  renderButtons() {
    const buttons = []
    this.props.buttons.forEach((button, index) => {
      const selectedIndex =
        this.state.selected == null
          ? this.props.defaultSelected
          : this.state.selected
      const shouldHighlight =
        selectedIndex === index && this.props.highlightSelected
      const classes = classnames('academy-button', {
        selected: shouldHighlight
      })
      const buttonElement = (
        <button
          key={`buttongroup-${index}`}
          className={classes}
          onClick={this.buttonClick.bind(this, button.onClick, index)}
          data-spec={button.dataSpec}
        >
          <span>{button.label}</span>
        </button>
      )
      buttons.push(buttonElement)
    })
    return buttons
  }

  render() {
    const buttons = this.renderButtons()
    const { className } = this.props
    const buttonGroupClassNames = classnames('button-group', className)
    return (
      <div className={buttonGroupClassNames} data-spec="button-group">
        {buttons}
      </div>
    )
  }
}

ButtonGroup.defaultProps = {
  highlightSelected: true,
  defaultSelected: 0
}

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isrequired,
      onClick: PropTypes.func.isrequired
    })
  ),
  highlightSelected: PropTypes.bool,
  defaultSelected: PropTypes.number
}

export default ButtonGroup
