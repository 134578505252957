import * as resources from '../models/resources'

// Action IDs
const prefix = (verb) => {
  return {
    begin: `BEGIN_${verb}/`,
    initialize: `REQUEST_${verb}/`,
    success: `REQUEST_${verb}_SUCCESS/`,
    error: `REQUEST_${verb}_FAILURE/`
  }
}
// Action ID CRUD request prefixes
export const createPrefix = prefix('CREATE')
export const readPrefix = prefix('READ')
export const updatePrefix = prefix('UPDATE')
export const deletePrefix = prefix('DELETE')

// resourceActionIDCreator mashes up the prefixes and a given resource.
//
// (param) resource: constant from resources.js that is the resource name
// (returns) object of action names for fetching, initing a request,
// request success, and request failure.
export const resourceActionIDCreator = (prefix, resource) => ({
  // What the component will dispatch
  begin: `${prefix.begin}${resource}`,
  // Parameter finagling here
  // Will emit immediately before api call
  initialize: `${prefix.initialize}${resource}`,
  // Emitted after an OK response
  success: `${prefix.success}${resource}`,
  // Emitted if something went wrong
  error: `${prefix.error}${resource}`
})

export const readResourceActionIDCreator = (resource) =>
  resourceActionIDCreator(readPrefix, resource)

export const createResourceActionIDCreator = (resource) =>
  resourceActionIDCreator(createPrefix, resource)

export const updateResourceActionIDCreator = (resource) =>
  resourceActionIDCreator(updatePrefix, resource)

export const deleteResourceActionIDCreator = (resource) =>
  resourceActionIDCreator(deletePrefix, resource)

// models
export const studentClassPerformance = {
  read: readResourceActionIDCreator(resources.STUDENT_CLASS_PERFORMANCE)
}
export const classes = {
  read: readResourceActionIDCreator(resources.CLASSES)
}
export const topClasses = {
  read: readResourceActionIDCreator(resources.TOP_CLASSES)
}
export const teachers = {
  read: readResourceActionIDCreator(resources.TEACHERS)
}
export const grades = {
  read: readResourceActionIDCreator(resources.GRADES)
}
export const inactiveTeachers = {
  read: readResourceActionIDCreator(resources.INACTIVE_TEACHERS)
}
export const unactivatedTeachers = {
  read: readResourceActionIDCreator(resources.UNACTIVATED_TEACHERS)
}
export const students = {
  read: readResourceActionIDCreator(resources.STUDENTS),
  readAssignmentStatistics: readResourceActionIDCreator(
    resources.STUDENT_ASSIGNMENT_STATISTICS
  ),
  readPerformance: readResourceActionIDCreator(resources.STUDENT_PERFORMANCE),
  readWithStatistics: 'FETCH_STUDENTS_WITH_STATISTICS'
}
export const student = {
  read: readResourceActionIDCreator(resources.STUDENT)
}
export const studentsBatch = {
  read: readResourceActionIDCreator(resources.STUDENTS_BATCH)
}
export const studentActivityLists = {
  read: readResourceActionIDCreator(resources.STUDENT_ACTIVITY_LISTS)
}
export const studentClassesStatistics = {
  read: readResourceActionIDCreator(resources.STUDENT_CLASSES_STATISTICS)
}
export const schools = {
  read: readResourceActionIDCreator(resources.SCHOOLS)
}
export const reportingMethods = {
  read: readResourceActionIDCreator(resources.REPORTING_METHODS)
}
export const subjects = {
  read: readResourceActionIDCreator(resources.SUBJECTS)
}
export const subjectsAndCourses = {
  read: readResourceActionIDCreator(resources.SUBJECTS_AND_COURSES)
}
export const user = {
  read: readResourceActionIDCreator(resources.USER),
  readPrefs: readResourceActionIDCreator(`${resources.USER}Prefs`),
  writePrefs: createResourceActionIDCreator(`${resources.USER}Prefs`)
}
export const district = {
  read: readResourceActionIDCreator(resources.DISTRICT)
}
export const teacherAggregate = {
  read: { begin: `${readPrefix.begin}${resources.TEACHER_AGGREGATE}` }
}

export const schoolSubjectGroups = {
  read: readResourceActionIDCreator(resources.SCHOOL_SUBJECT_GROUPS),
  create: createResourceActionIDCreator(resources.SCHOOL_SUBJECT_GROUPS)
}

export const REMOVE_COURSE_TEMP_ID = 'REMOVE_COURSE_TEMP_ID'
export const courses = {
  read: readResourceActionIDCreator(resources.COURSES),
  create: createResourceActionIDCreator(resources.COURSES),
  delete: deleteResourceActionIDCreator(resources.COURSES),
  update: updateResourceActionIDCreator(`${resources.COURSES}Update`),
  share: updateResourceActionIDCreator(`${resources.COURSES}Share`),
  unshare: updateResourceActionIDCreator(`${resources.COURSES}Unshare`),
  undelete: updateResourceActionIDCreator(`${resources.COURSES}Undelete`)
}

// reports
export const teacherEngagement = {
  read: readResourceActionIDCreator(resources.TEACHER_ENGAGEMENT)
}
export const studentAchievement = {
  read: readResourceActionIDCreator(resources.STUDENT_ACHIEVEMENT)
}

export const teacherReports = {
  read: readResourceActionIDCreator(resources.TEACHER_REPORTS)
}

export const trackEvent = {
  create: createResourceActionIDCreator(resources.EVENT)
}

export const assets = {
  read: createResourceActionIDCreator(resources.ASSETS)
}

export const units = {
  read: readResourceActionIDCreator(resources.UNITS)
}

export const skillGroups = {
  read: readResourceActionIDCreator(resources.SKILL_GROUPS)
}

export const skills = {
  read: readResourceActionIDCreator(resources.SKILLS),
  readIndividualSkills: readResourceActionIDCreator(
    resources.INDIVIDUAL_SKILLS
  ),
  fetchSkillsFromSubjectGroupAndCourse:
    'FETCH_SKILLS_FROM_SUBJECT_GROUP_AND_COURSE'
}

export const userManagement = {
  parseImportFile: createResourceActionIDCreator(
    `${resources.USER_MANAGEMENT}ParseImportFile`
  ),
  addUsers: createResourceActionIDCreator(
    `${resources.USER_MANAGEMENT}AddUsers`
  ),
  clearImportErrors: `${resources.USER_MANAGEMENT}ClearImportErrors`,
  clearImport: `${resources.USER_MANAGEMENT}ClearImport`,
  sendIngestionInviteEmail: createResourceActionIDCreator(
    `${resources.USER_MANAGEMENT}SendIngestionInviteEmail`
  )
}

export const sisIntegration = {
  sendSISCredentials: createResourceActionIDCreator(
    `${resources.SIS_INTEGRATION}SendCredentials`
  )
}

export const sisCredentials = {
  read: readResourceActionIDCreator(
    `${resources.SIS_INTEGRATION}CheckCredentials`
  )
}

export const sisSchoolMatches = {
  read: readResourceActionIDCreator(`${resources.SIS_INTEGRATION}FetchMatches`),
  update: updateResourceActionIDCreator(
    `${resources.SIS_INTEGRATION}UpdateMatches`
  )
}

export const sisConfigurations = {
  read: readResourceActionIDCreator(resources.SIS_CONFIGURATIONS),
  update: updateResourceActionIDCreator(resources.SIS_CONFIGURATIONS)
}

export const studentAccounts = {
  read: readResourceActionIDCreator(resources.STUDENT_ACCOUNTS),
  readBatch: readResourceActionIDCreator(`${resources.STUDENT_ACCOUNTS}Batch`)
}

export const teacherAccounts = {
  sendInviteEmails: createResourceActionIDCreator(
    `${resources.TEACHER_ACCOUNTS}SendInviteEmails`
  )
}

export const UPDATE_STUDENT_CURRENT_PAGE = 'UPDATE_STUDENT_CURRENT_PAGE'
export const UPDATE_STUDENT_PAGINATION = 'UPDATE_STUDENT_PAGINATION'

export const FETCH_STUDENT_CLASSES = 'FETCH_STUDENT_CLASSES'
export const FETCH_SCHOOL_SUBJECTS_COURSES = 'FETCH_SCHOOL_SUBJECTS_COURSES'
export const FETCH_TRIAL_SCHOOL_SUBJECTS = 'FETCH_TRIAL_SCHOOL_SUBJECTS'

export const localStorage = {
  get: {
    begin: 'LOCAL_STORAGE_GET_BEGIN',
    success: (key) => `LOCAL_STORAGE_GET_SUCCESS/${key}`
  },
  set: {
    begin: 'LOCAL_STORAGE_SET_BEGIN',
    success: (key) => `LOCAL_STORAGE_SET_SUCCESS/${key}`
  }
}

const actionIDs = {
  readResourceActionIDCreator,
  createResourceActionIDCreator,
  updateResourceActionIDCreator,
  deleteResourceActionIDCreator
}

export default actionIDs
