import { all, call, select } from 'redux-saga/effects'

import { ROLE } from '../../helpers/constants'

import * as helperSagas from './helperSagas'
import * as adminSagas from './adminSagas'
import * as coreSagas from './coreSagas'
import * as userManagementActions from '../actions/userManagementActions'
import * as userManagementModels from '../models/userManagementModels'
import * as userManagementTransform from '../models/userManagementTransform'
import * as fromState from '../fromState'

export function* callAndRefetchUsers(saga, action) {
  yield call(saga, action)
  const role = action.role
  const numUsersAdded = yield select(fromState.getImportNumUsersAdded, role)
  if (numUsersAdded > 0) {
    const forcedAction = { ...action, shouldForceFetch: true }
    if (role === ROLE.TEACHER) {
      yield all([
        call(coreSagas.fetchTeachers, forcedAction),
        call(adminSagas.fetchInactiveTeachers, forcedAction),
        call(adminSagas.fetchUnactivatedTeachers, forcedAction)
      ])
    } else {
      yield all([
        call(coreSagas.fetchStudents, {
          schoolID: action.schoolID,
          query: '',
          offset: 0,
          limit: 50,
          shouldForceFetch: true
        }),
        call(adminSagas.fetchStudentActivityLists, forcedAction)
      ])
    }
  }
}

export function* parseImportFile(action) {
  const engineOptions = {
    transformer: userManagementTransform.transformImportFileResponse,
    needToFetch: true,
    initializeArgs: [action.file, action.schoolID, action.role],
    successArgs: [action.file, action.role],
    errorArgs: [action.file, action.role]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.bulkImportUsers.parseImportFile,
    () =>
      userManagementModels.parseImportFile(
        action.file,
        action.schoolID,
        action.role
      ),
    engineOptions
  )
}

export function* parseImportFileAndRefetchUsers(action) {
  yield call(callAndRefetchUsers, parseImportFile, action)
}

export function* addUsers(action) {
  const engineOptions = {
    transformer: userManagementTransform.transformAddUsersResponse,
    needToFetch: true,
    initializeArgs: [
      action.users,
      action.schoolID,
      action.ingestionID,
      action.role
    ],
    successArgs: [action.role],
    errorArgs: [action.role]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.bulkImportUsers.addUsers,
    () =>
      userManagementModels.addUsers(
        action.users,
        action.schoolID,
        action.ingestionID,
        action.role
      ),
    engineOptions
  )
}

export function* addUsersAndRefetch(action) {
  yield call(callAndRefetchUsers, addUsers, action)
}

export function* sendIngestionInviteEmail(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.ingestionID],
    successArgs: [action.ingestionID],
    errorArgs: [action.ingestionID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.bulkImportUsers.sendIngestionInviteEmail,
    () =>
      userManagementModels.sendIngestionInviteEmail(
        action.ingestionID,
        action.schoolID
      ),
    engineOptions
  )
}

export function* getStudentAccounts(action) {
  const loaded = yield select(
    fromState.getStudentAccountsByIngestionID,
    action.ingestionID
  )
  const engineOptions = {
    needToFetch: !loaded,
    transformer: userManagementTransform.transformStudentAccountResponse,
    initializeArgs: [action.ingestionID],
    successArgs: [action.ingestionID],
    errorArgs: [action.ingestionID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.studentAccounts.read,
    () =>
      userManagementModels.getStudentAccounts(
        action.ingestionID,
        action.schoolID
      ),
    engineOptions
  )
}

export function* sendInviteEmails(action) {
  const userIDsStr = action.userIDs.join()
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.schoolID, userIDsStr],
    successArgs: [userIDsStr],
    errorArgs: [userIDsStr]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.teacherAccounts.sendInviteEmails,
    () =>
      userManagementModels.sendInviteEmails(action.schoolID, action.userIDs),
    engineOptions
  )
}

export function* getStudentAccountsBatch(action) {
  const engineOptions = {
    needToFetch: true,
    transformer: userManagementTransform.transformStudentAccountResponse
  }
  yield call(
    helperSagas.fetchResourceEngine,
    userManagementActions.studentAccounts.readBatch,
    () =>
      userManagementModels.getStudentAccountsByID(
        action.schoolID,
        Array.from(action.studentIDs)
      ),
    engineOptions
  )
}
