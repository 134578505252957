import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import i18n from '../../helpers/i18n'

// Kiddom Components
import Button from '../ui/buttons/button'
import SISConfigurationOptionPresenter from './sisConfigurationOptionPresenter'
import CalenderRowIcon from '../../images/icons/calendar.svg'
import DatePicker from 'react-datepicker'
import moment from 'moment'

import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/date_select.scss'
import './sisConfigurationPresenter.scss'

const configurationTitle = i18n.t('titles.sis.syncConfiguration')
const schedulingTitle = i18n.t('titles.sis.syncScheduling')
const allGradesTitle = i18n.t('titles.allGrades')
const allSubjectsTitle = i18n.t('titles.allSubjects')
const allSchoolsTitle = i18n.t('titles.allSchools')

const configurationDescription = i18n.t(
  'passages.sis.syncConfigurationDescription'
)
const schedulingDescription = i18n.t('passages.sis.syncSchedulingDescription')
const setConfigurationsLabel = i18n.t('labels.setSISConfiguration')
const scheduledSyncLabel = i18n.t('labels.scheduledSync')
const setConfigurationsSuccessLabel = i18n.t(
  'labels.setSISConfigurationSuccess'
)

const sisConfigurationError = i18n.t('errors.invalidSISConfigurations')

function SISConfigurationPresenter({
  schools,
  grades,
  subjects,
  selectedSchoolIDs,
  selectedGradeIDs,
  selectedSubjectIDs,
  schedule,
  setConfigurations,
  sisError
}) {
  const [visible, setVisible] = useState(false)
  const [saved, setSaved] = useState(false)
  const [date, setDate] = useState(new Date(schedule))
  const [schoolIDs, setSchoolIDs] = useState(new Set(selectedSchoolIDs))
  const [gradeIDs, setGradeIDs] = useState(new Set(selectedGradeIDs))
  const [subjectIDs, setSubjectIDs] = useState(new Set(selectedSubjectIDs))

  useEffect(() => {
    if (!saved) {
      return
    }

    const hasError = sisError.get('configurations').status === 400
    const message = hasError
      ? sisConfigurationError
      : setConfigurationsSuccessLabel
    const className = hasError ? 'sis-error-toast' : 'sis-toast'
    toast(message, {
      className: className,
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true
    })

    setSaved(false)
  }, [sisError.get('configurations')])

  const onCalendarDateClick = () => {
    setVisible(!visible)
  }

  const getTomorrow = () => {
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    return tomorrow
  }

  return (
    <div className="page">
      <h1>{configurationTitle}</h1>
      <p>{configurationDescription}</p>
      <div className="sis-configuratiton-option">
        <SISConfigurationOptionPresenter
          options={schools}
          selected={schoolIDs}
          setSelected={setSchoolIDs}
          title={allSchoolsTitle}
        />
        {selectedGradeIDs && selectedGradeIDs.length > 0 && (
          <SISConfigurationOptionPresenter
            options={grades}
            selected={gradeIDs}
            setSelected={setGradeIDs}
            title={allGradesTitle}
          />
        )}
        {selectedSubjectIDs && selectedSubjectIDs.length > 0 && (
          <SISConfigurationOptionPresenter
            options={subjects}
            selected={subjectIDs}
            setSelected={setSubjectIDs}
            title={allSubjectsTitle}
          />
        )}
      </div>
      <h1>{schedulingTitle}</h1>
      <p>{schedulingDescription}</p>
      <div className="sis-configuratiton-option">
        <div className="gray-item-pill">
          {scheduledSyncLabel}:
          <strong className="scheduled-date" onClick={onCalendarDateClick}>
            {moment(date).format('dddd, MMMM DD YYYY')}
          </strong>
          <CalenderRowIcon
            className="calendar-icon"
            onClick={onCalendarDateClick}
          />
        </div>
        <DatePicker
          showPopperArrow={false}
          customInput={<div></div>}
          open={visible}
          calendarClassName="k-dateselect-calendar"
          minDate={getTomorrow()}
          onChange={(date) => {
            setDate(date)
            setVisible(false)
          }}
          selected={date}
          onClickOutside={() => {
            setVisible(false)
          }}
        />
        <Button
          label={setConfigurationsLabel}
          className="sis-button"
          buttonStyle="primary"
          onClick={() => {
            setConfigurations(date, schoolIDs, gradeIDs, subjectIDs)
            setSaved(true)
          }}
        />
      </div>
    </div>
  )
}

export default SISConfigurationPresenter
