import * as utils from './utils'

/* Data Visualization Colors */
export const white = '#FFFFFF'
export const dataExceedingA = '#2893A6'
export const dataPassNeutral = '#46B3C6'
export const dataMasteringB = '#ABD9EC'
export const dataAverageC = '#FFEA82'
export const dataApproachingD = '#FDAE61'
export const dataDevelopingFailF = '#E0374A'
export const academyBase = '#00AF91'
export const growthChangeRed = '#FC5A51'
export const growthChangeGreen = '#3AEB86'
export const coolGrey = '#929D9D'
export const neutralBkgndLight = '#F4F6F6'
export const neutralBkgndMedium = '#DADFDF'
export const neutralDisabled = '#B2B9B9'
export const inProgressState = '#5DB3FF'
export const defaultBar = '#131313'

export const hexToRGBA = (hex, alpha = 1) => {
  let r, g, b
  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
  } else {
    r = parseInt(hex.slice(1, 2).repeat(2), 16)
    g = parseInt(hex.slice(2, 3).repeat(2), 16)
    b = parseInt(hex.slice(3, 4).repeat(2), 16)
  }
  return `rgba(${r},${g},${b},${alpha})`
}

const reportingMethodColors = {
  two: [dataPassNeutral, dataDevelopingFailF],
  three: [dataExceedingA, dataAverageC, dataDevelopingFailF],
  four: [dataExceedingA, dataMasteringB, dataApproachingD, dataDevelopingFailF],
  five: [
    dataExceedingA,
    dataMasteringB,
    dataAverageC,
    dataApproachingD,
    dataDevelopingFailF
  ],
  six: [
    dataExceedingA,
    dataMasteringB,
    dataPassNeutral,
    dataAverageC,
    dataApproachingD,
    dataDevelopingFailF
  ]
}

/* Reporting Methods have colors based on the number of reporting methods */
/* This function helps gather those */
export function getReportingMethodColorsByIndex(numBuckets, indexForColor) {
  switch (numBuckets) {
    case 2:
      return reportingMethodColors.two[indexForColor]
    case 3:
      return reportingMethodColors.three[indexForColor]
    case 5:
      return reportingMethodColors.five[indexForColor]
    case 6:
      return reportingMethodColors.six[indexForColor]
    case 4:
    default:
      return reportingMethodColors.four[indexForColor]
  }
}

export const progressColor = (progress, reportingMethod = null) => {
  if (!reportingMethod) {
    return defaultProgressColor(progress)
  }
  const scales = reportingMethod.scales
  const index = utils.findReportingMethodScale(progress, scales)
  if (index < 0) {
    return (
      window.Rollbar &&
      window.Rollbar.error(
        'Progress number not encapsulated in scales of this reporting method.'
      )
    )
  }
  return getReportingMethodColorsByIndex(scales.size, index)
}

function defaultProgressColor(progress) {
  if (progress >= 75 && progress <= 100) {
    return dataExceedingA
  } else if (progress >= 50 && progress < 75) {
    return dataMasteringB
  } else if (progress >= 25 && progress < 50) {
    return dataApproachingD
  } else if (progress >= 0 && progress < 25) {
    return dataDevelopingFailF
  }
  return defaultBar
}
