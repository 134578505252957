import React from 'react'

import './menu.scss'

export const Menu = (props) => {
  return (
    <div className="menu-wrapper">
      <ul className="menu">{props.children}</ul>
    </div>
  )
}

export default Menu
