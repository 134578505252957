import { fork, takeEvery } from 'redux-saga/effects'
import * as helperSagas from './helperSagas'
import * as coreSagas from './coreSagas'
import * as actionIDs from '../actions/actionIDs'

const coreSagaWatchers = [
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentsBatch.read,
      coreSagas.fetchStudentsBatch
    )
  ),
  fork(
    helperSagas.resourceWatcher(actionIDs.classes.read, coreSagas.fetchClasses)
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.teachers.read,
      coreSagas.fetchTeachers
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.students.read,
      coreSagas.fetchStudents
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.schools.read,
      coreSagas.fetchSchoolsAndDistrict
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.subjects.read,
      coreSagas.fetchSubjects
    )
  ),
  fork(helperSagas.resourceWatcher(actionIDs.user.read, coreSagas.fetchUser)),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.user.readPrefs,
      coreSagas.fetchUserPrefs
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.user.writePrefs,
      coreSagas.writeUserPrefs
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.district.read,
      coreSagas.fetchDistrict
    )
  ),
  fork(
    helperSagas.resourceWatcher(actionIDs.grades.read, coreSagas.fetchGrades)
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.localStorage.get,
      coreSagas.getInLocalStorage
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.localStorage.set,
      coreSagas.setInLocalStorage
    )
  ),
  takeEvery(actionIDs.trackEvent.create.begin, coreSagas.trackEvent),
  takeEvery(
    actionIDs.students.readWithStatistics,
    coreSagas.getStudentsWithStatistics
  )
]

export default coreSagaWatchers
