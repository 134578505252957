import React from 'react'
import i18n from '../../helpers/i18n'
import { redirectToKiddomApp } from '../../helpers/utils'
import './header.scss'

export const LinkToKiddomClassroom = () => {
  return (
    <div className="kiddom-redirect">
      <button onClick={redirectToKiddomApp}>
        {i18n.t('labels.goToKiddomClassroom')}
      </button>
    </div>
  )
}
