export const sortUsersByNameFunc = (a, b) => {
  const aName = a.get('name') || ''
  const bName = b.get('name') || ''

  const nameComparison = aName.localeCompare(bName)
  if (nameComparison !== 0) {
    return nameComparison
  }

  // Backup to make sure the sort is stable.
  return a.get('id') - b.get('id')
}
