import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { USER_MANAGEMENT_ROLES } from '../../helpers/constants'

import * as userManagementActions from '../../store/actions/userManagementActions'
import {
  didFileImportFail,
  getActiveImportFile,
  isProcessingFileImport
} from '../../store/fromState'

import BulkUserUploadPresenter from './bulkUserUploadPresenter'

const BulkUserUploadContainer = (props) => (
  <BulkUserUploadPresenter {...props} />
)

const mapStateToProps = (state, ownProps) => ({
  selectedFile: getActiveImportFile(state),
  didFileImportFail: didFileImportFail(state, ownProps.role),
  isImportingFile: isProcessingFileImport(state, ownProps.role)
})

const mapDispatchToProps = (dispatch) => ({
  parseImportFile: (file, schoolID, role) =>
    dispatch(
      userManagementActions.bulkImportUsers.parseImportFile.begin(
        file,
        schoolID,
        role
      )
    )
})

BulkUserUploadContainer.propTypes = {
  schoolID: PropTypes.string.isRequired,
  role: PropTypes.oneOf(USER_MANAGEMENT_ROLES).isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BulkUserUploadContainer)
