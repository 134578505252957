import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import UnselectedIcon from '../../../images/icons/unselected.svg'
import SelectedIcon from '../../../images/icons/checkbox-selected.svg'

import './checkbox.scss'

const Checkbox = (props) => {
  const { id, onChange, checked } = props
  const checkboxId = `checkbox-${id}`
  return (
    <div className="select-wrapper" data-spec="select-wrapper">
      <input
        id={checkboxId}
        key={checkboxId}
        type="checkbox"
        checked={!!checked}
        className="item-select"
        tabIndex="0"
        onChange={() => onChange(id)}
      />
      <label htmlFor={checkboxId}>
        <CheckboxIcon checked={checked} />
      </label>
    </div>
  )
}

function CheckboxIcon({ checked }) {
  const uncheckedClass = classnames({ visible: !checked })
  const checkedClass = classnames({ visible: !!checked })

  return (
    <React.Fragment>
      <UnselectedIcon data-spec="unchecked" className={uncheckedClass} />
      <SelectedIcon data-spec="checked" className={checkedClass} />
    </React.Fragment>
  )
}

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {}
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func
}
export default Checkbox
