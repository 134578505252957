import React from 'react'

import i18n from '../../helpers/i18n'

const StudentClassOverview = (props) => {
  const studentClassOverviewLabel = i18n.t('titles.studentClassOverview')
  return (
    <div className="student-overview page">
      <h2>{studentClassOverviewLabel}</h2>
      <h3>{props.match.params.studentID}</h3>
      <h3>{props.match.params.classID}</h3>
    </div>
  )
}

export default StudentClassOverview
