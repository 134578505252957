import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as fromState from '../../store/fromState'
import * as userManagementActions from '../../store/actions/userManagementActions'
import { ACCOUNT_TYPES, ROLE } from '../../helpers/constants'
import { TEACHERS } from '../../store/models/resources'

import withToJS from '../hoc/withToJS'
import UserManagementPresenter from './userManagementPresenter'

// HOC to that layers in state for account type. This allows us to control the
// details of all user management components from this top-level container based
// on the supplied account type.
const withAccountType = (Component) =>
  class AddAccountType extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        accountType: ACCOUNT_TYPES.STAFF
      }
    }

    setAccountType = (accountType) => {
      this.setState({ accountType })
    }

    render() {
      return (
        <Component
          accountType={this.state.accountType}
          setAccountType={this.setAccountType}
        />
      )
    }
  }

const WrappedPresenter = withToJS(UserManagementPresenter)
const UserManagementContainer = (props) => <WrappedPresenter {...props} />

const getUsersByRole = {
  [ROLE.TEACHER]: fromState.getTeachersBySchoolID,
  [ROLE.STUDENT]: fromState.getStudentIDsBySchoolID
}
const getUsers = (state, schoolID, role) =>
  getUsersByRole[role](state, schoolID)

const hasLoaded = (state, props) => {
  if (props.accountType.role === ROLE.STUDENT) {
    return !fromState.isFetchingStudents(state)
  }
  const schoolID = props.match.params.schoolID
  return (
    fromState.getIDsList(state, TEACHERS, schoolID).size ||
    fromState.didFetchSchoolID(state, TEACHERS, schoolID)
  )
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const role = ownProps.accountType.role
  const users = getUsers(state, schoolID, role)
  return {
    hasLoaded: hasLoaded(state, ownProps),
    numUsersAdded: fromState.getImportNumUsersAdded(state, role),
    reviewUsers: fromState.getImportErrors(state, role),
    ingestionID: fromState.getImportIngestionID(state, role),
    hasUsers: !!users && !!users.size,
    didFileImportFail: fromState.didFileImportFail(state, role),
    isImportingFile: fromState.isProcessingFileImport(state, role)
  }
}

export const mapDispatchToProps = (dispatch) => ({
  registerUsers: (users, schoolID, ingestionID, role) => {
    dispatch(
      userManagementActions.bulkImportUsers.addUsers.begin(
        users,
        schoolID,
        ingestionID,
        role
      )
    )
    dispatch(userManagementActions.bulkImportUsers.clearImportErrors(role))
  },
  clearErrors: (role) =>
    dispatch(userManagementActions.bulkImportUsers.clearImportErrors(role)),
  clearImport: (role) =>
    dispatch(userManagementActions.bulkImportUsers.clearImport(role))
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const role = ownProps.accountType.role
  const schoolID = ownProps.match.params.schoolID
  const ingestionID = stateProps.ingestionID
  const registerUsers = (users) =>
    dispatchProps.registerUsers(users, schoolID, ingestionID, role)

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    registerUsers
  }
}

export default withAccountType(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(UserManagementContainer)
  )
)
