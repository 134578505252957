import Immutable from 'immutable'
import { all, call, put, select } from 'redux-saga/effects'
import * as coreActions from '../actions/coreActions'
import * as coreModels from '../models/coreModels'
import * as localStorageClient from '../models/localStorageClient'
import * as resources from '../models/resources'
import * as helperSagas from './helperSagas'
import * as coreTransform from '../models/coreTransform'
import * as adminTransform from '../models/adminTransform'
import * as adminModels from '../models/adminModels'
import * as fromState from '../fromState'

/* Saga Workers */
export function* fetchClasses(action) {
  yield put(coreActions.classes.read.initialize())
  const { classesResponse, statisticsResponse } = yield all({
    classesResponse: call(coreModels.getClasses, action.schoolID),
    statisticsResponse: call(coreModels.getClassesStatistics, action.schoolID)
  })
  const isClassError = yield call(
    helperSagas.sagaErrorHandling,
    classesResponse,
    coreActions.classes.read,
    [],
    'Error Fetching Classes from API:'
  )
  if (isClassError) {
    return
  }
  const isStatisticsError = yield call(
    helperSagas.sagaErrorHandling,
    statisticsResponse,
    coreActions.classes.read,
    [],
    'Error Fetching ClassesStatistics from API:'
  )
  if (isStatisticsError) {
    return
  }

  const transformedClasses = coreTransform.classes(classesResponse.response)
  const transformedStats = coreTransform.classesStatistics(
    statisticsResponse.response
  )
  yield put(
    coreActions.classes.read.success(
      transformedClasses,
      transformedStats,
      action.schoolID
    )
  )
}

export function* fetchDistrict(action) {
  const districtName = yield select(
    fromState.getDistrictName,
    action.districtID
  )
  const districtLoaded = !!districtName
  const engineOptions = {
    needToFetch: !districtLoaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.district.read,
    () => coreModels.getDistrict(action.districtID),
    engineOptions
  )
}

export const fetchUser = () =>
  helperSagas.fetchResource(coreActions.user.read, coreModels.getUser)

export function* fetchUserPrefs() {
  // Ensure user exists in state before fetching user prefs, since prefs
  // will live inside user
  const user = yield select(fromState.getUser)

  if (!user) {
    yield call(fetchUser)
  }
  const userPrefLoaded = yield select(fromState.getUserPreferences)
  const engineOptions = { needToFetch: !userPrefLoaded }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.user.readPrefs,
    coreModels.getUserPrefs,
    engineOptions
  )
}

export function* writeUserPrefs(action) {
  // Do not proceed if shouldSetUserPref is false
  if (!action.shouldSetUserPref) {
    return
  }
  const originalPrefs = yield select(fromState.getUserPreferences)
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.preferences],
    errorArgs: [action.preferences, originalPrefs]
  }
  const originalValue = originalPrefs.get(action.preferences[0].key)
  // If userPreference already has this value set, do not write again
  if (!originalValue || originalValue !== action.preferences[0].value) {
    yield call(
      helperSagas.fetchResourceEngine,
      coreActions.user.writePrefs,
      () => coreModels.writeUserPrefs(action.preferences),
      engineOptions
    )
  }
}

export function* fetchSubjects() {
  const loaded = yield select(fromState.resourcesLoaded, [resources.SUBJECTS])
  const engineOptions = {
    transformer: coreTransform.subjects,
    needToFetch: !loaded
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.subjects.read,
    coreModels.getSubjects,
    engineOptions
  )
}

export function* fetchGrades() {
  const grades = yield select(fromState.getList, resources.GRADES)
  const loaded = grades && grades.size

  const engineOptions = {
    transformer: coreTransform.grades,
    needToFetch: !loaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.grades.read,
    coreModels.getGrades,
    engineOptions
  )
}

export function* fetchTeachers(action) {
  const { schoolID, shouldForceFetch } = action
  const existing = yield select(fromState.getTeachersBySchoolID, schoolID)
  const loaded = !shouldForceFetch && !!existing && existing.size
  const engineOptions = {
    transformer: (teachers) => coreTransform.teachers(teachers, schoolID),
    successArgs: [schoolID],
    needToFetch: !loaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.teachers.read,
    () => coreModels.getTeachers(schoolID),
    engineOptions
  )
}

// fetching a single student
export const fetchStudent = (action) =>
  helperSagas.fetchResource(
    coreActions.student.read,
    () => coreModels.getStudent(action.schoolID, action.studentID),
    (student) => coreTransform.student(student[0])
  )

export function* fetchStudents(action) {
  const pagination = yield select(fromState.getStudentsPagination)
  const hasPage = pagination.getIn(['pages', action.offset.toString()])
  const sameSearch = pagination.get('query') === action.query
  const hasLoaded = hasPage && sameSearch
  if (hasLoaded && !action.shouldForceFetch) {
    return
  }
  yield put(coreActions.students.read.initialize())
  const studentsResponse = yield call(
    coreModels.getStudents,
    action.schoolID,
    action.query,
    action.offset,
    action.limit
  )
  const isStudentsError = yield call(
    helperSagas.sagaErrorHandling,
    studentsResponse,
    coreActions.students.read.error
  )
  if (isStudentsError) {
    return
  }

  const { students, totalPages } = coreTransform.students(
    studentsResponse.response
  )

  yield put(coreActions.students.read.success(action.schoolID, students))

  const studentIDs = students.map((s) => s.id)
  yield put(
    coreActions.students.read.updatePagination(
      studentIDs,
      action.query,
      action.offset,
      totalPages,
      action.schoolID
    )
  )
}

function* fetchStudentsAssignmentStatistics(action) {
  const checkLoaded = (state, studentIDs) => {
    studentIDs.every((id) => {
      const student = fromState.getResourceByID(state, id)
      return student && student.get('assignmentsDue') !== undefined
    })
  }
  const isLoaded = yield select(checkLoaded, action.studentIDs)
  const engineOptions = {
    transformer: coreTransform.studentsStatistics,
    needToFetch: !isLoaded
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.students.readAssignmentStatistics,
    () =>
      coreModels.getStudentsAssignmentStats(action.schoolID, action.studentIDs),
    engineOptions
  )
}

function* fetchStudentsPerformance(action) {
  const checkLoaded = (state, studentIDs) => {
    studentIDs.every((id) => {
      const student = fromState.getResourceByID(state, id)
      return student && student.get('studentMastery') !== undefined
    })
  }
  const isLoaded = yield select(checkLoaded, action.studentIDs)
  const engineOptions = {
    transformer: adminTransform.studentPerformances,
    needToFetch: !isLoaded
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.students.readPerformance,
    () =>
      adminModels.getStudentAchievementByID(action.schoolID, action.studentIDs),
    engineOptions
  )
}

export function* getStudentsWithStatistics(action) {
  yield call(fetchStudents, action)
  const students = yield select(fromState.getStudentsInPage, action.offset)
  const studentIDs = students.map((s) => s.get('id'))
  const schoolID = action.schoolID
  yield all([
    call(fetchStudentsAssignmentStatistics, { schoolID, studentIDs }),
    call(fetchStudentsPerformance, { schoolID, studentIDs })
  ])
}

export function* fetchStudentsBatch(action) {
  let toFetch = []
  if (action.studentIDs) {
    const existingStudentIDs = yield select(
      fromState.getIDsList,
      resources.STUDENTS
    )
    toFetch = Immutable.Set(action.studentIDs).subtract(existingStudentIDs)
  }
  const engineOptions = {
    needToFetch: toFetch.size,
    transformer: coreTransform.studentsBatch
  }

  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.studentsBatch.read,
    () => adminModels.getStudentsBatch(action.schoolID, toFetch),
    engineOptions
  )
}

export function* fetchSchools(action) {
  const schools = yield select(fromState.getList, resources.SCHOOLS)
  const schoolsLoaded = schools && schools.size

  const engineOptions = {
    transformer: coreTransform.schools,
    needToFetch: !schoolsLoaded || action.force
  }

  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.schools.read,
    coreModels.getSchools,
    engineOptions
  )
}

export function* fetchSchoolsAndDistrict(action) {
  yield call(fetchSchools, action)
  let districtID = yield select(fromState.getDistrictIDFromAnySchool)
  if (!districtID) {
    districtID = yield select(fromState.getUserProp, 'district_id')
  }

  if (districtID) {
    yield call(fetchDistrict, { districtID })
  }
}

export const trackEvent = (action) =>
  helperSagas.fetchResourceEngine(
    coreActions.trackEvent.create,
    () => coreModels.trackEvent(action.eventName, action.data),
    { needToFetch: true }
  )

export function* getInLocalStorage(action) {
  const value = yield call(localStorageClient.getFromStore, action.key)
  yield put(coreActions.localStorage.get.success(action.key)(action.key, value))
}

export function* setInLocalStorage(action) {
  yield call(localStorageClient.setInStore, action.key, action.value)
  yield put(
    coreActions.localStorage.set.success(action.key)(action.key, action.value)
  )
}
