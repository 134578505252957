import { combineReducers } from 'redux-immutable'
import admin from './adminReducers'
import classes from './classesReducer'
import courses from './coursesReducer'
import curriculum from './curriculumReducer'
import userManagement from './userManagementReducer'
import district from './districtReducer'
import grades from './gradesReducer'
import schools from './schoolsReducer'
import students from './studentsReducer'
import schoolSubjectGroups from './subjectGroupsReducer'
import subjects from './subjectsReducer'
import teachers from './teachersReducer'
import user from './userReducer'
import skills from './skillsReducer'
import skillGroups from './skillGroupsReducer'
import assets from './assetsReducer'
import sisIntegration from './sisIntegrationReducer'

const appReducers = combineReducers({
  admin,
  assets,
  classes,
  courses,
  curriculum,
  district,
  grades,
  schools,
  schoolSubjectGroups,
  skills,
  skillGroups,
  students,
  subjects,
  teachers,
  user,
  userManagement,
  sisIntegration
})
export default appReducers
