import React from 'react'
import PropTypes from 'prop-types'

import CircledIcon from '../../common/circledIcon'
import LeftIcon from '../../../images/icons/arrow-previous.svg'
import RightIcon from '../../../images/icons/arrow-next.svg'

import './leftRight.scss'

const LeftRight = (props) => {
  const { leftClick, rightClick } = props
  return (
    <div className="left-right-buttons" data-spec="left-right-buttons">
      <button onClick={leftClick}>
        <CircledIcon>
          <LeftIcon />
        </CircledIcon>
      </button>
      <button onClick={rightClick}>
        <CircledIcon>
          <RightIcon />
        </CircledIcon>
      </button>
    </div>
  )
}

LeftRight.propTypes = {
  leftClick: PropTypes.func.isRequired,
  rightClick: PropTypes.func.isRequired
}

export default LeftRight
