import { fork } from 'redux-saga/effects'

import { resourceWatcher } from './helperSagas'
import {
  sisIntegration,
  sisCredentials,
  sisSchoolMatches,
  sisConfigurations
} from '../actions/actionIDs'
import * as sisIntegrationSagas from './sisIntegrationSagas'

const sisIntegrationSagaWatchers = [
  fork(
    resourceWatcher(
      sisIntegration.sendSISCredentials,
      sisIntegrationSagas.sendSISCredentials
    )
  ),
  fork(
    resourceWatcher(
      sisCredentials.read,
      sisIntegrationSagas.fetchSISCredentials
    )
  ),
  fork(
    resourceWatcher(sisSchoolMatches.read, sisIntegrationSagas.fetchSISMatches)
  ),
  fork(
    resourceWatcher(
      sisSchoolMatches.update,
      sisIntegrationSagas.updateSISMatches
    )
  ),
  fork(
    resourceWatcher(
      sisConfigurations.read,
      sisIntegrationSagas.fetchSISConfigurations
    )
  ),
  fork(
    resourceWatcher(
      sisConfigurations.update,
      sisIntegrationSagas.updateSISConfigurations
    )
  )
]

export default sisIntegrationSagaWatchers
