import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import i18n from '../../helpers/i18n'
import { generateErrorMessageAndRetry } from '../../helpers/utils'
import * as routes from '../../routes/routes'
import { courseShape } from '../../helpers/propShapes/courseShape'
import { errorShape } from '../../helpers/propShapes/common'
import {
  LoadingBadge,
  SavedBadge,
  ErrorBadge,
  NewBadge,
  RetryingAfterErrorBadge
} from '../common/badges/badges'
import CourseFormContainer from './courseFormContainer'
import EditIcon from '../../images/icons/edit.svg'
import DeleteIcon from '../../images/icons/delete.svg'
import OptionMenu from '../ui/optionMenu/optionMenu'
import WithUndo from '../hoc/withUndo'
import './courseListViewItem.scss'

export class CourseListViewItemPresenter extends WithUndo {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false
    }
  }

  getBadge = () => {
    const { error, course, isDeleting, isFetching } = this.props
    if (!course && isFetching) {
      return <LoadingBadge />
    }
    if (course.tempID) {
      return <SavedBadge />
    }
    if (isDeleting && error) {
      return <RetryingAfterErrorBadge />
    }
    if (error) {
      return <ErrorBadge />
    }
    if (course.isNew) {
      return <NewBadge />
    }
    return null
  }

  getListItem = () => {
    const { course, isFetching } = this.props
    const classNames = classnames('badge-container', {
      badgegrow: isFetching || course.tempID
    })
    return (
      <div key="course-name" className="course-name">
        <div className={classNames}>{this.getBadge()}</div>
        <h3>{course.name}</h3>
      </div>
    )
  }

  showOptionsMenu() {
    return (
      <OptionMenu
        data-spec="option-menu"
        key="option-menu"
        options={[
          {
            name: 'edit-course',
            label: i18n.t('labels.editCourse'),
            onClick: () => this.toggleCourseForm(),
            icon: <EditIcon />
          },
          {
            name: 'delete-course',
            label: 'Delete',
            onClick: () => this.props.deleteCourse(),
            icon: <DeleteIcon />
          }
        ]}
      />
    )
  }

  linkToCurriculum = () => {
    const { subjectGroupID, course } = this.props
    return routes.CURRICULUM_COURSE_PATH_ROOT(subjectGroupID, course.id)
  }

  renderListItemAsLink = () => {
    return (
      <Link data-spec="course-link" key="link" to={this.linkToCurriculum()}>
        {this.getListItem()}
      </Link>
    )
  }

  renderListItem = () => {
    const { error, course, isFetching } = this.props
    if (course && course.tempID) {
      return this.renderListItemAsLink()
    }
    if (error || isFetching) {
      return this.getListItem()
    }
    return [this.renderListItemAsLink(), this.showOptionsMenu()]
  }

  // showWithUndo used by withUndo class
  showWithUndo() {
    const { course } = this.props
    return (
      <div className="course-list-view-item" data-spec="course-list-view-item">
        <div key="course-name" className={classnames('course-name', 'deleted')}>
          <h3>{course.name}</h3>
        </div>
      </div>
    )
  }

  getError = (message) => {
    return (
      <div key="error" data-spec="delete-course-error" className="error">
        {message}
      </div>
    )
  }

  showError = () => {
    const {
      isDeleting,
      shouldRetry,
      retryCountdown,
      error,
      course,
      deleteCourse
    } = this.props
    if (!error || !course) {
      return null
    }
    if (isDeleting) {
      return this.getError(i18n.t('errors.retrying'))
    }

    const message = i18n.t('errors.unableToDeleteCourse')
    const useRetry = !isDeleting && shouldRetry && retryCountdown
    const [, ...retryBody] = generateErrorMessageAndRetry(
      message,
      deleteCourse,
      retryCountdown,
      useRetry
    )
    return this.getError(retryBody)
  }

  toggleCourseForm = (id, isSubmitted) => {
    if (this.props.course) {
      this.setState({
        isOpen: !this.state.isOpen
      })
    }
    if (!isSubmitted) {
      this.props.removeForm && this.props.removeForm(id)
    }
  }

  // showWithoutUndo used by withUndo class
  showWithoutUndo() {
    const { course, subjectGroupID } = this.props
    if (this.state.isOpen && course) {
      return (
        <CourseFormContainer
          key={course.id}
          formID={course.id}
          removeForm={this.toggleCourseForm}
          schoolSubjectGroupID={subjectGroupID}
          addForm={this.props.addForm}
          data-spec="course-form-container"
        />
      )
    }
    return (
      <div className="course-list-view-item-container">
        <div
          key="course-list-view-item"
          className="course-list-view-item"
          data-spec="course-list-view-item"
        >
          {this.renderListItem()}
        </div>
        {this.showError()}
      </div>
    )
  }
}

CourseListViewItemPresenter.defaultProps = {
  error: null,
  isDeleting: null,
  shouldRetry: null,
  courseName: null,
  retryCountdown: null,
  subjectGroupID: null,
  isFetching: false,

  deleteCourse: null
}

CourseListViewItemPresenter.propTypes = {
  error: errorShape,
  course: courseShape.isRequired,
  isDeleting: PropTypes.bool,
  shouldRetry: PropTypes.bool,
  courseName: PropTypes.string,
  retryCountdown: PropTypes.number,
  subjectGroupID: PropTypes.string.isRequired,
  isFetching: PropTypes.bool,

  deleteCourse: PropTypes.func.isRequired
}

export default CourseListViewItemPresenter
