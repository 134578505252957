import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import './components/common/errortoast/errorToast.scss'

import withDataLoading from './components/hoc/withDataLoading'
import { USER, SCHOOLS } from './store/models/resources'
import * as utils from './helpers/utils'
import * as fromState from './store/fromState'
import * as coreActions from './store/actions/coreActions'
import DistrictOverview from './components/districtOverview'
import OnboardingLandingContainer from './components/onboarding/onboardingLandingContainer'
import OnboardingLoadingContainer from './components/onboarding/onboardingLoadingContainer'
import SchoolRouter from './routes/schoolRouter'
import UserManagementRouter from './routes/userManagementRouter'
import SISIntegrationRouter from './routes/sisIntegrationRouter'
import Header from './components/header/header'

import i18n from './helpers/i18n'
import * as SessionClient from './helpers/sessionClient'
import { startIntercom } from './helpers/intercomClient'
import * as routes from './routes/routes'
import { FEATURE_ACCESS_ID } from './helpers/constants'
import useFeatures from './hooks/useFeatures'
import { HelpScoutBeacon } from './components/help-scout-beacon/help-scout-beacon'
import FeatureContext from './context/featureContext'

const KiddomIcon = '../../../../../images/illustrations/kiddom.png'

const Pages = () => (
  <div className="underhead-container">
    <Switch>
      <Route exact path={routes.HOME_PATH} component={DistrictOverview} />
      <Route path="/schools" component={SchoolRouter} />
      <Route
        path={routes.USER_MANAGEMENT_ROUTE}
        component={UserManagementRouter}
      />
      <Route
        path={routes.SIS_INTEGRATION_ROUTE}
        component={SISIntegrationRouter}
      />
    </Switch>
  </div>
)

const Footer = (props) => {
  if (!props) {
    return null
  }
  const poweredBy = i18n.t('labels.poweredByKiddom')
  const { isUserInFreeTrial } = props
  const footerClasses = classnames('footer', {
    'free-trial': isUserInFreeTrial
  })

  return (
    <div className={footerClasses}>
      <img
        src={KiddomIcon}
        data-spec="kiddom-icon"
        alt={i18n.t('imageAlt.kiddomLogo')}
        className="kiddom-icon"
      />
      <p>{poweredBy}</p>
    </div>
  )
}

class App extends React.Component {
  async componentDidMount() {
    try {
      // checking if the user is logged in
      const { error } = await SessionClient.check()
      if (error) {
        throw error
      }
    } catch (error) {
      utils.redirectToClassroom()
    }
  }

  componentDidUpdate(prevProps) {
    this.maybeStartIntercom(prevProps)
    this.maybeAnalytics(prevProps)
    this.checkForRedirect()
  }

  maybeStartIntercom(prevProps) {
    if (
      prevProps.userEmail !== this.props.userEmail &&
      prevProps.userAnalyticsID !== this.props.userAnalyticsID
    ) {
      startIntercom(this.props.userEmail, this.props.userAnalyticsID)
    }
  }

  maybeAnalytics(prevProps) {
    if (
      window.analytics &&
      prevProps.userEmail !== this.props.userEmail &&
      prevProps.userAnalyticsID !== this.props.userAnalyticsID
    ) {
      window.analytics.identify(this.props.userAnalyticsID, {
        name: this.props.userFullName,
        email: this.props.userEmail
      })
      window.analytics.page()
    }
  }

  checkForRedirect() {
    const schoolsError =
      this.props.schoolsError && this.props.schoolsError.status === 401
    const userError =
      this.props.userError && this.props.userError.status === 401

    if (this.props.hasLoaded) {
      const authorized = utils.isAuthorizedToSeeAcademy(this.props.userRoles)
      if (!authorized || !this.props.hasAccess) {
        utils.redirectToClassroom(false)
      }
    } else if (userError && schoolsError) {
      utils.redirectToClassroom()
    }
  }

  render() {
    const HeaderComponent = ({
      props,
      stickyMobile,
      subheaderTitle = null,
      useSchoolSubheader = true
    }) => {
      return (
        <Header
          {...props}
          stickyMobile={stickyMobile}
          subheaderTitle={subheaderTitle}
          useSchoolSubheader={useSchoolSubheader}
        />
      )
    }
    return (
      <div className="app-container">
        <HelpScoutBeacon />
        <ToastContainer
          autoClose={false}
          closeButton={false}
          position="bottom-center"
          className="error-toast-container"
          toastClassName="error-toast"
          toastId="error-toast"
        />
        <Switch>
          <Route
            exact
            path={routes.ONBOARDING}
            component={OnboardingLandingContainer}
          />
          <Route
            exact
            path={routes.ONBOARDING_LOADING}
            component={OnboardingLoadingContainer}
          />
          <Route
            path={routes.CURRICULUM_COURSE_ROUTE}
            render={(props) => HeaderComponent({ props, stickyMobile: false })}
          />
          <Route
            path={routes.SCHOOL_PATH_PREFIX}
            render={(props) => HeaderComponent({ props, stickyMobile: true })}
          />
          <Route
            path={routes.USER_MANAGEMENT_ROUTE}
            render={(props) => HeaderComponent({ props, stickyMobile: true })}
          />
          <Route
            path={routes.SIS_INTEGRATION_ROUTE}
            render={(props) =>
              HeaderComponent({
                props,
                stickyMobile: true,
                subheaderTitle: i18n.t('userActions.sisIntegration'),
                useSchoolSubheader: false
              })
            }
          />
          <Route
            path={routes.HOME_PATH}
            render={(props) =>
              HeaderComponent({
                props,
                stickyMobile: true,
                useSchoolSubheader: false
              })
            }
          />
        </Switch>
        <Switch>
          <Route exact path={routes.ONBOARDING_LOADING} component={null} />
          <Route exact path={routes.ONBOARDING} component={null} />
          <Route path="/" render={Pages} />
        </Switch>
        <Switch>
          <Route exact path={routes.ONBOARDING_LOADING} component={null} />
          <Route exact path={routes.ONBOARDING} component={null} />
          <Route path="/" render={() => Footer(this.props)} />
        </Switch>
      </div>
    )
  }
}

export const mapStateToProps = (state) => ({
  userRoles: fromState.getUserProp(state, 'roles_list'),
  hasAccess: fromState.featureCheck(state, FEATURE_ACCESS_ID.fullAcademy),
  isUserInFreeTrial: fromState.userInFeature(
    state,
    FEATURE_ACCESS_ID.fullAcademy
  ),
  userID: fromState.getUserProp(state, 'id'),
  userEmail: fromState.getUserProp(state, 'email'),
  userAnalyticsID: fromState.getUserProp(state, 'analytics_id'),
  userFullName: fromState.getUserProp(state, 'name'),
  userError: fromState.getError(state, USER),
  schools: fromState.getList(state, SCHOOLS),
  schoolsError: fromState.getError(state, SCHOOLS)
})

const generateDataLoaders = (_props) => [
  coreActions.schools.read.begin(),
  coreActions.user.read.begin(),
  coreActions.user.readPrefs.begin()
]

const generateHasLoadedCheckers = (_props) => ({
  hasLoaded: [
    [fromState.resourcesLoaded, [[SCHOOLS]]],
    [fromState.userDataLoaded, [[]]],
    [fromState.userPrefDataLoaded, [[]]]
  ]
})

const reloadProps = ['userID', 'schools']

const withUseFeatures = (Component) => {
  const WrappedComponent = (props) => {
    const useFeat = useFeatures()
    return (
      <FeatureContext.Provider value={useFeat}>
        <Component {...props} />
      </FeatureContext.Provider>
    )
  }
  return WrappedComponent
}

export default withRouter(
  withDataLoading(
    connect(mapStateToProps)(withUseFeatures(App)),
    generateDataLoaders,
    generateHasLoadedCheckers,
    reloadProps
  )
)
