import { randomInt } from '../../helpers/utils'
import moment from 'moment'
import Immutable from 'immutable'
import {
  generateTimeframeIDFromDates,
  dateRangeModel
} from '../../helpers/chartDateHelpers'

const startLastMonth = dateRangeModel.month.lastRange.start
const startThisMonth = dateRangeModel.month.thisRange.start
const endThisMonth = dateRangeModel.month.thisRange.end
const minus30Days = dateRangeModel.month.lastXRange.start
const today = dateRangeModel.month.lastXRange.end

const startLastWeek = dateRangeModel.week.lastRange.start
const startThisWeek = dateRangeModel.week.thisRange.start
const endThisWeek = dateRangeModel.week.thisRange.end
const endLastWeek = dateRangeModel.week.lastRange.end
const last7Days = dateRangeModel.week.lastXRange.start

const startLastYear = dateRangeModel.year.lastRange.start
const startThisYear = dateRangeModel.year.thisRange.start
const endThisYear = dateRangeModel.year.thisRange.end
const endLastYear = dateRangeModel.year.lastRange.end
const last12Months = dateRangeModel.year.lastXRange.start

const startLastTerm = dateRangeModel.term.lastRange.start
const startThisTerm = dateRangeModel.term.thisRange.start
const endThisTerm = dateRangeModel.term.thisRange.end
const endLastTerm = dateRangeModel.term.lastRange.end
const last12Weeks = dateRangeModel.term.lastXRange.start

function distributedRandomByKeys(amount, keys, topHeavy) {
  const output = {}
  keys.forEach((key, index) => {
    let min = index * 25
    let max = (index + 1) * 25
    if (topHeavy && index === keys.length - 1) {
      min = 100
      max = 100
    }
    output[key] = {
      name: key,
      values: new Array(amount)
        .fill(0)
        .map((_label) => Math.floor(randomInt(min, max)))
    }
  })
  return output
}

function monthTimeFrames(endDate, startDate) {
  const length = moment(endDate).diff(startDate, 'days')
  return {
    start: startDate,
    end: endDate,
    length: parseInt(length, 10),
    type: 'month'
  }
}

function monthOfData(endDate, startDate, keys, weighted, studentData) {
  const timeFrame = monthTimeFrames(endDate, startDate)
  let dataByKey = distributedRandomByKeys(timeFrame.length, keys, weighted)
  if (studentData) {
    dataByKey = { reportingBuckets: Object.values(dataByKey) }
  }
  return { timeFrame, ...dataByKey }
}

function monthsOfData(_amount, keys, weighted, studentData) {
  // prefill months with last 30 days, then this month
  const last30DaysID = generateTimeframeIDFromDates(minus30Days, today)
  const thisMonthID = generateTimeframeIDFromDates(startThisMonth, endThisMonth)
  const lastMonthID = generateTimeframeIDFromDates(
    startLastMonth,
    startThisMonth
  )
  const data = {}
  data[last30DaysID] = monthOfData(
    today,
    minus30Days,
    keys,
    weighted,
    studentData
  )
  data[thisMonthID] = monthOfData(
    endThisMonth,
    startThisMonth,
    keys,
    weighted,
    studentData
  )
  data[lastMonthID] = monthOfData(
    startThisMonth,
    startLastMonth,
    keys,
    weighted,
    studentData
  )
  return data
}

function weekTimeFrame(start, end) {
  const timeFrame = { start, end, type: 'week' }
  return { timeFrame }
}

function weeksOfData(amount, keys, weighted, _studentData) {
  // prefill the weeks with this week, and last 7 days,
  const randomWeekOfData = distributedRandomByKeys.bind(
    this,
    amount,
    keys,
    weighted
  )

  const weeks = {}
  weeks[`${startThisWeek},${endThisWeek}`] = {
    ...weekTimeFrame(startThisWeek, endThisWeek),
    ...distributedRandomByKeys(7, keys, weighted)
  }
  weeks[`${last7Days},${today}`] = {
    ...weekTimeFrame(last7Days, today),
    ...randomWeekOfData()
  }
  weeks[`${startLastWeek},${endLastWeek}`] = {
    ...weekTimeFrame(startLastWeek, endLastWeek),
    ...randomWeekOfData()
  }
  return weeks
}

function termsOfData(amount, keys, weighted, _studentData) {
  const terms = {}
  terms[`${last12Weeks},${today}`] = {
    timeFrame: { start: last12Weeks, end: today, type: 'term', length: 12 },
    ...distributedRandomByKeys(amount, keys, weighted)
  }
  terms[`${startThisTerm},${endThisTerm}`] = {
    timeFrame: {
      start: startThisTerm,
      end: endThisTerm,
      type: 'term',
      length: 12
    },
    ...distributedRandomByKeys(amount, keys, weighted)
  }
  terms[`${startLastTerm},${endLastTerm}`] = {
    timeFrame: {
      start: startLastTerm,
      end: endLastTerm,
      type: 'term',
      length: 12
    },
    ...distributedRandomByKeys(amount, keys, weighted)
  }
  return terms
}

function yearsOfData(amount, keys, weighted, _studentData) {
  const years = {}
  years[`${last12Months},${today}`] = {
    timeFrame: { start: last12Months, end: today, type: 'year', length: 12 },
    ...distributedRandomByKeys(amount, keys, weighted)
  }
  years[`${startThisYear},${endThisYear}`] = {
    timeFrame: {
      start: startThisYear,
      end: endThisYear,
      type: 'year',
      length: 12
    },
    ...distributedRandomByKeys(amount, keys, weighted)
  }
  years[`${startLastYear},${endLastYear}`] = {
    timeFrame: {
      start: startLastYear,
      end: endLastYear,
      type: 'year',
      length: 12
    },
    ...distributedRandomByKeys(amount, keys, weighted)
  }

  return years
}

function generateMockDataFromKeys(keys, weighted, studentData) {
  return Immutable.fromJS({
    year: yearsOfData(12, keys, weighted, studentData),
    term: termsOfData(12, keys, weighted, studentData),
    month: monthsOfData(31, keys, weighted, studentData),
    week: weeksOfData(7, keys, weighted, studentData)
  })
}

export const teacherActivityMock = generateMockDataFromKeys([
  'assignments_graded',
  'assignments_assigned',
  'usage'
])

export const studentAchievementMock = generateMockDataFromKeys(
  ['Developing', 'Approaching', 'Mastery', 'Exceeds'],
  true,
  true
)

export const contentUsageMock = generateMockDataFromKeys([
  'assignments_free',
  'assignments_paid',
  'assignments_total'
])
