import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import CurriculumDirectoryPresenter from './curriculumDirectoryPresenter'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'

import {
  getSchoolName,
  getSubjectGroupsForSchool,
  isSchoolFreeTrialAndDemo,
  isUserFreeTrial,
  resourcesLoaded,
  subjectGroupsLoadedForSchool,
  userPrefDataLoaded,
  getUserPreference,
  getFreeTrialSchoolIDs
} from '../../store/fromState'

import { COURSES_VIEW } from '../../store/eventIDs'
import { DEMO_SCHOOL_CURRICULUM_VIEWED } from '../../store/userPreferences'

import * as coreActions from '../../store/actions/coreActions'
import * as curriculumActions from '../../store/actions/curriculumActions'
import * as resources from '../../store/models/resources'

const initiatedTour = 'initiatedCurriculumTour'
const WrappedPresenter = withToJS(CurriculumDirectoryPresenter)
const CurriculumDirectoryContainer = (props) => {
  useEffect(() => {
    if (
      props.hasLoaded &&
      props.hasInitiatedTour !== 'true' &&
      props.schoolID === props.realSchoolID
    ) {
      props.initiatedIntercomTour()
      window.Intercom('startTour', window.INTERCOM_TOURS.curriculum)
    }
  }, [props.hasLoaded, props.hasInitiatedTour, props.schoolID])

  return <WrappedPresenter {...props} />
}

export const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    trackCurriculumDirectoryView: (schoolName) =>
      dispatch(
        coreActions.trackEvent.create.begin(COURSES_VIEW, { schoolName })
      ),
    checkAndSetUserPref: (shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: DEMO_SCHOOL_CURRICULUM_VIEWED,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    },
    initiatedIntercomTour: () =>
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: initiatedTour,
              value: 'true'
            }
          ],
          true // shouldSetUserPref
        )
      )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const schoolName = getSchoolName(state, schoolID)
  const isLoading = !ownProps.hasLoaded
  const subjectGroupsForSchool = !isLoading
    ? getSubjectGroupsForSchool(state, schoolID)
    : Immutable.List()
  const renderSidebar = isUserFreeTrial(state)
  const shouldSetUserPref = isSchoolFreeTrialAndDemo(state, schoolID)
  const realSchoolID = getFreeTrialSchoolIDs(state).realSchoolID
  return {
    isLoading: !ownProps.hasLoaded,
    renderSidebar,
    schoolID,
    schoolName,
    subjectGroups: subjectGroupsForSchool,
    shouldSetUserPref,
    userPrefDataLoaded: userPrefDataLoaded(state),
    hasInitiatedTour: getUserPreference(state, initiatedTour),
    realSchoolID
  }
}

const generateDataLoaders = (props) => [
  coreActions.subjects.read.begin(),
  curriculumActions.fetchSchoolSubjectsAndCourses(props.match.params.schoolID)
]

const generateHasLoadedCheckers = (props) => ({
  hasLoaded: [
    [resourcesLoaded, [[resources.SUBJECTS]]],
    [subjectGroupsLoadedForSchool, [props.match.params.schoolID]],
    [userPrefDataLoaded, [[]]]
  ]
})

const reloadProps = [(props) => props.match.params.schoolID]

CurriculumDirectoryContainer.propTypes = {
  schoolID: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default withDataLoading(
  connect(mapStateToProps, mapDispatchToProps)(CurriculumDirectoryContainer),
  generateDataLoaders,
  generateHasLoadedCheckers,
  reloadProps
)
