import React from 'react'

import i18n from './helpers/i18n'
import {
  CURRICULUM_PATH_ROOT,
  SCHOOL_PATH_ROOT,
  USER_MANAGEMENT_PATH_ROOT,
  TEACHERS_PATH,
  STUDENTS_PATH,
  SIS_INTEGRATION_PATH_ROOT,
  SIS_INTEGRATION_PREFIX
} from './routes/routes'
import DashboardIcon from './images/icons/dashboard.svg'
import CurriculumIcon from './images/icons/curriculum.svg'
import PreferencesIcon from './images/icons/preferences.svg'
import SchoolsIcon from './images/icons/schools.svg'
import AddUserIcon from './images/icons/add-user.svg'
import RosterIcon from './images/icons/roster.svg'

const curriculumMenuData = (schoolID) => ({
  groupLabel: i18n.t('titles.curriculum'),
  groupIcon: <CurriculumIcon />,
  url: CURRICULUM_PATH_ROOT(schoolID),
  enabled: true,
  isCurrent: (location) =>
    location && location.pathname.startsWith(CURRICULUM_PATH_ROOT(schoolID)),
  isOutsideLink: false
})

const districtMenuData = (districtID) => {
  return [
    {
      groupLabel: i18n.t('titles.schoolDirectory'),
      groupIcon: <SchoolsIcon />,
      url: '/',
      isCurrent: (location) => location && location.pathname === '/',
      enabled: true
    },
    {
      groupLabel: i18n.t('userActions.sisIntegration'),
      groupIcon: <AddUserIcon />,
      url: SIS_INTEGRATION_PATH_ROOT(districtID),
      isCurrent: (location) =>
        location &&
        location.pathname.startsWith(SIS_INTEGRATION_PREFIX(districtID)),
      enabled: true
    }
  ]
}
// The 'enabled' setting will allow us to setup navigation
// before the pages are ready to publish.

export const navigationData = (schoolID, isAdmin, disableDeepLinks) => {
  if (!isAdmin) {
    return [curriculumMenuData(schoolID)]
  }
  return [
    {
      groupLabel: i18n.t('userActions.dashboards'),
      groupIcon: <DashboardIcon />,
      enabled: true,
      childLinks: [
        {
          url: SCHOOL_PATH_ROOT(schoolID),
          label: i18n.t('titles.schoolOverview')
        },
        {
          url: TEACHERS_PATH(schoolID),
          label: i18n.t('titles.teachersDashboard'),
          disabled: disableDeepLinks
        },
        {
          url: STUDENTS_PATH(schoolID),
          label: i18n.t('titles.studentsDashboard'),
          disabled: disableDeepLinks
        }
      ]
    },
    {
      groupLabel: i18n.t('userActions.rosters'),
      groupIcon: <RosterIcon />,
      enabled: false,
      childLinks: [
        {
          url: '/rosters/teachers',
          label: i18n.t('titles.teachers')
        },
        {
          url: '/rosters/students',
          label: i18n.t('titles.students')
        },
        {
          url: '/rosters/classes',
          label: i18n.t('titles.classes')
        },
        {
          url: '/rosters/administrators',
          label: i18n.t('titles.administrators')
        }
      ]
    },
    curriculumMenuData(schoolID),
    {
      groupLabel: i18n.t('userActions.schoolPreferences'),
      groupIcon: <PreferencesIcon />,
      url: '/preferences',
      enabled: false
    },
    {
      groupLabel: i18n.t('userActions.userManagement'),
      groupIcon: <PreferencesIcon />,
      url: USER_MANAGEMENT_PATH_ROOT(schoolID),
      enabled: true,
      isCurrent: (location) =>
        location &&
        location.pathname.startsWith(USER_MANAGEMENT_PATH_ROOT(schoolID)),
      isOutsideLink: false
    }
  ]
}

export const getNavigationData = (
  districtID,
  schoolID,
  isAdmin,
  disableDeepLinks
) => {
  if (!schoolID) {
    return districtMenuData(districtID)
  }

  return navigationData(schoolID, isAdmin, disableDeepLinks)
}
