import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import { paginationShape } from '../../helpers/propShapes/common'
import { reportingMethodShape } from '../../helpers/propShapes/chartDataShapes'
import StudentListView from './studentListView'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'
import withSearch from '../hoc/withSearch'
import withPagination from '../hoc/withPagination'
import './studentsOverview.scss'

const EnhancedListView = withSearch(withPagination(StudentListView))
export class StudentsOverviewPresenter extends React.Component {
  constructor(props) {
    super(props)
    // for tracking purposes only
    this.state = {
      searchTerm: '',
      page: 0
    }
    this.search = this.search.bind(this)
  }

  search(searchTerm) {
    this.setState({ searchTerm })
    return this.props.search && this.props.search(searchTerm)
  }

  render() {
    const props = this.props
    const { pagination, schoolID } = props
    const studentsDashboardLabel = i18n.t('titles.studentsDashboard')
    const allStudentsLabel = i18n.t('titles.allStudents')
    const paginationProps = {
      ...pagination,
      fetchPage: props.fetchPage,
      itemsByID: props.studentsByIDs,
      updateCurrentPage: props.updateCurrentPage,
      trackPageFlip: () =>
        props.trackSearch(this.state.searchTerm, props.currentPage)
    }
    const searchProps = {
      searchOn: ['name'],
      search: this.search,
      items: Object.values(props.studentsByIDs),
      // new search always lands on page 0
      trackSearch: (query) => props.trackSearch(query, 0)
    }
    return (
      <div className="students-overview page">
        <div className="page-title-bar">
          <h2>{studentsDashboardLabel}</h2>
        </div>
        <h3 className="student-label">{allStudentsLabel}</h3>
        <EnhancedListView
          schoolID={schoolID}
          reportingMethod={props.reportingMethod}
          {...searchProps}
          {...paginationProps}
          onClickRow={props.trackSingleStudentViewed}
        />
      </div>
    )
  }
}

StudentsOverviewPresenter.propTypes = {
  schoolID: PropTypes.string,
  pagination: paginationShape,
  studentByID: PropTypes.object,
  cards: PropTypes.arrayOf(PropTypes.node),
  reportingMethod: reportingMethodShape,

  // actions
  search: PropTypes.func,
  fetchPage: PropTypes.func,
  updateCurrentPage: PropTypes.func,
  trackSearch: PropTypes.func,
  trackSingleStudentViewed: PropTypes.func
}

export default withOptionalSidebar(StudentsOverviewPresenter, GuidanceTile)
