import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import i18n from '../../helpers/i18n'
import TeacherEngagementChart from '../charts/teacherEngagementChart'
import ContentUsageChart from '../charts/contentUsageChart'
import StudentAchievementChart from '../charts/studentAchievementChart'
import DoughnutChart from '../charts/doughnutChart'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'
import * as routes from '../../routes/routes'
import * as colors from '../../helpers/colors'

import './schoolOverview.scss'

const schoolOverviewLabel = i18n.t('titles.schoolOverview')
const teacherEngagementLabel = i18n.t('titles.teacherEngagement')
const viewStudentDashboardLabel = i18n.t('userActions.viewStudentDashboard')
const viewTeacherDashboardLabel = i18n.t('userActions.viewTeacherDashboard')
const teachersBeenActiveLabel = i18n.t('labels.teachersBeenActive')
const viewInactiveTeachersLabel = i18n.t('userActions.viewInactiveTeachers')
const teachersHaveActivatedLabel = i18n.t('labels.teachersHaveActivated')
const viewUnactivatedLabel = i18n.t('userActions.viewUnactivated')
const studentAchievementLabel = i18n.t('titles.studentAchievement')
const studentsBeenActiveLabel = i18n.t('labels.studentsBeenActive')
const viewInactiveStudentsLabel = i18n.t('userActions.viewInactiveStudents')
const studentsHaveActivatedLabel = i18n.t('labels.studentsHaveActivated')
const contentUsageLabel = i18n.t('titles.contentUsage')

export class SchoolOverviewPresenter extends React.Component {
  render() {
    const { schoolID, emptyChartData, studentEmptyChartData } = this.props
    const teachersPath = routes.TEACHERS_PATH(schoolID)
    const studentsPath = routes.STUDENTS_PATH(schoolID)
    const inactiveStudentsPath = routes.INACTIVE_STUDENTS_PATH(schoolID)
    const unactivatedStudentsPath = routes.UNACTIVATED_STUDENTS_PATH(schoolID)
    const inactiveTeachersPath = routes.INACTIVE_TEACHERS_PATH(schoolID)
    const unactivatedTeachersPath = routes.UNACTIVATED_TEACHERS_PATH(schoolID)
    const showContentUsage = window.FEATURES && window.FEATURES.contentChart
    const containerClass = classnames('school-overview', 'page', {
      'disable-links': emptyChartData
    })
    return (
      <div className={containerClass} data-spec="school-overview-container">
        <h2>{schoolOverviewLabel}</h2>
        <div className="teacher-engagement-container">
          <div className="chart-doughnut-combo">
            <div className="chart-container">
              <div className="chart-heading">
                <h3>{teacherEngagementLabel}</h3>
                <Link to={teachersPath}>{viewTeacherDashboardLabel}</Link>
              </div>
              <TeacherEngagementChart
                emptyChartData={emptyChartData}
                schoolID={this.props.schoolID}
              />
            </div>
            <div className="doughnut-blurb">
              <DoughnutChart
                percentFilled={this.props.activeTeachersPercent}
                filledColor={colors.academyBase}
                diameter={100}
              />
              <div className="text-container">
                <p>{teachersBeenActiveLabel}</p>
                <Link to={inactiveTeachersPath}>
                  {viewInactiveTeachersLabel}
                </Link>
              </div>
            </div>
            <div className="doughnut-blurb">
              <DoughnutChart
                percentFilled={this.props.activatedTeachersPercent}
                filledColor={colors.academyBase}
                diameter={100}
              />
              <div className="text-container">
                <p>{teachersHaveActivatedLabel}</p>
                <Link to={unactivatedTeachersPath}>{viewUnactivatedLabel}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="student-achievement-container">
          <div className="chart-doughnut-combo">
            <div className="chart-container">
              <div className="chart-heading">
                <h3>{studentAchievementLabel}</h3>
                <Link to={studentsPath}>{viewStudentDashboardLabel}</Link>
              </div>
              <StudentAchievementChart
                emptyChartData={studentEmptyChartData}
                schoolID={this.props.schoolID}
              />
            </div>
            <div className="doughnut-blurb">
              <DoughnutChart
                percentFilled={this.props.activeStudentsPercent}
                filledColor={colors.academyBase}
                diameter={100}
              />
              <div className="text-container">
                <p>{studentsBeenActiveLabel}</p>
                <Link to={inactiveStudentsPath}>
                  {viewInactiveStudentsLabel}
                </Link>
              </div>
            </div>
            <div className="doughnut-blurb">
              <DoughnutChart
                percentFilled={this.props.activatedStudentsPercent}
                filledColor={colors.academyBase}
                diameter={100}
              />
              <div className="text-container">
                <p>{studentsHaveActivatedLabel}</p>
                <Link to={unactivatedStudentsPath}>{viewUnactivatedLabel}</Link>
              </div>
            </div>
          </div>
        </div>
        {showContentUsage && (
          <div className="content-usage-container">
            <div className="chart-doughnut-combo">
              <div className="chart-container">
                <div className="chart-heading">
                  <h3>{contentUsageLabel}</h3>
                </div>
                <ContentUsageChart />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default withOptionalSidebar(SchoolOverviewPresenter, GuidanceTile)
