import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'
import EditIcon from '../../../images/icons/edit.svg'
import Checkmark from '../../../images/icons/checkmark.svg'
import Pill from '../pill/pill'

import './filters.scss'

/* Filters takes in a list of Filter objects
   and renders clickable pill with icon, that expands to show
   the filter obejct's renderFunc. The close Icon is provided here,
   and is not needed in the Filter Object's renderFunc.

   Filter: {
      FilterComponent: () => { return <JSX /> },
      key: 'filterUniqueKey',
      label: i18n.t('FilterLabel'),
   }

   For Exmaple Filter see components/ui/search/filters/gradeLevelFilter.js
*/

class Filters extends React.Component {
  constructor(props) {
    super(props)

    this.editFilter = this.editFilter.bind(this)
    this.renderFilter = this.renderFilter.bind(this)

    this.state = props.filtersState || {}
  }

  editFilter(key) {
    this.setState({
      editing: !this.state.editing,
      [key]: !this.state[key]
    })
    if (this.props.filtersState) {
      Object.assign(this.props.filtersState, {
        editing: !this.props.filtersState.editing,
        [key]: !this.props.filtersState[key]
      })
    }
  }

  renderFilterContent(filter) {
    const { FilterComponent, showClose } = filter
    const components = [
      <FilterComponent
        key={`${filter.key}-component`}
        postSelect={() => this.editFilter(filter.key)}
      />
    ]
    if (showClose) {
      components.push(
        <button
          className="close-filter"
          title={i18n.t('labels.close')}
          key={`${filter.key}-close`}
          onClick={() => this.editFilter(filter.key)}
        >
          <Checkmark />
        </button>
      )
    }

    return components
  }

  renderFilterPill(filter) {
    return (
      <button
        title={i18n.t('userActions.editFilter')}
        className="edit-filter"
        key={`${filter.key}-open`}
        onClick={() => this.editFilter(filter.key)}
      >
        <Pill
          key={filter.key}
          rightIcon={<EditIcon />}
          text={filter.label}
          theme="light"
        />
      </button>
    )
  }

  renderFilter(filter) {
    const state = this.props.filtersState || this.state
    const editing = state[filter.key]

    const containerClass = classnames('filter', { editing })

    const content = editing
      ? this.renderFilterContent(filter)
      : this.renderFilterPill(filter)
    return (
      <div key={`${filter.key}-container`} className={containerClass}>
        {content}
      </div>
    )
  }

  render() {
    const { filters } = this.props
    const state = this.props.filtersState || this.state
    const editing = state.editing
    const containerClass = classnames('search-filters', { editing })
    return (
      <div className={containerClass}>
        {(filters || []).map(this.renderFilter)}
      </div>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      FilterComponent: PropTypes.func,
      showClose: PropTypes.bool
    })
  )
}

export default Filters
