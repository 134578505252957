import { fork } from 'redux-saga/effects'

import helperSagas from './helperSagas'
import * as actionIDs from '../actions/actionIDs'
import * as userManagementSagas from './userManagementSagas'

const userManagementSagaWatchers = [
  fork(
    helperSagas.resourceWatcher(
      actionIDs.userManagement.parseImportFile,
      userManagementSagas.parseImportFileAndRefetchUsers
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.userManagement.addUsers,
      userManagementSagas.addUsersAndRefetch
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.userManagement.sendIngestionInviteEmail,
      userManagementSagas.sendIngestionInviteEmail
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentAccounts.read,
      userManagementSagas.getStudentAccounts
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.teacherAccounts.sendInviteEmails,
      userManagementSagas.sendInviteEmails
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentAccounts.readBatch,
      userManagementSagas.getStudentAccountsBatch
    )
  )
]

export default userManagementSagaWatchers
