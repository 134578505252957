import React from 'react'
import StudentAtRiskOverviewPresenter from './studentsAtRiskOverviewPresenter'

class StudentAtRiskOverviewContainer extends React.Component {
  render() {
    return <StudentAtRiskOverviewPresenter {...this.props} />
  }
}

export default StudentAtRiskOverviewContainer
