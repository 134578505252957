import * as actionIDs from './actionIDs'
import { makeActionCreator, makeResourceActionsCreators } from './actionHelpers'

export const bulkImportUsers = {
  parseImportFile: makeResourceActionsCreators(
    actionIDs.userManagement.parseImportFile,
    {
      begin: ['file', 'schoolID', 'role'],
      initialize: ['file', 'schoolID', 'role'],
      success: ['file', 'role', 'response'],
      error: ['file', 'role', 'error']
    }
  ),
  addUsers: makeResourceActionsCreators(actionIDs.userManagement.addUsers, {
    begin: ['users', 'schoolID', 'ingestionID', 'role'],
    initialize: ['users', 'schoolID', 'ingestionID', 'role'],
    success: ['role', 'response'],
    error: ['role', 'error']
  }),
  sendIngestionInviteEmail: makeResourceActionsCreators(
    actionIDs.userManagement.sendIngestionInviteEmail,
    {
      begin: ['ingestionID', 'schoolID'],
      initialize: ['ingestionID'],
      success: ['ingestionID'],
      error: ['ingestionID', 'error']
    }
  ),
  clearImportErrors: makeActionCreator(
    actionIDs.userManagement.clearImportErrors,
    'role'
  ),
  clearImport: makeActionCreator(actionIDs.userManagement.clearImport, 'role')
}

export const studentAccounts = {
  read: makeResourceActionsCreators(actionIDs.studentAccounts.read, {
    begin: ['ingestionID', 'schoolID'],
    initialize: ['ingestionID'],
    success: ['ingestionID', 'studentAccounts'],
    error: ['ingestionID', 'error']
  }),
  readBatch: makeResourceActionsCreators(actionIDs.studentAccounts.readBatch, {
    begin: ['schoolID', 'studentIDs'],
    success: ['studentAccounts'],
    error: ['error']
  })
}

export const teacherAccounts = {
  sendInviteEmails: makeResourceActionsCreators(
    actionIDs.teacherAccounts.sendInviteEmails,
    {
      begin: ['schoolID', 'userIDs'],
      initialize: ['schoolID', 'userIDs'],
      success: ['userIDs'],
      error: ['userIDs', 'error']
    }
  )
}
