import moment from 'moment'

import { authSendRequest } from '../../helpers/sendRequest'
import { chartScopeMap, processTeacherActivityResponse } from './adminTransform'
import { dateTimeForServerRequestFormat } from '../../helpers/datetime'

const makePlotRequest = (type, schoolID, chartScope, timeframeID) => {
  const scope = {
    school_id: Number(schoolID)
  }
  const span = timeframeID
  const reqBody = {
    type,
    options: {
      facet: 'all',
      scope,
      span,
      group_by: chartScopeMap[chartScope]
    }
  }
  return authSendRequest('POST', `${window.API_SERVER}/report/plot`, reqBody)
}

export const getTeacherEngagement = (
  schoolID,
  chartScope = 'month',
  timeframeID
) =>
  makePlotRequest('teacher:activity', schoolID, chartScope, timeframeID).then(
    ({ response, error }) => ({
      response: processTeacherActivityResponse(
        response,
        chartScope,
        timeframeID
      ),
      error
    })
  )

export const getStudentAchievements = (
  schoolID,
  chartScope = 'month',
  timeframeID
) => {
  return makePlotRequest(
    'student:achievement:overall',
    schoolID,
    chartScope,
    timeframeID
  )
}

export const getTeacherReports = (schoolID, teacherIDs) => {
  const scope = {
    school_id: Number(schoolID),
    teacher_ids: teacherIDs
  }
  const reqBody = {
    type: 'teacher:performance',
    options: {
      scope
    }
  }
  return authSendRequest('POST', `${window.API_SERVER}/report/plot`, reqBody)
}

export const getTopClasses = (schoolID) => {
  const endDate = dateTimeForServerRequestFormat(moment().add(1, 'day'))
  const startDate = dateTimeForServerRequestFormat(
    moment().subtract(30, 'days')
  )
  const span = `${startDate},${endDate}`
  const type = 'class:top_performing'
  const scope = { school_id: parseInt(schoolID, 10) }
  const reqBody = { type, options: { scope, span } }

  return authSendRequest('POST', `${window.API_SERVER}/report/plot`, reqBody)
}

export const getTeacherCustomList = (schoolID, type) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/teachers?${type}=true`
  )
}

export const getStudentsBatch = (schoolID, studentIDs) => {
  const studentIDParams = studentIDs.map((studentID, index) => {
    const prefix = index === 0 ? '' : '&'
    return `${prefix}student_id[]=${studentID}`
  })
  return authSendRequest(
    'GET',
    `${
      window.API_SERVER
    }/api/schools/${schoolID}/students?${studentIDParams.join('')}`
  )
}

export const getStudentActivityLists = (schoolID) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/students/activation`
  )
}

export const getStudentAchievementByID = (schoolID, studentIDs) => {
  const reqBody = {
    options: {
      scope: {
        school_id: parseInt(schoolID, 10),
        student_ids: studentIDs.map((id) => parseInt(id, 10))
      }
    },
    type: 'student:performance'
  }
  return authSendRequest('POST', `${window.API_SERVER}/report/plot`, reqBody)
}

export function getSchoolReportingMethods(schoolID) {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/reportingMethods`
  )
}

export const getStudentClassPerformance = (studentID, classID) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/class/${classID}/history?unit=week&student_id=${studentID}`
  )
}

// assignment statistics per class
export const getStudentClassesStatistics = (schoolID, studentID) =>
  authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/students/${studentID}/classes`
  )
