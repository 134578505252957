import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import './styles/main.scss' // default global styles
import App from './App'
import ScrollToTop from './scrollToTop'
import registerServiceWorker from './registerServiceWorker'
import configureStore from './helpers/configureStore'
import i18n from './helpers/i18n'

const store = configureStore()

// Load in Kiddom Translations - none for now, use default Keys for English
// Once we have locales, provided the user-saved locale here
i18n.loadKiddomTranslations('en-US', 'translation')

const wrappedApp = (
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={store}>
        <App />
      </Provider>
    </ScrollToTop>
  </BrowserRouter>
)

// Render React
ReactDOM.render(wrappedApp, document.getElementById('root'))
// Start up service worker
registerServiceWorker()
