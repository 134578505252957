import { Map } from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import { mapArrayObjectsByKey } from '../../helpers/utils'
import { SUBJECTS } from '../models/resources'
import * as reducerCreators from './reducerCreators'

export const initialState = Map({
  byID: Map(),
  fetching: false,
  error: null
})

const getSubjectsSuccess = (state, action) => {
  const newState = state.set('fetching', false)
  return mapArrayObjectsByKey(newState, action.subjects, 'id', ['byID'])
}

const handlers = reducerCreators.getHandlers(SUBJECTS, {
  [actionIDs.subjects.read.success]: getSubjectsSuccess
})

const subjects = reducerCreators.reducerCreator(initialState, handlers)

export default subjects
