import { getTeacherRecentClassIDForCurriculum } from '../store/models/curriculumModels'
import { TEACHERS_CURRICULUM_ASSIGNMENTS } from '../routes/routes'
// goToTeacherAssignments fetches teacher's recent class matching curriculum subjects and grades
// It then opens the class room app with the most recent class id recieved from endpoint
export const goToTeacherAssignments = (curriculumID, userID) => {
  const resp = getTeacherRecentClassIDForCurriculum(curriculumID, userID)
  // Opening tab in event handler so it is not blocked by popup blocker
  const newTab = window.open('', '_blank')
  resp
    .then((response) => {
      const result = response.response
      if (!result.class_id) {
        // Closing tab when no class id is found
        newTab.close()
        return
      }
      newTab.location = TEACHERS_CURRICULUM_ASSIGNMENTS(
        result.class_id,
        curriculumID
      )
    })
    .catch(() => {
      // Closing tab on error
      newTab.close()
    })
}
