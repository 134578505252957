import moment from 'moment'
import { DATE_FORMAT } from './constants'

// Term Specific setup
// TODO - use custom term length from API, currently 12 weeks
const thisTerm = Math.floor((moment().month() / 12) * 4)
const weeksToEndOfTerm = moment().week() - thisTerm * 12
const endLastTerm = thisTerm * 12
const lastTerm = thisTerm - 1 > 0 ? thisTerm - 1 : thisTerm
const startLastTerm = lastTerm * 12

// Date Range Model
// Use this central object for all timeframe calucation for:
// Year, Term, Week, month
// thisRange: latest possible view, shows some future dates
// lastXRange: default view; eg: last 30 days, last 7 days, last 12 months
// lastRange: previous to lastXrange, range before thisRange,
//   use to calculate earlier ranges subtracting range type

export const dateRangeModel = {
  week: {
    startDate: moment().subtract(7, 'days'),
    lastRange: {
      start: moment().subtract(1, 'week').day(1).format(DATE_FORMAT),
      end: moment().subtract(1, 'week').day(7).format(DATE_FORMAT)
    },
    lastXRange: {
      start: moment().add(1, 'day').subtract(1, 'week').format(DATE_FORMAT),
      end: moment().add(1, 'day').format(DATE_FORMAT)
    },
    thisRange: {
      start: moment().day(1).format(DATE_FORMAT),
      end: moment().day(7).format(DATE_FORMAT)
    }
  },
  month: {
    startDate: moment().subtract(30, 'days'),
    lastRange: {
      start: moment().subtract(1, 'month').date(1).format(DATE_FORMAT),
      end: moment().date(1).format(DATE_FORMAT)
    },
    lastXRange: {
      start: moment().add(1, 'day').subtract(30, 'days').format(DATE_FORMAT),
      end: moment().add(1, 'day').format(DATE_FORMAT)
    },
    thisRange: {
      start: moment().date(1).format(DATE_FORMAT),
      end: moment().add(1, 'month').date(1).format(DATE_FORMAT)
    }
  },
  term: {
    startDate: moment().subtract(12, 'weeks'),
    lastRange: {
      start: moment().week(startLastTerm).day(1).format(DATE_FORMAT),
      end: moment().week(endLastTerm).day(1).format(DATE_FORMAT)
    },
    lastXRange: {
      start: moment().add(1, 'day').subtract(12, 'weeks').format(DATE_FORMAT),
      end: moment().add(1, 'day').format(DATE_FORMAT)
    },
    thisRange: {
      start: moment().week(endLastTerm).day(1).format(DATE_FORMAT),
      end: moment().add(weeksToEndOfTerm, 'weeks').day(1).format(DATE_FORMAT)
    }
  },
  year: {
    startDate: moment().add(1, 'day').subtract(1, 'year'),
    lastRange: {
      start: moment().subtract(1, 'year').dayOfYear(1).format(DATE_FORMAT),
      end: moment().dayOfYear(1).format(DATE_FORMAT)
    },
    lastXRange: {
      start: moment()
        .add(1, 'day')
        .subtract(1, 'year')
        .add(1, 'month')
        .date(1)
        .format(DATE_FORMAT),
      end: moment().add(1, 'day').format(DATE_FORMAT)
    },
    thisRange: {
      start: moment().dayOfYear(1).format(DATE_FORMAT),
      end: moment().add(1, 'year').dayOfYear(1).format(DATE_FORMAT)
    }
  }
}

export function getStartAndEndDateForChart(direction, timeFrame) {
  const getTimeFrames = {
    next: getNextRange,
    prev: getPreviousRange
  }
  return getTimeFrames[direction](timeFrame)
}

function getNextRange(timeFrame) {
  const timeFrameEnd = timeFrame.get('end')
  const timeFrameStart = timeFrame.get('start')
  const timeFrameType = timeFrame.get('type')
  const dateRanges = dateRangeModel[timeFrameType]
  let startDate = dateRanges.startDate
  let endDate = moment()
  const endOfThisRange = moment(dateRanges.thisRange.end)
  const endOfLastRange = moment(dateRanges.lastRange.end)
  const endIsToday = moment().add(1, 'day').isSame(timeFrameEnd, 'day')
  const endIsThisRange = endOfThisRange.isSame(timeFrameEnd, 'day')
  const endIsLastRange = endOfLastRange.isSame(timeFrameEnd, 'day')
  if (endIsToday && !endIsThisRange) {
    // go to thisRange
    startDate = dateRanges.thisRange.start
    endDate = dateRanges.thisRange.end
  } else if (endIsThisRange) {
    // keep same range
    startDate = timeFrameStart
    endDate = timeFrameEnd
  } else if (endIsLastRange) {
    // go to lastXRange
    startDate = dateRanges.lastXRange.start
    endDate = dateRanges.lastXRange.end
  } else {
    startDate = moment(timeFrameEnd).format(DATE_FORMAT)
    if (timeFrameType !== 'term') {
      endDate = moment(startDate).add(1, timeFrameType).format(DATE_FORMAT)
    } else {
      endDate = moment(startDate).add(12, 'weeks').format(DATE_FORMAT)
    }
  }

  return { startDate, endDate }
}

function getPreviousRange(timeFrame) {
  const timeFrameEnd = timeFrame.get('end')
  const timeFrameStart = timeFrame.get('start')
  const timeFrameType = timeFrame.get('type')
  const dateRanges = dateRangeModel[timeFrameType]
  let startDate = dateRanges.startDate
  let endDate = moment()
  const endOfThisRange = moment(dateRanges.thisRange.end)
  const endIsToday = moment().add(1, 'day').isSame(timeFrameEnd, 'day')
  const endIsThisRange = endOfThisRange.isSame(timeFrameEnd, 'day')

  if (endIsToday) {
    // go to lastRange
    startDate = dateRanges.lastRange.start
    endDate = dateRanges.lastRange.end
  } else if (endIsThisRange) {
    // go to lastXRange
    startDate = dateRanges.lastXRange.start
    endDate = dateRanges.lastXRange.end
  } else {
    if (timeFrameType !== 'term') {
      startDate = moment(timeFrameStart)
        .subtract(1, timeFrameType)
        .format(DATE_FORMAT)
    } else {
      startDate = moment(timeFrameStart)
        .subtract(12, 'weeks')
        .format(DATE_FORMAT)
    }

    endDate = moment(timeFrameStart).format(DATE_FORMAT)
  }
  return { startDate, endDate }
}

export const parseDatesFromTimeframeID = (timeframeID) => {
  const dates = timeframeID.split(',')
  return {
    moments: { startDate: moment(dates[0]), endDate: moment(dates[1]) },
    formatted: { startDate: dates[0], endDate: dates[1] }
  }
}

export function generateTimeframeIDFromDates(start, end) {
  if (typeof start === 'string' && typeof end === 'string') {
    return `${start},${end}`
  } else {
    return `${start.format(DATE_FORMAT)},${end.format(DATE_FORMAT)}`
  }
}

// inital state for charts
export const initState = () => {
  const defaultEnd = dateRangeModel.month.lastXRange.end
  const defaultStart = dateRangeModel.month.lastXRange.start
  return {
    currentDataIndex: generateTimeframeIDFromDates(defaultStart, defaultEnd),
    currentDataType: 'month'
  }
}
