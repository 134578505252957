import { fromJS } from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import { DISTRICT } from '../models/resources'
import * as reducerCreators from './reducerCreators'
import { storeError } from './reducerHelpers'

export const initialState = fromJS({
  districtInfo: {},
  fetching: false,
  error: null
})

const getDistrictSuccess = (state, action) => {
  const newState = state.set('fetching', false)
  return newState.set('districtInfo', fromJS(action.district))
}

const handlers = reducerCreators.getHandlers(DISTRICT, {
  [actionIDs.district.read.success]: getDistrictSuccess,
  [actionIDs.district.read.error]: storeError
})

const district = reducerCreators.reducerCreator(initialState, handlers)

export default district
