import React from 'react'

import Pagination from '../ui/pagination/pagination'

const withPagination = (WrappedComponent) => {
  return class WithPaginationWithWrapped extends React.Component {
    constructor(props) {
      super(props)

      this.setCurrentPage = this.setCurrentPage.bind(this)
      this.shownItems = this.shownItems.bind(this)
    }

    componentDidUpdate(prevProps) {
      if (
        this.props.trackPageFlip &&
        prevProps.currentPage !== undefined &&
        this.props.currentPage !== undefined &&
        prevProps.currentPage !== this.props.currentPage
      ) {
        this.props.trackPageFlip()
      }
    }

    setCurrentPage(pageNumber) {
      const { pages, fetchPage, updateCurrentPage } = this.props
      const currentPageItemIDs = pages[pageNumber]
      // fetch more if there's not enough
      if (!currentPageItemIDs && fetchPage) {
        // fetch more
        fetchPage(pageNumber)
      } else {
        // update state's current page
        updateCurrentPage(pageNumber)
      }
    }

    shownItems() {
      const { pages, currentPage, itemsByID } = this.props
      const currentPageData = pages && pages[currentPage]
      return (currentPageData || []).map((id) => itemsByID[id])
    }

    render() {
      const { items, totalPages, currentPage, skip, ...otherProps } = this.props
      const usePagination = !skip && totalPages > 1
      const lineItems = skip ? items : this.shownItems()
      return (
        <div>
          <WrappedComponent items={lineItems} {...otherProps} />
          {usePagination && (
            <Pagination
              totalPages={totalPages}
              setCurrentPage={this.setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </div>
      )
    }
  }
}

export default withPagination
