import { all, call, put, select } from 'redux-saga/effects'

import * as coreActions from '../actions/coreActions'
import * as coreSagas from './coreSagas'
import * as helperSagas from './helperSagas'
import * as fromState from '../fromState'
import * as resources from '../models/resources'
import * as adminActions from '../actions/adminActions'
import * as adminModels from '../models/adminModels'
import * as adminTransform from '../models/adminTransform'

// fetch classes and their statistics for a single student
export function* fetchStudentClasses(action) {
  let state = yield select()
  let student = fromState.getResourceByID(
    state,
    resources.STUDENTS,
    action.studentID
  )
  if (!student) {
    yield call(coreSagas.fetchStudent, action)
  }
  yield all([
    call(coreSagas.fetchClasses, action),
    call(fetchStudentClassesStatistics, action)
  ])
  state = yield select()
  // Teacher Data for Class Stats
  student = fromState.getResourceByID(
    state,
    resources.STUDENTS,
    action.studentID
  )

  const shouldFetchTeachers = student
    .get('classStatisticsByID')
    .keySeq()
    .some((clazzID) => {
      const clazz = fromState.getResourceByID(state, resources.CLASSES, clazzID)
      const teacher = fromState.getResourceByID(
        state,
        resources.TEACHERS,
        clazz.teacherID
      )
      return !teacher
    })
  if (shouldFetchTeachers) {
    yield call(coreSagas.fetchTeachers, action)
  }
  // Performance data for each Class
  const classes = student.get('classStatisticsByID').valueSeq()
  const classPerformanceCalls = []
  for (let i = 0; i < classes.size; i++) {
    const clazz = classes.get(i)
    if (!clazz.get('performance')) {
      classPerformanceCalls.push(
        call(
          fetchStudentClassPerformance,
          student.get('id'),
          student.get('childID'),
          clazz.get('id')
        )
      )
    }
  }
  yield all(classPerformanceCalls)
}

export function* fetchTeacherReports(action) {
  const engineOptions = {
    needToFetch: true,
    transformer: adminTransform.mapTeacherReportsResponse,
    successArgs: [action.schoolID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    adminActions.teacherReports.read,
    () => adminModels.getTeacherReports(action.schoolID, action.teacherIDs),
    engineOptions
  )
}

/* This saga just fetches all three: teachers, classes, subjects */
/* The actions/reducers associated just set in the store if this is fetching, and ready */
export function* fetchTeacherAggregate(action) {
  yield all([
    call(coreSagas.fetchSubjects, action),
    call(coreSagas.fetchTeachers, action),
    call(coreSagas.fetchClasses, action),
    call(fetchReportingMethods, action)
  ])
  const state = yield select()
  const teacherIDs = fromState
    .getIDsList(state, resources.TEACHERS)
    .map((id) => parseInt(id, 10))
  yield fetchTeacherReports({ schoolID: action.schoolID, teacherIDs })
}

export const fetchTopClasses = (action) => {
  return helperSagas.fetchResource(
    adminActions.topClasses.read,
    () => adminModels.getTopClasses(action.schoolID),
    adminTransform.topClasses
  )
}

export function* fetchInactiveTeachers(action) {
  const existing = yield select(fromState.getInactive, resources.TEACHERS)
  const loaded = !action.shouldForceFetch && !!existing
  const engineOptions = {
    transformer: adminTransform.inactiveTeachers,
    needToFetch: !loaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    adminActions.inactiveTeachers.read,
    () => adminModels.getTeacherCustomList(action.schoolID, 'inactive'),
    engineOptions
  )
}

export function* fetchUnactivatedTeachers(action) {
  const existing = yield select(fromState.getUnactivated, resources.TEACHERS)
  const loaded = !action.shouldForceFetch && !!existing
  const engineOptions = {
    transformer: adminTransform.unactivatedTeachers,
    needToFetch: !loaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    adminActions.unactivatedTeachers.read,
    () => adminModels.getTeacherCustomList(action.schoolID, 'unactivated'),
    engineOptions
  )
}

export function* fetchReportingMethods(action) {
  const engineOptions = {
    needToFetch: true,
    transformer: adminTransform.reportingMethods,
    initializeArgs: [action.schoolID],
    successArgs: [action.schoolID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.reportingMethods.read,
    () => adminModels.getSchoolReportingMethods(action.schoolID),
    engineOptions
  )
}

export function* fetchStudentClassPerformance(studentID, childID, classID) {
  const engineOptions = {
    needToFetch: true,
    transformer: adminTransform.studentClassPerformance,
    successArgs: [studentID, childID, classID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.studentClassPerformance.read,
    () => adminModels.getStudentClassPerformance(childID, classID),
    engineOptions
  )
}

export function* fetchStudentClassesStatistics(action) {
  const engineOptions = {
    needToFetch: true,
    transformer: adminTransform.studentClassesStatistics,
    successArgs: [action.studentID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    coreActions.studentClassesStatistics.read,
    () =>
      adminModels.getStudentClassesStatistics(
        action.schoolID,
        action.studentID
      ),
    engineOptions
  )
}

export function* fetchStudentActivityLists(action) {
  const inactive = yield select(fromState.getStudentActivityListIDs, 'inactive')
  const engineOptions = {
    needToFetch: action.shouldForceFetch || inactive === null,
    transformer: adminTransform.studentActivityLists
  }
  yield call(
    helperSagas.fetchResourceEngine,
    adminActions.studentActivityLists.read,
    () => adminModels.getStudentActivityLists(action.schoolID),
    engineOptions
  )
}

export function* fetchTeacherEngagement(action) {
  const engineOptions = {
    needToFetch: true,
    successArgs: [action.schoolID]
  }

  yield helperSagas.fetchResourceEngine(
    adminActions.teacherEngagement.read,
    () =>
      adminModels.getTeacherEngagement(
        action.schoolID,
        action.groupBy,
        action.timeframeID
      ),
    engineOptions
  )
}

export function* fetchStudentAchievement(action) {
  yield put(adminActions.studentAchievement.read.initialize())
  const tasks = [
    call(
      adminModels.getStudentAchievements,
      action.schoolID,
      action.groupBy,
      action.timeframeID
    )
  ]
  let setMethod = yield select(
    fromState.getSchoolSetReportingMethod,
    action.schoolID
  )
  if (!setMethod) {
    tasks.push(call(fetchReportingMethods, action))
  } else {
    // since we reverse the api response before sending to be used
    // by adminTransform.processStudentAchievementBucket, we also need
    // to reverse the one stored in state before using it in that transform.
    setMethod.get('scales').reverse()
  }
  const results = yield all(tasks)
  const isError = yield call(
    helperSagas.sagaErrorHandling,
    results[0],
    adminActions.studentAchievement.read.error,
    [],
    'Error Fetching StudentAchievement Chart:'
  )
  if (isError) {
    return
  }

  const chartData = results[0].response
  if (!setMethod) {
    setMethod = yield select(
      fromState.getSchoolSetReportingMethod,
      action.schoolID
    )
  }
  const transformedChartData = adminTransform.processStudentAchievementBucket(
    chartData,
    setMethod,
    action.groupBy,
    action.timeframeID
  )
  yield put(
    adminActions.studentAchievement.read.success(
      action.schoolID,
      transformedChartData
    )
  )
}
