import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useParams } from 'react-router'

import i18n from '../../helpers/i18n'

import BulkActionNotification from './bulkActionNotification'
import BulkInviteEmailButtonContainer from './bulkInviteEmailButtonContainer'
import BulkStudentAccountsButtonContainer from './bulkStudentAccountsButton/bulkStudentAccountsButtonContainer'
import DeleteUsersButton from './deleteUsers.tsx'

import {
  ROLE,
  USER_MANAGEMENT_BULK_ACTIONS,
  USER_MANAGEMENT_ROLES
} from '../../helpers/constants'

import './bulkActions.scss'

export const numAccountsSelected = (count) =>
  i18n.t('labels.numAccountsSelected', { count })

const RoleActions = ({
  role,
  selected,
  setNotificationMessage,
  setNotificationType
}) => {
  if (role === ROLE.TEACHER) {
    return (
      <BulkInviteEmailButtonContainer
        className={`bulk-action ${USER_MANAGEMENT_BULK_ACTIONS.SEND_INVITE_EMAIL}`}
        key={USER_MANAGEMENT_BULK_ACTIONS.SEND_INVITE_EMAIL}
        userIDs={selected}
        setNotificationMessage={setNotificationMessage}
        setNotificationType={setNotificationType}
      />
    )
  }
  return (
    <BulkStudentAccountsButtonContainer
      className="bulk-action"
      userIDs={selected}
    />
  )
}

const BulkActions = (props) => {
  const { role, selected } = props
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [visible, setVisible] = useState(false)
  const className = classnames('bulk-actions-wrapper', {
    visible: selected && selected.size
  })

  const { schoolID } = useParams()
  useEffect(() => setVisible(!!notificationMessage), [notificationMessage])

  if (!selected) {
    return null
  }

  return (
    <React.Fragment>
      <div className={className} data-spec="bulk-actions-wrapper">
        <div className="selected-message" data-spec="selected-message">
          {numAccountsSelected(selected.size)}
        </div>
        <div className="actions-list">
          <RoleActions
            data-spec="role-actions"
            role={role}
            selected={selected}
            setNotificationMessage={setNotificationMessage}
            setNotificationType={setNotificationType}
          />
          {<DeleteUsersButton schoolID={schoolID} selected={selected} />}
        </div>
      </div>
      <BulkActionNotification
        visible={visible}
        message={notificationMessage}
        type={notificationType}
        dismiss={() => setVisible(false)}
      />
    </React.Fragment>
  )
}

BulkActions.defaultProps = {
  selected: null
}

BulkActions.propTypes = {
  role: PropTypes.oneOf(USER_MANAGEMENT_ROLES).isRequired,
  selected: PropTypes.object
}

export default BulkActions
