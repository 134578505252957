import { DEFAULT_PAGE_SIZE } from '../../helpers/constants'
import * as sendRequest from '../../helpers/sendRequest'
import { deviceUniqueID } from '../../helpers/utils'

export const getClasses = (schoolID, classIDs = []) => {
  let query = '?'
  classIDs.forEach((id) => {
    query += `class[]=${id}&`
  })
  return sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/classes${query}`
  )
}

export const getGrades = () => {
  return sendRequest.authSendRequest('GET', `${window.API_SERVER}/api/grades`)
}

export const getClassesStatistics = (schoolID) =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/classes/statistics`
  )

export const getTeachers = (schoolID) =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/teachers`
  )

export const getStudent = (schoolID, studentID) =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/students?student_id[]=${studentID}`
  )

export const getStudents = (
  schoolID,
  query = '',
  offset = 0,
  limit = DEFAULT_PAGE_SIZE
) =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/students?name=${query}&page_index=${offset}&page_width=${limit}`
  )

// assignment statistics over school
export const getStudentsAssignmentStats = (schoolID, studentIDs) => {
  const ids = studentIDs.map((id) => parseInt(id, 10))
  return sendRequest.authSendRequest(
    'POST',
    `${window.API_SERVER}/api/schools/${schoolID}/students/statistics`,
    ids
  )
}

export const getUser = () =>
  sendRequest.authSendRequest('GET', `${window.API_SERVER}/api2/user`)

export const getUserPrefs = () =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api2/user/preferences`
  )

export const writeUserPrefs = (preferences) =>
  sendRequest.authSendRequest(
    'POST',
    `${window.API_SERVER}/api2/user/preferences`,
    { prefs: preferences }
  )

export const getSchools = () =>
  sendRequest.authSendRequest('GET', `${window.API_SERVER}/api/user/schools`)

export const getSubjects = () =>
  sendRequest.authSendRequest('GET', `${window.API_SERVER}/api/subjects`)

export const getDistrict = (districtID) =>
  sendRequest.authSendRequest(
    'GET',
    `${window.API_SERVER}/api/district/${districtID}`
  )

export const trackEvent = (eventName, metadata) => {
  const body = {
    event: eventName,
    device_id: deviceUniqueID(),
    metadata
  }
  return sendRequest.authSendRequest(
    'POST',
    `${window.API_SERVER}/track/event`,
    body
  )
}

export const getMatchingSchool = (schoolName) => {
  let path = '/api/schools'
  if (schoolName) {
    path += `?name=${schoolName}`
  }
  return sendRequest.authSendRequest('GET', `${window.API_SERVER}${path}`)
}

export const updateSchool = (id, school) => {
  return sendRequest.authSendRequest(
    'PUT',
    `${window.API_SERVER}/api/schools/${id}`,
    school
  )
}
