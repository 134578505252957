import React, { useState, useEffect, useRef } from 'react'

import i18n from '../../../helpers/i18n'
import { datestring } from '../../../helpers/utils'
import DownloadIcon from '../../../images/icons/download.svg'
import LoadingDots from '../../common/loading/loadingDots'
import Checkbox from '../../ui/checkbox/checkbox'

const downloadCSV = i18n.t('userActions.downloadCSV')
const downloadAccountsLabel = i18n.t('userActions.downloadAccounts')

const BulkStudentAccountsButtonPresenter = (props) => {
  const {
    isLink,
    className,
    getAccounts,
    studentAccounts,
    isFetching,
    userIDs
  } = props
  const linkEl = useRef(null)
  const [hasClicked, setHasClicked] = useState(false)
  const [usePassword, setUsePassword] = useState(false)
  useEffect(() => {
    const linkNode = linkEl.current
    if (studentAccounts && linkNode && hasClicked) {
      linkNode.href = studentAccounts
      linkNode.click()
    }
  }, [studentAccounts])

  useEffect(() => {
    const linkNode = linkEl.current
    linkNode && linkNode.removeAttribute('href')
    setHasClicked(false)
  }, [userIDs])

  if (isFetching) {
    return <LoadingDots theme="dark" />
  }
  const handleClick = () => {
    const linkNode = linkEl.current
    if (isLink || usePassword) {
      if (!hasClicked) {
        linkNode && linkNode.removeAttribute('href')
        setHasClicked(true)
        getAccounts()
        return
      }
      linkNode.href = props.studentAccounts
    } else {
      linkNode && linkNode.removeAttribute('href')
      const accounts = props.selectedStudentAccounts
        .toJS()
        .map((account) => [account.name, account.username])
      accounts.unshift(['name', 'username'])
      // add header to the top
      const dlString =
        'data:text/csv;charset=utf-8,' +
        encodeURIComponent(accounts.map((e) => e.join(',')).join('\n'))
      linkNode.href = dlString
    }
  }

  const onSelected = () => {
    setUsePassword(!usePassword)
  }

  const content = isLink ? (
    downloadCSV
  ) : (
    <DownloadIcon
      title={downloadAccountsLabel}
      className={className}
      data-spec="download-accounts-icon"
    />
  )
  return (
    <div className="download-section">
      <a
        ref={linkEl}
        onClick={handleClick}
        className="download-accounts"
        data-spec="download-accounts"
        download={`KiddomStudentAccounts${datestring()}.csv`}
      >
        {content}
      </a>
      {!isLink && (
        <div className="password-checkbox-section">
          <Checkbox
            id="password-checkbox"
            checked={!!usePassword}
            onChange={onSelected}
          />
          <div className="password-text">
            Generate new randomized passwords for these accounts
          </div>
        </div>
      )}
    </div>
  )
}

export default BulkStudentAccountsButtonPresenter
