import React from 'react'
import PropTypes from 'prop-types'

import { generateErrorMessageAndRetry, maybeRetry } from '../../helpers/utils'
import i18n from '../../helpers/i18n'

import Undo from '../ui/undo/undo'
import withRetry from './withRetry'

/*
    A component which renders withUndo UI and withoutUndo UI of a component 
*/
class ShowWithUndo extends React.Component {
  saveModel() {
    this.props.clearRetryCountdown()
    this.props.onUndo()
  }

  renderUndoOrError = () => {
    const { error, onUndo, altText } = this.props
    if (!error) {
      return <Undo altText={altText} key="undo" onClick={onUndo} />
    }
    return this.getError()
  }

  getError = () => {
    const { shouldRetry, retryCountdown, onUndo } = this.props
    const message = i18n.t('errors.unableToUndo')
    const useRetry = shouldRetry && retryCountdown
    const [, ...retryBody] = generateErrorMessageAndRetry(
      message,
      onUndo,
      retryCountdown,
      useRetry
    )
    return (
      <div data-spec="undo-retry" key="undo-retry" className="undo-retry">
        {retryBody}
      </div>
    )
  }

  render() {
    return [this.props.children, this.renderUndoOrError()]
  }
}
const WrappedShowUndo = withRetry(ShowWithUndo)

/*
  showUndo is bool to decide when to show undo option
  onUndo is action on undo button
  undoError errors for undo retru
  clearUndoErrors  remove error handler for undo error retry
*/
class WithUndo extends React.Component {
  render() {
    const {
      showUndo,
      onUndo,
      undoError,
      clearUndoErrors,
      undoAltText
    } = this.props
    if (showUndo) {
      const shouldRetry =
        undoError && undoError.status ? maybeRetry(undoError.status) : false
      return (
        <WrappedShowUndo
          error={undoError}
          modelID="undo"
          clearErrors={() => {
            clearUndoErrors && clearUndoErrors()
          }}
          shouldRetry={shouldRetry}
          onUndo={onUndo}
          altText={undoAltText}
        >
          {this.showWithUndo()}
        </WrappedShowUndo>
      )
    }
    return this.showWithoutUndo()
  }
}
WithUndo.defaultProps = {
  onUndo: null,
  undoError: null,
  showUndo: false
}

WithUndo.propTypes = {
  onUndo: PropTypes.func.isRequired,
  undoError: PropTypes.object,
  showUndo: PropTypes.bool
}

export default WithUndo
