import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as fromState from '../store/fromState'
import * as coreActions from '../store/actions/coreActions'
import * as userPreferences from '../store/userPreferences'
import { SCHOOLS } from '../store/models/resources'
import { withSchoolOrDistrictAdminRoleCheck } from './hoc/withRoleCheckFor'
import SchoolCard from './common/cards/schoolCard'
import Loading from './kiddom/loading/loading'
import * as routes from '../routes/routes'
import './districtOverview.scss'

const createSection = (sectionName, schoolIDs, _history) => {
  const schoolCards = schoolIDs.map((schoolID, idx) => (
    <SchoolCard schoolID={schoolID} key={idx} />
  ))
  return (
    <div className="school-section" key={sectionName}>
      <a name={sectionName} className="page-anchor">
        <h2>{sectionName}</h2>
      </a>
      <div className="school-cards card-container">{schoolCards}</div>
    </div>
  )
}

export class DistrictOverview extends React.Component {
  componentDidMount() {
    if (!this.props.schoolIDs.size && !this.props.fetchingSchools) {
      this.props.loadData()
    }
  }

  componentDidUpdate() {
    const {
      history,
      isUserFreeTrial,
      freeTrialSchools,
      userPrefFreeTrialPath
    } = this.props
    if (isUserFreeTrial && userPrefFreeTrialPath) {
      const redirectPath =
        userPrefFreeTrialPath === userPreferences.CURRICULUM_PATH
          ? routes.CURRICULUM_PATH_ROOT(freeTrialSchools.realSchoolID)
          : routes.SCHOOL_PATH_ROOT(freeTrialSchools.demoSchoolID)
      history.push(redirectPath)
    }
  }

  renderSchoolCards() {
    return createSection(null, this.props.schoolIDs, this.props.history)
  }

  renderLoadingSpinner() {
    return <Loading type="academy" />
  }

  renderEmptyState() {
    return 'No school data found.' // TODO - something better
  }

  render() {
    let content = this.renderSchoolCards()
    if (this.props.schoolIDs && !this.props.fetchingSchools) {
      if (!this.props.schoolIDs.size) {
        content = this.renderEmptyState()
      }
    }
    if (!this.props.userDataLoaded || !this.props.userPrefDataLoaded) {
      content = this.renderLoadingSpinner()
    }
    return <div className="district-overview page">{content}</div>
  }
}

const mapStateToProps = (state) => {
  return {
    schoolIDs: fromState.getIDsList(state, SCHOOLS),
    fetchingSchools: fromState.isFetching(state, SCHOOLS),
    userDataLoaded: fromState.userDataLoaded(state),
    userPrefDataLoaded: fromState.userPrefDataLoaded(state),
    isUserFreeTrial: fromState.isUserFreeTrial(state),
    freeTrialSchools: fromState.getFreeTrialSchoolIDs(state),
    userPrefFreeTrialPath: fromState.getUserPreference(
      state,
      userPreferences.FREE_TRIAL_PATH_CHOICE
    )
  }
}
const mapDispatchToProps = (dispatch) => ({
  loadData: () => {
    dispatch(coreActions.schools.read.begin())
  }
})

export default withRouter(
  withSchoolOrDistrictAdminRoleCheck(
    connect(mapStateToProps, mapDispatchToProps)(DistrictOverview)
  )
)
