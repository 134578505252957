import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import './mobileMenu.scss'
import WithVisibleStateToggle from '../hoc/withVisibleStateToggle'
import HamburgerMenuIcon from '../../images/icons/menu.svg'
import CloseIcon from '../../images/icons/close.svg'
import { getNavigationData } from '../../navigation-data'
import { withSchoolOrDistrictAdminRoleCheck } from '../hoc/withRoleCheckFor'
import { preventDefaultPropagation } from '../../helpers/utils'

export class MobileMenu extends WithVisibleStateToggle {
  generateChildLinks(links) {
    const listItems = links.map((link, index) => {
      const disabledClass = classnames({ disabled: link.disabled })
      const preventDefault = link.disabled ? preventDefaultPropagation : null
      return (
        <li key={`${link.label}-${index}`} className={disabledClass}>
          <Link onClick={preventDefault} to={link.url}>
            {link.label}
          </Link>
        </li>
      )
    })
    return <ul>{listItems}</ul>
  }

  generateMenuMarkup(navItems) {
    const menuItems = navItems.map((item, index) => {
      if (!item.enabled) {
        return null
      }
      const hasChildren = item.childLinks && item.childLinks.length
      const childLinks = hasChildren
        ? this.generateChildLinks(item.childLinks)
        : null

      const outerElement = hasChildren ? (
        <div
          className="mobile-menu-section"
          key={`mobile-menu-setion-${index}`}
          onClick={this.hideVisible}
          data-spec="mobile-menu-section"
        >
          <h2>
            {item.groupIcon}
            {item.groupLabel}
          </h2>
          {childLinks}
        </div>
      ) : (
        <h2
          key={`${item.groupLabel}-${index}`}
          onClick={this.hideVisible}
          data-spec="mobile-menu-link"
        >
          <Link to={item.url}>
            {item.groupIcon}
            {item.groupLabel}
          </Link>
        </h2>
      )

      return outerElement
    })
    return menuItems
  }

  render() {
    const {
      isSchoolAdmin,
      isDistrictAdmin,
      districtID,
      schoolID,
      disableDashboardLinks
    } = this.props
    const menuClassNames = classnames('mobile-menu', {
      visible: this.state.visible
    })
    const isAdmin = isSchoolAdmin || isDistrictAdmin
    const navItems = getNavigationData(
      districtID,
      schoolID,
      isAdmin,
      disableDashboardLinks
    )
    return (
      <div className={menuClassNames} data-spec="mobile-menu">
        <CloseIcon
          className="close-menu"
          onClick={this.toggleVisible}
          data-spec="close-icon"
        />
        <HamburgerMenuIcon
          className="hamburger-menu"
          onClick={this.toggleVisible}
          data-spec="hamburger-icon"
        />
        <div
          className="mobile-menu-container"
          data-spec="mobile-menu-container"
        >
          {this.generateMenuMarkup(navItems)}
        </div>
      </div>
    )
  }
}

export default withSchoolOrDistrictAdminRoleCheck(
  MobileMenu,
  true // force render
)
