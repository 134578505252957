import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PreviousIcon from '../../../images/icons/arrow-previous.svg'
import NextIcon from '../../../images/icons/arrow-next.svg'

import './pagination.scss'

class Pagination extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalPages: this.props.totalPages,
      pagesReady: false
    }
    this.scrollPages = this.scrollPages.bind(this)
    this.pageContainer = React.createRef()
    this.pages = React.createRef()
  }

  componentDidMount() {
    if (this.props.totalPages) {
      this.scrollPages()
      this.setState({
        pagesReady: true
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.totalPages) {
      if (prevState.totalPages !== this.props.totalPages) {
        this.setState({ totalPages: this.props.totalPages, pagesReady: true })
      }
      this.scrollPages()
    }
  }

  scrollPages() {
    if (this.pages.current) {
      const container = this.pageContainer.current
      const page = this.pages.current
      const pageWidth = page.offsetWidth
      const containerWidth = container.offsetWidth
      const goTo =
        (this.props.currentPage + 1) * (pageWidth + 8) - containerWidth
      container.scrollLeft = goTo
    }
  }

  leftArrow() {
    const { currentPage, setCurrentPage } = this.props
    if (currentPage > 0) {
      return (
        <div
          className="icon"
          data-spec="left-arrow"
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          <PreviousIcon />
        </div>
      )
    }
  }

  rightArrow() {
    const { currentPage, totalPages, setCurrentPage } = this.props
    if (currentPage < totalPages - 1) {
      return (
        <div
          className="icon"
          data-spec="right-arrow"
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <NextIcon />
        </div>
      )
    }
  }

  renderPages() {
    const { totalPages, currentPage, setCurrentPage } = this.props
    const pages = [...Array(totalPages).keys()].map((i) => {
      const displayPage = i + 1
      return (
        <div
          className={classnames('page-index', {
            current: currentPage === i
          })}
          data-spec="page"
          key={i}
          onClick={() => setCurrentPage(i)}
          ref={this.pages}
        >
          {displayPage}
        </div>
      )
    })
    const pagesContainer = (
      <div className="page-container" ref={this.pageContainer}>
        {pages}
      </div>
    )
    return pagesContainer
  }

  render() {
    return (
      <div className="pagination">
        {this.leftArrow()}
        {this.renderPages()}
        {this.rightArrow()}
      </div>
    )
  }
}

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func
}

export default Pagination
