import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './horizontalStackedBar.scss'

const HorizontalStackedBar = (props) => {
  const { sections, height } = props
  const sectionsElements = sections
    .filter((section) => {
      return section.size > 0
    })
    .map((section, index, array) => {
      const sectionStyle = {
        backgroundColor: section.color,
        flexGrow: section.size
      }
      const classes = classnames('section', {
        'singular-section': array.size === 1
      })
      return (
        <div
          className={classes}
          style={sectionStyle}
          key={`bar-section-${index}`}
        />
      )
    })
  const barStyle = { height: `${height}px` }
  return (
    <div
      className="stacked-bar-container"
      data-spec="horizontal-stacked-bar"
      style={barStyle}
    >
      {sectionsElements}
    </div>
  )
}

HorizontalStackedBar.defaultProps = {
  height: 24
}

HorizontalStackedBar.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      size: PropTypes.number.isRequired,
      color: PropTypes.string
    })
  ),
  height: PropTypes.number
}

export default HorizontalStackedBar
