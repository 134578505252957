import React from 'react'

import CoursesSelect from './coursesSelect'
import Loading from '../kiddom/loading/loading'
import CurriculumSharingPresenter from './sharing/curriculumSharingPresenter'
import StickyContainer from '../ui/stickyContainer/stickyContainer'
import GuidanceTile from '../common/guidance/guidanceTile'
import i18n from '../../helpers/i18n'
import {
  FIRST_COURSE_VIEWED,
  DEMO_SCHOOL_COURSE_VIEWED
} from '../../store/userPreferences'

import './course.scss'

const getTreeURL = (treeID) => `${window.WEB_APP}/curriculum/${treeID}`

export class CoursePresenter extends React.Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.props.trackCurriculumView && this.props.trackCurriculumView()
    this.tryToSetUserPref()
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(
        FIRST_COURSE_VIEWED,
        this.props.shouldSetUserPref
      )
      this.props.checkAndSetUserPref(
        DEMO_SCHOOL_COURSE_VIEWED,
        this.props.shouldSetDemoUserPref
      )
      this.userPrefSet = true
    }
  }

  render() {
    const {
      isLoading,
      schoolID,
      selectedCourseID,
      shouldRenderGuidance,
      subjectGroupID,
      curriculumID
    } = this.props

    if (isLoading) {
      return <Loading type="academy" />
    }

    return (
      <div className="course-presenter" ref={this.containerRef}>
        <StickyContainer containerRef={this.containerRef} triggerHeight={0}>
          <CoursesSelect
            selectedCourseID={selectedCourseID}
            subjectGroupID={subjectGroupID}
          />
          <div className="course-columns">
            <div className="course-units-column">
              <div className="bundled-curriculum">
                <h3>{i18n.t('labels.bundledCurriculumIsBestView')}</h3>
                <div>
                  <a
                    target="_blank"
                    href={getTreeURL(curriculumID)}
                    rel="noreferrer"
                  >
                    {i18n.t('labels.clickHere')}
                  </a>
                  {i18n.t('labels.toViewFullCurriculum')}
                </div>
              </div>
            </div>
            <div className="course-sidebar-column">
              <CurriculumSharingPresenter
                courseID={selectedCourseID}
                schoolID={schoolID}
                subjectGroupID={subjectGroupID}
              />
              {shouldRenderGuidance && (
                <GuidanceTile data-spec="free-trial-guidance" />
              )}
            </div>
          </div>
        </StickyContainer>
      </div>
    )
  }
}

export default CoursePresenter
