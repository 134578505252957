import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './progressCircle.scss'
import CheckmarkIcon from '../../../images/icons/checkmark.svg'

export const ProgressCircle = ({ innerHeight }) => {
  const innerCircleHeight = {
    height: innerHeight < 10 ? '10%' : innerHeight + '%'
  }
  const circleOuterClasses = classnames('circle-outer', {
    complete: innerHeight === 100
  })
  return (
    <div className={circleOuterClasses} data-spec="circle-outer">
      <div
        className="circle-inner"
        data-spec="circle-inner"
        style={innerCircleHeight}
      />
      <CheckmarkIcon />
    </div>
  )
}

ProgressCircle.defaultProps = {
  height: 10
}

ProgressCircle.propTypes = {
  theme: PropTypes.number
}

export default ProgressCircle
