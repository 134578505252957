import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ScheduleAppointmentButton from './scheduleAppointmentButton'

import './freeTrial.scss'
import FreeTrialDaysRemaining from './freeTrialDaysRemaining'

export const FreeTrialPresenter = (props) => {
  const classNames = classnames('freetrial-container', {
    'is-demo': props.isViewingDemoSchool
  })
  return (
    <div className={classNames}>
      <FreeTrialDaysRemaining expirationDate={props.expirationDate} />
      <ScheduleAppointmentButton
        isViewingDemoSchool={props.isViewingDemoSchool}
      />
    </div>
  )
}

FreeTrialPresenter.propTypes = {
  expirationDate: PropTypes.string.isRequired,
  isViewingDemoSchool: PropTypes.bool.isRequired
}

export default FreeTrialPresenter
