import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'

import Button from '../ui/buttons/button'
import EmailIcon from '../../images/icons/email.svg'
import LoadingDots from '../common/loading/loadingDots'
import Modal from '../ui/modal/modal'

import './inviteEmailButton.scss'

const sendEmailLabel = i18n.t('userActions.sendInviteEmails')
const emailSentLabel = i18n.t('labels.inviteEmailSent', { count: 1 })
const emailsSentLabel = (count) => i18n.t('labels.inviteEmailSent', { count })
const emailSendErrorLabel = i18n.t('errors.inviteEmailSendError')
const sendEmailInvitesTitle = i18n.t('titles.sendEmailInvitesTitle')
const sendInviteAction = i18n.t('userActions.sendInvite')
const confMsg1 = i18n.t('passages.sendEmailInvitesConfirmation_1')
const confMsg2 = i18n.t('passages.sendEmailInvitesConfirmation_2')

export const EMAIL_BUTTON_TYPE = { INGESTION: 'ingestion', BULK: 'bulk' }

const ModalMessage = ({ count }) => {
  const members = i18n.t('labels.selectedStaffMembers', {
    count
  })
  return (
    <p>
      {confMsg1}
      <b> {members} </b>
      {confMsg2}
    </p>
  )
}

const InviteEmailButtonPresenter = (props) => {
  const {
    className,
    count,
    isFailed,
    isLoading,
    setNotificationMessage,
    setNotificationType,
    type
  } = props

  const modalRef = useRef(null)

  const [clicked, setClicked] = useState(false)
  useEffect(() => {
    if (type === EMAIL_BUTTON_TYPE.BULK) {
      const wasEmailSent = clicked && !isLoading && !isFailed
      if (wasEmailSent) {
        setNotificationMessage(emailsSentLabel(count))
        setNotificationType('success')
      } else if (isFailed) {
        setNotificationMessage(emailSendErrorLabel)
        setNotificationType('')
      }
    }
  }, [isLoading, isFailed])

  const handleOnClick = () => {
    const { sendEmail, ingestionID } = props
    sendEmail(ingestionID)
    modalRef.current.hideVisible()
    setClicked(true)
  }

  const wasEmailSent = clicked && !isLoading && !isFailed
  const label = (wasEmailSent && emailSentLabel) || sendEmailLabel
  const loadingClass = classnames('loading-dots', {
    hidden: !isLoading
  })
  const buttonClass = classnames({
    hidden: !!isLoading
  })

  let trigger
  if (type === EMAIL_BUTTON_TYPE.INGESTION) {
    trigger = (
      <Button
        buttonStyle="text"
        title={sendInviteAction}
        disabled={wasEmailSent}
        className={buttonClass}
        label={label}
      />
    )
  } else {
    trigger = <EmailIcon title={sendInviteAction} className={className} />
  }

  return (
    <div className="send-email-button">
      <Modal triggerElement={trigger} ref={modalRef}>
        <div className="invite-confirmation">
          <h1>{sendEmailInvitesTitle}</h1>
          <div className="underline" />
          <ModalMessage count={count} />
          <Button
            data-spec="confirm-button"
            className="confirm-button"
            label={sendInviteAction}
            onClick={handleOnClick}
          />
        </div>
      </Modal>
      {type === EMAIL_BUTTON_TYPE.INGESTION && (
        <LoadingDots
          theme="dark"
          className={loadingClass}
          data-spec="sending-email-dots"
        />
      )}
    </div>
  )
}

InviteEmailButtonPresenter.defaultProps = {
  type: EMAIL_BUTTON_TYPE.INGESTION,
  setNotificationMessage: () => {},
  setNotificationType: () => {}
}

InviteEmailButtonPresenter.propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isFailed: PropTypes.bool.isRequired,
  setNotificationMessage: PropTypes.func,
  setNotificationType: PropTypes.func,
  type: PropTypes.oneOf(Object.values(EMAIL_BUTTON_TYPE)).isRequired
}

export default InviteEmailButtonPresenter
