import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import { sortSubjectGroups } from '../../helpers/subjectGroups'
import { subjectGroupShape } from '../../helpers/propShapes/common'

import Loading from '../kiddom/loading/loading'
import SubjectGroupCardContainer from './subjectGroupCardContainer'
import CustomizeDirectoryContainer from './customizeDirectoryContainer'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'

import './curriculumDirectory.scss'

export class CurriculumDirectoryPresenter extends React.Component {
  componentDidMount() {
    this.tryToTrackPageView()
    this.tryToSetUserPref()
  }

  componentDidUpdate(prevProps, _prevState) {
    const changedSchools =
      prevProps.schoolID !== this.props.schoolID && this.props.schoolName
    const justLoadedName = !prevProps.schoolName && this.props.schoolName
    if (changedSchools || justLoadedName) {
      this.tryToTrackPageView()
    }
    this.tryToSetUserPref()
  }

  tryToTrackPageView() {
    const { trackCurriculumDirectoryView, schoolName } = this.props
    trackCurriculumDirectoryView &&
      schoolName &&
      trackCurriculumDirectoryView(schoolName)
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(this.props.shouldSetUserPref)
      this.userPrefSet = true
    }
  }

  render() {
    const curriculumDirectoryLabel = i18n.t('titles.curriculumDirectory')
    const sortedSubjectGroups = this.props.subjectGroups.sort(sortSubjectGroups)

    return (
      <div className="curriculum-directory page">
        <header className="curriculum-directory-header">
          <h1>{curriculumDirectoryLabel}</h1>
          <CustomizeDirectoryContainer schoolID={this.props.schoolID} />
        </header>
        {this.props.isLoading && <Loading type="academy" />}
        <div className="subject-group-cards">
          {sortedSubjectGroups.map((subjectGroup, index) => (
            <SubjectGroupCardContainer
              key={subjectGroup.id}
              subjectGroupID={subjectGroup.id}
              index={index}
            />
          ))}
        </div>
      </div>
    )
  }
}

CurriculumDirectoryPresenter.defaultProps = {
  headerMinimized: false,
  isLoading: false,
  renderSidebar: false,
  subjectGroups: []
}

CurriculumDirectoryPresenter.propTypes = {
  headerMinimized: PropTypes.bool,
  isLoading: PropTypes.bool,
  renderSidebar: PropTypes.bool,
  subjectGroups: PropTypes.arrayOf(subjectGroupShape)
}

export default withOptionalSidebar(CurriculumDirectoryPresenter, GuidanceTile)
