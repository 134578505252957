import React, { RefObject } from 'react'

export interface IModalContext {
  appRootRef: RefObject<HTMLElement> | null
  modalRootRef: RefObject<HTMLElement> | null
}

export const ModalContext = React.createContext<IModalContext>({
  appRootRef: null,
  modalRootRef: null
})

export interface IModalProviderProps extends IModalContext {
  children: React.ReactNode | string
}

export function ModalProvider({
  children,
  appRootRef,
  modalRootRef
}: IModalProviderProps) {
  return (
    <ModalContext.Provider value={{ appRootRef, modalRootRef }}>
      {children}
    </ModalContext.Provider>
  )
}
