import Immutable from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import * as reducerCreators from './reducerCreators'

export const initialState = Immutable.fromJS({
  byID: {}
})

export const storeCurriculum = (state, action) => {
  return state.setIn(
    ['byID', action.curriculum.id],
    Immutable.fromJS(action.curriculum)
  )
}

const handlers = {
  [actionIDs.units.read.success]: storeCurriculum
}

const curriculum = reducerCreators.reducerCreator(initialState, handlers)

export default curriculum
