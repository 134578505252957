export const SCHOOL_VIEW_EVENT = 'Academy School View'
export const STUDENTS_VIEW_EVENT = 'Academy Students List'
export const TEACHERS_VIEW_EVENT = 'Academy Teachers List'
export const SINGLE_STUDENT_VIEW_EVENT = 'Academy Student View'
export const SINGLE_TEACHER_VIEW_EVENT = 'Academy Teacher View'
export const DASHBOARD_PATH_SELECTED = 'Academy Dashboard Path Selected'
export const CURRICULUM_PATH_SELECTED = 'Academy Curriculum Path Selected'

export const COURSES_VIEW = 'Academy Courses View'
export const CURRICULUM_VIEW = 'Academy Curriculum View'
export const REQUEST_QUOTE = 'Academy Request Quote'
