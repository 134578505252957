import { authSendRequest } from '../../helpers/sendRequest'
import {
  SIS_ID_PREFIX,
  KIDDOM_SCHOOL,
  SIS_SCHOOL
} from '../../helpers/constants'

export const sendSISCredentials = (
  districtID,
  clientID,
  clientSecret,
  clientHost
) => {
  const url = `${window.API_SERVER}/v1/district/${districtID}/sis/connect`
  const body = {
    credential: {
      key: clientID,
      secret: clientSecret,
      endpoint: clientHost,
      type: 'oneroster'
    }
  }
  return authSendRequest('POST', url, body)
}

export const fetchSISCredentials = () => {
  const url = `${window.API_SERVER}/v1/sis/district/credentials`
  return authSendRequest('GET', url)
}

export const fetchSISMatches = (districtID) => {
  const url = `${window.API_SERVER}/v1/district/${districtID}/schools/sis/match`
  return authSendRequest('GET', url)
}

export const updateSISMatches = (districtID, pairs) => {
  const url = `${window.API_SERVER}/v1/district/${districtID}/schools/sis/match?sync=true`
  const body = {
    pairs: pairs
  }
  return authSendRequest('POST', url, body)
}

export const fetchSISConfigurations = (districtID) => {
  const url = `${window.API_SERVER}/v1/district/${districtID}/sis/sync/settings`
  return authSendRequest('GET', url)
}

const buildFilters = (filters, type, ids) => {
  filters.push({
    type: type,
    ids: Array.from(ids)
  })
  return filters
}

export const updateSISConfigurations = (
  districtID,
  schedule,
  schoolIDs,
  gradeIDs,
  subjectIDs
) => {
  const url = `${window.API_SERVER}/v1/district/${districtID}/sis/sync/settings`
  let filters = buildFilters([], 'school', schoolIDs)
  filters = buildFilters(filters, 'grade', gradeIDs)
  filters = buildFilters(filters, 'subject', subjectIDs)

  const body = {
    enable_daily_sync: true,
    schedule: schedule,
    filters: filters
  }

  return authSendRequest('POST', url, body)
}

const createSchoolObject = (school, isSISSchool = false) => {
  return {
    // trim so that names with spaces are not ahead when sorted
    name: school.name.trim(),
    id: isSISSchool ? `${SIS_ID_PREFIX}${school.id}` : school.id.toString(),
    type: isSISSchool ? SIS_SCHOOL : KIDDOM_SCHOOL
  }
}

const createSchoolsMapping = (schools, sisSchools) => {
  const schoolsMap = {}
  if (!schools || !sisSchools) {
    return schoolsMap
  }
  schools.forEach((school) => {
    schoolsMap[school.id.toString()] = createSchoolObject(school)
  })
  sisSchools.forEach((sisSchool) => {
    schoolsMap[`${SIS_ID_PREFIX}${sisSchool.id}`] = createSchoolObject(
      sisSchool,
      true
    )
  })
  return schoolsMap
}

// preprocess with existing pairs
const processAndCreateMapping = ({ schools, sis_schools, pairs }) => {
  // create schools map from schools and sis_schools array
  const combinedSchoolsMap = createSchoolsMapping(schools, sis_schools)
  if (!pairs || pairs.length === 0) {
    return combinedSchoolsMap
  }
  // iterate through pairs and remove from combinedSchoolsMap
  pairs.forEach((pair) => {
    const id = pair.id.toString()
    const sisID = `${SIS_ID_PREFIX}${pair.sis_id}`
    const kiddomSchool = { ...combinedSchoolsMap[id] }
    const sisSchool = { ...combinedSchoolsMap[sisID] }

    // delete pair
    delete combinedSchoolsMap[id]
    delete combinedSchoolsMap[sisID]

    // combine pair into one and add back to list
    // use kiddom school id and name as identifiers
    combinedSchoolsMap[id] = {
      id,
      name: kiddomSchool.name,
      pairData: {
        [id]: kiddomSchool,
        [sisID]: sisSchool
      }
    }
  })
  return combinedSchoolsMap
}

export const transformMatches = (data) => {
  const schoolsMap = processAndCreateMapping(data)
  data.schoolsMap = schoolsMap
  return data
}
