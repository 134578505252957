import React from 'react'
import PropTypes from 'prop-types'

// Kiddoms
import PreviousIcon from '../../../images/icons/arrow-previous.svg'
import NextIcon from '../../../images/icons/arrow-next.svg'
import './cardCarousel.scss'

class CardCarousel extends React.Component {
  constructor(props) {
    super(props)
    this.cardsContainer = React.createRef()
  }

  navigateCarousel(direction) {
    const container = this.cardsContainer.current
    const containerWidth = container.offsetWidth
    const currentScroll = container.scrollLeft
    const goTo = currentScroll + containerWidth * direction
    container.scrollLeft = goTo
  }

  render() {
    const { cards } = this.props

    return (
      <div className="card-carousel">
        <div className="icon" onClick={this.navigateCarousel.bind(this, -1)}>
          <PreviousIcon />
        </div>
        <div className="cards-container" ref={this.cardsContainer}>
          {cards}
        </div>
        <div className="icon" onClick={this.navigateCarousel.bind(this, 1)}>
          <NextIcon />
        </div>
      </div>
    )
  }
}

CardCarousel.defaults = {
  cards: []
}

CardCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.node)
}

export default CardCarousel
