import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import SchoolOverviewPresenter from './schoolOverviewPresenter'
import { TEACHERS } from '../../store/models/resources'
import { SCHOOL_VIEW_EVENT } from '../../store/eventIDs'
import withDataLoading from '../hoc/withDataLoading'
import { withSchoolOrDistrictAdminRoleCheck } from '../hoc/withRoleCheckFor'

const initiatedTour = 'initiatedTour'

class SchoolOverviewContainer extends React.Component {
  componentDidMount() {
    this.props.trackPageViewed()
    this.startIntercomTour()
  }

  componentDidUpdate(_prevProps) {
    this.startIntercomTour()
  }

  startIntercomTour = () => {
    const schoolID = this.props.match.params.schoolID
    if (
      this.props.hasLoaded &&
      this.props.hasInitiatedTour !== 'true' &&
      this.props.demoSchoolID === schoolID
    ) {
      this.props.initiatedIntercomTour()
      window.Intercom('startTour', window.INTERCOM_TOURS.dashboard)
    }
  }

  render() {
    const schoolID = this.props.match.params.schoolID
    return (
      <SchoolOverviewPresenter
        schoolID={schoolID}
        activeTeachersPercent={this.props.activeTeachersPercent}
        activatedTeachersPercent={this.props.activatedTeachersPercent}
        activeStudentsPercent={this.props.activeStudentsPercent}
        activatedStudentsPercent={this.props.activatedStudentsPercent}
        renderSidebar={this.props.renderSidebar}
        emptyChartData={this.props.emptyChartData}
        studentEmptyChartData={this.props.studentEmptyChartData}
      />
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const emptyChartData = !fromState.hasTeacherActivityChartData(state, schoolID)
  const studentEmptyChartData = !fromState.hasStudentAchievementData(
    state,
    schoolID
  )
  // Teacher Percentages
  const activeTeachersPercent = fromState.getActivePercent(state, TEACHERS)
  const activatedTeachersPercent = fromState.getActivatedPercent(
    state,
    TEACHERS
  )

  // STUDENTS
  const activeStudentsPercent = fromState.getActiveStudentPercent(state)
  const activatedStudentsPercent = fromState.getActivatedStudentPercent(state)

  const renderSidebar = fromState.isUserFreeTrial(state)
  const demoSchoolID = fromState.getFreeTrialSchoolIDs(state).demoSchoolID

  return {
    activeTeachersPercent,
    activatedTeachersPercent,
    activeStudentsPercent,
    activatedStudentsPercent,
    renderSidebar,
    emptyChartData,
    studentEmptyChartData,
    demoSchoolID,
    hasInitiatedTour: fromState.getUserPreference(state, initiatedTour)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    trackPageViewed: () =>
      dispatch(
        coreActions.trackEvent.create.begin(SCHOOL_VIEW_EVENT, {
          schoolID
        })
      ),
    initiatedIntercomTour: () =>
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: initiatedTour,
              value: 'true'
            }
          ],
          true // shouldSetUserPref
        )
      )
  }
}
const generateDataLoaders = (props) => [
  coreActions.teachers.read.begin(props.match.params.schoolID),
  adminActions.inactiveTeachers.read.begin(props.match.params.schoolID),
  adminActions.unactivatedTeachers.read.begin(props.match.params.schoolID),
  adminActions.studentActivityLists.read.begin(props.match.params.schoolID),
  coreActions.user.readPrefs.begin()
]

const generateHasLoadedCheckers = (_props) => ({
  hasLoaded: [[fromState.userPrefDataLoaded, [[]]]]
})

const reloadProps = ['schoolID']
export default withRouter(
  withSchoolOrDistrictAdminRoleCheck(
    withDataLoading(
      connect(mapStateToProps, mapDispatchToProps)(SchoolOverviewContainer),
      generateDataLoaders,
      generateHasLoadedCheckers,
      reloadProps
    )
  )
)
