import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import { USER_MANAGEMENT_ROLES } from '../../helpers/constants'

import AccountListContainer from './accountListContainer'
import AccountTypeTabs from './accountTypeTabs'
import AddIcon from '../../images/icons/add.svg'
import Button from '../ui/buttons/button'
import BulkUserUploadContainer from './bulkUserUploadContainer'
import ImportSuccessContainer from './importSuccessContainer'
import ReviewBulkAdd from './reviewBulkAdd'
import Loading from '../kiddom/loading/loading'

import './userManagement.scss'

const accountsTitle = i18n.t('titles.accounts')
const addLabel = i18n.t('labels.add')
const addAccountsLabel = i18n.t('userActions.addAccounts')

const AddAccountsButton = (props) => {
  const { hasUsers, role, showUpload, setShowUpload, clearImport } = props
  const className = classnames({
    hidden: !hasUsers
  })
  return (
    <Button
      className={className}
      data-spec="add-accounts-button"
      buttonStyle="text"
      onClick={() => {
        setShowUpload(!showUpload)
        clearImport(role)
      }}
      label={addAccountsLabel}
      icon={<AddIcon title={addLabel} />}
    />
  )
}

const initialDismissed = USER_MANAGEMENT_ROLES.reduce((result, role) => {
  result[role] = false
  return result
}, {})

const UserManagementPresenter = (props) => {
  const {
    hasLoaded,
    accountType,
    didFileImportFail,
    hasUsers,
    ingestionID,
    isImportingFile,
    numUsersAdded,
    reviewUsers,
    match: {
      params: { schoolID }
    },

    // actions
    clearErrors,
    clearImport,
    registerUsers,
    setAccountType
  } = props
  const role = accountType.role

  const [showUpload, setShowUpload] = useState(false)
  useEffect(
    () =>
      setShowUpload(
        hasLoaded && (!hasUsers || isImportingFile || didFileImportFail)
      ),
    [hasUsers, isImportingFile, didFileImportFail, accountType.role, hasLoaded]
  )

  const [notificationDismissed, setNotificationDismissed] = useState(
    initialDismissed
  )

  const [showNotification, setShowNotification] = useState(false)
  useEffect(() => setShowNotification(!isImportingFile && !!numUsersAdded), [
    isImportingFile,
    numUsersAdded
  ])

  const [showBulkAdd, setShowBulkAdd] = useState(
    !isImportingFile && !!reviewUsers && !!reviewUsers.length
  )
  useEffect(
    () =>
      setShowBulkAdd(!isImportingFile && !!reviewUsers && !!reviewUsers.length),
    [isImportingFile, reviewUsers, role]
  )

  useEffect(() => USER_MANAGEMENT_ROLES.forEach(clearImport), [])

  const bulkUploadClasses = classnames({
    remove: !showUpload
  })

  const content = (
    <React.Fragment>
      <ImportSuccessContainer
        showNotification={showNotification && !notificationDismissed[role]}
        setNotificationDismissed={(value) =>
          setNotificationDismissed({ ...notificationDismissed, [role]: value })
        }
        role={role}
        schoolID={schoolID}
      />
      <ReviewBulkAdd
        ingestionID={ingestionID}
        schoolID={schoolID}
        showBulkAdd={showBulkAdd}
        users={reviewUsers}
        dismiss={() => clearErrors(role)}
        registerUsers={registerUsers}
        role={role}
      />
      <div className={bulkUploadClasses} data-spec="bulk-upload">
        <BulkUserUploadContainer
          schoolID={schoolID}
          role={role}
          handleClose={() => setShowUpload(false)}
          isInline={hasUsers}
        />
      </div>
      <AccountListContainer role={role} />
    </React.Fragment>
  )

  return (
    <div className="user-management page">
      <h1>{accountsTitle}</h1>
      <div className="user-actions">
        <AccountTypeTabs
          selected={accountType}
          setAccountType={setAccountType}
        />
        <AddAccountsButton
          hasUsers={hasUsers}
          accountType={accountType}
          showUpload={showUpload}
          setShowUpload={setShowUpload}
          clearImport={clearImport}
          role={role}
        />
      </div>
      {!hasLoaded && <Loading type="academy" />}
      {content}
    </div>
  )
}

export default UserManagementPresenter
