import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  coursesLoaded,
  isUserFreeTrial,
  isSchoolFreeTrialAndDemo,
  isSchoolFreeTrialAndNotDemo,
  resourcesLoaded,
  subjectGroupsLoadedForSchool,
  userPrefDataLoaded,
  getCourseCurriculumID,
  getCourseTreeID
} from '../../store/fromState'
import * as resources from '../../store/models/resources'
import * as coreActions from '../../store/actions/coreActions'
import * as curriculumActions from '../../store/actions/curriculumActions'
import withDataLoading from '../hoc/withDataLoading'

import { CURRICULUM_VIEW } from '../../store/eventIDs'

import CoursePresenter from './coursePresenter'

const CourseContainer = (props) => <CoursePresenter {...props} />

export const mapDispatchToProps = (dispatch) => ({
  trackCurriculumView: () => {
    dispatch(coreActions.trackEvent.create.begin(CURRICULUM_VIEW))
  },
  checkAndSetUserPref: (userPref, shouldSetUserPref) => {
    dispatch(
      coreActions.user.writePrefs.begin(
        [
          {
            key: userPref,
            value: 'true'
          }
        ],
        shouldSetUserPref
      )
    )
  }
})

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const courseID = ownProps.match.params.courseID

  const shouldRenderGuidance = isUserFreeTrial(state)
  const shouldSetUserPref = isSchoolFreeTrialAndNotDemo(
    state,
    ownProps.match.params.schoolID
  )
  const shouldSetDemoUserPref = isSchoolFreeTrialAndDemo(
    state,
    ownProps.match.params.schoolID
  )

  return {
    isLoading: !ownProps.hasLoaded,
    schoolID,
    selectedCourseID: courseID,
    shouldRenderGuidance,
    shouldSetDemoUserPref,
    shouldSetUserPref,
    subjectGroupID: ownProps.match.params.subjectGroupID,
    userPrefDataLoaded: userPrefDataLoaded(state),
    treeID: getCourseTreeID(state, courseID),
    curriculumID: getCourseCurriculumID(state, courseID)
  }
}

const generateDataLoaders = (props) => [
  coreActions.subjects.read.begin(),
  curriculumActions.skillGroups.read.begin(),
  curriculumActions.schoolSubjectGroups.read.begin(props.match.params.schoolID),
  curriculumActions.units.read.begin(
    props.match.params.subjectGroupID,
    props.match.params.courseID
  )
]

const generateHasLoadedCheckers = (props) => ({
  hasLoaded: [
    [resourcesLoaded, [[resources.SUBJECTS, resources.COURSES]]],
    [subjectGroupsLoadedForSchool, [props.match.params.schoolID]],
    [coursesLoaded, [props.match.params.subjectGroupID]]
  ]
})

const reloadProps = [
  (props) => props.match.params.courseID,
  (props) => props.match.params.schoolID
]

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(CourseContainer),
    generateDataLoaders,
    generateHasLoadedCheckers,
    reloadProps
  )
)
