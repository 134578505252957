import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { FEATURE_ACCESS_ID } from '../../helpers/constants'
import * as fromState from '../../store/fromState'
import FreeTrialPresenter from './freeTrialPresenter'

const FreeTrialContainer = (props) => <FreeTrialPresenter {...props} />

export const mapStateToProps = (state, ownProps) => {
  state = state.parrot || state
  const isUserInFreeTrial = fromState.userInFeature(
    state,
    FEATURE_ACCESS_ID.fullAcademy
  )
  const demoSchoolID = fromState.getFreeTrialSchoolIDs(state).demoSchoolID
  const isViewingDemoSchool =
    isUserInFreeTrial &&
    demoSchoolID &&
    ownProps.match.params.schoolID === demoSchoolID
  return {
    expirationDate: fromState.featureExpiration(
      state,
      FEATURE_ACCESS_ID.fullAcademy
    ),
    isViewingDemoSchool
  }
}

export default withRouter(connect(mapStateToProps)(FreeTrialContainer))
