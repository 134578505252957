import React from 'react'

import PropType from 'prop-types'

import './menuItem.scss'

export class MenuItem extends React.Component {
  generateItemIcon() {
    return (
      <span className="menu-item-icon" data-spec={this.props.name + '-icon'}>
        {this.props.icon}
      </span>
    )
  }

  generateItemLabel() {
    return (
      <span className="menu-item-label" data-spec={this.props.name + '-label'}>
        {this.props.label}
      </span>
    )
  }

  render() {
    const itemIcon = this.generateItemIcon()
    const itemLabel = this.generateItemLabel()

    return (
      <li
        className={`menu-item ${this.props.name}`}
        data-spec={this.props.name}
      >
        <button
          disabled={this.props.disabled}
          data-spec={this.props.name + '-button'}
          onClick={this.props.onClick}
        >
          {itemIcon}
          {itemLabel}
        </button>
      </li>
    )
  }
}

MenuItem.propTypes = {
  label: PropType.oneOfType([PropType.string, PropType.node]).isRequired,
  icon: PropType.object.isRequired,
  name: PropType.string.isRequired,
  onClick: PropType.func,
  disabled: PropType.bool,
  className: PropType.string
}

MenuItem.defaultProps = {
  onClick: () => {},
  disabled: false,
  className: ''
}

export default MenuItem
