class ScrollLock {
  locked: { [key: string]: boolean } = {}

  overflowSave: string = ''

  element: HTMLElement

  constructor(element: HTMLElement) {
    this.element = element
  }

  isLocked() {
    return Object.values(this.locked).length
  }

  lock(lockedBy: string) {
    if (!this.isLocked()) {
      this.overflowSave = this.element?.style.overflow
      this.element.style.overflow = 'hidden'
    }
    // add this owner to the locked map
    this.locked[lockedBy] = true
  }

  unlock(lockedBy: string) {
    // remove this owner from the locked map
    delete this.locked[lockedBy]
    if (!this.isLocked()) {
      this.element.style.overflow = this.overflowSave
      this.overflowSave = ''
    }
  }
}

// keep a weak map of elements to keep track of locks.
const elements = new WeakMap<HTMLElement, ScrollLock>()

// useLockScrolling(element: HTMLElement, ownerName: string)
// usage create a scroll lock that will lock an html elments scrollbar until unlocked
// -- params --
// element: any html element you want to scroll lock
// ownerName: a unique string that will identify the locker/unlocker
// --- description---
// only one lock can exists per element,
// many owners can have access to the lock tied to one element.
// the element will remain locked unless all owners have unlocked or never locked
export function useLockScrolling(element: HTMLElement, ownerName: string) {
  // if we already have a lock for this element, use it
  let currentLock = elements.get(element)
  if (!currentLock) {
    // a lock for this element doesn't exist, so create it.
    currentLock = new ScrollLock(element)
    elements.set(element, currentLock)
  }

  return {
    lock: () => currentLock?.lock(ownerName),
    unlock: () => currentLock?.unlock(ownerName)
  }
}
