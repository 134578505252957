import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Kiddoms
import {
  SCHOOL_PATH_PREFIX,
  USER_MANAGEMENT_ROUTE,
  SIS_INTEGRATION_ROUTE
} from './routes'
import SchoolOverviewContainer from '../components/school/schoolOverviewContainer'
import ClassOverviewContainer from '../components/class/classOverviewContainer'
import TeacherRouter from './teacherRouter'
import StudentRouter from './studentRouter'
import CurriculumRouter from './curriculumRouter'
import UserManagementRouter from './userManagementRouter'
import SISIntegrationRouter from './sisIntegrationRouter'

const classPath = `${SCHOOL_PATH_PREFIX}/classes/:classID`

const teacherPathPrefix = `${SCHOOL_PATH_PREFIX}/teachers`
const studentsPathPrefix = `${SCHOOL_PATH_PREFIX}/students`
const curriculumPathPrefix = `${SCHOOL_PATH_PREFIX}/curriculum`

const SchoolRouter = () => (
  <Switch>
    <Route
      exact
      path={SCHOOL_PATH_PREFIX}
      component={SchoolOverviewContainer}
    />
    <Route exact path={classPath} component={ClassOverviewContainer} />
    <Route path={teacherPathPrefix} component={TeacherRouter} />
    <Route path={studentsPathPrefix} component={StudentRouter} />
    <Route path={curriculumPathPrefix} component={CurriculumRouter} />
    <Route path={USER_MANAGEMENT_ROUTE} component={UserManagementRouter} />
    <Route path={SIS_INTEGRATION_ROUTE} component={SISIntegrationRouter} />
  </Switch>
)

export default SchoolRouter
