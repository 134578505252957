import i18n from './i18n'

export const TINYMCE_API_KEY =
  'iv7ewu50uvpsg59w21rvmljawny4eru6asxqs32y4ayda3rc'

export const EMAIL_REGEX = /^(.+)@(.+)\.(.+)$/

export const ROLE = {
  TEACHER: 'teacher',
  SCHOOL_ADMIN: 'administrator',
  DISTRICT_ADMIN: 'district-admin',
  STUDENT: 'student'
}
export const CURRICULUM_OWNER = 'owner'
export const CURRICULUM_CO_OWNER = 'object-co-owner'
export const CURRICULUM_COLLABORATOR = 'object-collaborator'
export const CURRICULUM_EDITOR_ROLES = new Set([
  CURRICULUM_OWNER,
  CURRICULUM_CO_OWNER
])
export const CURRICULUM_ACCESS_ROLES = [
  {
    text: i18n.t('labels.viewOnly'),
    role: CURRICULUM_COLLABORATOR
  },
  {
    text: i18n.t('labels.edit'),
    role: CURRICULUM_CO_OWNER
  }
]

export const KIDDOM_MIDDLE_SCHOOL_ID = 1102840

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DEFAULT_PAGE_SIZE = 50
export const DEFAULT_CARD_PAGE_SIZE = 48
export const DEFAULT_RETRY_DELAY = 10

export const AVATAR_FALLBACK_SRC =
  'https://s3.amazonaws.com/kiddomteacherdashboard/avatars/New_Teach/TM01.png'

export const NOT_APPLICABLE = 'N/A'
export const NO = 'No'

export const KEY_CODES = {
  DOWN: 40,
  UP: 38,
  ENTER: 13,
  ESCAPE: 27
}

export const UPLOAD_S3_BUCKET_PREFIX = '/academy-uploads'

export const subjectGroupTypes = {
  CORE: 'core',
  ELECTIVE: 'elective'
}

export const FEATURE_ACCESS_ID = {
  fullAcademy: 1
}

export const APPOINTLET_SERVICE_ID = {
  getAcademyQuote: '111189'
}

export const SUCCESS_EMAIL = 'success@kiddom.co'
export const SUPPORT_EMAIL = 'support@kiddom.co'

// Error labels in API response from bulk user file upload endpoint
export const BLANK_NAME_ERROR_LABEL = 'blank_name'
export const INVALID_EMAIL_ERROR_LABEL = 'invalid_email'

// Accept types for file input fields
export const FILE_TYPE_CSV = '.csv'

export const ACCOUNT_TYPES = {
  STAFF: { label: i18n.t('titles.staff'), role: ROLE.TEACHER },
  STUDENTS: { label: i18n.t('titles.students'), role: ROLE.STUDENT }
}

export const USER_MANAGEMENT_ROLES = Object.values(ACCOUNT_TYPES).map(
  (accountType) => accountType.role
)

export const USER_MANAGEMENT_BULK_ACTIONS = {
  SEND_INVITE_EMAIL: 'sendInvite'
}

export const PARENT_PERMISSION_LETTER_LINK =
  'https://docs.google.com/document/d/1yar22ebdfeoh4kchPxhpvClVkS5Gv4NsnjCebqXvaKg'

export const CLASSROOM = 'classroom'
export const ACADEMY = 'academy'

export const SIS_STATE = Object.freeze({
  FETCH_CREDENTIALS: 1,
  SEND_CREDENTIALS: 2,
  FETCH_SCHOOL_MATCHES: 3,
  MATCH_SCHOOLS: 4,
  SYNC_SCHOOLS: 5,
  SET_CONFIGURATION: 6
})

export const SIS_ID_PREFIX = 'sis-'
export const KIDDOM_SCHOOL = 'kiddomSchool'
export const SIS_SCHOOL = 'sisSchool'
