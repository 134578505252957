import React from 'react'
import classnames from 'classnames'

import '../common/sidebar/sidebar.scss'

// HOC for a page with an optional sidebar component
// Page and sidebar will be split on the page in a 2:1 ratio
// (param) WrappedComponent: main page presenter component
// (param) SidebarComponent: sidebar component
// (returns) 1 searchably enhanced component
// Props:
// - renderSidebar: boolean for whether sidebar should be rendered or not

export const withOptionalSidebar = (WrappedComponent, SidebarComponent) => (
  props
) => {
  const { renderSidebar } = props
  const mainClasses = classnames('main-section', {
    'with-sidebar': renderSidebar
  })
  return !renderSidebar ? (
    <WrappedComponent {...props} />
  ) : (
    <div className="optional-sidebar">
      <div className={mainClasses}>
        <WrappedComponent {...props} />
      </div>
      {renderSidebar && (
        <div className="sidebar-section">
          <div className="fixed-container">
            <div className="sidebar-content-container">
              <SidebarComponent data-spec="sidebar-content" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default withOptionalSidebar
