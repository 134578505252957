import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../../helpers/i18n'

import UndoIcon from '../../../images/icons/undo.svg'
import './undo.scss'

export const Undo = ({ altText, onClick }) => (
  <div data-spec="undo" className="undo" onClick={onClick}>
    <UndoIcon alt={altText} />
    {i18n.t('labels.undo')}
  </div>
)

Undo.propTypes = {
  onClick: PropTypes.func.isRequired,
  altText: PropTypes.string.isRequired
}
export default Undo
