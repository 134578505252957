import React from 'react'
import { Route, Switch } from 'react-router-dom'

// Kiddoms
import { SCHOOL_PATH_PREFIX } from './routes'
import StudentActivityListPageContainer from '../components/student/studentActivityListPageContainer'
import StudentsOverviewContainer from '../components/student/studentsOverviewContainer'
import StudentsAtRiskOverviewContainer from '../components/student/studentsAtRiskOverviewContainer'
import StudentOverviewContainer from '../components/student/studentOverviewContainer'
import StudentClassOverviewContainer from '../components/student/studentClassOverviewContainer'
import { withSchoolOrDistrictAdminRoleCheck } from '../components/hoc/withRoleCheckFor'

const studentsPath = `${SCHOOL_PATH_PREFIX}/students`
const studentPath = `${SCHOOL_PATH_PREFIX}/students/:studentID`
const studentClassPath = `${studentPath}/classes/:classID`
const studentsAtRiskPath = `${SCHOOL_PATH_PREFIX}/at-risk`
const inactiveStudentsPath = `${SCHOOL_PATH_PREFIX}/students/inactive`
const unactivatedStudentsPath = `${SCHOOL_PATH_PREFIX}/students/unactivated`

const InactiveStudents = (routeProps) => (
  <StudentActivityListPageContainer {...routeProps} listType="inactive" />
)
const UnactivatedStudents = (routeProps) => (
  <StudentActivityListPageContainer {...routeProps} listType="unactivated" />
)

const StudentRouter = () => (
  <Switch>
    <Route exact path={studentsPath} component={StudentsOverviewContainer} />
    <Route
      exact
      path={studentsAtRiskPath}
      component={StudentsAtRiskOverviewContainer}
    />
    <Route exact path={inactiveStudentsPath} render={InactiveStudents} />
    <Route exact path={unactivatedStudentsPath} render={UnactivatedStudents} />
    <Route exact path={studentPath} component={StudentOverviewContainer} />
    <Route
      exact
      path={studentClassPath}
      component={StudentClassOverviewContainer}
    />
  </Switch>
)

export default withSchoolOrDistrictAdminRoleCheck(StudentRouter)
