import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroup from '../../ui/buttonGroup/buttonGroup'
import Loading from '../../kiddom/loading/loading'

import './cardsPage.scss'

class CardsPage extends React.Component {
  renderCards() {
    if (!this.props.cards || !this.props.cards.length) {
      return <div className="empty">{this.props.emptyMessage}</div>
    }
    return <div className="card-container">{this.props.cards}</div>
  }

  renderLoading() {
    return <Loading type="academy" />
  }

  render() {
    const classnames = `${this.props.pageClassName} cards-page page`
    const content = this.props.loading
      ? this.renderLoading()
      : this.renderCards()
    const navigationButtons = <ButtonGroup {...this.props.buttonProps} />
    return (
      <div className={classnames}>
        <div className="title-bar">
          <h1>{this.props.pageTitle}</h1>
          {navigationButtons}
        </div>
        <p>{this.props.subtitle}</p>
        {content}
      </div>
    )
  }
}

CardsPage.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageClassName: PropTypes.string.isRequired
}

export default CardsPage
