import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import Dropdown from '../../images/icons/dropdown.svg'
import { getNavigationData } from '../../navigation-data'
import Popup from '../ui/popup/popup'
import MobileMenu from './mobileMenu'
import { withSchoolOrDistrictAdminRoleCheck } from '../hoc/withRoleCheckFor'
import { preventDefaultPropagation } from '../../helpers/utils'

const generatePopupMenuBody = (navItem) => {
  const links = navItem.childLinks.map((item, index) => {
    const disabledClass = classnames({ disabled: item.disabled })
    const preventDefault = item.disabled ? preventDefaultPropagation : null
    return (
      <li
        data-spec={item.label}
        className={disabledClass}
        key={`${item.label}-${index}`}
      >
        <Link onClick={preventDefault} to={item.url}>
          {item.label}
        </Link>
      </li>
    )
  })

  return (
    <div className="popup-body">
      <h4>{navItem.groupLabel}</h4>
      <ul className="nav-links">{links}</ul>
    </div>
  )
}

const linkedNavItem = (item, index, currentLocation) => {
  return (
    <Link
      className={classnames('menu-item', {
        current: item.isCurrent && item.isCurrent(currentLocation)
      })}
      to={item.isOutsideLink ? '#' : item.url}
      key={`${item.groupLabel}-${index}`}
      data-spec="menu-item"
      onClick={item.isOutsideLink ? () => window.open(item.url) : null}
    >
      <div className="menu-icon">{item.groupIcon}</div>
      <span>{item.groupLabel}</span>
    </Link>
  )
}

const dropdownNavItem = (item, index) => {
  const content = (
    <div className="menu-item" key={`${item.groupLabel}-${index}`}>
      <div className="menu-icon">{item.groupIcon}</div>
      <span>{item.groupLabel}</span>
      <Dropdown className="dropdown" />
    </div>
  )
  return (
    <Popup triggerElement={content} key={`popup-${index}`}>
      {generatePopupMenuBody(item)}
    </Popup>
  )
}

export const NavigationMenu = (props) => {
  const isAdmin = props.isSchoolAdmin || props.isDistrictAdmin

  const navItems = getNavigationData(
    props.districtID,
    props.schoolID,
    isAdmin,
    props.disableDashboardLinks
  )
  const menuItems = navItems.map((item, index) => {
    if (!item.enabled) {
      return null
    }
    const hasChildren = item.childLinks && item.childLinks.length
    return hasChildren
      ? dropdownNavItem(item, index)
      : linkedNavItem(item, index, props.location)
  })
  return (
    <div className="menu">
      <MobileMenu
        districtID={props.districtID}
        disableDashboardLinks={props.disableDashboardLinks}
        schoolID={props.schoolID}
      />
      {menuItems}
    </div>
  )
}

export default withRouter(
  withSchoolOrDistrictAdminRoleCheck(
    NavigationMenu,
    true // force render
  )
)
