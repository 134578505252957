import { fromJS, List } from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import * as reducerCreators from './reducerCreators'

import { ROLE, USER_MANAGEMENT_ROLES } from '../../helpers/constants'

const buildInitialState = (state, role) => {
  state[`${role}FileImport`] = {}
  state.fetching[`${role}FileImport`] = false
  state.error[`${role}FileImport`] = null
  state.error[`${role}AddUsers`] = null

  if (role === ROLE.TEACHER) {
    state.error.sendInviteEmail = {}
  }
}

export const initialState = (() => {
  const state = {
    fetching: {},
    error: {},
    studentAccounts: { byIngestionID: {} }
  }
  USER_MANAGEMENT_ROLES.forEach((role) => buildInitialState(state, role))
  return fromJS(state)
})()

const parseImportFileOptions = {
  fetchingLocation: (state, action, fetching) => {
    const fileName = action.file[0].name
    let newState = state
    newState = newState.setIn(['activeImportFile'], fileName)
    newState = newState.setIn(
      ['fetching', `${action.role}FileImport`],
      fetching
    )
    return newState
  },
  placementFunction: (state, action) => {
    let newState = state
    newState = newState.setIn(
      [`${action.role}FileImport`],
      fromJS(action.response)
    )
    newState = newState.set('activeImportFile', null)
    return newState
  },
  errorLocation: (state, action, error) =>
    state.setIn(['error', `${action.role}FileImport`], error)
}

const addUsersOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', `${action.role}AddUsers`], fetching),
  placementFunction: (state, action) => {
    const numUsersAddedPath = [
      `${action.role}FileImport`,
      'schools',
      0,
      'numUsersAdded'
    ]
    const previousNumUsersAdded = state.getIn(numUsersAddedPath) || 0
    const newNumUsersAdded =
      (action && action.response && action.response.numUsersAdded) || 0
    return state.setIn(
      numUsersAddedPath,
      previousNumUsersAdded + newNumUsersAdded
    )
  },
  errorLocation: (state, action, error) =>
    state.setIn(['error', `${action.role}AddUsers`], error)
}

const sendIngestionInviteEmailOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'sendInviteEmail', action.ingestionID], fetching),
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'sendInviteEmail', action.ingestionID], error)
}

const clearImportErrors = (state, { role }) =>
  state.setIn([`${role}FileImport`, 'schools', 0, 'errors'], List())

const readStudentAccountsOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(
      ['fetching', 'studentAccounts', 'byIngestionID', action.ingestionID],
      fetching
    ),
  placementFunction: (state, action) => {
    return state.setIn(
      ['studentAccounts', 'byIngestionID', action.ingestionID],
      action.studentAccounts
    )
  },
  errorLocation: (state, action, error) =>
    state.setIn(
      ['error', 'studentAccounts', 'byIngestionID', action.ingestionID],
      error
    )
}

const sendInviteEmailsOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'sendInviteEmail', action.userIDs], fetching),
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'sendInviteEmail', action.userIDs], error)
}

const readStudentAccountsHandlers = reducerCreators.handleCreator(
  actionIDs.studentAccounts.read,
  {},
  readStudentAccountsOptions
)

const readStudentAccountsBatchOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'studentAccounts', 'batch'], fetching),
  placementFunction: (state, action) => {
    return state.setIn(['studentAccounts', 'batch'], action.studentAccounts)
  },
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'studentAccounts', 'batch'], error)
}

const readBatchStudentAccountsHandlers = reducerCreators.handleCreator(
  actionIDs.studentAccounts.readBatch,
  {},
  readStudentAccountsBatchOptions
)

const clearImport = (state, { role }) => {
  const oldState = state.getIn([])
  const newState = oldState.withMutations((map) => {
    map.set(`${role}FileImport`, fromJS({}))

    if (role === ROLE.TEACHER) {
      map.setIn(['error', 'sendInviteEmail'], fromJS({}))
    }

    map.setIn(['fetching', `${role}FileImport`], false)
    map.setIn(['error', `${role}FileImport`], null)
    map.setIn(['error', `${role}AddUsers`], null)
  })
  return state.setIn([], newState)
}

const parseImportFileHandlers = reducerCreators.handleCreator(
  actionIDs.userManagement.parseImportFile,
  {},
  parseImportFileOptions
)

const addUsersHandlers = reducerCreators.handleCreator(
  actionIDs.userManagement.addUsers,
  {},
  addUsersOptions
)

const sendIngestionInviteEmailHandlers = reducerCreators.handleCreator(
  actionIDs.userManagement.sendIngestionInviteEmail,
  {},
  sendIngestionInviteEmailOptions
)

const sendInviteEmailsHandlers = reducerCreators.handleCreator(
  actionIDs.teacherAccounts.sendInviteEmails,
  {},
  sendInviteEmailsOptions
)

const handlers = Object.assign(
  {
    [actionIDs.userManagement.clearImport]: clearImport,
    [actionIDs.userManagement.clearImportErrors]: clearImportErrors
  },
  addUsersHandlers,
  parseImportFileHandlers,
  sendIngestionInviteEmailHandlers,
  sendInviteEmailsHandlers,
  readStudentAccountsHandlers,
  readBatchStudentAccountsHandlers
)

const userManagement = reducerCreators.reducerCreator(initialState, handlers)

export default userManagement
