export const transformError = (err) => ({
  name: {
    ...err.name
  },
  email: {
    ...err.email
  }
})

export const transformSchool = (school) => ({
  id: school.id.toString(),
  numUsersAdded: school.num_added,
  errors: school.errors && school.errors.map(transformError)
})

export const transformImportFileResponse = (data) => ({
  ingestionID: data.ingestion_id.toString(),
  schools: data.schools && data.schools.map(transformSchool)
})

export const transformAddUsersResponse = (data) => ({
  ingestionID: data.ingestion_id.toString(),
  numUsersAdded: data.num_added,
  errors: data.errors && data.errors.map(transformError)
})

export const transformStudentAccountResponse = (data) => {
  const header = ['name', 'username', 'password']
  const accounts = data.accounts.map((account) => [
    account.name,
    account.username,
    account.password
  ])
  // add header to the top
  accounts.unshift(header)
  return (
    'data:text/csv;charset=utf-8,' +
    encodeURIComponent(accounts.map((e) => e.join(',')).join('\n'))
  )
}
