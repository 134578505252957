import { authSendRequest } from '../../helpers/sendRequest'
import {
  DEFAULT_PAGE_SIZE,
  CURRICULUM_COLLABORATOR
} from '../../helpers/constants'

export const getSubjectGroupsForSchool = (schoolID) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/subjects`
  )
}

export const getSubjectsAndCourses = (schoolID) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/${schoolID}/subjects_courses`
  )
}

export const createSubjectGroup = (schoolID, subjectID, subjectType) => {
  const reqBody = { type: subjectType, subject_ids: [parseInt(subjectID, 10)] }
  return authSendRequest(
    'POST',
    `${window.API_SERVER}/api/schools/${schoolID}/subjects`,
    reqBody
  )
}

export const getCoursesForSubjectGroup = (schoolSubjectGroupID) => {
  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/schools/subjects/${schoolSubjectGroupID}/courses`
  )
}

export const getUnitsFromCurriculumID = (curriclumID) =>
  authSendRequest(
    'GET',
    `${window.API_SERVER}/api/curriculum/${curriclumID}/tree`
  )

export const getSkillGroups = () =>
  authSendRequest('GET', `${window.API_SERVER}/api/standard/groups`)

export const getSkillsFiltered = (
  schoolID,
  searchQuery,
  skillGroupIDs,
  subjectIDs,
  gradeIDs,
  pageNumber
) => {
  let optionsQuery = `?page_width=${DEFAULT_PAGE_SIZE}`
  optionsQuery += schoolID ? `&school_id=${schoolID}` : ''
  optionsQuery += searchQuery ? `&q=${searchQuery}` : ''
  optionsQuery += pageNumber ? `&page_index=${pageNumber}` : ''
  optionsQuery += skillGroupIDs
    ? skillGroupIDs.map((id) => (id ? `&standard_group_id=${id}` : '')).join('')
    : ''
  optionsQuery += (subjectIDs || []).map((id) => `&subject_id=${id}`).join('')
  optionsQuery +=
    gradeIDs && gradeIDs.size
      ? [...gradeIDs].map((gradeID) => `&grade_id=${gradeID}`).join('')
      : ''

  return authSendRequest(
    'GET',
    `${window.API_SERVER}/api/v1/standards${optionsQuery}`
  )
}

export const getIndividualSkills = (skillIDs) => {
  return authSendRequest('POST', `${window.API_SERVER}/api/v1/standards`, {
    IDs: skillIDs.map((skillID) => parseInt(skillID, 10))
  })
}

export const updateCourse = (courseID, courseName, gradeLevelIDs) =>
  authSendRequest(
    'PUT',
    `${window.API_SERVER}/api/schools/courses/${courseID}`,
    {
      name: courseName,
      grade_level_ids: Array.from(gradeLevelIDs).map((x) => parseInt(x, 10))
    }
  )

export const shareCurriculumWithUser = (
  curriculumID,
  userID,
  role = CURRICULUM_COLLABORATOR
) =>
  authSendRequest(
    'POST',
    `${window.API_SERVER}/api/curriculum/${curriculumID}/share`,
    {
      share: [{ user_id: Number(userID), role }]
    }
  )

export const unshareCurriculumWithUser = (curriculumID, userID) =>
  authSendRequest(
    'DELETE',
    `${window.API_SERVER}/api/curriculum/${curriculumID}/share/${userID}`
  )

export const getTeacherRecentClassIDForCurriculum = (curriculumID, userID) =>
  authSendRequest(
    'GET',
    `${window.API_SERVER}/api/curriculum/${curriculumID}/teacher/${userID}/class/recent`
  )

export const deleteCourse = (courseID) =>
  authSendRequest(
    'DELETE',
    `${window.API_SERVER}/api/schools/courses/${courseID}`
  )
export const undeleteCourse = (courseID) =>
  authSendRequest(
    'PUT',
    `${window.API_SERVER}/api/schools/courses/${courseID}/undelete`
  )
