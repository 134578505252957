import * as actionIDs from './actionIDs'
import { makeActionCreator, makeResourceActionsCreators } from './actionHelpers'

export const teacherReports = {
  read: makeResourceActionsCreators(actionIDs.teacherReports.read, {
    begin: ['schoolID', 'teacherIDs'],
    success: ['schoolID', 'response']
  })
}

export const teacherEngagement = {
  read: makeResourceActionsCreators(actionIDs.teacherEngagement.read, {
    begin: ['schoolID', 'groupBy', 'timeframeID'],
    success: ['schoolID', 'response']
  })
}

export const studentAchievement = {
  read: makeResourceActionsCreators(actionIDs.studentAchievement.read, {
    begin: ['schoolID', 'groupBy', 'timeframeID'],
    success: ['schoolID', 'chartData']
  })
}

export const topClasses = {
  read: makeResourceActionsCreators(actionIDs.topClasses.read, {
    begin: ['schoolID'],
    success: ['classes']
  })
}

export const inactiveTeachers = {
  read: makeResourceActionsCreators(actionIDs.inactiveTeachers.read, {
    begin: ['schoolID'],
    success: ['inactiveTeacherIDs']
  })
}

export const unactivatedTeachers = {
  read: makeResourceActionsCreators(actionIDs.unactivatedTeachers.read, {
    begin: ['schoolID'],
    success: ['unactivatedTeacherIDs']
  })
}

export const studentActivityLists = {
  read: makeResourceActionsCreators(actionIDs.studentActivityLists.read, {
    begin: ['schoolID'],
    success: ['studentActivityLists']
  })
}

// fetch classes and their statistics for a single student
export const fetchStudentClasses = makeActionCreator(
  actionIDs.FETCH_STUDENT_CLASSES,
  'schoolID',
  'studentID'
)
