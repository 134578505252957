import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

// Kiddom Helpers
import i18n from '../../helpers/i18n'
import { courseShape } from '../../helpers/propShapes/courseShape'
import { updateItemInSet } from '../../helpers/set'
// Kiddom Components
import CloseIcon from '../../images/icons/close.svg'
import GradeLevelSelectContainer from './gradeLevelSelectContainer'
import Button from '../ui/buttons/button'
// Kiddom Styles
import './courseFormPresenter.scss'
import './courseListView.scss'

const characterLimit = 45
const isCourseNameValid = (name) => name.length === 0 || name.trim() === ''
export class CourseFormPresenter extends React.Component {
  constructor(props) {
    super(props)
    let currentName = ''
    let currentGrades = []
    if (this.props.course) {
      currentName = this.props.course.name
      currentGrades = this.props.course.grades
    }
    this.state = {
      newCourseName: currentName,
      selectedGradeLevelIDs: new Set(currentGrades),
      isSaveDisabled: true,
      inputError: '',
      formOpen: true,
      isSubmitted: false,
      submitRequested: false,
      formCancel: false
    }
    this.closeForm = this.closeForm.bind(this)
    this.changeCourseName = this.changeCourseName.bind(this)
    this.saveCourse = this.saveCourse.bind(this)
    this.toggleGradeLevel = this.toggleGradeLevel.bind(this)
  }

  componentDidUpdate() {
    if (this.state.inputError === '' && this.props.errorValue) {
      this.setState({
        inputError: this.props.errorValue.message
          ? this.props.errorValue.message
          : i18n.t('errors.defaultError')
      })
    }
  }

  onExited = () => {
    if (!this.state.submitRequested) {
      return this.props.removeForm(this.props.formID, false)
    }
    const isSubmitted = this.saveCourse()
    this.setState(() => ({
      isSubmitted
    }))
    if (!isSubmitted) {
      this.setState(() => ({
        formOpen: true
      }))
    }
    if (this.state.addNewForm && !this.state.isSaveDisabled) {
      this.props.addForm()
    }
    this.props.removeForm(this.props.formID, isSubmitted)
  }

  closeForm(close = false) {
    this.setState({ formOpen: false, close })
  }

  setIsSaveDisabled = (isSaveDisabled) => {
    this.setState({
      isSaveDisabled
    })
  }

  changeCourseName(e) {
    const newCourseName = e.target.value
    this.setState({
      newCourseName
    })
    this.setIsSaveDisabled(isCourseNameValid(newCourseName))
  }

  toggleGradeLevel(gradeLevelID) {
    const selectedGradeLevelIDs = updateItemInSet(
      this.state.selectedGradeLevelIDs,
      gradeLevelID
    )
    this.setState({ selectedGradeLevelIDs })
    this.setIsSaveDisabled(isCourseNameValid(this.state.newCourseName))
  }

  setInputError = (inputError) => this.setState({ inputError })
  // isValidInput checks for course name duplication
  // when editing course user may save course with the old name
  isValidInput = () => {
    const course = this.props.courses.find(
      (course) => course.name === this.state.newCourseName
    )
    if (!course) {
      this.setInputError('')
      return true
    }
    if (course.id !== this.props.formID) {
      this.setInputError(i18n.t('errors.duplicateCourseError'))
      return false
    }
    this.setInputError('')
    return true
  }

  saveForm = (addNewForm) => {
    if (!this.isValidInput()) {
      return false
    }
    this.setState(() => ({
      submitRequested: true,
      addNewForm
    }))
    this.closeForm()
  }

  saveCourse() {
    this.props.editCourse(
      this.props.formID,
      this.state.newCourseName,
      this.state.selectedGradeLevelIDs
    )
    return true
  }

  // Render the content for the form to create courses
  renderCreateForm() {
    const placeholderText = i18n.t('labels.addCoursePlaceholder')
    const gradeLevelText = i18n.t('labels.gradeLevelText')

    return (
      <div className="create-course-form">
        <div className="create-course-form-top-row">
          <span className="create-course-form-inputs">
            <input
              autoFocus
              className={classnames('course-name-input', {
                inputError: this.state.inputError.length > 0
              })}
              value={this.state.newCourseName}
              onChange={this.changeCourseName}
              placeholder={placeholderText}
              maxLength={characterLimit}
            />
          </span>
          <CloseIcon
            className="close-form"
            onClick={() => this.closeForm(true)}
            data-spec="close-icon"
            tabIndex="0"
          />
        </div>
        {this.state.inputError.length > 0 && (
          <div className="error-text">{this.state.inputError}</div>
        )}
        <div className="grade-levels-container">
          <div className="grade-levels-label">{gradeLevelText}</div>
          <GradeLevelSelectContainer
            values={this.state.selectedGradeLevelIDs}
            toggleGradeLevel={this.toggleGradeLevel}
          />
        </div>
        <div className="save-links-container">
          <Button
            label={i18n.t('labels.cancel')}
            data-spec="cancel-button"
            buttonStyle="secondary"
            onClick={() => this.closeForm()}
            className="create-course-button cancel-button cancel-edit"
          />
          <Button
            label={i18n.t('labels.save')}
            data-spec="save-button"
            disabled={this.state.isSaveDisabled}
            onClick={() => this.saveForm()}
            className="create-course-button"
          />
        </div>
      </div>
    )
  }

  render() {
    const classNames = classnames('create-course-form-container', {
      inputError: this.state.inputError.length > 0
    })
    const transitionClassNames = classnames({
      'course-form': !this.state.close,
      'course-form-removing': this.state.close
    })
    return (
      <CSSTransition
        in={this.state.formOpen}
        timeout={500}
        name="course-form"
        classNames={transitionClassNames}
        appear
        exit
        onExited={this.onExited}
      >
        <div className={classNames} data-spec="course-form">
          {this.renderCreateForm()}
        </div>
      </CSSTransition>
    )
  }
}

CourseFormPresenter.defaultProps = {
  schoolSubjectGroupID: null,
  formID: null,
  courses: [],
  course: null,
  errorValue: null,
  addForm: null,
  removeForm: null,
  checkAndSetUserPref: null
}
CourseFormPresenter.propTypes = {
  schoolSubjectGroupID: PropTypes.string.isRequired,
  formID: PropTypes.string.isRequired,
  courses: PropTypes.arrayOf(courseShape),
  course: courseShape,
  errorValue: PropTypes.object,
  addForm: PropTypes.func,
  removeForm: PropTypes.func.isRequired,
  checkAndSetUserPref: PropTypes.func
}

export default CourseFormPresenter
