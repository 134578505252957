import './styles.scss'

import Button, { ButtonSize, ButtonVariant } from '../button/button'
import { ModalContext, ModalProvider } from './modal_context'
import React, { ReactNode, useContext, useEffect, useState } from 'react'

import Close from '../../../images/icons/kiddom-close.svg'
import ReactModal from 'react-modal'
import classnames from 'classnames'
import { useLockScrolling } from './use_lock_scrolling'

export { ModalContext, ModalProvider }

export enum ModalSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  TakeOver = 'takeover'
}

export enum ModalVariant {
  Regular = 'regular',
  Square = 'square'
}

export interface IModalProps {
  className?: string
  isOpen: boolean
  size?: ModalSize
  children?: ReactNode | string
  title: string | ReactNode
  subtitle?: string | ReactNode
  titleIcon?: ReactNode
  isSubtitleUnderTitle?: boolean
  borderUnderHeader?: boolean
  primaryActionText?: string | null
  secondaryActionText?: string
  primaryActionHandle?: () => Promise<void>
  secondaryActionHandle?: () => Promise<void>
  errorHandler?: (error: Error) => Promise<void>
  closeActionHandle?: () => Promise<boolean | void>
  closeOnEsc?: boolean
  closeOnOverlay?: boolean
  isDisabled?: boolean
  confirmationTitle?: string
  confirmationView?: ReactNode
  confirmationPrimaryActionText?: string
  confirmationSecondaryActionText?: string
  extraActions?: ReactNode
  primaryButtonVariant?: ButtonVariant | null
  forceClose?: () => Promise<void>
  variant?: ModalVariant
  betaComponent?: ReactNode
}

export function Modal({
  className,
  isOpen,
  size = ModalSize.Medium,
  children,
  title,
  subtitle,
  titleIcon,
  borderUnderHeader = true,
  primaryActionText = 'OK',
  secondaryActionText,
  primaryActionHandle,
  secondaryActionHandle,
  errorHandler,
  closeActionHandle = () => Promise.resolve(),
  forceClose,
  closeOnEsc = true,
  closeOnOverlay = true,
  isDisabled = false,
  confirmationView,
  confirmationPrimaryActionText,
  confirmationTitle,
  confirmationSecondaryActionText,
  isSubtitleUnderTitle,
  extraActions,
  primaryButtonVariant,
  variant = ModalVariant.Regular,
  betaComponent
}: IModalProps) {
  const { appRootRef, modalRootRef } = useContext(ModalContext)
  const [show, setShow] = useState<boolean>(false)
  const [modalRoot, setModalRoot] = useState<HTMLElement>(document.body)
  const [classes, setClasses] = useState<string>(updateClasses(size))
  const { lock, unlock } = useLockScrolling(document.body, 'Modal')
  const [needConfirmation, setNeedConfirmation] = useState<boolean>(
    !!confirmationView
  )

  function getModalRoot(): HTMLElement {
    return modalRoot
  }

  async function handleModalClose(
    event?: React.MouseEvent<Element> | React.KeyboardEvent<Element>
  ) {
    event?.preventDefault()

    // reset to initial
    setNeedConfirmation(!!confirmationView)

    try {
      const result = await closeActionHandle()
      if (!result) {
        setShow(false)
      }
    } catch (error: any) {
      if (typeof errorHandler === 'function') {
        await errorHandler(error)
      }
    }
  }

  async function handlePrimaryClick(
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    event.preventDefault()

    if (needConfirmation) {
      setNeedConfirmation(false)
      return
    }

    try {
      if (typeof primaryActionHandle === 'function') {
        await primaryActionHandle()
      }
      await handleModalClose()
    } catch (error: any) {
      if (typeof errorHandler === 'function') {
        await errorHandler(error)
      }
    }
  }

  async function handleSecondaryClick(
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    event.preventDefault()

    if (!needConfirmation && confirmationView) {
      setNeedConfirmation(true)
      return
    }

    try {
      if (typeof secondaryActionHandle === 'function') {
        await secondaryActionHandle()
      }
      await handleModalClose()
    } catch (error: any) {
      if (typeof errorHandler === 'function') {
        await errorHandler(error)
      }
    }
  }

  async function onForceClose(
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> {
    event.preventDefault()

    try {
      if (typeof forceClose === 'function') {
        await forceClose()
      }
      await handleModalClose()
    } catch (error: any) {
      if (typeof errorHandler === 'function') {
        await errorHandler(error)
      }
    }
  }

  let modalTitle = title
  let secondaryText = secondaryActionText
  let showingConfirmation = false
  if (!needConfirmation) {
    showingConfirmation = !!confirmationView
    modalTitle = confirmationTitle || title
    secondaryText = confirmationSecondaryActionText || secondaryActionText
  }

  function getIcon() {
    if (!titleIcon) return null
    return <div className="k-modal__header-icon">{titleIcon}</div>
  }

  function getTitle(): ReactNode {
    if (!title) return null
    return (
      <h1 className="k-modal__header-title">
        {modalTitle} {betaComponent}
      </h1>
    )
  }

  function getSubtitle(): ReactNode {
    if (!subtitle) return null
    return <h2 className="k-modal__header-subtitle">{subtitle}</h2>
  }

  // NOTE: This useEffect should only run once or only when appRoot changes.
  useEffect(() => {
    // NOTE: Make sure appRoot is defined and valid before we can show the
    // modal.
    if (
      appRootRef &&
      appRootRef.current &&
      modalRootRef &&
      modalRootRef.current
    ) {
      setShow(isOpen)
      setModalRoot(modalRootRef.current)
      ReactModal.setAppElement(appRootRef.current)
    } else {
      setShow(false)
    }
  }, [isOpen, appRootRef, modalRootRef])

  useEffect(() => {
    setClasses(updateClasses(size))
  }, [size])

  const headerClassnames = classnames(
    'k-modal__header',
    `k-modal__header--${variant}`,
    {
      'k-modal__header--bare': !borderUnderHeader
    }
  )

  const headerTextClassnames = classnames(
    {
      'k-modal__header-text-undersubtitle': isSubtitleUnderTitle
    },
    'k-modal__header-text'
  )

  const modalClassnames = classnames(
    'k-modal-content',
    `k-modal-content--${variant}`,
    className,
    {
      'k-modal-takeover': size === ModalSize.TakeOver
    }
  )

  const modalContents = children && (
    <React.Fragment>
      <div style={{ display: showingConfirmation ? 'none' : 'inherit' }}>
        {children}
      </div>
      {confirmationView && (
        <div style={{ display: showingConfirmation ? 'inherit' : 'none' }}>
          {confirmationView}
        </div>
      )}
    </React.Fragment>
  )

  return (
    <ReactModal
      closeTimeoutMS={300}
      onAfterOpen={lock}
      onAfterClose={unlock}
      isOpen={show}
      // NOTE: Adding this here to overwrite default styles.
      // Modal.defaultStyles are defined here:
      // > https://github.com/reactjs/react-modal/blob/94ad567fc4c40654f14c048099fd60cf2e7f7197/src/components/Modal.js#L204
      // > https://github.com/reactjs/react-modal/blob/94ad567fc4c40654f14c048099fd60cf2e7f7197/src/components/Modal.js#L95-L118
      style={{ overlay: {}, content: {} }}
      className={modalClassnames}
      portalClassName={classes}
      overlayClassName="k-modal-overlay"
      onRequestClose={handleModalClose}
      parentSelector={getModalRoot}
      shouldCloseOnEsc={closeOnEsc}
      shouldCloseOnOverlayClick={closeOnOverlay}
    >
      <div className={headerClassnames}>
        <div className={'k-modal__header-title-row'}>
          {getIcon()}
          <div className={headerTextClassnames}>
            {getTitle()}
            {getSubtitle()}
          </div>
        </div>
        <Button
          className="k-modal__header-close"
          variant={ButtonVariant.Icon}
          size={ButtonSize.Medium}
          isEqualDimens={true}
          alt="Close modal"
          onClick={forceClose ? onForceClose : handleModalClose}
        >
          <Close />
        </Button>
      </div>
      <div className={`k-modal__body k-modal__body--${variant}`}>
        {modalContents || <p>Modal is empty.</p>}
      </div>
      <div className={`k-modal__footer k-modal__footer--${variant}`}>
        {primaryActionText || confirmationPrimaryActionText ? (
          <Button
            className="k-modal__footer-action k-modal__footer-action--primary"
            variant={primaryButtonVariant || ButtonVariant.Primary}
            onClick={handlePrimaryClick}
            disabled={isDisabled}
          >
            {showingConfirmation && confirmationPrimaryActionText
              ? confirmationPrimaryActionText
              : primaryActionText}
          </Button>
        ) : null}
        {secondaryText ? (
          <Button
            className="k-modal__footer-action k-modal__footer-action--secondary"
            variant={ButtonVariant.Secondary}
            onClick={handleSecondaryClick}
          >
            {secondaryText}
          </Button>
        ) : null}
        {extraActions || null}
      </div>
    </ReactModal>
  )
}

function updateClasses(size: ModalSize): string {
  return classnames({
    'k-modal': true,
    'k-modal--small': size === 'small',
    'k-modal--medium': size === 'medium',
    'k-modal--large': size === 'large',
    'k-modal--takeover': size === 'takeover'
  })
}
