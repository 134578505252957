import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import i18n from '../../../helpers/i18n'

import './guidanceTile.scss'
import GuidanceTileStep from './guidanceTileStep'
import * as userPreferences from '../../../store/userPreferences'
import { getSchoolIsDemo } from '../../../store/fromState'

export const GuidanceTile = (props) => {
  const guidanceLabel = props.isDemo
    ? i18n.t('labels.exploreYourDemoSchool')
    : i18n.t('labels.optimizeYourTrial')
  const stepsToRender = []
  if (props.isDemo) {
    // Step 1: Explore Teacher Dashboard
    const stepOneDemoProps = {
      requiredUserPrefSteps: [
        userPreferences.TEACHER_DASHBOARD_VIEWED,
        userPreferences.TEACHER_PROFILE_VIEWED,
        userPreferences.TEACHER_VIEWED_IN_CLASSROOM
      ],
      guidanceStepLabel: 'freeTrialDemoSchoolStepOne'
    }
    // Step 2: Explore Student Dashboard
    const stepTwoDemoProps = {
      requiredUserPrefSteps: [
        userPreferences.STUDENT_DASHBOARD_VIEWED,
        userPreferences.STUDENT_PROFILE_VIEWED,
        userPreferences.STUDENT_VIEWED_IN_CLASSROOM
      ],
      guidanceStepLabel: 'freeTrialDemoSchoolStepTwo'
    }
    // Step 3: Explore Demo Curriculum
    const stepThreeDemoProps = {
      requiredUserPrefSteps: [
        userPreferences.DEMO_SCHOOL_CURRICULUM_VIEWED,
        userPreferences.DEMO_SCHOOL_COURSE_VIEWED,
        userPreferences.DEMO_SCHOOL_PLANNER_VIEWED
      ],
      guidanceStepLabel: 'freeTrialDemoSchoolStepThree'
    }
    stepsToRender.push(
      <GuidanceTileStep {...stepOneDemoProps} key="demo-school-step-one" />
    )
    stepsToRender.push(
      <GuidanceTileStep {...stepTwoDemoProps} key="demo-school-step-two" />
    )
    stepsToRender.push(
      <GuidanceTileStep {...stepThreeDemoProps} key="demo-school-step-three" />
    )
  } else {
    // Step 1: Create and view a course
    const stepOneProps = {
      requiredUserPrefSteps: [
        userPreferences.FIRST_COURSE_SAVED,
        userPreferences.FIRST_COURSE_VIEWED
      ],
      guidanceStepLabel: 'freeTrialYourSchoolStepOne'
    }
    stepsToRender.push(
      <GuidanceTileStep {...stepOneProps} key="your-school-step-one" />
    )
  }
  return (
    <div className="guidance-box">
      <div className="guidance-box-content">
        <div className="free-trial-optimize-label">{guidanceLabel}</div>
        {stepsToRender}
      </div>
    </div>
  )
}

export const mapStateToProps = (state, ownProps) => {
  return { isDemo: getSchoolIsDemo(state, ownProps.match.params.schoolID) }
}

export default withRouter(connect(mapStateToProps)(GuidanceTile))
