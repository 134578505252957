import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'
import CloseIcon from '../../../images/icons/close.svg'

import './notification.scss'

const Notification = (props) => {
  const { type, dismissNotification } = props
  const className = classnames('notification', type, props.className)

  return (
    <div className={className}>
      <div className="content">{props.children}</div>
      <CloseIcon
        className="close"
        data-spec="close"
        title={i18n.t('labels.close')}
        onClick={dismissNotification}
      />
    </div>
  )
}

Notification.propTypes = {
  type: PropTypes.oneOf(['success'])
}

export default Notification
