import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Immutable from 'immutable'

import i18n from '../../helpers/i18n'
import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import {
  INACTIVE_TEACHERS_PATH,
  UNACTIVATED_TEACHERS_PATH
} from '../../routes/routes'
import CardsPage from '../common/cards/cardsPage'
import TeacherCard from '../common/cards/teacherCard'
import { condenseNumber } from '../../helpers/utils'
import {
  TEACHERS,
  CLASSES,
  SUBJECTS,
  REPORTING_METHODS
} from '../../store/models/resources'
import {
  TEACHERS_VIEW_EVENT,
  SINGLE_TEACHER_VIEW_EVENT
} from '../../store/eventIDs'
import withDataLoading from '../hoc/withDataLoading'

class UnactivatedTeachers extends React.Component {
  componentDidMount() {
    this.props.trackPageViewed()
  }

  renderCards() {
    return this.props.teachers
      .filter(
        (teacher) =>
          this.props.unactivatedIDs &&
          this.props.unactivatedIDs.includes(teacher.get('id'))
      )
      .map((teacher, index) => {
        return (
          <TeacherCard
            schoolID={this.props.match.params.schoolID}
            teacher={teacher}
            history={this.props.history}
            key={`teacher-card-${index}`}
            onClick={() => this.props.trackSingleTeacherViewed(teacher.id)}
          />
        )
      })
  }

  generateButtonProps() {
    const {
      inactiveIDs,
      unactivatedIDs,
      isLoading,
      schoolID,
      history
    } = this.props
    if (isLoading) {
      return {
        buttons: [],
        defaultSelected: 1
      }
    }
    const numInactive = condenseNumber(inactiveIDs.size)
    const numUnactivated = condenseNumber(unactivatedIDs.size)
    return {
      buttons: [
        {
          label: `${numInactive} Inactive`,
          onClick: () => {
            history.push(INACTIVE_TEACHERS_PATH(schoolID))
          }
        },
        {
          label: `${numUnactivated} Unactivated`,
          onClick: () => {
            history.push(UNACTIVATED_TEACHERS_PATH(schoolID))
          }
        }
      ],
      defaultSelected: 1 // inactive = 0, unactivated = 1
    }
  }

  render() {
    const unactivatedTeachersLabel = i18n.t('titles.unactivatedTeachers')
    const emptyMessageLabel = i18n.t('labels.noTeachersInThisCategory')
    const unactivatedTeachersDescription = i18n.t(
      'passages.unactivatedTeachersDescription'
    )
    const cardsPageProps = {
      loading: this.props.isLoading,
      cardData: this.props.teachers,
      pageTitle: unactivatedTeachersLabel,
      subtitle: unactivatedTeachersDescription,
      pageClassName: 'unactivated-teachers',
      emptyMessage: emptyMessageLabel,
      buttonProps: this.generateButtonProps(),
      cards: this.renderCards().toArray()
    }
    return <CardsPage {...cardsPageProps} />
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID

  const {
    stillFetching,
    haveAllData,
    fetchingInactive,
    fetchingUnactivated
  } = ownProps
  if (
    stillFetching ||
    fetchingInactive ||
    fetchingUnactivated ||
    !haveAllData
  ) {
    return { isLoading: true }
  }
  const inactiveIDs = fromState.getInactive(state, TEACHERS)
  const unactivatedIDs = fromState.getUnactivated(state, TEACHERS)
  const { teachers } = fromState.aggregateTeacherClassData(state, schoolID)

  return {
    teachers,
    inactiveIDs,
    unactivatedIDs,
    schoolID
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    trackPageViewed: () =>
      dispatch(
        coreActions.trackEvent.create.begin(TEACHERS_VIEW_EVENT, {
          schoolID,
          filter: {
            unactivated: true
          }
        })
      ),
    trackSingleTeacherViewed: (id) => {
      const data = {
        schoolID,
        fromWhere: 'unactivated',
        teacher: {
          id,
          inactive: false,
          unactivated: true
        }
      }
      return dispatch(
        coreActions.trackEvent.create.begin(SINGLE_TEACHER_VIEW_EVENT, data)
      )
    }
  }
}

UnactivatedTeachers.defaultProps = {
  teachers: Immutable.List()
}

const generateDataLoaders = (props) => [
  coreActions.teacherAggregate.read.begin(props.match.params.schoolID),
  adminActions.inactiveTeachers.read.begin(props.match.params.schoolID),
  adminActions.unactivatedTeachers.read.begin(props.match.params.schoolID)
]
const reloadProps = ['schoolID']

const generateLoadingProps = (props) => ({
  fetchingInactive: [[fromState.isFetchingInactive, [TEACHERS]]],
  fetchingUnactivated: [[fromState.isFetchingUnactivated, [TEACHERS]]],
  stillFetching: [
    [
      fromState.isFetchingResources,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ],
  haveAllData: [[fromState.teacherAccountStatusLoaded, []]]
})

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(UnactivatedTeachers),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
