import React from 'react'
import PropTypes from 'prop-types'

import { ROLE, USER_MANAGEMENT_ROLES } from '../../helpers/constants'

import i18n from '../../helpers/i18n'

import GreenCheck from '../../images/icons/green-checkmark.svg'
import InviteEmailButtonContainer from './inviteEmailButtonContainer'
import Notification from '../ui/notification/notification'
import BulkStudentAccountsButtonContainer from './bulkStudentAccountsButton/bulkStudentAccountsButtonContainer'

import './importSuccess.scss'

const accountsCreatedMessage = (numUsersAdded) =>
  i18n.t('labels.accountsCreatedSuccess', {
    count: numUsersAdded
  })

export const ImportSuccessPresenter = (props) => {
  const {
    numUsersAdded,
    setNotificationDismissed,
    showNotification,
    role
  } = props
  if (!showNotification) {
    return null
  }

  return (
    <Notification
      className="import-success"
      dismissNotification={() => setNotificationDismissed(true)}
    >
      <GreenCheck className="green-check" />
      <span>{accountsCreatedMessage(numUsersAdded)}</span>
      {role === ROLE.TEACHER && (
        <InviteEmailButtonContainer
          data-spec="invite-email"
          count={numUsersAdded}
        />
      )}
      {role === ROLE.STUDENT && (
        <BulkStudentAccountsButtonContainer
          isLink
          data-spec="download-accounts"
        />
      )}
    </Notification>
  )
}

ImportSuccessPresenter.propTypes = {
  numUsersAdded: PropTypes.number.isRequired,
  setNotificationDismissed: PropTypes.func.isRequired,
  showNotification: PropTypes.bool.isRequired,
  role: PropTypes.oneOf(USER_MANAGEMENT_ROLES).isRequired
}

export default ImportSuccessPresenter
