import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'

import Popup from '../ui/popup/popup'
import Menu from '../ui/menu/menu'
import MenuItem from '../ui/menu/menuItem'
import ControlsIcon from '../../images/icons/controls.svg'
import CheckmarkIcon from '../../images/icons/checkmark.svg'

import { subjectShape } from '../../helpers/propShapes/common'
import {
  sortSubjects,
  subjectsToCSSClass,
  getSubjectsAsTitle
} from '../../helpers/subjects'

import './customizeDirectory.scss'

export class CustomizeDirectoryPresenter extends React.Component {
  constructor(props) {
    super(props)

    this.popupRef = React.createRef()
  }

  subjectsMenu(subjects) {
    return (
      <Menu>
        {subjects.map((subject, index) => {
          const subjectName = subject.name
          const title = getSubjectsAsTitle([subjectName])
          const iconClasses = classnames(
            'subject-icon',
            'circled-icon',
            subjectsToCSSClass([subjectName])
          )
          const doesGroupExistForSubject = this.props.parentSubjectIDsInSchool.includes(
            subject.id
          )
          const checkmarkClasses = classnames('subject-checkmark', {
            selected: doesGroupExistForSubject
          })

          return (
            <MenuItem
              key={index}
              label={
                <React.Fragment>
                  <span className="subject-label" data-spec="subject-label">
                    {title}
                  </span>
                  <CheckmarkIcon
                    title={i18n.t('labels.selected')}
                    className={checkmarkClasses}
                    data-spec="subject-checkmark"
                  />
                </React.Fragment>
              }
              icon={<span className={iconClasses} />}
              name={title}
              onClick={() => {
                !doesGroupExistForSubject &&
                  this.props.createSubjectGroup(subject)
              }}
            />
          )
        })}
      </Menu>
    )
  }

  render() {
    const trigger = (
      <span className="customize-directory-button">
        <ControlsIcon
          title={i18n.t('labels.customize')}
          className="customize-icon"
        />
        <span>{i18n.t('labels.customizeDirectory')}</span>
      </span>
    )

    return (
      <div className="customize-directory" data-spec="customize-directory">
        <Popup
          position="bottom"
          alignment="end"
          isActionSheet
          closeOnClick={false}
          triggerElement={trigger}
          ref={this.popupRef}
        >
          {this.subjectsMenu(sortSubjects(this.props.parentSubjects))}
        </Popup>
      </div>
    )
  }
}

CustomizeDirectoryPresenter.propTypes = {
  parentSubjects: PropTypes.arrayOf(subjectShape).isRequired,
  parentSubjectIDsInSchool: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default CustomizeDirectoryPresenter
