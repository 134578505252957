import React from 'react'
import { connect } from 'react-redux'

import withDataLoading from '../../../../hoc/withDataLoading'
import withToJS from '../../../../hoc/withToJS'
import SubjectSelectPresenter from './subjectSelectPresenter'
import { isParentSubject } from '../../../../../helpers/subjects'
import * as coreActions from '../../../../../store/actions/coreActions'
import { getResource, resourcesLoaded } from '../../../../../store/fromState'
import * as resources from '../../../../../store/models/resources'

const WrappedPresenter = withToJS(SubjectSelectPresenter)

export const SubjectSelectContainer = (props) => {
  if (!props.hasLoaded) {
    return null
  }

  const parentSubjects = props.subjectsByID.valueSeq().filter(isParentSubject)
  const subjectValue = props.value
    ? props.subjectsByID.get(props.value)
    : undefined

  return (
    <WrappedPresenter
      postSelect={props.postSelect}
      subjects={parentSubjects}
      value={subjectValue}
      onClickSubject={props.onClickSubject}
    />
  )
}

export const mapStateToProps = (state) => ({
  subjectsByID: getResource(state, resources.SUBJECTS)
})

const generateDataLoaders = () => [coreActions.subjects.read.begin()]

const generateHasLoadedCheckers = () => ({
  hasLoaded: [[resourcesLoaded, [[resources.SUBJECTS]]]]
})

export default withDataLoading(
  connect(mapStateToProps, null)(SubjectSelectContainer),
  generateDataLoaders,
  generateHasLoadedCheckers
)
