/*
 * checkForEmptyData
 * input: data (object or Immutable Map)
 * output: boolean
 * example: {dataBucket: {values: [0,0,0,1]}} => false
 * example: {dataBucket: {values: [0,0,0,0]}} => true
 */
export function checkForEmptyData(data) {
  if (!data) {
    return true
  }
  if (data.toJS) {
    data = data.toJS()
  }
  let empty = true
  empty = !Object.values(data).find((dataset) => {
    if (!dataset.values) {
      return null
    }
    return dataset.values.find(function (data) {
      return data
    })
  })
  return empty
}
