import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import * as adminActions from '../../store/actions/adminActions'
import withDataLoading from '../hoc/withDataLoading'
import Loading from '../kiddom/loading/loading'
import LineChart from './lineChart'
import ChartControls from './chartControls'
import { initState } from '../../helpers/chartDateHelpers'
import { studentAchievementTooltipOptions } from './tooltips/studentAchievementTooltip'
import * as colors from '../../helpers/colors'
import { dataCoordinates } from '../../helpers/utils'
import { getStudentAchievement } from '../../store/fromState'
import { chartDataShape } from '../../helpers/propShapes/chartDataShapes'
import { studentAchievementMock } from '../../store/mockData/schoolOverview'
import NoChartDataMessage from './noChartDataMessage'

class StudentAchievementChart extends React.Component {
  constructor(props) {
    super(props)
    this.lineRef = React.createRef()
    this.state = initState()
  }

  generateChartData() {
    const { currentDataType, currentDataIndex } = this.state
    const { studentAchievement, emptyChartData } = this.props
    const currentData = studentAchievement.getIn([
      currentDataType,
      currentDataIndex
    ])
    let chartLength
    if (currentData && currentData.get('timeFrame')) {
      chartLength = currentData.getIn(['timeFrame', 'length'])
    } else {
      return null
    }

    const numReportingBuckets = currentData.get('reportingBuckets').size
    let dataBuckets = currentData.get('reportingBuckets')
    if (emptyChartData) {
      dataBuckets = dataBuckets.reverse()
    }
    const datasets = dataBuckets
      .map((reportingBucket, index) => {
        const lastBucket = index + 1 === numReportingBuckets
        const firstBucket = index === 0
        const color = colors.getReportingMethodColorsByIndex(
          numReportingBuckets,
          index
        )
        return {
          label: reportingBucket.get('name'),
          data: dataCoordinates(reportingBucket.get('values').toArray()),
          fill: lastBucket ? 'start' : '+1',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          borderColor: firstBucket ? 'transparent' : colors.white,
          backgroundColor: colors.hexToRGBA(color, 0.6)
        }
      })
      .toJS()

    return {
      ...ChartControls.buildChartLabels(currentData, chartLength),
      datasets
    }
  }

  componentDidMount() {
    this.fetchDataIfNecessary(
      this.state.currentDataType,
      this.state.currentDataIndex
    )
  }

  componentDidUpdate(prevProps) {
    if (!this.props.emptyChartData && prevProps.emptyChartData) {
      this.props.fetchStudentAchievement(
        this.state.currentDataType,
        this.state.currentDataIndex
      )
    }
  }

  updateChartDataView = (currentDataView) => {
    const { timeFrame, nextTimeframeID } = currentDataView
    this.fetchDataIfNecessary(timeFrame, nextTimeframeID)
    this.setState({
      currentDataType: timeFrame,
      currentDataIndex: nextTimeframeID
    })
  }

  fetchDataIfNecessary(timeframe, timeframeID) {
    const nextTimeFrame = this.props.studentAchievement.getIn([
      timeframe,
      timeframeID
    ])
    if (!nextTimeFrame) {
      this.props.fetchStudentAchievement(timeframe, timeframeID)
    }
  }

  renderChart(chartData) {
    const chartControlProps = {
      allData: this.props.studentAchievement,
      currentTimeframeID: this.state.currentDataIndex,
      currentTimeFrame: this.state.currentDataType,
      updateChartDataView: this.updateChartDataView,
      disabled: this.props.emptyChartData
    }
    const chartOptions = studentAchievementTooltipOptions(
      this.lineRef,
      chartData.datasets.size
    )
    if (!chartData) {
      return null
    }
    return (
      <div className="student-achievement-chart">
        <NoChartDataMessage active={this.props.emptyChartData} />
        <LineChart
          ref={this.lineRef}
          chartData={chartData}
          options={chartOptions}
          hasMouseMoveAnnotation
        />
        <ChartControls {...chartControlProps} />
      </div>
    )
  }

  renderLoading() {
    return <Loading type="academy" />
  }

  render() {
    const chartData = this.generateChartData()
    const content = chartData
      ? this.renderChart(chartData)
      : this.renderLoading()
    return content
  }
}

StudentAchievementChart.propTypes = {
  schoolID: PropTypes.string,
  studentAchievement: chartDataShape,
  fetchStudentAchievement: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchStudentAchievement: (timeFrame, timeframeID) => {
    return dispatch(
      adminActions.studentAchievement.read.begin(
        ownProps.schoolID,
        timeFrame,
        timeframeID
      )
    )
  }
})

const mapStateToProps = (state, ownProps) => {
  const studentAchievement = ownProps.emptyChartData
    ? studentAchievementMock
    : getStudentAchievement(state, ownProps.schoolID)

  return {
    studentAchievement
  }
}
const generateDataLoaders = (props) => {
  const initConditions = initState()
  return [
    adminActions.studentAchievement.read.begin(
      props.schoolID,
      initConditions.currentDataType,
      initConditions.currentDataIndex
    )
  ]
}

const reloadProps = ['schoolID']

export default withDataLoading(
  connect(mapStateToProps, mapDispatchToProps)(StudentAchievementChart),
  generateDataLoaders,
  null,
  reloadProps
)
