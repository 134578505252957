import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import {
  BLANK_NAME_ERROR_LABEL,
  EMAIL_REGEX,
  INVALID_EMAIL_ERROR_LABEL,
  ROLE,
  USER_MANAGEMENT_ROLES
} from '../../helpers/constants'
import i18n from '../../helpers/i18n'

import EditableList from '../ui/listView/editableList'
import Button from '../ui/buttons/button'

import './reviewBulkAdd.scss'

const SHORT_VIEW_LIMIT = 5
const createAccountsLabel = i18n.t('userActions.createAccounts')
const dismissAllErrorsLabel = i18n.t('userActions.dismissAllErrors')
const numErrorsLabel = (numErrors) =>
  i18n.t('errors.numErrors', { count: numErrors })
const reviewImportErrorsText = i18n.t('passages.reviewImportErrors')
const viewAllLabel = i18n.t('labels.viewAll')
const viewLessLabel = i18n.t('labels.viewLess')

class ReviewBulkAdd extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      emailUsers: false,
      users: this.props.users || [],
      fixedErrors: 0,
      showAll: false
    }
    this.registerFixedUsers = this.registerFixedUsers.bind(this)
  }

  changeEmail = (index, value) => {
    let isValid
    if (this.props.role === ROLE.STUDENT && value.length === 0) {
      isValid = true
    } else {
      isValid = value.match(EMAIL_REGEX)
    }
    const error = isValid ? '' : INVALID_EMAIL_ERROR_LABEL
    this.changeValueInList('email', index, value, isValid, error)
  }

  changeName = (index, value) => {
    const trimmedName = value && value.trim()
    const isValid = trimmedName && trimmedName.length
    const error = isValid ? '' : BLANK_NAME_ERROR_LABEL
    this.changeValueInList('name', index, value, isValid, error)
  }

  changeValueInList = (type, index, value, isValid, error = '') => {
    let { fixedErrors } = this.state
    const users = this.state.users.slice(0)
    const user = users[index]
    user[type].value = value
    user[type].valid = !!isValid
    if (isValid && user[type].error) {
      user[type].error = ''
      if (!user.name.error && !user.email.error) {
        fixedErrors++
      }
    } else if (!isValid && !user[type].error) {
      user[type].error = error
      fixedErrors--
    }
    this.setState({ users, fixedErrors })
  }

  toggleShowAll = () => {
    this.setState({ showAll: !this.state.showAll })
  }

  getValidUsers = () => {
    return this.state.users.filter((user) => {
      return !user.name.error && !user.email.error
    })
  }

  registerFixedUsers = () => {
    const nonErrorUsers = this.getValidUsers()
    this.props.registerUsers(nonErrorUsers)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.users !== this.props.users) {
      this.setState({ users: this.props.users || [] })
    }
  }

  render() {
    const { users, showAll, fixedErrors } = this.state
    const { showBulkAdd } = this.props
    const numUsers = users.length
    if (!showBulkAdd || !numUsers) {
      return null
    }
    const displayedUsers = showAll ? users : users.slice(0, 5)
    const showAllClass = classnames('show-all', {
      hidden: numUsers <= SHORT_VIEW_LIMIT
    })
    const showAllToggleLabel = showAll ? viewLessLabel : viewAllLabel
    return (
      <div className="review-bulk-add" data-spec="review-bulk-add">
        <h3>
          <span>{numErrorsLabel(numUsers)}</span>
          <Button
            className={showAllClass}
            data-spec="show-all"
            buttonStyle="text"
            onClick={this.toggleShowAll}
            label={showAllToggleLabel}
          />
        </h3>
        <p>{reviewImportErrorsText}</p>
        <EditableList
          items={displayedUsers}
          changeName={this.changeName}
          changeEmail={this.changeEmail}
          role={this.role}
        />
        <div className="action-buttons">
          <Button
            label={dismissAllErrorsLabel}
            data-spec="dismiss-all-errors"
            buttonStyle="secondary"
            disabled={!!fixedErrors}
            onClick={this.props.dismiss}
          />
          <Button
            label={createAccountsLabel}
            data-spec="create-accounts"
            disabled={!fixedErrors}
            onClick={this.registerFixedUsers}
          />
        </div>
      </div>
    )
  }
}

const bulkAddValueShape = PropTypes.shape({
  value: PropTypes.string,
  valid: PropTypes.bool,
  error: PropTypes.string
})

ReviewBulkAdd.defaultProps = {
  registerUsers: () => {},
  showBulkAdd: true
}

ReviewBulkAdd.propTypes = {
  dismiss: PropTypes.func,
  registerUsers: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: bulkAddValueShape,
      email: bulkAddValueShape
    }).isRequired
  ),
  role: PropTypes.oneOf(USER_MANAGEMENT_ROLES).isRequired,
  showBulkAdd: PropTypes.bool
}

export default ReviewBulkAdd
