import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import { connect } from 'react-redux'

import * as curriculumActions from '../../store/actions/curriculumActions'
import withToJS from '../hoc/withToJS'

import {
  getParentSubjects,
  getSubjectGroupsForSchool
} from '../../store/fromState'
import { subjectGroupTypes } from '../../helpers/constants'

import CustomizeDirectoryPresenter from './customizeDirectoryPresenter'

const CustomizeDirectoryContainer = (props) => (
  <CustomizeDirectoryPresenter {...props} />
)

export const mapStateToProps = (state, ownProps) => {
  // get subject IDs in subject groups containing exactly one subject
  const schoolSubjectIDs = Immutable.Set(
    getSubjectGroupsForSchool(state, ownProps.schoolID)
      .map((s) => {
        const subjectIDs = s.get('subjectIDs')
        return subjectIDs && subjectIDs.size === 1
          ? subjectIDs.get(0)
          : undefined
      })
      .flatten(true)
  )
  const parentSubjects = Immutable.List(getParentSubjects(state).valueSeq())
  const parentSubjectIDs = Immutable.Set(
    parentSubjects.map((s) => s.get('id')).valueSeq()
  )
  const parentSubjectIDsInSchool = parentSubjectIDs.intersect(schoolSubjectIDs)

  return {
    parentSubjects,
    parentSubjectIDsInSchool
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => ({
  createSubjectGroup: (subject) => {
    const tempID = Date.now().toString()
    const subjectType = subject.isCore
      ? subjectGroupTypes.CORE
      : subjectGroupTypes.ELECTIVE
    dispatch(
      curriculumActions.schoolSubjectGroups.create.begin(
        ownProps.schoolID,
        subject.id,
        subjectType,
        tempID
      )
    )
  }
})

const WrappedComponent = withToJS(CustomizeDirectoryContainer)

CustomizeDirectoryContainer.propTypes = {
  schoolID: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
