import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

import { ROLE } from '../../helpers/constants'
import i18n from '../../helpers/i18n'
import BulkActions from './bulkActions'
import Loading from '../kiddom/loading/loading'
import SelectableList from '../ui/listView/selectableList'
import withSearch from '../hoc/withSearch'
import withPagination from '../hoc/withPagination'

import './accountListPresenter.scss'

const nameLabel = i18n.t('labels.name')
const emailLabel = i18n.t('labels.email')
const statusLabel = i18n.t('labels.status')
const roleLabel = i18n.t('labels.role')
const usernameLabel = i18n.t('labels.username')
const nameHeading = { label: nameLabel, className: '' }
const roleHeading = { label: roleLabel, className: 'lg' }
const accountStatusHeading = { label: statusLabel, className: 'lg' }

const listHeadings = {
  [ROLE.TEACHER]: [
    nameHeading,
    { label: emailLabel, className: 'md' },
    roleHeading,
    accountStatusHeading
  ],
  [ROLE.STUDENT]: [
    nameHeading,
    { label: usernameLabel, className: 'md' },
    accountStatusHeading
  ]
}

const listStyles = {
  [ROLE.TEACHER]: {
    name: '',
    email: 'data md',
    role: 'data lg',
    accountStatus: 'data lg'
  },
  [ROLE.STUDENT]: {
    name: '',
    username: 'data md',
    accountStatus: 'data lg'
  }
}

const basicDataKeys = {
  [ROLE.TEACHER]: ['email', 'role', 'accountStatus'],
  [ROLE.STUDENT]: ['username', 'accountStatus']
}

// NOTE: Not entirely sure how this thing works, the way it is used ends up
// just returning an arrow function with selected as the param.
// eslint-disable-next-line react/display-name
const roleBulkActions = (role) => (selected) => (
  <BulkActions role={role} selected={selected} />
)

const AccountListView = (props) => {
  const { items, role, selected, setSelected } = props

  return (
    <SelectableList
      items={items}
      basicDataKeys={basicDataKeys[role]}
      role={role}
      listHeadings={listHeadings[role]}
      listStyles={listStyles[role]}
      selected={selected}
      setSelected={setSelected}
    >
      {roleBulkActions(role)}
    </SelectableList>
  )
}

const EnhancedListView = withSearch(withPagination(AccountListView))

const AccountListPresenter = (props) => {
  const {
    fetchPage,
    hasLoaded,
    pagination,
    role,
    search,
    updateCurrentPage,
    usersByID
  } = props
  const users = Object.values(usersByID)
  const [selected, setSelected] = useState(new Set())
  useEffect(() => {
    setSelected(new Set())
  }, [role])
  if (!users.length) {
    return null
  }
  const searchProps = {
    searchOn: ['name', 'email', 'username'],
    items: users,
    search: role === ROLE.STUDENT && search
  }
  const paginationProps = {
    ...pagination,
    fetchPage,
    itemsByID: usersByID,
    updateCurrentPage,
    skip: role === ROLE.TEACHER
  }
  const selectableProps = {
    selected,
    setSelected
  }
  const className = classnames('list-view-container', {
    loading: !hasLoaded
  })

  return (
    <div className="account-list">
      {!hasLoaded && <Loading type="academy" />}
      <EnhancedListView
        className={className}
        role={role}
        {...searchProps}
        {...paginationProps}
        {...selectableProps}
      />
    </div>
  )
}

const ensureLoaded = (prevProps, nextProps) => {
  return !nextProps.hasLoaded
}

export default React.memo(AccountListPresenter, ensureLoaded)
