import React from 'react'
import { fromJS, Set } from 'immutable'
import Cookie from 'js-cookie'

import i18n from './i18n'
import { LOGIN_URL, CLASSES } from '../routes/routes'
import { CURRICULUM_EDITOR_ROLES, ROLE } from './constants'
import ImportantIcon from '../images/icons/important.svg'
import { sortGrades } from './gradeLevels'

/*
/* random int between min, and max.
/* Including min, excluding max.
/* Requires ints as inputs.
/* Used for Mock Data
*/
export function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}

/*
/* calcPercentTotal
/* Used for getting a percent from part of a whole.
/* Ex: image upload 500 bytes loaded, vs 1000 bytes total = 50%.
*/
export function calcPercentTotal(loaded, total) {
  let percent = 0
  if (total) {
    percent = (loaded * 100) / total
  }
  return Math.floor(percent)
}

/*
/* condenseNumber
/* input up to 999,999 - does not handle Millions
/*
*/
export function condenseNumber(number) {
  if (number < 1000) {
    return number
  } else {
    const thousands = (number / 1000).toFixed(1)
    return `${thousands}K`
  }
}

export function mapArrayObjectsByKey(store, objectsList, storeBy, storeAt) {
  let newStore = store
  objectsList.forEach((object) => {
    newStore = newStore.mergeIn([...storeAt, object[storeBy]], fromJS(object))
  })
  return newStore
}

// mapSkillObjectsByKey maps the correlation keys first. If not found,
// maps with id.
export function mapSkillObjectsByKey(store, objectsList) {
  let newStore = store
  objectsList.forEach((object) => {
    if (object.correlatedOriginalID !== '0') {
      newStore = newStore.mergeIn(
        ['byCorrelatedOriginalID', object.correlatedOriginalID],
        fromJS(object)
      )
    } else {
      newStore = newStore.mergeIn(['byID', object.id], fromJS(object))
    }
  })
  return newStore
}

export const dataCoordinates = (values) => {
  return values.map((point, index) => {
    return { x: index, y: values[index] }
  })
}

export const getChartInstance = (chartRef) => {
  return chartRef && chartRef.current && chartRef.current.chartInstance
}

/* aggregateTeacherData */
/* takes a single Teacher, all classes, and all subjects */
export const aggregateTeacherData = (classes, teacher, _subjectLibrary) => {
  const teacherClasses = classes.filter((c) =>
    c.get('instructorIDs').includes(teacher.get('id'))
  )

  const subjects = []
  teacherClasses.forEach((clazz) => {
    subjects.push(...clazz.get('subjects'))
  })

  const grades = teacherClasses
    .reduce((allGrades, c) => allGrades.union(c.get('grades')), Set())
    .sort(sortGrades)
  const numStudents = teacherClasses.reduce(
    (total, c) => total + c.get('studentCount'),
    0
  )
  const assignmentsAssigned = teacherClasses.reduce(
    (total, c) => total + c.get('assignmentsAssigned'),
    0
  )
  const assignmentsScored = teacherClasses.reduce(
    (total, c) => total + c.get('assignmentsScored'),
    0
  )

  return teacher.merge({
    classCount: teacherClasses.count(),
    grades,
    numStudents,
    assignmentsAssigned,
    assignmentsScored,
    subjects: [...new Set(subjects)] // dedupe the es6 way
  })
}

export const isAuthorizedToSeeAcademy = (userRoles) =>
  userRoles.some((role) =>
    [ROLE.SCHOOL_ADMIN, ROLE.DISTRICT_ADMIN, ROLE.TEACHER].includes(role)
  )

export const redirectToClassroom = (fromAcademy = true) => {
  let url = `${LOGIN_URL}`
  if (fromAcademy) {
    url += `?from=academy&redirect=${encodeURIComponent(window.location.href)}`
  }
  window.location.replace(url)
}

// similar to redirectToClassroom except it goes to classes to reduce redirect time
export const redirectToKiddomApp = () => {
  window.location.href = CLASSES
}

export const findReportingMethodScale = (progress, scales) =>
  scales.findIndex((scale) => {
    const progressIsTop = progress === 100 && scale.maxScore === 100
    const progressInBand =
      scale.minScore <= progress && progress < scale.maxScore
    return progressIsTop || progressInBand
  })

export const reportingMethodScaleName = (progress, reportingMethod) => {
  const scales = reportingMethod.scales
  const index = findReportingMethodScale(progress, scales)
  if (index < 0) {
    return (
      window.Rollbar &&
      window.Rollbar.error(
        'Progress number not encapsulated in scales of this reporting method.'
      )
    )
  }
  return scales[index].name
}

// getGoodReportingLevel
// Some areas want to display the amount of scores above a certain point.
// Since reporting methods can range from 2-6 levels, we select a different
//    level for each unique length set(mastery levels are stored from high to low):
//    2->1, 3->1, 4->2, 5->2, 6->2
// This function finds the correct level, and returns the 'label'.
// Input is setReportingMethod, a single reporting method object, with scales
//    property.
// Some names are ranges: "3.0 - 3.59", "80% - 90%" - we will clip these out so
//    we just use the bottom number.
export function getGoodReportingLevel(reportingMethod) {
  const scales = reportingMethod.get('scales')
  const length = scales.size
  const goodIndex = length > 3 ? 1 : 0
  let name = scales.getIn([goodIndex, 'name'])
  const nameRangeIndicator = name.search(' - ')
  if (nameRangeIndicator > -1) {
    name = name.substring(0, nameRangeIndicator)
  }
  return name
}

// adopted uuid from classroom
const uuid = () => {
  // s4 generates random character sequences of 4 characters a piece.
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)

  // This builds them into the GUID-style format.
  return [
    s4(),
    s4(),
    '-',
    s4(),
    '-',
    s4(),
    '-',
    s4(),
    '-',
    s4(),
    s4(),
    s4()
  ].join('')
}

export const deviceUniqueID = () => {
  const id = Cookie.get('device_unique_id').replace(/"/g, '')

  const verifiedID = checkDeviceID(id)
  if (id !== verifiedID) {
    Cookie.set('device_unique_id', verifiedID)
  }

  return verifiedID
}

export function checkDeviceID(id) {
  if (!id || id.length !== 36) {
    id = uuid()
  }
  return id
}

// https://davidwalsh.name/javascript-debounce-function
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export const debounce = (func, wait = 1500, immediate = false) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

// maybeRetry
// Returns true if the request status code indicates we should retry based on
// typical best practices (retry on 5xx, not on 4xx).
export const maybeRetry = (statusCode) =>
  !statusCode || Math.floor(statusCode / 100) !== 4

// generateErrorMessageAndRetry
// input:
// - errorMessage: string
// - retryAction: function
// - retryCountdown: string provided by withRetry
// - useRetry: bool
// output:
// JSX with text and button
export function generateErrorMessageAndRetry(
  message,
  retryAction,
  retryCountdown,
  useRetry
) {
  const retryMessage = i18n.t('errors.retryInXSeconds', {
    count: retryCountdown
  })
  const errorMessage = useRetry ? `${message} ${retryMessage}` : `${message} `
  const retryLabel = i18n.t('labels.retryNow')
  const retry = (
    <button onClick={retryAction} title={retryLabel} key="error-retry-button">
      {retryLabel}
    </button>
  )
  return [
    <ImportantIcon className="icon" key="error-icon" />,
    errorMessage,
    retry
  ]
}

export const collaboratorIsEditor = (roleKey) => {
  return CURRICULUM_EDITOR_ROLES.has(roleKey)
}

// returns a sort function that sorts objects based on any field.
export const sortObjectsByField = (field) => (a, b) => {
  let aValue = a[field]
  let bValue = b[field]

  // ignore case
  if (typeof aValue === 'string') {
    aValue = aValue.toUpperCase()
  }
  if (typeof bValue === 'string') {
    bValue = bValue.toUpperCase()
  }

  if (aValue < bValue) {
    return -1
  } else if (aValue > bValue) {
    return 1
  }
  return 0
}

export const havePropsChanged = (props, otherProps, keys) =>
  keys.some((key) => props[key] !== otherProps[key])

export function preventDefaultPropagation(e) {
  e.preventDefault()
  e.stopPropagation()
}

export const isStringNullOrWhitespace = (str) =>
  str === null || /^ *$/.test(str)

export const datestring = () => {
  const now = new Date()
  return `${now.getFullYear()}_${
    now.getMonth() + 1
  }_${now.getDate()}_${now.getHours()}_${now.getMinutes()}`
}
