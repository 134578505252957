// Action Creators
export function makeActionCreator(type, ...argNames) {
  return function (...args) {
    const action = { type }
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index]
    })
    return action
  }
}

export const makeResourceActionsCreators = (
  resourceActionIDs,
  options = {}
) => ({
  begin: makeActionCreator(resourceActionIDs.begin, ...(options.begin || [])),
  initialize: makeActionCreator(
    resourceActionIDs.initialize,
    ...(options.initialize || [])
  ),
  success: makeActionCreator(
    resourceActionIDs.success,
    ...(options.success || [])
  ),
  error: makeActionCreator(
    resourceActionIDs.error,
    ...(options.error || ['error'])
  )
})

const actionHelpers = {
  makeActionCreator,
  makeResourceActionsCreators
}

export default actionHelpers
