import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import i18n from '../../../helpers/i18n'
import ArrowNext from '../../../images/icons/arrow-next.svg'
import Checkbox from '../checkbox/checkbox'
import GrowthPercentage from '../growthPercentage'
import HorizontalStackedBar from '../../charts/horizontalStackedBar'
import ProgressBar from '../../charts/progressBar'

import { neutralBkgndMedium } from '../../../helpers/colors'

import './listView.scss'

export const Select = ({ item, selected, onSelected }) => (
  <div className="list-cell">
    <Checkbox
      id={item.id}
      checked={!!selected && selected.has(item.id)}
      onChange={onSelected}
    />
  </div>
)

class ListView extends React.Component {
  // person object - avatar image src, Name, Subjects on sub-line for Teachers.
  renderPersonColumn(item) {
    let subjectsList = item.subjects
    subjectsList =
      (subjectsList && subjectsList.length && subjectsList.join(', ')) || null
    return (
      <div className="person-container" data-spec={'person-container'}>
        <div className="avatar-labels-container">
          <img src={item.avatar} alt={i18n.t('imageAlt.userAvatar')} />
          <div className="person-labels">
            <span className="main-line" title={item.name}>
              {item.name}
            </span>
            {subjectsList && (
              <div className="sub-line-container">
                <span className="sub-line">{subjectsList}</span>
                <span className="sub-line-hover">{subjectsList}</span>
              </div>
            )}
          </div>
        </div>
        <div className="right-arrow">
          <ArrowNext />
        </div>
      </div>
    )
  }

  renderBasicData(dataPoints, item) {
    if (!this.props.basicDataKeys) {
      return null
    }
    return dataPoints.map((dataKey) => {
      let output = item[dataKey]
      if (typeof output !== 'string') {
        output = output || 0
      }
      if (Array.isArray(output)) {
        output = output.join(', ')
      }
      // check mapping with headings to see shownAt
      // classname for mobile responsiveness
      const classname = classnames('list-cell', this.props.listStyles[dataKey])
      return (
        <div className={classname} key={`col-cell-${dataKey}`} title={output}>
          {output}
        </div>
      )
    })
  }

  renderChart(item) {
    // TODO del progressBar once theres a stacked bar for students
    return (
      <div className="list-cell chart-section md">
        {this.props.stackedBar ? (
          <HorizontalStackedBar sections={item.studentMastery} />
        ) : (
          <ProgressBar
            progress={item.studentMastery}
            showLabel={false}
            backgroundColor={neutralBkgndMedium}
            reportingMethod={this.props.reportingMethod}
          />
        )}
      </div>
    )
  }

  renderControls = (id) => {
    const { controls } = this.props
    const className = 'row-controls'
    if (!controls) {
      return null
    }
    const createdControls = controls(id)
    if (!createdControls) {
      return null
    }
    return (
      <div data-spec={className} className={className}>
        {createdControls}
      </div>
    )
  }

  render() {
    const {
      basicDataKeys,
      items,
      listStyles,
      onClickRow,
      onSelected,
      renderRow,
      rowLink,
      selected,
      showMastery,
      withSelect
    } = this.props
    const itemRenderer = renderRow || this.renderPersonColumn

    return items.map((item, index) => {
      const linkDestination = rowLink ? rowLink(item.id) : null
      const ListRow = linkDestination ? Link : 'div'
      return (
        <div key={`column-${index}`} data-spec="list-row" className="list-row">
          <ListRow
            data-spec="list-row-selectable"
            className="list-row-selectable"
            to={linkDestination}
            onClick={() => onClickRow && onClickRow(item.id)}
          >
            {withSelect && (
              <Select item={item} selected={selected} onSelected={onSelected} />
            )}
            <div className="list-cell">{itemRenderer(item)}</div>
            {this.renderBasicData(basicDataKeys, item)}
            {showMastery && this.renderChart(item)}
            {listStyles && listStyles.recentGrowth != null && (
              <div className="list-cell lg">
                <GrowthPercentage value={item.recentGrowth} />
              </div>
            )}
          </ListRow>
          {this.renderControls(item.id)}
        </div>
      )
    })
  }
}

ListView.defaultProps = {
  items: [],
  basicData: [],
  controls: null
}

ListView.propTypes = {
  basicDataKeys: PropTypes.arrayOf(PropTypes.string),
  controls: PropTypes.func
}

export default ListView
