import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { List } from 'immutable'

import withDataLoading from '../../hoc/withDataLoading'
import * as coreActions from '../../../store/actions/coreActions'
import * as curriculumActions from '../../../store/actions/curriculumActions'
import { COURSES, TEACHERS } from '../../../store/models/resources'
import { DEMO_SCHOOL_PLANNER_VIEWED } from '../../../store/userPreferences'
import { withRoleCheckFor } from '../../hoc/withRoleCheckFor'
import {
  getResourceByIDs,
  getCourseCollaborators,
  resourcesLoaded,
  getResourceByID,
  isSchoolFreeTrialAndDemo
} from '../../../store/fromState'
import { sortUsersByNameFunc } from '../../../helpers/users'

import CollaboratorListPresenter from './collaboratorListPresenter'

const CollaboratorListContainer = (props) => (
  <CollaboratorListPresenter {...props} />
)

export const mapStateToProps = (state, ownProps) => {
  const { hasLoaded, courseID } = ownProps
  let collaborators = new List()
  let teacherRoles, curriculumID

  if (courseID && hasLoaded) {
    teacherRoles = getCourseCollaborators(state, courseID)
    const course = getResourceByID(state, COURSES, courseID)
    curriculumID = course.get('curriculumID')
    if (teacherRoles) {
      collaborators = getResourceByIDs(state, TEACHERS, teacherRoles.keySeq())
    }
  }
  const shouldSetUserPref = isSchoolFreeTrialAndDemo(
    state,
    ownProps.match && ownProps.match.params.schoolID
  )
  return {
    loading: !hasLoaded,
    collaborators: collaborators.sort(sortUsersByNameFunc),
    teacherRoles,
    isAdmin: ownProps.isSchoolAdmin || ownProps.isDistrictAdmin,
    curriculumID,
    shouldSetUserPref
  }
}

export const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    checkAndSetUserPref: (shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: DEMO_SCHOOL_PLANNER_VIEWED,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    }
  }
}

const generateDataLoaders = (props) => [
  curriculumActions.courses.read.begin(props.subjectGroupID),
  curriculumActions.units.read.begin(props.subjectGroupID, props.courseID),
  coreActions.teachers.read.begin(props.schoolID)
]

const generateHasLoadedCheckers = (props) => ({
  hasLoaded: [[resourcesLoaded, [[COURSES, TEACHERS], props.schoolID]]]
})

const reloadProps = ['schoolID', 'subjectGroupID', 'courseID']

export default withRouter(
  withRoleCheckFor(
    withDataLoading(
      connect(mapStateToProps, mapDispatchToProps)(CollaboratorListContainer),
      generateDataLoaders,
      generateHasLoadedCheckers,
      reloadProps
    ),
    [], // check for no role, just add user roles props
    true // force render
  )
)
