import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import Button from '../ui/buttons/button'

import * as coreActions from '../../store/actions/coreActions'
import * as fromState from '../../store/fromState'
import { APPOINTLET_SERVICE_ID } from '../../helpers/constants'
import { REQUEST_QUOTE } from '../../store/eventIDs'

import './scheduleAppointment.scss'

const buttonLabel = i18n.t('labels.requestQuote')

export const ScheduleAppointmentButton = (props) => {
  const { userName, userEmail, trackAppointletView } = props
  const buttonStyle = classnames({
    'flat-green': !props.isViewingDemoSchool,
    blue: props.isViewingDemoSchool
  })
  return (
    <Button
      type="button"
      label={buttonLabel}
      title={buttonLabel}
      className="schedule-appointment"
      buttonStyle={buttonStyle}
      onClick={() => loadAppointlet(userName, userEmail, trackAppointletView)}
    />
  )
}

function loadAppointlet(name, email, tracker) {
  window
    .appointlet({
      organization: 'kiddom',
      service: APPOINTLET_SERVICE_ID.getAcademyQuote,
      email,
      fields: { 'your-name': name || '' }
    })
    .show()
  tracker && tracker()
}

export const mapStateToProps = (state, _ownProps) => {
  state = state.parrot || state
  return {
    userName: fromState.getUserProp(state, 'name'),
    userEmail: fromState.getUserProp(state, 'email')
  }
}

export const mapDispatchToProps = (dispatch) => ({
  trackAppointletView: () => {
    dispatch(coreActions.trackEvent.create.begin(REQUEST_QUOTE))
  }
})

ScheduleAppointmentButton.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  isViewingDemoSchool: PropTypes.bool.isRequired
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointmentButton)
