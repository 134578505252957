import React from 'react'
import Immutable from 'immutable'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import i18n from '../../helpers/i18n'
import {
  INACTIVE_TEACHERS_PATH,
  UNACTIVATED_TEACHERS_PATH
} from '../../routes/routes'
import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import CardsPage from '../common/cards/cardsPage'
import TeacherCard from '../common/cards/teacherCard'
import * as utils from '../../helpers/utils'
import {
  TEACHERS,
  CLASSES,
  SUBJECTS,
  REPORTING_METHODS
} from '../../store/models/resources'
import {
  TEACHERS_VIEW_EVENT,
  SINGLE_TEACHER_VIEW_EVENT
} from '../../store/eventIDs'
import withDataLoading from '../hoc/withDataLoading'

class InactiveTeachers extends React.Component {
  componentDidMount() {
    this.props.trackPageViewed()
  }

  renderCards() {
    return this.props.teachers
      .filter(
        (teacher) =>
          this.props.inactiveIDs &&
          this.props.inactiveIDs.includes(teacher.get('id'))
      )
      .map((teacher, index) => {
        return (
          <TeacherCard
            schoolID={this.props.match.params.schoolID}
            teacher={teacher}
            history={this.props.history}
            key={`teacher-card-${index}`}
            onClick={() =>
              this.props.trackSingleTeacherViewed(teacher.get('id'))
            }
          />
        )
      })
  }

  generateButtonProps() {
    const {
      inactiveIDs,
      unactivatedIDs,
      isLoading,
      needToFetchData,
      schoolID,
      history
    } = this.props
    if (needToFetchData || isLoading) {
      return {
        buttons: [],
        defaultSelected: 0
      }
    }
    const numInactive = utils.condenseNumber(inactiveIDs.size)
    const numUnactivated = utils.condenseNumber(unactivatedIDs.size)
    return {
      buttons: [
        {
          label: `${numInactive} Inactive`,
          onClick: () => {
            history.push(INACTIVE_TEACHERS_PATH(schoolID))
          }
        },
        {
          label: `${numUnactivated} Unactivated`,
          onClick: () => {
            history.push(UNACTIVATED_TEACHERS_PATH(schoolID))
          }
        }
      ],
      defaultSelected: 0 // inactive = 0, unactivated = 1
    }
  }

  render() {
    const inactiveTeachersLabel = i18n.t('titles.inactiveTeachers')
    const emptyMessageLabel = i18n.t('labels.noTeachersInThisCategory')
    const inactiveTeachersDescription = i18n.t(
      'passages.inactiveTeachersDescription'
    )
    const cardsPageProps = {
      loading: this.props.isLoading,
      cardData: this.props.teachers,
      pageTitle: inactiveTeachersLabel,
      subtitle: inactiveTeachersDescription,
      emptyMessage: emptyMessageLabel,
      pageClassName: 'inactive-teachers',
      buttonProps: this.generateButtonProps(),
      cards: this.renderCards().toArray()
    }
    return <CardsPage {...cardsPageProps} />
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const { haveAllData } = ownProps
  if (!haveAllData) {
    return { isLoading: true }
  }

  const inactiveIDs = fromState.getInactive(state, TEACHERS)
  const unactivatedIDs = fromState.getUnactivated(state, TEACHERS)
  const { teachers } = fromState.aggregateTeacherClassData(state, schoolID)

  return {
    teachers,
    inactiveIDs,
    unactivatedIDs,
    schoolID
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    trackPageViewed: () =>
      dispatch(
        coreActions.trackEvent.create.begin(TEACHERS_VIEW_EVENT, {
          schoolID,
          filter: {
            inactive: true
          }
        })
      ),
    trackSingleTeacherViewed: (id) => {
      const data = {
        schoolID,
        fromWhere: 'inactive',
        teacher: {
          id,
          inactive: true,
          unactivated: false
        }
      }
      return dispatch(
        coreActions.trackEvent.create.begin(SINGLE_TEACHER_VIEW_EVENT, data)
      )
    }
  }
}

InactiveTeachers.defaultProps = {
  teachers: Immutable.List()
}

const generateDataLoaders = (props) => [
  coreActions.teacherAggregate.read.begin(props.match.params.schoolID),
  adminActions.inactiveTeachers.read.begin(props.match.params.schoolID),
  adminActions.unactivatedTeachers.read.begin(props.match.params.schoolID)
]
const reloadProps = ['schoolID']

const generateLoadingProps = (props) => ({
  isLoading: [
    [fromState.isFetchingInactive, [TEACHERS]],
    [fromState.isFetchingUnactivated, [TEACHERS]],
    [
      fromState.isFetchingResources,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ],
  haveAllData: [[fromState.teacherAccountStatusLoaded, []]]
})

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(InactiveTeachers),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
