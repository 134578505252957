import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { List } from 'immutable'

import withDataLoading from '../hoc/withDataLoading'
import { GRADES } from '../../store/models/resources'
import * as coreActions from '../../store/actions/coreActions'
import GradeLevelSelectPresenter from './gradeLevelSelectPresenter'
import { getList, resourcesLoaded } from '../../store/fromState'
import { filterGradeLevelsByCategory } from '../../helpers/gradeLevels'
import withToJS from '../hoc/withToJS'

const WrappedGradeLevelSelectPresenter = withToJS(GradeLevelSelectPresenter)

export class GradeLevelSelectContainer extends React.Component {
  render() {
    let { gradeLevels, category, values, size } = this.props
    if (gradeLevels && category) {
      gradeLevels = filterGradeLevelsByCategory(gradeLevels, category)
    }

    return (
      <WrappedGradeLevelSelectPresenter
        toggleGradeLevel={this.props.toggleGradeLevel}
        gradeLevels={gradeLevels}
        values={values}
        size={size}
      />
    )
  }
}

GradeLevelSelectContainer.propTypes = {
  toggleGradeLevel: PropTypes.func.isRequired,
  category: PropTypes.string,
  values: PropTypes.object,
  size: PropTypes.string
}

export const mapStateToProps = (state, ownProps) => {
  state = state.parrot || state
  let gradeLevels = ownProps.hasLoaded ? getList(state, GRADES) : new List()
  gradeLevels =
    gradeLevels &&
    gradeLevels.sort(
      (a, b) => a.get('gradeOrdinality') - b.get('gradeOrdinality')
    )
  return {
    gradeLevels
  }
}

const generateDataLoaders = () => [coreActions.grades.read.begin()]

const generateHasLoadedCheckers = () => {
  return {
    hasLoaded: [[resourcesLoaded, [[GRADES]]]]
  }
}

const reloadProps = []
export default withDataLoading(
  connect(mapStateToProps, null)(GradeLevelSelectContainer),
  generateDataLoaders,
  generateHasLoadedCheckers,
  reloadProps
)
