import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import * as coreActions from '../../store/actions/coreActions'
import {
  STUDENTS_VIEW_EVENT,
  SINGLE_STUDENT_VIEW_EVENT
} from '../../store/eventIDs'
import { REPORTING_METHODS, STUDENTS } from '../../store/models/resources'
import { STUDENT_DASHBOARD_VIEWED } from '../../store/userPreferences'
import * as fromState from '../../store/fromState'
import StudentsOverviewPresenter from './studentsOverviewPresenter'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'

const WrappedPresenter = withToJS(StudentsOverviewPresenter)

export class StudentsOverviewContainer extends React.Component {
  componentDidMount() {
    this.props.trackSearch('', 0)
    // for tracking purposes only
    this.tryToSetUserPref()
  }

  componentDidUpdate() {
    this.tryToSetUserPref()
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(this.props.shouldSetUserPref)
      this.userPrefSet = true
    }
  }

  render() {
    return <WrappedPresenter {...this.props} />
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const pagination = fromState.getStudentsPagination(state)
  const studentsByIDs = fromState.getResource(state, STUDENTS)
  const renderSidebar =
    fromState.isUserFreeTrial(state) &&
    fromState.getSchoolIsDemo(state, schoolID)
  const shouldSetUserPref = fromState.isSchoolFreeTrialAndDemo(state, schoolID)
  return {
    pagination,
    renderSidebar,
    reportingMethod: fromState.getSchoolSetReportingMethod(state, schoolID),
    studentsByIDs,
    schoolID,
    shouldSetUserPref,
    userPrefDataLoaded: fromState.userPrefDataLoaded(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const fetchStudents = (query = '', offset = 0) =>
    dispatch(
      coreActions.students.readWithStatistics(
        schoolID,
        query,
        offset,
        ownProps.limit
      )
    )

  return {
    search: (query = '') => fetchStudents(query),
    fetchStudents,
    updateCurrentPage: (currentPage) =>
      dispatch(coreActions.students.read.updateCurrentPage(currentPage)),
    trackSearch: (query, page) => {
      return dispatch(
        coreActions.trackEvent.create.begin(STUDENTS_VIEW_EVENT, {
          schoolID,
          filter: {
            // new search will always be on page 0
            page,
            name: query
          }
        })
      )
    },
    trackSingleStudentViewed: (id) =>
      dispatch(
        coreActions.trackEvent.create.begin(SINGLE_STUDENT_VIEW_EVENT, {
          schoolID,
          fromWhere: 'listAll',
          student: {
            id
          }
        })
      ),
    checkAndSetUserPref: (shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: STUDENT_DASHBOARD_VIEWED,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    fetchPage: (pageNumber) =>
      dispatchProps.fetchStudents(stateProps.query, pageNumber),
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

StudentsOverviewContainer.defaultProps = {
  needToFetchReportingMethod: false,
  studentsByIDs: Immutable.Map({}),
  totalPages: 0,
  query: null,
  pages: null,
  limit: 50
}

StudentsOverviewContainer.propTypes = {
  needToFetchReportingMethod: PropTypes.bool,
  totalPages: PropTypes.number,
  limit: PropTypes.number
}
const generateDataLoaders = (props) => [
  coreActions.students.readWithStatistics(
    props.match.params.schoolID,
    '',
    0,
    props.limit
  ),
  coreActions.reportingMethods.read.begin(props.match.params.schoolID)
]
const generateLoadingProps = (props) => ({
  isLoading: [
    [fromState.isFetching, [STUDENTS]],
    [fromState.isFetchingReportingMethods, [props.match.params.schoolID]],
    [
      fromState.resourcesLoaded,
      [[REPORTING_METHODS], props.match.params.schoolID]
    ]
  ]
})

export default withRouter(
  withDataLoading(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(StudentsOverviewContainer),
    generateDataLoaders,
    generateLoadingProps,
    []
  )
)
