import Immutable from 'immutable'
import moment from 'moment'

import { FEATURE_ACCESS_ID } from '../helpers/constants'
import * as resources from './models/resources'
import * as utils from '../helpers/utils'
import { chartDataInitialState } from './reducers/adminReducers'
import { checkForEmptyData } from '../helpers/chartHelpers'

// resource fetching generics
export const getList = (state, resource) =>
  state.getIn([resource, 'byID']).valueSeq()
export const getIDsList = (state, resource, schoolID) =>
  state
    .getIn([resource, 'byID'])
    .filter(
      (resource) =>
        !schoolID ||
        !resource.get('schoolID') ||
        resource.get('schoolID') === schoolID
    )
    .keySeq()
export const getResource = (state, resource) => state.getIn([resource, 'byID'])
export const getResourceByID = (state, resource, id) =>
  state.getIn([resource, 'byID', id])

export const isFetching = (state, resource) =>
  state.getIn([resource, 'fetching'])
export const getError = (state, resource) => state.getIn([resource, 'error'])

// Students
export const getStudentsInPage = (state, page) => {
  const pagination = getStudentsPagination(state)
  const pageIDs = pagination.getIn(['pages', page.toString()])
  return getResourceByIDs(state, resources.STUDENTS, pageIDs)
}
export function getStudentsPagination(state) {
  return state.getIn([resources.STUDENTS, 'pagination'])
}

export const isFetchingStudentsResource = (state) => {
  const fetchingMap = state.getIn([resources.STUDENTS, 'fetching'])
  let isFetching = false
  fetchingMap.forEach((typeFetching) => {
    isFetching = isFetching || typeFetching
  })
  return isFetching
}
export const isFetchingStudentActivityLists = (state) =>
  state.getIn([resources.STUDENTS, 'fetching', 'activityLists'])

export const isFetchingStudents = (state) =>
  state.getIn([resources.STUDENTS, 'fetching', 'students'])

export const getStudentActivityLists = (state) =>
  state.getIn([resources.STUDENTS, 'activityLists'])

export const getActivityLists = (state, resource) =>
  state.getIn([resource, 'activityLists'])

export const getStudentActivityListIDs = (state, type) =>
  state.getIn([resources.STUDENTS, 'activityLists', type])

export const getStudentIDsBySchoolID = (state, schoolID) => {
  const school = getResourceByID(state, resources.SCHOOLS, schoolID)
  return school && school.get('studentIDs')
}

export const isFetchingSISConfigurations = (state) =>
  state.getIn(['sisIntegration', 'fetching', 'configurations'])
export const getSISConfigurations = (state) =>
  state.getIn(['sisIntegration', 'configurations'])

export function getResourceByIDs(state, resource, ids) {
  return ids.reduce((result, id) => {
    const item = getResourceByID(state, resource, id)
    if (item) {
      result = result.push(item)
    }
    return result
  }, new Immutable.List())
}

export const getTeachersBySchoolID = (state, schoolID) => {
  const all = state.getIn([resources.TEACHERS, 'byID'])
  const filtered =
    all && all.filter((teacher) => teacher.get('schoolID') === schoolID)
  return filtered && filtered.valueSeq()
}

export const getClassesBySchoolID = (state, schoolID) => {
  const all = state.getIn([resources.CLASSES, 'byID'])
  const filtered =
    all && all.filter((clazz) => clazz.get('schoolID') === schoolID)
  return filtered && filtered.valueSeq()
}

export const getCourseCollaborators = (state, courseID) =>
  state.getIn([resources.COURSES, 'byID', courseID, 'sharedWithByUserID'])

export const getStudentAccountStatusCount = (state, type) => {
  const activityList = getStudentActivityListIDs(state, type)
  return activityList && activityList.size
}

export const getActiveStudentPercent = (state) => {
  const active = getStudentAccountStatusCount(state, 'active')
  const activated = getStudentAccountStatusCount(state, 'activated')
  const unactivated = getStudentAccountStatusCount(state, 'unactivated')
  if (!activated) {
    return null
  }
  const total = activated + unactivated
  return Math.ceil((active / total) * 100)
}

export const getActivatedStudentPercent = (state) => {
  const activated = getStudentAccountStatusCount(state, 'activated')
  const unactivated = getStudentAccountStatusCount(state, 'unactivated')
  if (!activated) {
    return null
  }
  const total = activated + unactivated
  return Math.ceil((activated / total) * 100)
}

export const getInactiveStudentCount = (state) =>
  getStudentAccountStatusCount(state, 'inactive')

export const getUnactivatedStudentCount = (state) =>
  getStudentAccountStatusCount(state, 'unactivated')

// Schools
export const loadedBothTrialSchools = (state) => {
  const list = getList(state, resources.SCHOOLS)
  return list && list.size > 1
}

export const getSchoolName = (state, schoolID) =>
  state.getIn(['schools', 'byID', schoolID, 'schoolName'])

export const getSchoolSetReportingMethod = (state, schoolID) => {
  const reportingMethodID = state.getIn([
    'schools',
    'byID',
    schoolID,
    'reportingMethods',
    'setMethodID'
  ])
  const reportingMethod = state.getIn([
    'schools',
    'byID',
    schoolID,
    'reportingMethods',
    reportingMethodID
  ])
  return reportingMethod
}

export const getSchoolIsDemo = (state, schoolID) => {
  const school = getResourceByID(state, 'schools', schoolID)
  return school && school.get('isDemo')
}

export const isFetchingReportingMethods = (state, schoolID) =>
  state.getIn(['schools', 'byID', schoolID, 'fetchingReportingMethods'])

// classes
// topClasses - get a list of class IDs from state.classes.top, then fetch those
// class objects and return them in the order they came from state.classes.top
export const getTopClasses = (state, schoolID) => {
  const top = state.getIn(['classes', 'top'])
  let orderedClasses = Immutable.List()
  top.forEach((classID) => {
    const clazz = getResourceByID(state, 'classes', classID.toString())
    if (clazz && clazz.get('schoolID') === schoolID) {
      orderedClasses = orderedClasses.push(clazz)
    }
  })
  return orderedClasses
}

export const isFetchingTopClasses = (state) =>
  state.getIn(['classes', 'fetchingTop'])
export const isFetchingInactive = (state, resource) =>
  state.getIn([resource, 'fetchingInactive'])
export const isFetchingUnactivated = (state, resource) =>
  state.getIn([resource, 'fetchingUnactivated'])

// Chart Data
export const getStudentAchievement = (state, schoolID) => {
  const chartData = state.getIn([
    'admin',
    'chartData',
    'bySchoolID',
    schoolID,
    'student',
    'achievement',
    'overall'
  ])
  if (chartData) {
    return chartData
  }
  return chartDataInitialState.getIn(['student', 'achievement', 'overall'])
}

export const hasStudentAchievementData = (state, schoolID) => {
  const studentAchievement = getStudentAchievement(state, schoolID)
  const hasLoadedData = studentAchievement.get('month').size > 0
  return hasLoadedData
}

export const getContentUsage = (state, schoolID) => {
  const chartData = state.getIn([
    'admin',
    'chartData',
    'bySchoolID',
    schoolID,
    'contentUsage',
    'overall'
  ])
  if (chartData) {
    return chartData
  }
  return chartDataInitialState.getIn(['contentUsage', 'overall'])
}

export const getTeacherActivity = (state, schoolID) => {
  const chartData = state.getIn([
    'admin',
    'chartData',
    'bySchoolID',
    schoolID,
    'teacher',
    'activity'
  ])
  if (chartData) {
    return chartData
  }
  return chartDataInitialState.getIn(['teacher', 'activity'])
}

export const hasTeacherActivityChartData = (state, schoolID) => {
  const teacherActivity = getTeacherActivity(state, schoolID)
  const hasLoadedData = teacherActivity.get('month').size > 0
  const currentTimeFrame = teacherActivity.get('month').first()
  return hasLoadedData && !checkForEmptyData(currentTimeFrame)
}

// Teacher Reports
export const getTeacherReportsFetching = (state) =>
  state.getIn(['admin', 'teacherReports', 'fetching'])

export const getAllTeacherReports = (state, schoolID) => {
  const data = state.getIn(['admin', 'teacherReports', 'bySchoolID', schoolID])
  return data && data.toArray()
}

export const getTeacherReport = (state, schoolID, teacherID) => {
  const report = state.getIn([
    'admin',
    'teacherReports',
    'bySchoolID',
    schoolID,
    teacherID,
    'report'
  ])
  if (!report) {
    return
  }
  return report
}

// User - get full user data as JS object
export const getUser = (state) => state.getIn(['user', 'userInfo'])

// User - Get single user property
export const getUserProp = (state, prop) => {
  return state.getIn(['user', 'userInfo', prop])
}

export const getUserPreference = (state, preference) => {
  const userPreferences = getUserProp(state, 'userPreferences')
  return userPreferences && userPreferences.get(preference)
}

export const getUserPreferences = (state) => {
  return getUserProp(state, 'userPreferences')
}

export const userDataLoaded = (state) => {
  return state.getIn(['user', 'userInfo', 'id']) !== undefined
}

export const userPrefDataLoaded = (state) => {
  return state.getIn(['user', 'userInfo', 'userPreferences']) !== undefined
}

export const userFreeTrialExpirationDate = (state) => {
  return featureExpiration(
    getUserProp(state, 'platform_features'),
    FEATURE_ACCESS_ID.fullAcademy
  )
}

export const isUserFreeTrial = (state) => {
  const userProp = getUserProp(state, 'platform_features')
  if (!userProp || !userProp.size) {
    return null
  }
  const feature = userProp.find(
    (f) => f.get('id') === FEATURE_ACCESS_ID.fullAcademy
  )
  return feature && feature.get('free_trial')
}

// District
export const getDistrictIDFromSchool = (state, schoolID) =>
  state.getIn(['schools', 'byID', schoolID, 'districtID'])

export const getDistrictIDFromAnySchool = (state) => {
  const school = getList(state, resources.SCHOOLS).get(0)
  return school && school.get('districtID')
}

export const getDistrictID = (state) =>
  state.getIn(['district', 'districtInfo', 'id'])

export const getDistrictName = (state) =>
  state.getIn(['district', 'districtInfo', 'name'])

// Subjects
export const getSubjectByID = (state, id) => {
  const subjects = state.getIn(['subjects', 'byID', id])
  return subjects
}

export const mapSubjectsByID = (state, subjectIDs) =>
  subjectIDs
    ? subjectIDs
        .map((subjectID) => {
          return getSubjectByID(state, subjectID)
        })
        .filter((s) => s)
    : []

export const getSubjects = (state) => state.getIn(['subjects', 'byID'])

// Inactive & Unactivated
export const teacherAccountStatusLoaded = (state) => {
  const loaded = resourcesLoaded(state, [
    resources.TEACHERS,
    resources.CLASSES,
    resources.SUBJECTS
  ])

  return loaded && accountStatusLoaded(state, resources.TEACHERS)
}

export function accountStatusLoaded(state, resource) {
  const inactiveLoaded = getInactive(state, resource) !== null
  const unactivatedLoaded = getUnactivated(state, resource) !== null
  return inactiveLoaded && unactivatedLoaded
}

export function getInactive(state, resource) {
  const inactive = state.getIn([resource, 'activityLists', 'inactive'])
  return inactive
}

export const getActivePercent = (state, resource) => {
  const inactive = getInactive(state, resource)
  const mainResource = getList(state, resource)

  if (!mainResource.size) {
    return null
  }

  return (
    100 -
    Math.floor((((inactive && inactive.size) || 0) / mainResource.size) * 100)
  )
}

export function getUnactivated(state, resource) {
  return state.getIn([resource, 'activityLists', 'unactivated'])
}

export const getActivatedPercent = (state, resource) => {
  const unactivated = getUnactivated(state, resource)
  const mainResource = getList(state, resource)

  if (!mainResource.size) {
    return null
  }

  return (
    100 -
    Math.floor(
      (((unactivated && unactivated.size) || 0) / mainResource.size) * 100
    )
  )
}

// Helpers
export const checkTeachersStudentsClassesData = (state, schoolID) => {
  const neededResources = [
    resources.TEACHERS,
    resources.CLASSES,
    resources.SUBJECTS,
    resources.REPORTING_METHODS
  ]
  const stillFetching = isFetchingResources(state, neededResources, schoolID)
  const haveAllData = resourcesLoaded(state, neededResources, schoolID)
  return { stillFetching, haveAllData }
}

// Check if we're fetching required resources.
export function isFetchingResources(state, neededResources = [], schoolID) {
  const fetching = neededResources.some((r) => {
    switch (r) {
      case resources.REPORTING_METHODS:
        if (!schoolID) {
          window.Rollbar &&
            window.Rollbar.error(
              `schoolID is required if checking ${resources.REPORTING_METHODS}`
            )
          return false
        }
        return isFetchingReportingMethods(state, schoolID)
      case resources.STUDENTS:
        return isFetchingStudentsResource(state)
      case resources.SIS_CONFIGURATIONS:
        return isFetchingSISConfigurations(state)
      default:
        return isFetching(state, r)
    }
  })
  return fetching
}

// Check if we have required resources.
export function resourcesLoaded(state, neededResources = [], schoolID = null) {
  let loaded = true
  neededResources.forEach((r) => {
    switch (r) {
      case resources.REPORTING_METHODS:
        if (!schoolID) {
          window.Rollbar &&
            window.Rollbar.error(
              `schoolID is required if checking ${resources.REPORTING_METHODS}`
            )
          return null
        }
        loaded = loaded && getSchoolSetReportingMethod(state, schoolID)
        break
      case resources.SIS_CONFIGURATIONS:
        loaded = loaded && getSISConfigurations(state).schedule !== undefined
        break
      default:
        loaded =
          loaded &&
          (getIDsList(state, r, schoolID).size ||
            didFetchSchoolID(state, r, schoolID))
    }
  })
  return loaded
}

export function didFetchSchoolID(state, resource, schoolID) {
  return state.getIn([resource, 'didFetchSchoolID', schoolID])
}

// Curriculum Directory goodies
export const getSubjectGroupsForSchool = (state, schoolID) => {
  const schoolSubjectIDs = state.getIn([
    'schools',
    'byID',
    schoolID,
    'subjectGroupIDs'
  ])
  const subjectGroups = (schoolSubjectIDs || Immutable.List()).map((groupID) =>
    state.getIn(['schoolSubjectGroups', 'byID', groupID])
  )

  return subjectGroups.map((subjectGroup) =>
    populateSubjectGroupWithSubjects(state, subjectGroup)
  )
}

// get the subjectGroup and populate with subjects
export const getSubjectGroupsByID = (state, id) => {
  const subjectGroup = getResourceByID(
    state,
    resources.SCHOOL_SUBJECT_GROUPS,
    id
  )
  return subjectGroup && populateSubjectGroupWithSubjects(state, subjectGroup)
}

function populateSubjectGroupWithSubjects(state, subjectGroup) {
  return subjectGroup.merge({
    subjects: mapSubjectsByID(state, subjectGroup.get('subjectIDs'))
  })
}

export const getParentSubjects = (state) =>
  getSubjects(state).filter((s) => !s.get('parentID'))

export const subjectGroupsLoadedForSchool = (state, schoolID) => {
  return (
    state.getIn(['schools', 'byID', schoolID, 'subjectGroupIDs']) !== undefined
  )
}

export const isFetchingSubjectGroupsForSchool = (state, schoolID) =>
  state.getIn(['schoolSubjectGroups', 'fetching', 'bySchoolID', schoolID])

// courses
export const getCoursesFromSubjectGroup = (state, schoolSubjectGroupID) => {
  const courseIDs = state.getIn([
    'schoolSubjectGroups',
    'byID',
    schoolSubjectGroupID,
    'courseIDs'
  ])
  return (courseIDs || Immutable.List()).map((id) =>
    getResourceByID(state, resources.COURSES, id)
  )
}

export const coursesLoaded = (state, schoolSubjectGroupID) => {
  const courseIDs = state.getIn([
    'schoolSubjectGroups',
    'byID',
    schoolSubjectGroupID,
    'courseIDs'
  ])
  return courseIDs !== undefined
}

export const getCourseCurriculumID = (state, courseID) => {
  const course = getResourceByID(state, resources.COURSES, courseID)
  return course && course.get('curriculumID')
}

export const getCourseTreeID = (state, courseID) => {
  const course = getResourceByID(state, resources.COURSES, courseID)
  if (!course) {
    return null
  }
  return state.getIn([
    'curriculum',
    'byID',
    course.get('curriculumID'),
    'treeID'
  ])
}

export const isFetchingCourses = (state, schoolSubjectGroupID) =>
  state.getIn(['courses', 'fetching', 'byID', schoolSubjectGroupID])

export const getCourseFormFetchingAtID = (state, id) =>
  state.getIn(['courses', 'fetching', 'byID', id])

export const getCourseFormErrorValue = (state, id) =>
  state.getIn(['courses', 'error', 'byID', id])

export const getSubjectGroupByID = (state, schoolSubjectGroupID) => {
  const subjectGroup = state.getIn([
    'schoolSubjectGroups',
    'byID',
    schoolSubjectGroupID
  ])
  return subjectGroup.merge({
    subjects: mapSubjectsByID(state, subjectGroup.get('subjectIDs'))
  })
}

/* aggregateClassData */
/*  (input) state - redux state
/*  (output) {teachers}
/* fetches classes from state and populates the subjects and teacher properties
/* from other portions of state.
*/
export const aggregateClassData = (state, schoolID, classList) => {
  const classes = classList || getClassesBySchoolID(state, schoolID)

  return classes
    .map((clazz) => {
      const subjects = mapSubjectsByID(
        state,
        clazz.get('subjectIDs')
      ).map((subject) => subject.get('name'))
      const teacher = getResourceByID(
        state,
        resources.TEACHERS,
        clazz.get('teacherID')
      )

      return clazz.merge({
        subjects,
        teacher
      })
    })
    .filter((clazz) => {
      return clazz.get('schoolID') === schoolID
    })
    .toList()
}

/* aggregateTeacherClassData */
/*  (input) state - redux state
/*  (output) {teachers, classes}
/* use aggregateTeacherData and subjects and classes from the store to build
/* a full teacher object with subjects for each teacher in teachers.
*/
export const aggregateTeacherClassData = (state, schoolID, classList) => {
  const classes = aggregateClassData(state, schoolID, classList)

  let teachers = getTeachersBySchoolID(state, schoolID)
  if (!classes.size || !teachers.size) {
    return { teachers: Immutable.List(), classes: Immutable.List() }
  }
  const subjects = getSubjects(state)
  teachers = teachers
    .map((teacher) => {
      return utils.aggregateTeacherData(classes, teacher, subjects)
    })
    .map((teacher) => {
      let studentMastery = []
      const reports = getTeacherReport(state, schoolID, teacher.get('id'))
      if (reports) {
        studentMastery = reports.get('studentMastery')
      }
      return teacher.merge({
        studentMastery
      })
    })
  return { teachers, classes }
}

export const unitsLoadedForCourseID = (state, courseID) =>
  state.getIn([resources.UNITS, 'byCourseID', courseID]) !== undefined

export const getCourseGradesWithoutNA = (state, courseID) => {
  const grades = state.getIn([resources.COURSES, 'byID', courseID, 'grades'])
  return (
    grades &&
    grades.filter((grade) => {
      return grade !== 'N/A'
    })
  )
}

export const getAllSkillGroupWithSubjects = (state) => {
  const skillGroups = getList(state, resources.SKILL_GROUPS)
  if (!skillGroups.size) {
    return new Immutable.List()
  }
  return skillGroups.map((standardGroup) => {
    const subjectIDs = standardGroup.get('subjectIDs')
    if (!subjectIDs || !subjectIDs.size) {
      return standardGroup
    }
    const subjects = subjectIDs.map((id) =>
      getResourceByID(state, resources.SUBJECTS, id)
    )
    return standardGroup.merge({ subjects: subjects })
  })
}

export const getCourseDeleteError = (state, courseID) =>
  state.getIn(['courses', 'error', 'deleteByID', courseID])

export const getCourseUndeleteError = (state, courseID) =>
  state.getIn(['courses', 'error', 'undeleteByID', courseID])

export const getDeletedCourseIDs = (state) =>
  state.getIn(['courses', 'deletedID'])

export const isDeletingCourse = (state, courseID) =>
  state.getIn(['courses', 'deleting', 'byID', courseID])

// Platform features
export function featureExpiration(state, featureID) {
  const userFeatures = getUserProp(state, 'platform_features')
  if (!userFeatures || !userFeatures.size) {
    return null
  }
  const feature =
    userFeatures && userFeatures.find((f) => f.get('id') === featureID)
  if (feature && feature.get('free_trial') && feature.get('expires_at')) {
    return feature.get('expires_at')
  }
  return '9999-12-31 00:00:00'
}

export const featureCheck = (state, featureID) => {
  const expiry = featureExpiration(state, featureID)
  return !expiry || moment(expiry) > moment().utc()
}

export const userInFeature = (state, featureID) => {
  const userFeatures = getUserProp(state, 'platform_features')
  if (!userFeatures || !userFeatures.size) {
    return null
  }
  const feature = userFeatures.find((f) => f.get('id') === featureID)
  return feature && feature.get('free_trial')
}

export const getFreeTrialSchoolIDs = (state) => {
  if (!isUserFreeTrial(state)) {
    return {
      realSchoolID: null,
      demoSchoolID: null
    }
  }
  const schools = getList(state, resources.SCHOOLS)
  const realSchool = schools.find((school) => !school.get('isDemo'))
  const demoSchool = schools.find((school) => school.get('isDemo'))

  return {
    realSchoolID: realSchool && realSchool.get('id'),
    demoSchoolID: demoSchool && demoSchool.get('id')
  }
}

export const isSchoolFreeTrialAndDemo = (state, schoolID) =>
  isUserFreeTrial(state) && getSchoolIsDemo(state, schoolID)

export const isSchoolFreeTrialAndNotDemo = (state, schoolID) =>
  isUserFreeTrial(state) && !getSchoolIsDemo(state, schoolID)

export const getSearchSchoolList = (state) =>
  state.getIn(['schools', 'searchList'])

export const getLatestImport = (state, role) => {
  const userManagementState = state.get('userManagement')
  if (!userManagementState) {
    return null
  }
  return userManagementState.get(`${role}FileImport`)
}

export const getImportErrors = (state, role) => {
  const latestImport = getLatestImport(state, role)
  return (
    (latestImport &&
      latestImport.get('schools') &&
      latestImport.get('schools').get(0).get('errors')) ||
    Immutable.List()
  )
}

export const getImportNumUsersAdded = (state, role) => {
  const latestImport = getLatestImport(state, role)
  return (
    (latestImport &&
      latestImport.get('schools') &&
      latestImport.get('schools').get(0).get('numUsersAdded')) ||
    0
  )
}

export const getImportIngestionID = (state, role) => {
  const latestImport = getLatestImport(state, role)
  return (latestImport && latestImport.get('ingestionID')) || null
}

export const getActiveImportFile = (state) =>
  state && state.getIn(['userManagement', 'activeImportFile'])

export const isProcessingFileImport = (state, role) =>
  state.getIn(['userManagement', 'fetching', `${role}FileImport`])

export const didFileImportFail = (state, role) =>
  !!state.getIn(['userManagement', 'error', `${role}FileImport`])

export const isSendingInviteEmail = (state, id) =>
  !!state.getIn(['userManagement', 'fetching', 'sendInviteEmail', id])

export const didSendInviteEmailFail = (state, id) =>
  !!state.getIn(['userManagement', 'error', 'sendInviteEmail', id])

export const getStudentAccountsByIngestionID = (state, ingestionID) =>
  state.getIn([
    resources.USER_MANAGEMENT,
    'studentAccounts',
    'byIngestionID',
    ingestionID
  ])

export const getStudentAccountsBatch = (state) =>
  state.getIn([resources.USER_MANAGEMENT, 'studentAccounts', 'batch'])

export const getStudentAccountsBatchFetching = (state) =>
  state.getIn([
    resources.USER_MANAGEMENT,
    'fetching',
    'studentAccounts',
    'batch'
  ])

export const isFetchingStudentAccountsByIngestionID = (state, ingestionID) =>
  state.getIn([
    resources.USER_MANAGEMENT,
    'fetching',
    'studentAccounts',
    'byIngestionID',
    ingestionID
  ])

export const getSISIntegration = (state) => state.getIn(['sisIntegration'])

const fromState = {
  getList,
  getResourceByID,
  resourcesLoaded
}

export default fromState
