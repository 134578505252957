import * as actionIDs from './actionIDs'
import { makeActionCreator, makeResourceActionsCreators } from './actionHelpers'

export const schoolSubjectGroups = {
  read: makeResourceActionsCreators(actionIDs.schoolSubjectGroups.read, {
    begin: ['schoolID'],
    initialize: ['schoolID'],
    success: ['schoolID', 'subjectGroups'],
    error: ['schoolID', 'error']
  }),
  create: makeResourceActionsCreators(actionIDs.schoolSubjectGroups.create, {
    begin: ['schoolID', 'subjectID', 'subjectType', 'tempID'],
    initialize: ['schoolID', 'subjectID', 'subjectType', 'tempID'],
    success: [
      'schoolID',
      'subjectID',
      'subjectType',
      'tempID',
      'schoolSubjectGroup'
    ],
    error: ['tempID', 'error']
  })
}

export const subjectsAndCourses = {
  read: makeResourceActionsCreators(actionIDs.subjectsAndCourses.read, {
    begin: ['schoolID'],
    initialize: ['schoolID'],
    success: ['schoolID', 'subjectsAndCourses'],
    error: ['schoolID', 'error']
  })
}

export const courses = {
  read: makeResourceActionsCreators(actionIDs.courses.read, {
    begin: ['schoolSubjectGroupID', 'forceFetch'],
    initialize: ['schoolSubjectGroupID'],
    success: ['schoolSubjectGroupID', 'courses'],
    error: ['schoolSubjectGroupID', 'error']
  }),
  update: makeResourceActionsCreators(actionIDs.courses.update, {
    begin: ['courseID', 'courseName', 'gradeLevelIDs'],
    initialize: ['courseID'],
    success: ['courseID', 'courseName', 'gradeLevelIDs'],
    error: ['courseID', 'error']
  }),
  share: makeResourceActionsCreators(actionIDs.courses.share, {
    begin: ['curriculumID', 'courseID', 'userID', 'role'],
    initialize: ['courseID', 'userID', 'role'],
    success: ['courseID', 'userID', 'role'],
    error: ['courseID', 'userID', 'error']
  }),
  unshare: makeResourceActionsCreators(actionIDs.courses.unshare, {
    begin: ['curriculumID', 'courseID', 'userID'],
    initialize: ['courseID', 'userID'],
    success: ['courseID', 'userID'],
    error: ['courseID', 'userID', 'error']
  }),
  delete: makeResourceActionsCreators(actionIDs.courses.delete, {
    begin: ['courseID'],
    initialize: ['courseID'],
    success: ['courseID'],
    error: ['courseID', 'error']
  }),
  undelete: makeResourceActionsCreators(actionIDs.courses.undelete, {
    begin: ['courseID'],
    initialize: ['courseID'],
    success: ['courseID'],
    error: ['courseID', 'error']
  }),
  removeCourseTempIDs: makeActionCreator(
    actionIDs.REMOVE_COURSE_TEMP_ID,
    'subjectGroupID'
  )
}

// fetch school subject groups and their courses for a school
export const fetchSchoolSubjectsAndCourses = makeActionCreator(
  actionIDs.FETCH_SCHOOL_SUBJECTS_COURSES,
  'schoolID',
  'forceFetch'
)

export const units = {
  read: makeResourceActionsCreators(actionIDs.units.read, {
    begin: ['subjectGroupID', 'courseID', 'forceFetch'],
    initialize: ['courseID'],
    success: ['courseID', 'curriculum', 'assets'],
    error: ['courseID', 'error']
  })
}

export const skillGroups = {
  read: makeResourceActionsCreators(actionIDs.skillGroups.read, {
    begin: [],
    initialize: [],
    success: ['skillGroups'],
    error: ['error']
  })
}

export const skills = {
  read: makeResourceActionsCreators(actionIDs.skills.read, {
    begin: [
      'schoolID',
      'unitID',
      'searchQuery',
      'skillGroupIDs',
      'subjectIDs',
      'gradeIDs',
      'pageNumber'
    ],
    initialize: ['unitID', 'searchQuery'],
    success: ['unitID', 'skillGroupIDs', 'searchQuery', 'gradeIDs', 'skills'],
    error: ['error']
  }),
  readIndividualSkills: makeResourceActionsCreators(
    actionIDs.skills.readIndividualSkills,
    {
      begin: ['skillIDs', 'unitID'],
      initialize: ['skillIDs', 'unitID'],
      success: ['skillIDs', 'unitID', 'skills'],
      error: ['error']
    }
  ),
  fetchSkillsFromSubjectGroupAndCourse: makeActionCreator(
    actionIDs.skills.fetchSkillsFromSubjectGroupAndCourse,
    'schoolID',
    'unitID',
    'subjectGroupID',
    'courseID'
  )
}

// fetch curriculum data for real schools
export const fetchTrialSchoolSubjects = makeActionCreator(
  actionIDs.FETCH_TRIAL_SCHOOL_SUBJECTS
)
