import Immutable from 'immutable'

import { mapArrayObjectsByKey } from '../../helpers/utils'
import * as actionIDs from '../actions/actionIDs'
import {
  TEACHERS,
  INACTIVE_TEACHERS,
  UNACTIVATED_TEACHERS
} from '../models/resources'
import * as reducerCreators from './reducerCreators'

export const initialState = Immutable.fromJS({
  byID: {},
  activityLists: {
    inactive: null,
    unactivated: null
  },
  fetching: false,
  fetchingInactive: false,
  fetchingUnactivated: false,
  didFetchSchoolID: {},
  error: {
    teachers: null,
    inactive: null,
    unactivated: null
  }
})

const getTeachersSuccess = (state, action) => {
  let newState = state.set('fetching', false)
  newState = newState.setIn(['didFetchSchoolID', action.schoolID], true)
  return mapArrayObjectsByKey(newState, action.teachers, 'id', ['byID'])
}

const getInactiveSuccess = (state, action) => {
  const newState = state.set('fetchingInactive', false)
  return newState.setIn(
    ['activityLists', 'inactive'],
    Immutable.List(action.inactiveTeacherIDs)
  )
}

const getUnactivatedSuccess = (state, action) => {
  const newState = state.set('fetchingUnactivated', false)
  return newState.setIn(
    ['activityLists', 'unactivated'],
    Immutable.List(action.unactivatedTeacherIDs)
  )
}

const handlers = reducerCreators.getHandlers(TEACHERS, {
  [actionIDs.teachers.read.success]: getTeachersSuccess,
  [actionIDs.inactiveTeachers.read.error]: (state, action) =>
    state.merge({
      fetching: false,
      errors: { teachers: action.error }
    })
})

const inactiveHandlers = reducerCreators.getHandlers(INACTIVE_TEACHERS, {
  [actionIDs.inactiveTeachers.read.success]: getInactiveSuccess,
  [actionIDs.inactiveTeachers.read.initialize]: (state) =>
    state.set('fetchingInactive', true),
  [actionIDs.inactiveTeachers.read.error]: (state, action) =>
    state.merge({
      fetchingInactive: false,
      errors: { inactive: action.error }
    })
})

const unactivatedHandlers = reducerCreators.getHandlers(UNACTIVATED_TEACHERS, {
  [actionIDs.unactivatedTeachers.read.success]: getUnactivatedSuccess,
  [actionIDs.unactivatedTeachers.read.initialize]: (state) =>
    state.set('fetchingUnactivated', true),
  [actionIDs.unactivatedTeachers.read.error]: (state, action) =>
    state.merge({
      fetchingUnactivated: false,
      errors: { unactivated: action.error }
    })
})

const teachers = reducerCreators.reducerCreator(
  initialState,
  Object.assign(handlers, inactiveHandlers, unactivatedHandlers)
)

export default teachers
