import React from 'react'

import i18n from '../../helpers/i18n'
import { condenseNumber } from '../../helpers/utils'
import {
  INACTIVE_STUDENTS_PATH,
  UNACTIVATED_STUDENTS_PATH,
  STUDENT_ID_PATH
} from '../../routes/routes'
import CardsPage from '../common/cards/cardsPage'
import Card from '../common/cards/card'
import withPagination from '../hoc/withPagination'

const CardsPageWithPagination = withPagination(CardsPage)

const INACTIVE_LIST_TYPE = 'inactive'
const emptyMessageLabel = i18n.t('labels.noStudentsInThisCategory')

class StudentActivityListPagePresenter extends React.Component {
  renderCards() {
    const { schoolID, students } = this.props
    return students.map((student) => {
      const url = STUDENT_ID_PATH(schoolID, student.id)
      const cardProps = {
        icon: student.avatar,
        title: student.name,
        url,
        subtitle: '',
        onClick: () => this.props.trackSingleStudentViewed(student.id)
      }
      return <Card {...cardProps} key={`student-card-${student.id}`} />
    })
  }

  generateButtonProps() {
    const {
      numInactive,
      numUnactivated,
      isLoading,
      schoolID,
      history,
      listType
    } = this.props
    const buttonTypeIndex = listType === INACTIVE_LIST_TYPE ? 0 : 1
    if (isLoading) {
      return {
        buttons: [],
        defaultSelected: buttonTypeIndex
      }
    }
    const inactive = condenseNumber(numInactive)
    const unactivated = condenseNumber(numUnactivated)
    return {
      buttons: [
        {
          label: `${inactive} Inactive`,
          dataSpec: 'inactive-button',
          onClick: () => {
            history.push(INACTIVE_STUDENTS_PATH(schoolID))
          }
        },
        {
          label: `${unactivated} Unactivated`,
          dataSpec: 'unactivated-button',
          onClick: () => {
            history.push(UNACTIVATED_STUDENTS_PATH(schoolID))
          }
        }
      ],
      defaultSelected: buttonTypeIndex // inactive = 0, unactivated = 1
    }
  }

  render() {
    const {
      pages,
      listType,
      isLoading,
      studentsByIDs,
      totalPages,
      updateCurrentPage
    } = this.props

    const pageTitle = i18n.t(`titles.${listType}Students`)
    const subtitle =
      listType === INACTIVE_LIST_TYPE
        ? i18n.t('passages.inactiveStudentsDescription')
        : i18n.t('passages.unactivatedStudentsDescription')
    const cardsPageProps = {
      loading: isLoading,
      pageTitle,
      subtitle,
      emptyMessage: emptyMessageLabel,
      pageClassName: 'inactive-students',
      buttonProps: this.generateButtonProps(),
      cards: this.renderCards()
    }
    const paginationProps = {
      totalPages,
      fetchPage: updateCurrentPage,
      pages,
      currentPage: this.props.currentPage || 0,
      itemsByID: studentsByIDs,
      updateCurrentPage
    }
    return <CardsPageWithPagination {...cardsPageProps} {...paginationProps} />
  }
}

export default StudentActivityListPagePresenter
