import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { fromJS } from 'immutable'

import rootSaga from '../store/sagas/rootSaga'
import sagaMonitor from '../store/sagas/sagaMonitor/index'
import appReducers from '../store/reducers/reducers'

export default function configureStore(state) {
  const monitor = process.env.NODE_ENV !== 'production' && sagaMonitor
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor: monitor })
  const store = createStore(
    appReducers,
    fromJS(state),
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  )
  sagaMiddleware.run(rootSaga)
  return store
}
