import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as coreActions from '../../store/actions/coreActions'
import * as curriculumActions from '../../store/actions/curriculumActions'
import * as fromState from '../../store/fromState'
import CourseFormPresenter from './courseFormPresenter'
import withToJS from '../hoc/withToJS'
import { FIRST_COURSE_SAVED } from '../../store/userPreferences'

const WrappedPresenter = withToJS(CourseFormPresenter)
const CourseFormContainer = (props) => <WrappedPresenter {...props} />

export const mapStateToProps = (state, ownProps) => {
  const courses = fromState.getCoursesFromSubjectGroup(
    state,
    ownProps.schoolSubjectGroupID
  )
  const course = courses.find(
    (course) =>
      course.get('tempID') === ownProps.formID ||
      course.get('id') === ownProps.formID
  )
  const shouldSetUserPref = fromState.isSchoolFreeTrialAndNotDemo(
    state,
    ownProps.match && ownProps.match.params.schoolID
  )

  return {
    courses, // We need this to check for course name duplication
    course, // Course being edited
    errorValue: fromState.getCourseFormErrorValue(state, ownProps.formID),
    shouldSetUserPref
  }
}

export const mapDispatchToProps = (dispatch, _ownProps) => ({
  editCourse: (courseID, courseName, gradeLevelIDs) =>
    dispatch(
      curriculumActions.courses.update.begin(
        courseID,
        courseName,
        gradeLevelIDs
      )
    ),
  checkAndSetUserPref: (shouldSetUserPref) => {
    dispatch(
      coreActions.user.writePrefs.begin(
        [
          {
            key: FIRST_COURSE_SAVED,
            value: 'true'
          }
        ],
        shouldSetUserPref
      )
    )
  }
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CourseFormContainer)
)
