import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SCHOOL_PATH_PREFIX, CURRICULUM_COURSE_ROUTE } from './routes'
import CurriculumDirectoryContainer from '../components/curriculum/curriculumDirectoryContainer'
import CourseContainer from '../components/curriculum/courseContainer'

const curriculumPathPrefix = `${SCHOOL_PATH_PREFIX}/curriculum`

const CurriculumRouter = () => (
  <Switch>
    <Route
      exact
      path={curriculumPathPrefix}
      component={CurriculumDirectoryContainer}
    />
    <Route exact path={CURRICULUM_COURSE_ROUTE} component={CourseContainer} />
  </Switch>
)

export default CurriculumRouter
