export const errorTransform = (error, rollbarUUID) => {
  const { status, type, jsonBody } = error
  if (status || type || jsonBody) {
    let errorCode, message
    if (jsonBody) {
      errorCode = jsonBody.error_code
      message = jsonBody.message
    }
    return {
      status,
      type,
      errorCode,
      message,
      ...rollbarUUID
    }
  }
  return rollbarUUID
}

export const stringifyArray = (arr) => (arr ? arr.map((x) => x.toString()) : '')
