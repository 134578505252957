import React from 'react'
import { connect } from 'react-redux'

import { dataCoordinates } from '../../helpers/utils'
import { teacherEngagementTooltipOptions } from './tooltips/teacherEngagementTooltip'
import { getTeacherActivity } from '../../store/fromState'
import { initState } from '../../helpers/chartDateHelpers'
import * as adminActions from '../../store/actions/adminActions'
import { teacherActivityMock } from '../../store/mockData/schoolOverview'
import NoChartDataMessage from './noChartDataMessage'

import withDataLoading from '../hoc/withDataLoading'
import LineChart from './lineChart'
import Loading from '../kiddom/loading/loading'
import ChartControls from './chartControls'

class TeacherEngagementChart extends React.Component {
  constructor(props) {
    super(props)
    this.lineRef = React.createRef()
    this.state = initState()
  }

  generateChartData() {
    const { currentDataIndex, currentDataType } = this.state
    const currentData = this.props.teacherActivity.getIn([
      currentDataType,
      currentDataIndex
    ])
    let chartLength
    if (currentData && currentData.get('timeFrame')) {
      chartLength = currentData.getIn(['timeFrame', 'length'])
    } else {
      return null
    }

    return {
      ...ChartControls.buildChartLabels(currentData, chartLength),
      datasets: [
        {
          label: currentData.getIn(['usage', 'name']),
          data: dataCoordinates(
            currentData.getIn(['usage', 'values']).toArray()
          ),
          pointHoverBackgroundColor: '#1bb7ff',
          pointHoverBorderColor: '#ffffff',
          pointHoverRadius: 8,
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointBorderWidth: 2,
          pointRadius: 8,
          pointHitRadius: 8,
          backgroundColor: 'rgba(0,175,145, 0.2)',
          borderWidth: 3,
          borderColor: '#00af91'
        },
        {
          label: currentData.getIn(['assignments_assigned', 'name']),
          data: dataCoordinates(
            currentData.getIn(['assignments_assigned', 'values']).toArray()
          ),
          fill: 'unfilled',
          pointHoverBackgroundColor: '#606c6c',
          pointHoverBorderColor: '#ffffff',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointBorderWidth: 2,
          pointRadius: 8,
          borderWidth: 2,
          borderColor: 'rgba(66,76,76,0.6)'
        },
        {
          label: currentData.getIn(['assignments_graded', 'name']),
          data: dataCoordinates(
            currentData.getIn(['assignments_graded', 'values']).toArray()
          ),
          fill: 'unfilled',
          borderDash: [5, 5],
          pointHoverBackgroundColor: '#606c6c',
          pointHoverBorderColor: '#ffffff',
          pointBackgroundColor: 'transparent',
          pointBorderColor: 'transparent',
          pointBorderWidth: 2,
          pointRadius: 8,
          borderWidth: 2,
          borderColor: 'rgba(66,76,76,0.6)'
        }
      ]
    }
  }

  componentDidMount() {
    this.fetchDataIfNecessary(
      this.state.currentDataType,
      this.state.currentDataIndex
    )
  }

  updateChartDataView = (currentDataView) => {
    const { timeFrame, nextTimeframeID } = currentDataView
    this.fetchDataIfNecessary(timeFrame, nextTimeframeID)
    this.setState({
      currentDataType: timeFrame,
      currentDataIndex: nextTimeframeID
    })
  }

  fetchDataIfNecessary(timeframe, timeframeID) {
    const nextTimeFrame = this.props.teacherActivity.getIn([
      timeframe,
      timeframeID
    ])

    if (!this.props.realLoaded || !nextTimeFrame) {
      this.props.fetchTeacherEngagement(timeframe, timeframeID)
    }
  }

  renderChart(chartData) {
    const chartControlProps = {
      allData: this.props.teacherActivity,
      currentTimeframeID: this.state.currentDataIndex,
      currentTimeFrame: this.state.currentDataType,
      updateChartDataView: this.updateChartDataView,
      disabled: this.props.emptyChartData
    }
    const chartOptions = teacherEngagementTooltipOptions(this.lineRef)
    if (!chartData) {
      return null
    }
    return (
      <div className="teacher-engagement-chart">
        <NoChartDataMessage active={this.props.emptyChartData} />
        <LineChart
          ref={this.lineRef}
          chartData={chartData}
          options={chartOptions}
        />
        <ChartControls {...chartControlProps} />
      </div>
    )
  }

  renderLoading() {
    return <Loading type="academy" />
  }

  render() {
    const chartData = this.generateChartData()
    const content = chartData
      ? this.renderChart(chartData)
      : this.renderLoading()
    return content
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchTeacherEngagement: (timeFrame, timeframeID) =>
    dispatch(
      adminActions.teacherEngagement.read.begin(
        ownProps.schoolID,
        timeFrame,
        timeframeID
      )
    )
})

const mapStateToProps = (state, ownProps) => {
  const realTeacherActivity = getTeacherActivity(state, ownProps.schoolID)
  const teacherActivity = ownProps.emptyChartData
    ? teacherActivityMock
    : realTeacherActivity

  return {
    teacherActivity
  }
}

const generateDataLoaders = (props) => {
  const initConditions = initState()
  return [
    adminActions.teacherEngagement.read.begin(
      props.schoolID,
      initConditions.currentDataType,
      initConditions.currentDataIndex
    )
  ]
}

const reloadProps = ['schoolID']

export default withDataLoading(
  connect(mapStateToProps, mapDispatchToProps)(TeacherEngagementChart),
  generateDataLoaders,
  null,
  reloadProps
)
