import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

// Kiddom helper
import * as fromState from '../../store/fromState'
import * as curriculumActions from '../../store/actions/curriculumActions'

// Kiddom Components
import ListView from '../ui/listView/listView'
import CourseListViewItemContainer from './courseListViewItemContainer'
// Kiddom Styles
import './courseListView.scss'

export class CourseListView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newCourses: []
    }
    this.addCourseForm = this.addCourseForm.bind(this)
    this.removeForm = this.removeForm.bind(this)
  }

  // Removing deleted courses when unmounting so we don't see them
  // else where and next time when component is mounted
  componentWillUnmount() {
    this.props.removeTempIDs()
  }

  addCourseForm() {
    this.setState((state) => {
      const newCourses = [...state.newCourses]
      newCourses.push({
        id: Date.now().toString()
      })
      return {
        newCourses
      }
    })
  }

  removeForm(formID) {
    this.setState((state) => {
      const newCourses = [...state.newCourses].filter(({ id }) => id !== formID)
      return {
        newCourses
      }
    })
  }

  getCourseList = () => {
    const allCourses = this.props.courses.toJS()
    const courses = allCourses.filter((course) => !course.tempID)
    // When multiple courses are added at the same time
    // We don't want courses to move up in the list
    const newCourses = [...this.state.newCourses].map((course) => {
      const newlyCreatedCourse = allCourses.find(
        (newCourse) => newCourse.tempID === course.id
      )
      if (newlyCreatedCourse) {
        return newlyCreatedCourse
      }
      return course
    })
    return [...courses, ...newCourses]
  }

  renderRow = (course) => {
    return (
      <CourseListViewItemContainer
        subjectGroupID={this.props.subjectGroupID}
        courseID={course.id}
        formID={course.id}
        removeForm={this.removeForm}
        addForm={this.addCourseForm}
      />
    )
  }

  render() {
    return (
      <div className="course-list-view list-view-container">
        <ListView items={this.getCourseList()} renderRow={this.renderRow} />
      </div>
    )
  }
}

CourseListView.defaultProps = {
  subjectGroupID: null,
  courses: []
}

CourseListView.propTypes = {
  subjectGroupID: PropTypes.string.isRequired,
  courses: PropTypes.instanceOf(Immutable.Iterable)
}

const mapStateToProps = (state, ownProps) => {
  const courses = fromState.getCoursesFromSubjectGroup(
    state,
    ownProps.subjectGroupID
  )
  const existingCourses = courses
    .filter((course) => !course.get('tempID'))
    .sort((a, b) => a.get('name').localeCompare(b.get('name')))
  const newCourses = courses.filter((course) => course.get('tempID'))
  return {
    courses: existingCourses.concat(newCourses)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeTempIDs: () => {
    dispatch(
      curriculumActions.courses.removeCourseTempIDs(ownProps.subjectGroupID)
    )
    dispatch(
      curriculumActions.courses.read.begin(ownProps.subjectGroupID, true) // forceFetch courses
    )
  }
})

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(CourseListView)
