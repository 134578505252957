import { authSendRequest } from '../../helpers/sendRequest'

declare global {
  interface Window {
    API_SERVER: string
  }
}

export const softDeleteUserAccounts = (
  schoolID: string,
  userIDs: string[]
): Promise<any> => {
  return authSendRequest(
    'PATCH',
    `${window.API_SERVER}/v1/school/${schoolID}/users`,
    { user_ids: userIDs.map((id) => parseInt(id, 10)) }
  )
}
