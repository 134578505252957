import React from 'react'

import i18n from '../../helpers/i18n'

const ClassOverviewPresenter = (props) => {
  const classOverviewLabel = i18n.t('titles.classOverview')
  return (
    <div className="class-overview page">
      <h2>{classOverviewLabel}</h2>
      <h3>{props.match.params.classID}</h3>
    </div>
  )
}

export default ClassOverviewPresenter
