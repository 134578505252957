import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Kiddoms
import { personShape } from '../../../helpers/propShapes/common'
import i18n from '../../../helpers/i18n'
import { gradesLabel } from '../../../helpers/gradeLevels'
import ProgressBar from '../../charts/progressBar'
import * as colors from '../../../helpers/colors'
import Card from './card'
import * as fromState from '../../../store/fromState'
import { getGoodReportingLevel } from '../../../helpers/utils'

class ClassCard extends React.Component {
  generateSubtitle() {
    const subject = this.props.subjects[0] || 'No Subject'
    return `${subject} • ${gradesLabel(this.props.grades)}`
  }

  render() {
    const {
      icon,
      iconComponent,
      title,
      subtitle,
      assignmentsAssigned,
      scoredPercent,
      missingPercent,
      studentCount,
      masteryLevelPercent,
      url,
      onClick,
      setReportingMethod
    } = this.props

    const cardProps = {
      icon,
      iconComponent,
      title,
      url,
      onClick,
      subtitle: subtitle || this.generateSubtitle(),
      statsTitle: i18n.t('labels.assignments'),
      stats: [
        {
          stat: assignmentsAssigned,
          label: i18n.t('labels.assigned')
        },
        {
          stat: `${scoredPercent}%`,
          label: i18n.t('labels.scored')
        },
        {
          stat: `${missingPercent}%`,
          label: i18n.t('labels.missing')
        }
      ]
    }

    const studentLabel = i18n.t('labels.numStudents', {
      studentCount,
      plural: studentCount === 1 ? '' : 's'
    })
    let reportingLabel = '...'
    if (setReportingMethod) {
      reportingLabel = getGoodReportingLevel(setReportingMethod)
    }
    const label1 = i18n.t('labels.percentAtLabelOrHigher', {
      percent: masteryLevelPercent,
      label: reportingLabel
    })

    return (
      <Card {...cardProps}>
        <div className="class-card-container">
          <div className="section">
            <h3>{studentLabel}</h3>
            <p>{label1}</p>
            <ProgressBar
              progress={masteryLevelPercent}
              backgroundColor={colors.neutralBkgndLight}
              showLabel={false}
              height={16}
              partitioned
            />
          </div>
        </div>
      </Card>
    )
  }
}

ClassCard.propTypes = {
  teacher: personShape,
  studentCount: PropTypes.number.isRequired,
  assignmentsAssigned: PropTypes.number.isRequired,
  scoredPercent: PropTypes.number.isRequired,
  missingPercent: PropTypes.number.isRequired,
  masteryLevelPercent: PropTypes.number.isRequired,
  subjects: PropTypes.arrayOf(PropTypes.string),
  grades: PropTypes.arrayOf(PropTypes.string),
  schoolID: PropTypes.string.isRequired
}

export default connect((state, ownProps) => {
  return {
    setReportingMethod: fromState.getSchoolSetReportingMethod(
      state,
      ownProps.schoolID
    )
  }
}, null)(ClassCard)
