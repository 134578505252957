import React from 'react'

import i18n from '../../helpers/i18n'

const StudentsAtRiskOverview = (_props) => {
  const studentOverviewLabel = i18n.t('titles.studentOverview')
  return (
    <div className="students-at-risk-overview page">
      <h2>{studentOverviewLabel}</h2>
    </div>
  )
}

export default StudentsAtRiskOverview
