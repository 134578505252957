import moment from 'moment'

import i18n from '../../helpers/i18n'
import { DATE_FORMAT } from '../../helpers/constants'
import * as colors from '../../helpers/colors'
import { parseDatesFromTimeframeID } from '../../helpers/chartDateHelpers'
import * as coreTransform from './coreTransform'

// topClasses
export const topClasses = (resp) => {
  if (resp.data && resp.data.length) {
    return resp.data[0].values
  }
  return []
}

const getReadableActivityName = (name) =>
  ({
    'report:teacher:sign_in': i18n.t('labels.signIn'),
    'report:teacher:usage': i18n.t('labels.usage'),
    'report:teacher:add_assignment': i18n.t('labels.addAssignment'),
    'report:teacher:score_assignment': i18n.t('labels.scoreAssignment')
  }[name])

const getKeyNameFromActivityName = (name) =>
  ({
    'report:teacher:sign_in': 'sign_in',
    'report:teacher:usage': 'usage',
    'report:teacher:add_assignment': 'assignments_assigned',
    'report:teacher:score_assignment': 'assignments_graded'
  }[name])

export const chartScopeMap = {
  term: 'weeks',
  month: 'days',
  week: 'days',
  year: 'months'
}

const convertDateToNumber = {
  weeks: (date) => date.week(),
  days: (date) => date.dayOfYear(),
  months: (date) => date.month()
}

const processActivityValues = (groupedBy, startDate, endDate, values) => {
  // numberOfPoints points is the number of values on x-axis of a chart
  // when groupBy is week it will be number of weeks between start and end date
  // when groupBy is day it will be number of days between start and end date
  // when groupBy is month it will be number of months between start and end date
  let numberOfPoints = endDate.diff(startDate, groupedBy)
  // moment calculates the difference in months as 11, but we always want 12 points of data for year by months view
  if (groupedBy === 'months') {
    // year view
    numberOfPoints = 12
  }
  const valueArrays = []
  let i
  for (i = 0; i < numberOfPoints; i++) {
    // server returns json as key value of numberOfWeek, day or month
    const date = moment(startDate).add(i, groupedBy)
    const future = date.diff(moment().format(DATE_FORMAT), 'days') > 0
    // numericValue value is day/week/month number which will be same for local or utc time
    const numericValue = convertDateToNumber[groupedBy](date)
    const valuesOnDate = values[numericValue]
    if (!future) {
      if (valuesOnDate) {
        valueArrays.push(valuesOnDate)
      } else {
        valueArrays.push(0)
      }
    }
  }
  return valueArrays
}

export const processTeacherActivityResponse = (
  response,
  chartScope,
  timeframeID
) => {
  const { startDate, endDate } = parseDatesFromTimeframeID(timeframeID).moments
  const length =
    chartScope === 'year'
      ? 12
      : endDate.diff(startDate, chartScopeMap[chartScope])
  const processResponse = {
    timeFrame: {
      start: startDate.format(DATE_FORMAT),
      end: endDate.format(DATE_FORMAT),
      length,
      type: chartScope
    }
  }
  const data = (response && response.data) || []
  data.forEach((activity) => {
    processResponse[getKeyNameFromActivityName(activity.name)] = {
      name: getReadableActivityName(activity.name),
      values: processActivityValues(
        chartScopeMap[chartScope],
        startDate,
        endDate,
        activity.values
      )
    }
  })
  return { [chartScope]: [processResponse] }
}

/*
  Student Achievement Bucket Response
  see adminFixtures for response exmaple
  The values are returned as day/month/week number with
  [% of exceeding, % of mastering, % of approaching, % of developing]
*/

export const processStudentAchievementBucket = (
  chartDataResponse,
  setMethod,
  chartScope,
  timeframeID
) => {
  if (chartDataResponse.data.length !== 1) {
    return Error('Expecting only one data item in list')
  }

  const groupBy = chartScopeMap[chartScope]
  const { startDate, endDate } = parseDatesFromTimeframeID(timeframeID).moments
  // number of values on x-axis
  // it can be number of days/weeks/months based on startDate and endDate
  const numberOfPoints = endDate.diff(startDate, groupBy)

  const buckets = chartDataResponse.data[0].values

  const processedResponse = {
    timeFrame: {
      start: startDate.format(DATE_FORMAT),
      end: endDate.format(DATE_FORMAT),
      length: numberOfPoints,
      type: chartScope
    },
    reportingBuckets: setMethod
      .get('scales')
      .reverse()
      .map((scale) => ({
        name: scale.get('name'),
        values: []
      }))
      .toJS()
  }
  for (let i = 0; i < numberOfPoints; i++) {
    // server returns json as key value of numberOfWeek, day or month
    const date = moment(startDate).add(i, groupBy)
    const numericValue = convertDateToNumber[groupBy](date)
    const bucket = buckets[numericValue]
    const future = date.diff(moment().format(DATE_FORMAT), 'days') > 0
    if (!future) {
      if (bucket) {
        let total = 0
        bucket.forEach((value) => {
          total += value
        })
        let previousValue = 0
        bucket.reverse().forEach((value, index) => {
          const cumulativeAmount = value + previousValue
          previousValue = cumulativeAmount
          const percent = Math.round((cumulativeAmount / total) * 100)
          processedResponse.reportingBuckets[index].values.push(percent)

          if (index === processedResponse.reportingBuckets.length - 1) {
            total = 0
            previousValue = 0
          }
        })
      } else {
        processedResponse.reportingBuckets.forEach((reportingBucket) => {
          reportingBucket.values.push(0)
        })
      }
    }
  }
  processedResponse.reportingBuckets.reverse()
  return { [chartScope]: [processedResponse] }
}

const teacherPerformanceReportNiceName = (name) =>
  ({
    'teacher:add_assigment:count': 'assignmentsAssigned',
    'teacher:scored_assignment:count': 'assignmentsScored',
    'teacher:students_mastery': 'studentMastery'
  }[name])

const studentMasteryColorForIndex = (index) =>
  [
    colors.dataDevelopingFailF,
    colors.dataApproachingD,
    colors.dataMasteringB,
    colors.dataExceedingA
  ][index]

/*
   Server returns student mastery in
   Exceeding, Mastering, Approaching and Developing order
   UI needs it in reverse order
   BE does not return colors we need to add colors here aswell
   See adminFixtures for server response exmaple

 */
const studentMasteryOrder = (studentMastery) => {
  return studentMastery.reverse().map((size, index) => ({
    size,
    color: studentMasteryColorForIndex(index)
  }))
}

export const mapTeacherReportsResponse = (response) => {
  return response.data.map((report) => {
    const values = report.values
    if (report.name === 'teacher:students_mastery') {
      Object.keys(report.values).forEach((teacherID) => {
        values[teacherID] = studentMasteryOrder(report.values[teacherID])
      })
    }
    return {
      values,
      name: teacherPerformanceReportNiceName(report.name)
    }
  })
}

export const studentPerformances = (resp) => resp.data[0].values

export const studentActivityLists = (resp) => {
  const result = {}
  Object.keys(resp).forEach((key) => {
    result[key] = resp[key].map((id) => id.toString())
  })
  return result
}

export const inactiveTeachers = (resp) =>
  resp.inactive.map((teacher) => teacher.id.toString())

export const unactivatedTeachers = (resp) =>
  resp.unactivated.map((teacher) => teacher.id.toString())

export const transformReportingMethod = (data) => ({
  id: data.id.toString(),
  isSelected: data.is_selected,
  name: data.name,
  scales: data.scales.map((scale) => ({
    id: scale.id.toString(),
    name: scale.name,
    minScore: scale.min_score,
    maxScore: scale.max_score
  }))
})

export const reportingMethods = (data) => data.map(transformReportingMethod)

export const studentClassPerformance = (data) => {
  let performance = 0
  if (data && data.grades) {
    const lastIndex = data.grades.length - 1
    const percentage = data.grades[lastIndex].percentage
    if (percentage) {
      performance = Math.round(percentage)
    }
  }
  return performance
}

export const studentClassesStatistics = (data) =>
  data.map((cls) => ({
    id: cls.class_id.toString(),
    ...coreTransform.studentStatistics(cls.stats)
  }))
