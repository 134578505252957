import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  didSendInviteEmailFail,
  isSendingInviteEmail
} from '../../store/fromState'
import * as userManagementActions from '../../store/actions/userManagementActions'

import InviteEmailButtonPresenter, {
  EMAIL_BUTTON_TYPE
} from './inviteEmailButtonPresenter'

export const BulkInviteEmailButtonContainer = (props) => (
  <InviteEmailButtonPresenter {...props} type={EMAIL_BUTTON_TYPE.BULK} />
)

export const mapStateToProps = (state, ownProps) => {
  const userIDs = Array.from(ownProps.userIDs)
  const userIDsStr = userIDs.join()
  return {
    ingestionID: userIDs,
    count: userIDs.length,
    isFailed: didSendInviteEmailFail(state, userIDsStr),
    isLoading: isSendingInviteEmail(state, userIDsStr)
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    sendEmail: (userIDs) => {
      return dispatch(
        userManagementActions.teacherAccounts.sendInviteEmails.begin(
          schoolID,
          userIDs
        )
      )
    }
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BulkInviteEmailButtonContainer)
)
