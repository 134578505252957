import React from 'react'

import { gradeFilterString } from '../../../../helpers/gradeLevels'
import GradeLevelSelectContainer from '../../../curriculum/gradeLevelSelectContainer'

export default function gradeLevelSelectFilter(searchGrades, toggleGradeLevel) {
  return {
    key: 'grades',
    label: gradeFilterString(searchGrades),
    showClose: true,
    FilterComponent: function FilterComponent() {
      return (
        <GradeLevelSelectContainer
          key="grades-container"
          values={searchGrades}
          toggleGradeLevel={toggleGradeLevel}
          size="smaller"
        />
      )
    }
  }
}
