import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import { subjectsToCSSClass } from '../../helpers/subjects'
import { subjectGroupTitleAndSubjectNames } from '../../helpers/subjectGroups'
import { subjectGroupShape } from '../../helpers/propShapes/common'

import ArrowUp from '../../images/icons/arrow-up.svg'
import SubjectIcon from '../ui/subjectIcon'
import CourseListView from './courseListView'
import './subjectGroupCard.scss'

export class SubjectGroupCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coursesVisible: false
    }
    this.toggleCourses = this.toggleCourses.bind(this)
    this.cardRef = React.createRef()
  }

  toggleCourses() {
    this.setState({ coursesVisible: !this.state.coursesVisible })
  }

  componentDidMount() {
    if (this.props.courseCount) {
      this.setState({ coursesVisible: true })
    }
  }

  componentDidUpdate(prevProps) {
    const prevCount = prevProps.courseCount
    const currCount = this.props.courseCount
    // If a course is created, automatically expand the course list
    if (currCount > prevCount) {
      this.setState({ coursesVisible: true })
    }
  }

  render() {
    const { subjectGroup, courseCount } = this.props
    const { coursesVisible } = this.state
    const { title, subjectNames } = subjectGroupTitleAndSubjectNames(
      subjectGroup
    )
    let courseCountLabel = `${courseCount} ${i18n.t('labels.courses')}`
    if (courseCount === 1) {
      courseCountLabel = `${courseCount} ${i18n.t('labels.course')}`
    }
    const className = classnames(
      'subject-group-card',
      subjectsToCSSClass(subjectNames),
      {
        coursesVisible
      }
    )

    return (
      <div ref={this.cardRef} className={className}>
        <header
          className="subject-group-card-header"
          title={title}
          onClick={this.toggleCourses}
        >
          <div className="subject-group-info">
            <SubjectIcon names={subjectNames} />
            <div className="title-container">
              <h3>{title}</h3>
              <span>{courseCountLabel}</span>
            </div>
          </div>
          <div className="subject-group-buttons">
            {courseCount > 0 && (
              <div className="arrow-icon-container">
                <div className="vertical-separator" />
                <ArrowUp
                  className={classnames('arrow-icon', {
                    flipped: !coursesVisible
                  })}
                  onClick={this.toggleCourses}
                  data-spec="arrow"
                />
              </div>
            )}
          </div>
        </header>
        <div className="course-list-form-container">
          <div
            className={classnames('course-list', {
              visible: coursesVisible
            })}
            data-spec="course-list"
          >
            <CourseListView
              ref="courseListView"
              subjectGroupID={subjectGroup.id}
            />
          </div>
        </div>
      </div>
    )
  }
}

SubjectGroupCard.defaultProps = {
  courseCount: 0
}

SubjectGroupCard.propTypes = {
  subjectGroup: subjectGroupShape,
  courseCount: PropTypes.number
}

export default SubjectGroupCard
