import './button.scss'

import React, { MouseEvent, ReactNode } from 'react'

import classnames from 'classnames'

export enum ButtonState {
  Active = 'active',
  Focus = 'focus',
  Hover = 'hover'
}

export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export enum ButtonType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit'
}

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Action = 'action',
  Minimal = 'minimal',
  PillStandard = 'pill-standard',
  Icon = 'icon',
  EncasedIcon = 'encased-icon',
  CustomAria = 'custom-aria',
  LabelAndIcon = 'label-and-icon',
  Destructive = 'destructive',
  Send = 'send'
}

export interface IButtonBaseProp {
  state?: ButtonState
  className?: string
  type?: ButtonType
  disabled?: boolean
  size?: ButtonSize
  variant?: ButtonVariant
  isBlock?: boolean
  isEqualDimens?: boolean
  alt?: string
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>
  mouseEnter?(event?: MouseEvent<HTMLButtonElement>): void
  mouseLeave?(event?: MouseEvent<HTMLButtonElement>): void
  isTransparent?: boolean
  value?: number | string
}

export interface IButtonProps extends IButtonBaseProp {
  children: ReactNode | string
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  function Button(
    {
      /*
      do not see this reference and should not be passed along with props
      */
      // eslint-disable-next-line
      isBlock,
      // eslint-disable-next-line
      isEqualDimens,
      state,
      children,
      type = ButtonType.Button,
      disabled = false,
      size = ButtonSize.Small,
      variant = ButtonVariant.Primary,
      onClick,
      value,
      className,
      alt,
      isTransparent,
      mouseEnter,
      mouseLeave,
      // NOTE: dangerous props passing as these apply directly to an html botton
      ...props
    }: IButtonProps,
    ref
  ) {
    const classes = classnames(
      `k-button k-button--${variant}`,
      /* 
         NOTE: this is faulty at least in storybook 
         wherein undefined string can be passed 
      */
      `k-button-size-${size}`,
      {
        'button-equal-dimens': isEqualDimens,
        'k-button--active': state === ButtonState.Active,
        'k-button--focus': state === ButtonState.Focus,
        'k-button--disabled': disabled,
        'k-button--hover': state === ButtonState.Hover,
        'k-button--transparent-setting': isTransparent
      },
      className
    )

    return (
      <button
        type={type}
        className={classes}
        disabled={disabled}
        onClick={onClick}
        aria-label={alt}
        title={alt}
        ref={ref}
        value={value}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        {...props}
      >
        {children}
      </button>
    )
  }
)

export default Button
