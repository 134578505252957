import React from 'react'
import { connect } from 'react-redux'

import {
  getImportIngestionID,
  getImportNumUsersAdded,
  getStudentAccountsByIngestionID,
  isFetchingStudentAccountsByIngestionID
} from '../../store/fromState'
import * as userManagementActions from '../../store/actions/userManagementActions'
import withToJS from '../hoc/withToJS'
import ImportSuccessPresenter from './importSuccessPresenter'

const WrappedPresenter = withToJS(ImportSuccessPresenter)
const ImportSuccessContainer = (props) => <WrappedPresenter {...props} />

export const mapStateToProps = (state, ownProps) => {
  const role = ownProps.role
  const ingestionID = getImportIngestionID(state, role)

  return {
    ingestionID,
    numUsersAdded: getImportNumUsersAdded(state, role),
    studentAccounts: getStudentAccountsByIngestionID(state, ingestionID),
    isFetchingStudentAccounts: isFetchingStudentAccountsByIngestionID(
      state,
      ingestionID
    )
  }
}

export const mapDispatchToProps = (dispatch) => ({
  getStudentAccounts: (ingestionID, schoolID) =>
    dispatch(
      userManagementActions.studentAccounts.read.begin(ingestionID, schoolID)
    )
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const schoolID = ownProps.schoolID
  const ingestionID = stateProps.ingestionID

  const getStudentAccounts = () =>
    dispatchProps.getStudentAccounts(ingestionID, schoolID)

  return {
    ...stateProps,
    ...ownProps,
    getStudentAccounts
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ImportSuccessContainer)
