import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Kiddoms
import i18n from '../../../helpers/i18n'
import './card.scss'

class Card extends React.Component {
  renderStats() {
    const stats = this.props.stats.map((stat, idx) => (
      <div key={`stat-${idx}`}>
        <h2 className="stat">{stat.stat}</h2>
        <p className="label">{stat.label}</p>
      </div>
    ))
    return (
      <div className="stats-container">
        {this.props.statsTitle && <h3>{this.props.statsTitle}</h3>}
        <div className="stats">{stats}</div>
      </div>
    )
  }

  renderIcon() {
    if (this.props.iconComponent) {
      return this.props.iconComponent
    }
    return (
      this.props.icon && (
        <img
          className="icon"
          data-spec="icon"
          alt={i18n.t('imageAlt.userAvatar')}
          src={this.props.icon}
        />
      )
    )
  }

  renderCardContent() {
    return (
      <React.Fragment>
        <div className="header-container">
          {this.renderIcon()}
          <div className="title-container">
            <h3>{this.props.title}</h3>
            <p>{this.props.subtitle}</p>
          </div>
        </div>
        {this.props.stats && this.renderStats()}
        {this.props.children}
      </React.Fragment>
    )
  }

  render() {
    const content = this.renderCardContent()
    const { url, onClick, className } = this.props
    const linkWrapperContent = url ? (
      <Link to={url} data-spec="link">
        {content}
      </Link>
    ) : (
      content
    )

    return (
      <div
        className={`${className} card`}
        onClick={onClick}
        data-spec="base-card"
      >
        {linkWrapperContent}
      </div>
    )
  }
}

Card.defaultProps = {
  className: ''
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  iconComponent: PropTypes.node,
  statsTitle: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      stat: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired
    })
  ),
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string
}

export default Card
