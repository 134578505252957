import { fromJS } from 'immutable'
import { reducerCreator, handleCreator } from './reducerCreators'
import * as actionIDs from '../actions/actionIDs'
import { SIS_STATE } from '../../helpers/constants'
import { getMaxPairs } from '../../helpers/sisHelpers'

export const initialState = (() => {
  const state = {
    fetching: {
      sendCredentials: false,
      credentials: false,
      matches: false,
      configurations: false,
      submitMatches: false
    },
    error: {
      sendCredentials: {},
      credentials: {},
      matches: {},
      configurations: {},
      submitMatches: {}
    },
    state: SIS_STATE.FETCH_CREDENTIALS,
    matches: {},
    configurations: {}
  }
  return fromJS(state)
})()

// for submitting sis credential form
const setSISCredentialsOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'sendCredentials'], fetching)
    return newState
  },
  placementFunction: (state) => {
    let newState = state
    newState = newState.setIn(['state'], SIS_STATE.FETCH_SCHOOL_MATCHES)
    newState = newState.setIn(['error', 'sendCredentials'], {})
    return newState
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'sendCredentials'], error)
  }
}

// for checking credential status
const sisCredentialsOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'credentials'], fetching)
    return newState
  },
  placementFunction: (state, action) => {
    let newState = state
    let nextState = SIS_STATE.SEND_CREDENTIALS
    if (action.response.credentials.length > 0) {
      nextState = SIS_STATE.FETCH_SCHOOL_MATCHES
    }

    newState = newState.setIn(['state'], nextState)
    newState = newState.setIn(['error', 'credentials'], {})
    return newState
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'credentials'], error)
  }
}

// for fetching matches
const sisMatchesOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'matches'], fetching)
    return newState
  },
  placementFunction: (state, action) => {
    let newState = state
    const response = action.response
    newState = newState.setIn(['matches'], response)

    let nextState = SIS_STATE.MATCH_SCHOOLS
    if (response.schools.length === 0) {
      nextState = SIS_STATE.SYNC_SCHOOLS
    } else if (response.pairs.length === getMaxPairs(response)) {
      nextState = SIS_STATE.SET_CONFIGURATION
    }

    newState = newState.setIn(['state'], nextState)
    newState = newState.setIn(['error', 'matches'], {})
    return newState
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'matches'], error)
  }
}

// for submitting matches (post)
const updateSISMatchesOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'submitMatches'], fetching)
    return newState
  },
  placementFunction: (state) => {
    let newState = state
    newState = newState.setIn(['state'], SIS_STATE.SET_CONFIGURATION)
    newState = newState.setIn(['error', 'submitMatches'], {})
    return newState
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'submitMatches'], error)
  }
}

const readSISConfigurationsOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'configurations'], fetching)
    return newState
  },
  placementFunction: (state, action) => {
    let newState = state
    newState = newState.setIn(['configurations'], action.response)
    newState = newState.setIn(['error', 'configurations'], {})
    return newState
  },
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'configurations'], error)
}

const readSISConfigurationsHandlers = handleCreator(
  actionIDs.sisConfigurations.read,
  {},
  readSISConfigurationsOptions
)

const updateSISConfigurationsOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['fetching', 'configurations'], fetching)
    return newState
  },
  placementFunction: (state, action) => {
    let newState = state

    const configurations = state.getIn(['configurations'])
    configurations.subjects = Array.from(action.subjectIDs)
    configurations.grades = Array.from(action.gradeIDs)
    configurations.schedule = action.schedule
    configurations.school_settings.forEach((s) => {
      s.is_sync_enabled = action.schoolIDs.has(s.school_id)
    })

    newState = newState.setIn(['configurations'], configurations)
    newState = newState.setIn(['error', 'configurations'], {})
    return newState
  },
  errorLocation: (state, action, error) => {
    let newState = state
    newState = newState.setIn(['error', 'configurations'], error)
    return newState
  }
}

const updateSISConfigurationsHandlers = handleCreator(
  actionIDs.sisConfigurations.update,
  {},
  updateSISConfigurationsOptions
)

const sisIntegrationHandlers = handleCreator(
  actionIDs.sisIntegration.sendSISCredentials,
  {},
  setSISCredentialsOptions
)

const sisCredentialsHandlers = handleCreator(
  actionIDs.sisCredentials.read,
  {},
  sisCredentialsOptions
)

const sisMatchesHandlers = handleCreator(
  actionIDs.sisSchoolMatches.read,
  {},
  sisMatchesOptions
)

const updateSISMatchesHandlers = handleCreator(
  actionIDs.sisSchoolMatches.update,
  {},
  updateSISMatchesOptions
)

const handlers = Object.assign(
  sisIntegrationHandlers,
  sisCredentialsHandlers,
  sisMatchesHandlers,
  updateSISMatchesHandlers,
  readSISConfigurationsHandlers,
  updateSISConfigurationsHandlers
)

const sisIntegration = reducerCreator(initialState, handlers)

export default sisIntegration
