import { fromJS } from 'immutable'

import * as reducerCreators from './reducerCreators'
import { mapArrayObjectsByKey } from '../../helpers/utils'

export const initialState = fromJS({
  byID: {},
  fetching: false,
  error: {}
})

const intialReadOptions = {
  placementFunction: (state, action) =>
    mapArrayObjectsByKey(state, action.assets, 'id', ['byID'])
}

const tempS3Options = {
  placementFunction: (state, action) => {
    return state.setIn(['byID', action.file.id], fromJS(action.file))
  }
}

const updateFromApiOptions = {
  placementFunction: (state, action) => {
    const newState = state.removeIn(['byID', action.file.id])
    return newState.setIn(
      ['byID', action.responseFile.id],
      fromJS(action.responseFile)
    )
  }
}

const getHandlers = reducerCreators.handleCreator('', {}, intialReadOptions)
const s3Handlers = reducerCreators.handleCreator('', {}, tempS3Options)
const unitGetHandlers = reducerCreators.handleCreator(
  '',
  {},
  updateFromApiOptions
)
const handlers = { ...unitGetHandlers, ...getHandlers, ...s3Handlers }
const assets = reducerCreators.reducerCreator(initialState, handlers)

export default assets
