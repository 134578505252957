import React from 'react'
import { connect } from 'react-redux'
import * as fromState from '../../store/fromState'
import { ROLE } from '../../helpers/constants'
import { SCHOOLS } from '../../store/models/resources'
import * as routes from '../../routes/routes'

const isUserAnyOf = (userRoles, desiredRoles) =>
  userRoles && userRoles.some((role) => desiredRoles.includes(role))

export const withSchoolOrDistrictAdminRoleCheck = (
  WrappedComponent,
  forceRender
) =>
  withRoleCheckFor(
    WrappedComponent,
    [ROLE.SCHOOL_ADMIN, ROLE.DISTRICT_ADMIN],
    forceRender
  )

export const withDistrictAdminRoleCheck = (WrappedComponent, forceRender) =>
  withRoleCheckFor(WrappedComponent, [ROLE.DISTRICT_ADMIN], forceRender)

export function withRoleCheckFor(WrappedComponent, roles, forceRender = false) {
  class WithRoleCheckFor extends React.Component {
    hasGivenRole() {
      return isUserAnyOf(this.props.userRoles, roles)
    }

    isDistrictAdmin() {
      return isUserAnyOf(this.props.userRoles, [ROLE.DISTRICT_ADMIN])
    }

    isSchoolAdmin() {
      return isUserAnyOf(this.props.userRoles, [ROLE.SCHOOL_ADMIN])
    }

    isSchoolTeacher() {
      return isUserAnyOf(this.props.userRoles, [ROLE.TEACHER])
    }

    getSchoolID() {
      if (
        this.props.schoolIDs &&
        this.props.schoolIDs.size &&
        this.props.schoolIDs.size >= 1
      ) {
        return this.props.schoolIDs.get(0)
      }
      return null
    }

    redirectToSingleSchool() {
      const schoolID = this.getSchoolID()
      if (schoolID) {
        this.props.history.push(routes.SCHOOL_PATH_ROOT(schoolID))
      }
    }

    redirectToSchoolCurriculum() {
      const schoolID = this.getSchoolID()
      if (schoolID) {
        this.props.history.push(routes.CURRICULUM_PATH_ROOT(schoolID))
      }
    }

    checkForRedirect() {
      if (this.isSchoolAdmin() || this.isDistrictAdmin()) {
        this.redirectToSingleSchool()
      }
      if (this.isSchoolTeacher()) {
        this.redirectToSchoolCurriculum()
      }
    }

    render() {
      if (!this.hasGivenRole() && !forceRender) {
        this.checkForRedirect()
        return null
      }
      return (
        <WrappedComponent
          isSchoolAdmin={this.isSchoolAdmin()}
          isDistrictAdmin={this.isDistrictAdmin()}
          isSchoolTeacher={this.isSchoolTeacher()}
          {...this.props}
        />
      )
    }
  }
  const mapStateToProps = (state) => {
    state = state.parrot || state
    return {
      userRoles: fromState.getUserProp(state, 'roles_list'),
      schoolIDs: fromState.getIDsList(state, SCHOOLS)
    }
  }
  return connect(mapStateToProps)(WithRoleCheckFor)
}

export default withRoleCheckFor
