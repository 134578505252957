import Immutable from 'immutable'

import { mapArrayObjectsByKey } from '../../helpers/utils'
import * as actionIDs from '../actions/actionIDs'
import {
  STUDENT,
  STUDENTS,
  STUDENT_CLASSES_STATISTICS,
  STUDENT_ACTIVITY_LISTS,
  STUDENTS_BATCH,
  STUDENT_CLASS_PERFORMANCE,
  STUDENT_ASSIGNMENT_STATISTICS,
  STUDENT_PERFORMANCE
} from '../models/resources'
import * as reducerCreators from './reducerCreators'

const paginationModel = {
  query: null,
  totalPages: 0,
  pages: null,
  currentPage: 0,
  schoolID: null
}

export const initialState = Immutable.fromJS({
  byID: {},
  pagination: paginationModel,
  activityLists: {
    inactive: null,
    active: null,
    activated: null,
    unactivated: null
  },
  totalPages: 0,
  fetching: {},
  errors: {}
})

const getStudentOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'students'], fetching),
  placementFunction: (state, action) =>
    mapArrayObjectsByKey(state, action.students, 'id', ['byID']),
  errorLocation: (state, action, error) =>
    state.setIn(['errors', 'students'], error)
}

const getStudentHandlers = reducerCreators.getHandlers(
  STUDENTS,
  {},
  getStudentOptions
)

export const getAssignmentStatisticsOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'statistics'], fetching),
  placementFunction: (state, action) => {
    let newState = state
    Object.keys(action.statistics).forEach((id) => {
      let student = state.getIn(['byID', id])
      if (student) {
        student = student.merge({
          ...action.statistics[id]
        })
        newState = newState.setIn(['byID', id], student)
      }
    })
    return newState
  },
  errorLocation: (state, action, error) =>
    state.setIn(['errors', 'statistics'], error)
}

const getAssignmentStatHandlers = reducerCreators.getHandlers(
  STUDENT_ASSIGNMENT_STATISTICS,
  {},
  getAssignmentStatisticsOptions
)

export const getPerformanceOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'performance'], fetching),
  placementFunction: (state, action) => {
    let newState = state
    Object.keys(action.performances).forEach((id) => {
      let student = state.getIn(['byID', id])
      if (student) {
        student = student.merge({
          studentMastery: action.performances[id]
        })
        newState = newState.setIn(['byID', id], student)
      }
    })
    return newState
  },
  errorLocation: (state, action, error) =>
    state.setIn(['errors', 'performance'], error)
}

const getPerformanceHandlers = reducerCreators.getHandlers(
  STUDENT_PERFORMANCE,
  {},
  getPerformanceOptions
)

export const getStudentsBatchSuccess = (state, action) => {
  let newState = state.setIn(['fetching', 'batch'], false)
  const students = action.students ? action.students : [action.student]
  newState = mapArrayObjectsByKey(newState, students, 'id', ['byID'])
  return newState
}

export const getStudentActivityListsSuccess = (state, action) =>
  state.mergeDeep({
    fetching: { activityLists: false },
    activityLists: action.studentActivityLists
  })
export const getStudentClassPerformanceSuccess = (state, action) => {
  let newState = state.setIn(['fetching', 'performance'], false)
  let student = newState.getIn(['byID', action.studentID])
  if (student) {
    let classStats = student.getIn(['classStatisticsByID', action.classID])
    if (!classStats) {
      student = student.mergeDeep({
        classStatisticsByID: {
          [action.classID]: {
            performance: action.performance
          }
        }
      })
    } else {
      classStats = classStats.merge({ performance: action.performance })
      student = student.mergeIn(
        ['classStatisticsByID', action.classID],
        classStats
      )
    }
    newState = newState.setIn(['byID', action.studentID], student)
  }
  return newState
}

export const getStudentClassesStatisticsSuccess = (state, action) => {
  const newState = state.setIn(['fetching', 'statistics'], false)
  const { studentID, classesStatistics } = action
  let student = state
    .getIn(['byID', studentID])
    .set('classStatisticsByID', Immutable.Map({}))
  student = mapArrayObjectsByKey(student, classesStatistics, 'id', [
    'classStatisticsByID'
  ])
  return newState.setIn(['byID', studentID], student)
}

export const updatePagination = (state, action) => {
  const { offset, studentIDs, schoolID, totalPages } = action
  let pagination = state.get('pagination')
  // if the query has not changed, keep the current pagination data
  // otherwise, reset pagination
  if (
    state.getIn(['pagination', 'query']) === action.query &&
    state.getIn(['pagination', 'schoolID']) === schoolID
  ) {
    let pages = pagination.get('pages') || new Immutable.Map({})
    pages = pages.set(offset.toString(), studentIDs)
    pagination = pagination.merge({
      pages,
      currentPage: parseInt(offset, 10),
      totalPages
    })
  } else {
    // first page only
    pagination = Immutable.fromJS({
      query: action.query,
      totalPages: action.totalPages,
      pages: { 0: studentIDs },
      currentPage: 0,
      schoolID
    })
  }
  return state.set('pagination', pagination)
}

export const updateCurrentPage = (state, action) => {
  let pagination = state.get('pagination')
  pagination = pagination.set('currentPage', action.currentPage)
  return state.set('pagination', pagination)
}

const handlers = Object.assign(
  {
    ...reducerCreators.getHandlers(STUDENTS_BATCH, {
      [actionIDs.studentsBatch.read.success]: getStudentsBatchSuccess,
      [actionIDs.studentsBatch.read.initialize]: (state) =>
        state.setIn(['fetching', 'batch'], true),
      [actionIDs.studentsBatch.read.error]: (state, action) =>
        state.mergeDeep({
          fetching: { students: false },
          errors: { students: action.error }
        })
    }),
    ...reducerCreators.getHandlers(STUDENT_ACTIVITY_LISTS, {
      [actionIDs.studentActivityLists.read
        .success]: getStudentActivityListsSuccess,
      [actionIDs.studentActivityLists.read.initialize]: (state) =>
        state.setIn(['fetching', 'activityLists'], true),
      [actionIDs.studentActivityLists.read.error]: (state, action) =>
        state.mergeDeep({
          fetching: { activityLists: false },
          errors: { activityLists: action.error }
        })
    }),
    ...reducerCreators.getHandlers(STUDENT, {
      [actionIDs.student.read.success]: getStudentsBatchSuccess,
      [actionIDs.student.read.initialize]: (state) =>
        state.setIn(['fetching', 'batch'], true)
    }),
    ...reducerCreators.getHandlers(STUDENT_CLASSES_STATISTICS, {
      [actionIDs.studentClassesStatistics.read
        .success]: getStudentClassesStatisticsSuccess,
      [actionIDs.studentClassesStatistics.read.initialize]: (state) =>
        state.setIn(['fetching', 'statistics'], true)
    }),
    ...reducerCreators.getHandlers(STUDENT_CLASS_PERFORMANCE, {
      [actionIDs.studentClassPerformance.read
        .success]: getStudentClassPerformanceSuccess,
      [actionIDs.studentClassPerformance.read.initialize]: (state) =>
        state.setIn(['fetching', 'performance'], true)
    }),
    [actionIDs.UPDATE_STUDENT_PAGINATION]: updatePagination,
    [actionIDs.UPDATE_STUDENT_CURRENT_PAGE]: updateCurrentPage
  },
  getStudentHandlers,
  getAssignmentStatHandlers,
  getPerformanceHandlers
)

const students = reducerCreators.reducerCreator(initialState, handlers)

export default students
