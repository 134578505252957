import React, { useEffect, useState } from 'react'
import i18n from '../../helpers/i18n'
import './sisConfigurationOptionPresenter.scss'
import './sisMatchingPresenter.scss'
import { Select } from '../ui/listView/listView'
import classnames from 'classnames'
import Button from '../ui/buttons/button'
import CheckMarkIcon from '../../images/icons/checkmark.svg'
import {
  transformMapToList,
  undoPair,
  combinePair,
  selectionIsOkay,
  onSelected,
  getNumPairs,
  getPairsArray,
  isSISSchool
} from './sisHelpers.js'
import { SIS_STATE } from '../../helpers/constants'
import { getMaxPairs } from '../../helpers/sisHelpers'
import SubmitMatchesButton from './sisMatchesModal'

const matchingSchoolsLabel = i18n.t('titles.sis.matchingSchools')
const matchingExplain = i18n.t('passages.sis.matchingExplain')
const matchSchoolsLabel = i18n.t('titles.sis.matchSchools')
const sisSyncLabel = i18n.t('labels.sisSync')

// show green check and undo if item is paired
// else show checkbox with red font color
// if checkbox selected, show black font
const MatchingList = ({
  list,
  selected,
  onSelected,
  undoPair,
  allSchoolsMatched
}) => {
  return (
    <div className="list-view-container">
      {list.map((item) => {
        return (
          <div key={item.id} className="list-row">
            {item.pairData ? (
              <div className="list-cell sis-green-check">
                <CheckMarkIcon />
              </div>
            ) : (
              <Select item={item} selected={selected} onSelected={onSelected} />
            )}
            <div className="sis-list-view-item" data-spec="sis-list-view-item">
              <div key="option-name" className={classnames('option-name', '')}>
                <h3
                  className={classnames({
                    'sis-match-unselected': !selected.has(item.id),
                    'sis-match-paired': !!item.pairData,
                    'sis-all-matched': allSchoolsMatched
                  })}
                >
                  {item.name}
                </h3>
                {isSISSchool(item) && (
                  <h3 className="sis-sync">{sisSyncLabel}</h3>
                )}
              </div>
            </div>
            {item.pairData && (
              <p className="sis-undo" onClick={undoPair(item.id)}>
                Undo
              </p>
            )}
          </div>
        )
      })}
    </div>
  )
}

const SISMatchingPresenter = ({
  districtID,
  sisIntegration,
  fetchSISMatches,
  updateSISMatches,
  history
}) => {
  const { matches } = sisIntegration

  // schoolsMap will be used as the source of truth
  // rendering list will be derived from this map
  const [schoolsMap, setSchoolsMap] = useState({
    ...sisIntegration.matches.schoolsMap
  })

  useEffect(() => {
    // if user visits page without coming from /sis/integration
    // (from url), there will be no matches data, so it must be
    // fetched
    fetchSISMatches(districtID)
  }, [])

  useEffect(() => {
    // once data is fetched, matches data will be updated in redux
    // we must set then set schoolsMap to schoolsMap from redux
    if (sisIntegration.matches.schoolsMap) {
      setSchoolsMap(sisIntegration.matches.schoolsMap)
    }
  }, [sisIntegration.matches])

  useEffect(() => {
    switch (sisIntegration.state) {
      case SIS_STATE.SET_CONFIGURATION:
        // push to /sis/configurations page, occurs after matching schools
        // successfully
        history.push('./configurations')
        break
    }
  }, [sisIntegration.state])

  // for set of checks and updating checkboxes
  const [selected, setSelected] = useState(new Set())

  // return if no data present
  if (Object.keys(matches).length === 0) {
    return null
  }

  // useful when sisSchools.length !== kiddomSchools.length
  const maxPairs = getMaxPairs(matches)

  // submit manually matched schools to backend
  const submitMatchesHandler = () => {
    const pairsArray = getPairsArray(schoolsMap)
    updateSISMatches(districtID, pairsArray)
  }
  const allSchoolsMatched = getNumPairs(schoolsMap) === maxPairs
  return (
    <div className="page">
      <div className="sis-matching-header">
        <h1>{matchingSchoolsLabel}</h1>
        <p>{matchingExplain}</p>
      </div>
      <div
        className="sis-list-view
        list-view-container
        selectable-list
        sis-matching-list-container"
      >
        <MatchingList
          list={transformMapToList(schoolsMap)}
          selected={selected}
          onSelected={(id) =>
            onSelected(id, selected, setSelected, schoolsMap, setSchoolsMap)
          }
          undoPair={undoPair(schoolsMap, setSchoolsMap, selected, setSelected)}
          allSchoolsMatched={allSchoolsMatched}
        />
      </div>
      <div className="sis-button-container">
        {allSchoolsMatched ? (
          <SubmitMatchesButton submitMatchesHandler={submitMatchesHandler} />
        ) : (
          <Button
            disabled={!selectionIsOkay(selected, schoolsMap)}
            label={matchSchoolsLabel}
            buttonStyle="primary"
            onClick={combinePair(
              schoolsMap,
              setSchoolsMap,
              selected,
              setSelected
            )}
          />
        )}
      </div>
    </div>
  )
}

export default SISMatchingPresenter
