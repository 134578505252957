import React from 'react'

import i18n from '../../../helpers/i18n'
import LoadingDots from '../../common/loading/loadingDots'
import { collaboratorIsEditor } from '../../../helpers/utils'
import * as navigationHelpers from '../../../helpers/navigationHelpers'

import './collaboratorList.scss'

const CollaboratorListPresenter = (props) => {
  const { collaborators, loading, teacherRoles, curriculumID, isAdmin } = props
  const noTeachersLabel = i18n.t('passages.noInvitedTeachersDescription')
  const hasCollabs = collaborators && collaborators.size > 0

  let content = <div className="list empty">{noTeachersLabel}</div>
  if (loading) {
    content = <LoadingDots theme="dark" />
  }
  const renderCollaboratorTile = (collaborator) => {
    const id = collaborator.get('id')
    const roleKey = teacherRoles.getIn([id, 'role'])
    const isEditor = collaboratorIsEditor(roleKey)
    const editorLabel = i18n.t('labels.editor')

    return (
      <li
        onClick={() => {
          isAdmin && navigationHelpers.goToTeacherAssignments(curriculumID, id)
          props.checkAndSetUserPref(props.shouldSetUserPref)
        }}
        className="collaborator"
        key={id}
        data-spec="collaborator-row"
      >
        <img
          src={collaborator.get('avatar')}
          alt={i18n.t('imageAlt.userAvatar')}
        />
        <div className="name-role">
          <span className="name">{collaborator.get('name')}</span>
          <span className="role">{isEditor && editorLabel}</span>
        </div>
      </li>
    )
  }

  if (hasCollabs) {
    content = (
      <ul className="collaborators">
        {collaborators.map(renderCollaboratorTile)}
      </ul>
    )
  }
  return content
}

export default CollaboratorListPresenter
