import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'
import UploadIcon from '../../../images/icons/upload-icon.svg'

import './fileUpload.scss'

const defaultYourFilesLabel = i18n.t('labels.yourFilesOr')
const defaultBrowseLabel = i18n.t('userActions.browse')

export class FileUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      overWithFile: false
    }

    this.dragDropProps = {
      onDragOver: this.setHover,
      onDragEnter: this.setHover,
      onDragLeave: this.removeHover,
      onDragEnd: this.removeHover,
      onDrop: (e) => {
        this.removeHover(e)
        this.dropFiles(e)
      },
      onSubmit: (e) => {
        e.preventDefault()
        e.stopPropagation()
      },
      onChange: (e) => {
        this.selectFiles(e)
      }
    }
  }

  setHover = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setHoveringWithFile(true)
  }

  removeHover = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setHoveringWithFile(false)
  }

  setHoveringWithFile = (over) => {
    this.setState({ overWithFile: over })
  }

  selectFiles = (e) => {
    this.props.upload(e.nativeEvent.target.files)
  }

  dropFiles = (e) => {
    this.props.upload(e.nativeEvent.dataTransfer.files)
  }

  defaultContent() {
    const {
      disabled,
      browseLabel,
      flavorTextLabel,
      yourFilesLabel
    } = this.props
    let mainText = disabled
      ? i18n.t('titles.comingSoon')
      : i18n.t('titles.dragAndDrop')
    const mobileMainText = disabled
      ? i18n.t('titles.comingSoon')
      : i18n.t('userActions.clickToUploadFiles')
    const browseLink = <span className="browse-link">{browseLabel}</span>
    let subText = (
      <React.Fragment>
        <span>
          {yourFilesLabel}
          {browseLink}
        </span>
        {!!flavorTextLabel && <div>{flavorTextLabel}</div>}
      </React.Fragment>
    )

    if (this.state.overWithFile) {
      mainText = i18n.t('titles.dropYourFile')
      subText = i18n.t('titles.toBeginUpload')
    }
    return (
      <React.Fragment>
        {this.props.labelIcon}
        <div className="main-text">{mainText}</div>
        <div className="mobile-main-text">{mobileMainText}</div>
        {!disabled && <div className="sub-text">{subText}</div>}
      </React.Fragment>
    )
  }

  render() {
    const { disabled, children } = this.props

    const content = children || this.defaultContent()

    const containerClass = classnames('file-upload', this.props.className, {
      'file-hover': this.state.overWithFile,
      disabled
    })

    return (
      <form
        className={containerClass}
        data-spec="file-upload-form"
        {...this.dragDropProps}
      >
        <input
          className="file-input"
          type="file"
          name="files"
          id={`file-upload-${this.props.keyProp}`}
          data-spec="file-upload-input"
          multiple={this.props.allowMultiple}
          accept={this.props.acceptTypes}
          disabled={disabled}
        />
        <label
          htmlFor={`file-upload-${this.props.keyProp}`}
          data-spec="file-upload-label"
        >
          {content}
        </label>
      </form>
    )
  }
}

FileUpload.defaultProps = {
  yourFilesLabel: defaultYourFilesLabel,
  browseLabel: defaultBrowseLabel,
  flavorTextLabel: null,
  allowMultiple: true,
  acceptTypes: '',
  labelIcon: <UploadIcon />
}

FileUpload.propTypes = {
  upload: PropTypes.func.isRequired,
  className: PropTypes.string,
  keyProp: PropTypes.string,
  yourFilesLabel: PropTypes.string,
  browseLabel: PropTypes.string,
  flavorTextLabel: PropTypes.string,
  labelIcon: PropTypes.element.isRequired,
  allowMultiple: PropTypes.bool,
  acceptTypes: PropTypes.string
}

export default FileUpload
