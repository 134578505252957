import React from 'react'
import classnames from 'classnames'

import SelectedIcon from '../../../images/icons/checkbox-selected.svg'
import SomeSelectedIcon from '../../../images/icons/checkbox-mixed.svg'
import UnselectedIcon from '../../../images/icons/unselected.svg'

import { listHeadingsShape } from '../../../helpers/propShapes/listViewShapes'

import './selectableListHeader.scss'

export const SELECTED = {
  SOME: 'some',
  NONE: 'none',
  ALL: 'all'
}

const SelectableListHeader = (props) => {
  const { listHeadings, items, selected, setSelected } = props

  if (!listHeadings || !listHeadings.length) {
    return null
  }

  const itemIDs = items.map((item) => item.id)
  return (
    <div className="list-row headers" key="headers" data-spec="header-row">
      <SelectAll
        checkedType={getHeadingSelectedType(itemIDs, selected)}
        onChecked={() => onSelectAll(itemIDs, selected, setSelected)}
      />
      {listHeadings.map((h) => {
        const classNames = classnames('list-cell', h.className)
        return (
          <div
            className={classNames}
            key={h.label}
            data-spec={h.label}
            title={h.label}
          >
            {h.label}
          </div>
        )
      })}
    </div>
  )
}

export function SelectAll({ checkedType, onChecked }) {
  const noneClassName = classnames({ visible: checkedType === SELECTED.NONE })
  const someClassName = classnames({ visible: checkedType === SELECTED.SOME })
  const allClassName = classnames({ visible: checkedType === SELECTED.ALL })
  return (
    <button
      data-spec="select-all"
      className="list-cell select-wrapper select-all"
      onClick={onChecked}
      tabIndex="0"
    >
      <UnselectedIcon data-spec="none" className={noneClassName} />
      <SomeSelectedIcon data-spec="some" className={someClassName} />
      <SelectedIcon data-spec="all" className={allClassName} />
    </button>
  )
}

export function onSelectAll(itemIDs, selected, setSelected) {
  const headingSelected = getHeadingSelectedType(itemIDs, selected)
  const shouldSelectAll = headingSelected !== SELECTED.ALL
  let newSelected
  if (shouldSelectAll) {
    newSelected = new Set([...Array.from(selected), ...itemIDs])
  } else {
    newSelected = new Set(selected)
    itemIDs.forEach((id) => newSelected.delete(id))
  }

  setSelected(newSelected)
}

export function getHeadingSelectedType(itemIDs, selected) {
  if (itemIDs.every((id) => !selected.has(id))) {
    return SELECTED.NONE
  }
  if (itemIDs.every((id) => selected.has(id))) {
    return SELECTED.ALL
  }
  return SELECTED.SOME
}

SelectableListHeader.propTypes = {
  listHeadings: listHeadingsShape.isRequired
}

export default SelectableListHeader
