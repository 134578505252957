import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../helpers/i18n'

import { teacherItemShape } from '../../helpers/propShapes/listViewShapes'
import UserInfoHeader from '../ui/userInfoHeader/userInfoHeader'
import Loading from '../kiddom/loading/loading'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'

const TeacherOverviewPresenter = (props) => {
  const { teacher, isLoading } = props
  if (isLoading) {
    return <Loading type="academy" />
  }
  const teacherOverviewLabel = i18n.t('titles.teacherOverview')
  const classesLabel = i18n.t('labels.classes')
  const userHeaderProps = {
    name: teacher.name,
    subjects: teacher.subjects,
    avatar: teacher.avatar,
    grades: teacher.grades
  }

  return (
    <div className="teacher-overview page">
      <h1>{teacherOverviewLabel}</h1>
      <UserInfoHeader {...userHeaderProps} />
      <h2>{classesLabel}</h2>
      <div className="teacher-class-cards card-container">
        {props.classCards}
      </div>
    </div>
  )
}

TeacherOverviewPresenter.propTypes = {
  isLoading: PropTypes.bool,
  teacher: teacherItemShape,
  classCards: PropTypes.arrayOf(PropTypes.node)
}

export default withOptionalSidebar(TeacherOverviewPresenter, GuidanceTile)
