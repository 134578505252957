export const DISTRICT_PATH_PREFIX = '/district/:districtID'
export const SCHOOL_PATH_PREFIX = '/schools/:schoolID'
export const HOME_PATH = '/'
export const DISTRICT_PATH_ROOT = (districtID) => `/district/${districtID}`
export const SCHOOL_PATH_ROOT = (schoolID) => `/schools/${schoolID}`

// PAGES
export const TEACHER_ID_PATH = (schoolID, teacherID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/teachers/${teacherID}`
export const STUDENT_ID_PATH = (schoolID, studentID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/students/${studentID}`
export const CURRICULUM_PATH_ROOT = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/curriculum`
export const USER_MANAGEMENT_PATH_ROOT = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/userManagement`
export const SIS_INTEGRATION_PREFIX = (districtID) =>
  `${DISTRICT_PATH_ROOT(districtID)}/sis`
export const SIS_INTEGRATION_PATH_ROOT = (districtID) =>
  `${SIS_INTEGRATION_PREFIX(districtID)}/integration`

export const ONBOARDING = '/onboarding'
export const ONBOARDING_LOADING = '/onboarding/loading'

// TEACHER/STUDENT LIST PAGES
export const TEACHERS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/teachers`
export const STUDENTS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/students`
export const INACTIVE_STUDENTS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/students/inactive`
export const UNACTIVATED_STUDENTS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/students/unactivated`
export const INACTIVE_TEACHERS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/teachers/inactive`
export const UNACTIVATED_TEACHERS_PATH = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/teachers/unactivated`

// USER MANAGEMENT PAGES
export const USER_MANAGEMENT_ROUTE = `${SCHOOL_PATH_PREFIX}/userManagement`

// SIS INTEGRATION PAGES
export const SIS_INTEGRATION_ROUTE = `${DISTRICT_PATH_PREFIX}/sis`

// WEB_APP
export const LOGIN_URL = `${window.WEB_APP}/login`
export const CLASSES = `${window.WEB_APP}/classes`
export const CLASS_URL = (classID) =>
  `${window.WEB_APP}/class/${classID}/reports`
export const URL_WITH_GUIDANCE = (url) => `${url}?tryGuidance=true`
export const CLASS_STUDENT_URL = (classID, studentID) =>
  `${window.WEB_APP}/class/${classID}/student/${studentID}/reports`

export const CURRICULUM_COURSE_ROUTE = `${SCHOOL_PATH_PREFIX}/curriculum/:subjectGroupID/course/:courseID`
export const CURRICULUM_COURSE_PATH_ROOT = (subjectGroupID, courseID) =>
  `curriculum/${subjectGroupID}/course/${courseID}`
export const SCHOOL_CURRICULUM_COURSE_PATH_ROOT = (
  schoolID,
  subjectGroupID,
  courseID
) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/${CURRICULUM_COURSE_PATH_ROOT(
    subjectGroupID,
    courseID
  )}`

export const TEACHERS_CURRICULUM_ASSIGNMENTS = (classID, curriclumID) =>
  `${window.WEB_APP}/class/${classID}/timeline?curriculum_id=${curriclumID}`

// PAGE PATTERNS
const TEACHER_ID_PATH_PATTERN = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/teachers/:teacherID`
const STUDENT_ID_PATH_PATTERN = (schoolID) =>
  `${SCHOOL_PATH_ROOT(schoolID)}/students/:studentID`

export const DEMO_TO_REAL_SCHOOL_PATHS = (demoSchoolID, realSchoolID) => ({
  exactMatch: {
    [SCHOOL_PATH_ROOT(demoSchoolID)]: SCHOOL_PATH_ROOT(realSchoolID),
    [TEACHERS_PATH(demoSchoolID)]: SCHOOL_PATH_ROOT(realSchoolID),
    [STUDENTS_PATH(demoSchoolID)]: SCHOOL_PATH_ROOT(realSchoolID),
    [CURRICULUM_PATH_ROOT(demoSchoolID)]: CURRICULUM_PATH_ROOT(realSchoolID)
  },
  patternMatch: {
    [TEACHER_ID_PATH_PATTERN(demoSchoolID)]: SCHOOL_PATH_ROOT(realSchoolID),
    [STUDENT_ID_PATH_PATTERN(demoSchoolID)]: SCHOOL_PATH_ROOT(realSchoolID)
  }
})

export const REAL_TO_DEMO_SCHOOL_PATHS = (demoSchoolID, realSchoolID) => ({
  exactMatch: {
    [SCHOOL_PATH_ROOT(realSchoolID)]: SCHOOL_PATH_ROOT(demoSchoolID),
    [TEACHERS_PATH(realSchoolID)]: TEACHERS_PATH(demoSchoolID),
    [STUDENTS_PATH(realSchoolID)]: STUDENTS_PATH(demoSchoolID),
    [CURRICULUM_PATH_ROOT(realSchoolID)]: CURRICULUM_PATH_ROOT(demoSchoolID)
  },
  patternMatch: {}
})
