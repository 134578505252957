import React from 'react'
import { connect } from 'react-redux'
import withToJS from '../hoc/withToJS'
import SISIntegrationPresenter from './sisIntegrationPresenter'
import { withRoleCheckFor } from '../hoc/withRoleCheckFor'
import * as sisIntegrationActions from '../../store/actions/sisIntegrationActions'
import { getSISIntegration } from '../../store/fromState'

const WrappedPresenter = withToJS(SISIntegrationPresenter)
const SISIntegrationContainer = (props) => <WrappedPresenter {...props} />

const mapStateToProps = (state, ownProps) => ({
  districtID: ownProps.match.params.districtID,
  sisIntegration: getSISIntegration(state)
})

const mapDispatchToProps = (dispatch) => ({
  sendSISCredentials: (districtID, clientID, clientSecret, clientHost) =>
    dispatch(
      sisIntegrationActions.sendSISCredentials.begin(
        districtID,
        clientID,
        clientSecret,
        clientHost
      )
    ),
  fetchSISCredentials: () =>
    dispatch(sisIntegrationActions.sisCredentials.read.begin()),
  fetchSISMatches: (districtID) =>
    dispatch(sisIntegrationActions.sisSchoolMatches.read.begin(districtID)),
  syncSchools: (districtID) =>
    dispatch(
      sisIntegrationActions.sisSchoolMatches.update.begin(districtID, null)
    )
})

export default withRoleCheckFor(
  connect(mapStateToProps, mapDispatchToProps)(SISIntegrationContainer),
  [],
  true
)
