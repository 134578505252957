import { channel } from 'redux-saga'
import { put, call, take, fork } from 'redux-saga/effects'
import { errorTransform } from '../models/transformHelpers'
import { redirectToClassroom } from '../../helpers/utils'
import * as SessionClient from '../../helpers/sessionClient'

export const actionsEqual = (a, b) => {
  const aKeys = Object.keys(a)
  const bKeys = Object.keys(b)
  if (aKeys.length !== bKeys.length) {
    return false
  }
  for (let i = 0; i < aKeys.length; i++) {
    const aKey = aKeys[i]
    if (a[aKey] !== b[aKey]) {
      return false
    }
  }
  return true
}

export const actionInArray = (array, object) =>
  array.some((x) => actionsEqual(x, object))

// Used to take one request and block others to prevent multiple calls
// adapted from https://github.com/redux-saga/redux-saga/issues/830
export function* takeOneAndBlock(pattern, worker) {
  // on going requests
  const actions = []
  while (true) {
    const action = yield take(pattern)
    // same request still being processed
    if (actionInArray(actions, action)) {
      continue
    }
    actions.push(action)
    yield fork(function* () {
      yield call(worker, action)
      // remove once its been resolved
      const actionIdx = actions.splice(action)
      actions.splice(actionIdx, 1)
    })
  }
}

// resourceWatcher creates a watcher saga to watch a type of resource
// (param) resourceActions: should be and object that comes from coreActions that
//    has a fetch action.  Fetch action has the pattern this watcher will
//    listen for before kicking off saga
// (param) saga: is the saga to be run.
//
// (returns) a watcher saga
// TODO: update this to accept other types of actions once we need to
// write data
export const resourceWatcher = (resourceActions, saga) => {
  const watcher = function* () {
    yield takeOneAndBlock(resourceActions.begin, saga)
  }
  return watcher
}

// serializedResourceWatcher creates a watcher saga to watch a type of resource.
// Any action triggered across all serializedResourceWatcher instances is
// serialized through a single *global* channel. It is not typical to need this
// watcher. Please consider your use case prior to using it and look at current
// usage.
export const serializedResourceWatcher = (resourceActions, method) => {
  const watcher = function* () {
    const chan = yield call(channel)
    yield fork(handleSerializedRequest, chan, method)
    while (true) {
      const action = yield take(resourceActions.begin)
      yield put(chan, action)
    }
  }
  return watcher
}

function* handleSerializedRequest(chan, method) {
  while (true) {
    const action = yield take(chan)
    yield call(method, action)
  }
}

// fetchResource is a saga that will fetch a given resource.  It will emit
// an initialize action, and a success/failure action depending on the results
// of the fetch
// (param) resourceAction: should be and object that comes from coreActions that
//    has a fetch, initialize, success, and error.
// (param) getter: API call to be called when this saga is running.
// (param) transformer: transforming function that should come from
//    transform.js to transform the response from getter
export function* fetchResource(resourceActions, getter, transformer) {
  const engineOptions = {
    transformer,
    needToFetch: true
  }
  yield fetchResourceEngine(resourceActions, getter, engineOptions)
}

const handleUnauthorized = () =>
  SessionClient.remove().then(redirectToClassroom)

// sagaErrorHandling will parse json from the error and put the rollbar uuid
// into the store
// (params) responseStructure
// (params) errorActionCreator ex: actions.classes.read.error
// (params) errorArgs, arguments to errorActionCreator
// (params) errorMessagePrefix
export function* sagaErrorHandling(
  responseStructure,
  errorActionCreator,
  errorActionArgs,
  errorMessagePrefix = 'Error Fetching API Resource:'
) {
  if (!responseStructure.error) {
    return false
  }
  const error = responseStructure.error
  try {
    error.jsonBody = yield call([error, 'json'])
  } catch (ignored) {
    // The body is not JSON, so json-parsing it failed.
  }
  const rollbarUUID =
    window.Rollbar && window.Rollbar.error(errorMessagePrefix, error)
  if (error.status === 401) {
    return handleUnauthorized()
  }
  yield put(
    errorActionCreator(
      ...(errorActionArgs || []),
      errorTransform(error, rollbarUUID)
    )
  )
  return true
}

// fetchResourceEngine is a saga that will fetch a given resource.  It will emit
// an initialize action, and a success/failure action depending on the results
// of the fetch
// (param) resourceAction: should be and object that comes from coreActions that
//    has a fetch, initialize, success, and error.
// (param) getter: API call to be called when this saga is running.
// (param) options: object with the following options
//    (param, optional) needToFetch: boolean determining if we need to fetch
//      this resourse
//    (param, optional) beginArgs: list of args that is used to customize
//      the arguments that are then sent to the initialize function of the
//      resourceAction argument.
//    (param, optional) successArgs: list of args with transformed data
//      appended to it. it is used to customize the arguments that are
//      sent to the success function of the resourceAction argument
//    (param, optional) errorArgs: list of args with transformed data
//      appended to it. it is used to customize the arguments that are
//      sent to the error function of the resourceAction argument
export function* fetchResourceEngine(resourceActions, getter, options) {
  const { needToFetch, transformer, initializeArgs, successArgs } = options
  if (!needToFetch) {
    return
  }

  const putArgs = resourceActions.initialize(...(initializeArgs || []))
  yield put(putArgs)
  const responseStructure = yield call(getter)
  const isError = yield call(
    sagaErrorHandling,
    responseStructure,
    resourceActions.error,
    options.errorArgs
  )
  if (isError) {
    return
  }
  const response = responseStructure.response
  const transformed = transformer ? transformer(response) : response
  const args = successArgs || []
  args.push(transformed)
  yield put(resourceActions.success(...args))
}

const helperSagas = {
  fetchResourceEngine,
  resourceWatcher,
  serializedResourceWatcher
}

export default helperSagas
