import Immutable, { List } from 'immutable'
import * as resources from '../models/resources'
import * as fromState from '../fromState'

export function getSchoolPreferredSkillGroups(state) {
  return state
    .getIn([resources.SKILL_GROUPS, 'byID'])
    .filter((skillGroup) => skillGroup.get('source') === 'school')
}

// getInitialSkillGroup
// input: state(Immutable.Map), subjects(Immutable.List)
// output: skill group ID as string from the first skillsGroup that
// contains one of the specified subjectIDs
export function getInitialSkillGroup(state, subjects) {
  const schoolSkillGroups = getSchoolPreferredSkillGroups(state)
  const firstSkillGroupInSubject =
    schoolSkillGroups &&
    schoolSkillGroups.size > 0 &&
    schoolSkillGroups.valueSeq().find((skillGroup) => {
      let skillInGroup = false
      if (skillGroup.get('subjectIDs')) {
        skillGroup.get('subjectIDs').forEach((subjectID) => {
          if (subjects) {
            subjects.forEach((initialSubjectID) => {
              if (subjectID === initialSubjectID.toString()) {
                skillInGroup = true
              }
            })
          }
        })
      }
      return skillInGroup
    })
  // return the first one that matches subjects provided, or
  // just the first one int he list if no subjects provided.
  return (
    (firstSkillGroupInSubject && firstSkillGroupInSubject.get('id')) ||
    schoolSkillGroups.valueSeq().getIn([0, 'id'])
  )
}

// getSkillsInGroup
// input: state(Map), skillGroupID(string)
// output: Map of skillsByID listed under a provided skillGroup
export function getSkillsInGroup(state, skillGroupID) {
  // get skillIDs from skill group
  const skillsInGroup = state.getIn([
    resources.SKILL_GROUPS,
    'byID',
    skillGroupID,
    'skillsIDs'
  ])
  // reduce skill IDs to skills Map mapped by id
  const skills =
    skillsInGroup &&
    skillsInGroup.reduce(
      (result, skillID) =>
        result.set(skillID, state.getIn([resources.SKILLS, 'byID', skillID])),
      Immutable.Map()
    )
  return skills
}

// filterSkillsByGradeSubject
// input: skills(List), gradeIDs(List), subjectIDs(List)
// output: new List that is skills filtering out skills that don't contain
// one of the provided subjects or grades.
export function filterSkillsByGradeSubject(skills, gradeIDs, subjectIDs) {
  // filter out skills that don't match grade/subject
  return (
    skills &&
    skills.filter((skill) => {
      const grade = skill.get('gradeID')
      const subject = skill.get('subjectID')
      const inGrade =
        gradeIDs && gradeIDs.size ? gradeIDs.includes(grade) : true
      const inSubject = subjectIDs.includes(subject)
      const inFilter = inGrade && inSubject
      return inFilter
    })
  )
}

// getFilteredSkillGroups
// input: state(Map), unitID
// output: skills in tree structure as needed for rendering using
// buildSkillGroupTree with a filtered list of skills in the skill group.
// returns list of server returns standards based on query
export function getFilteredSkillGroups(state, unitID, searchQuery) {
  const filteredIDs =
    state.getIn([resources.SKILLS, 'filter', unitID, searchQuery, 'IDs']) ||
    new List()
  return filteredIDs.reduce(
    (result, skillID) =>
      result.set(skillID, state.getIn([resources.SKILLS, 'byID', skillID])),
    Immutable.Map()
  )
}

export function skillGroupLoaded(state, subjectGroupID, schoolID) {
  const subjectGroupsLoaded = fromState.subjectGroupsLoadedForSchool(
    state,
    schoolID
  )
  if (subjectGroupsLoaded) {
    const subjectGroup = fromState.getResourceByID(
      state,
      resources.SCHOOL_SUBJECT_GROUPS,
      subjectGroupID
    )
    if (subjectGroup) {
      const subjectIDs = subjectGroup.get('subjectIDs')
      return getInitialSkillGroup(state, subjectIDs)
    }
  }
  return false
}

export function getSkillsByIDs(state, skillIDs) {
  let skills = new Immutable.List()
  skillIDs.forEach((skillID) => {
    let skill = state.getIn([
      resources.SKILLS,
      'byCorrelatedOriginalID',
      skillID
    ])

    if (!skill) {
      skill = state.getIn([resources.SKILLS, 'byID', skillID])
    }

    if (skill) {
      skills = skills.push(skill)
    }
  })
  return skills
}

export const getFilterLoadMoreStatus = (state, unitID, searchQuery) =>
  state.getIn([
    resources.SKILLS,
    'filter',
    unitID,
    searchQuery,
    'enableLoadMore'
  ])
