import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import withToJS from '../hoc/withToJS'
import withDataLoading from '../hoc/withDataLoading'
import * as resources from '../../store/models/resources'
import * as fromState from '../../store/fromState'
import * as coreActions from '../../store/actions/coreActions'
import * as userPreferences from '../../store/userPreferences'
import {
  CURRICULUM_PATH_SELECTED,
  DASHBOARD_PATH_SELECTED
} from '../../store/eventIDs'

import OnboardingLandingPresenter from './onboardingLandingPresenter'

const WrappedPresenter = withToJS(OnboardingLandingPresenter)

export const OnboardingLandingContainer = (props) => {
  if (!props.hasLoaded) {
    return null
  }

  return <WrappedPresenter {...props} />
}

export const mapStateToProps = (state) => {
  return {
    bothSchoolsLoaded: fromState.loadedBothTrialSchools(state),
    ...fromState.getFreeTrialSchoolIDs(state)
  }
}

export const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    trackCurriculumSelected: () => {
      dispatch(coreActions.trackEvent.create.begin(CURRICULUM_PATH_SELECTED))
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: userPreferences.FREE_TRIAL_PATH_CHOICE,
              value: userPreferences.CURRICULUM_PATH
            }
          ],
          true
        )
      )
    },
    trackDashboardSelected: () => {
      dispatch(coreActions.trackEvent.create.begin(DASHBOARD_PATH_SELECTED))
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: userPreferences.FREE_TRIAL_PATH_CHOICE,
              value: userPreferences.DASHBOARD_PATH
            }
          ],
          true
        )
      )
    },
    fetchSchools: (force) => {
      dispatch(coreActions.schools.read.begin(force))
    }
  }
}

const generateDataLoaders = () => [coreActions.schools.read.begin()]

const generateHasLoadedCheckers = () => ({
  hasLoaded: [[fromState.resourcesLoaded, [[resources.SCHOOLS]]]]
})

export default withDataLoading(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(OnboardingLandingContainer)
  ),
  generateDataLoaders,
  generateHasLoadedCheckers
)
