import React, { useState, useEffect } from 'react'
import i18n from '../../helpers/i18n'
import './sisIntegrationPresenter.scss'
import TextInput from '../common/inputs/textInput'
import Button from '../ui/buttons/button'
import { SIS_STATE } from '../../helpers/constants'

const sisDashboardLabel = i18n.t('titles.sis.integrationSetup')
const oneRosterLabel = i18n.t('labels.oneRoster')
const sisExplain = i18n.t('passages.sis.integrationExplain')
const clientIDLabel = i18n.t('labels.clientID')
const clientSecretLabel = i18n.t('labels.clientSecret')
const clientHostLabel = i18n.t('labels.host')
const submitLabel = i18n.t('labels.submit')
const mustBeDistrictAdminToUseSIS = i18n.t('errors.mustBeDistrictAdminToUseSIS')
const invalidCredentials = i18n.t('errors.invalidCredentials')

function SISForm({ sisIntegration, sendSISCredentials, districtID }) {
  const [clientID, setClientID] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [clientHost, setClientHost] = useState('')

  const onSubmit = async (e) => {
    e.preventDefault()
    sendSISCredentials(districtID, clientID, clientSecret, clientHost)
  }

  const { errorCode } = sisIntegration.error.sendCredentials

  return (
    <div className="sis-form-container">
      <form className="sis-form" onSubmit={onSubmit}>
        <label>{oneRosterLabel}</label>
        <TextInput
          underline
          secondary
          className="sis-input"
          value={clientID}
          label={clientIDLabel}
          onChange={(e) => setClientID(e.target.value)}
        />
        <TextInput
          underline
          secondary
          className="sis-input"
          value={clientSecret}
          label={clientSecretLabel}
          onChange={(e) => setClientSecret(e.target.value)}
        />
        <TextInput
          underline
          secondary
          disableErrorLine
          className="sis-input"
          value={clientHost}
          label={clientHostLabel}
          onChange={(e) => setClientHost(e.target.value)}
          error={errorCode && invalidCredentials}
        />
        <Button label={submitLabel} className="sis-button" />
      </form>
    </div>
  )
}

function SISIntegrationPresenter({
  districtID,
  isDistrictAdmin,
  sendSISCredentials,
  sisIntegration,
  fetchSISCredentials,
  fetchSISMatches,
  syncSchools,
  history
}) {
  useEffect(() => {
    switch (sisIntegration.state) {
      case SIS_STATE.FETCH_CREDENTIALS:
        // when first visit page, fetch credentials
        // if in MATCH_SCHOOLS stage push to /sis/matching
        // else if in SET_CONFIGURATION push to /sis/configurations
        // else stay on the same page
        fetchSISCredentials()
        break
      case SIS_STATE.FETCH_SCHOOL_MATCHES:
        // occurs after submitting form or revisiting page after
        // submitting credentials. fetches match api
        fetchSISMatches(districtID)
        break
      case SIS_STATE.MATCH_SCHOOLS:
        // push to /sis/matching page, occurs after fetching matches
        // successfully
        history.push('./matching')
        break
      case SIS_STATE.SYNC_SCHOOLS:
        // call match
        syncSchools(districtID)
        break
      case SIS_STATE.SET_CONFIGURATION:
        // push to /sis/configurations page, occurs after  matching schools
        // successfully or no matching is required
        history.push('./configurations')
        break
    }
  }, [sisIntegration.state])

  if (
    isDistrictAdmin === undefined ||
    sisIntegration.state !== SIS_STATE.SEND_CREDENTIALS
  ) {
    // if data is still fetching, return null here.
    return null
  }

  if (isDistrictAdmin) {
    return (
      <div className="page">
        <div className="sis-header">
          <h1>{sisDashboardLabel}</h1>
          <p>{sisExplain}</p>
          {sisIntegration.state === SIS_STATE.SEND_CREDENTIALS && (
            <SISForm
              sisIntegration={sisIntegration}
              sendSISCredentials={sendSISCredentials}
              districtID={districtID}
            />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <div className="page">
        <p>{mustBeDistrictAdminToUseSIS}</p>
      </div>
    )
  }
}

export default SISIntegrationPresenter
