import React from 'react'
import PropTypes from 'prop-types'

import './inviteTeachers.scss'
import i18n from '../../../helpers/i18n'
import { courseShape } from '../../../helpers/propShapes/courseShape'
import { personShape } from '../../../helpers/propShapes/common'

import CloseIcon from '../../../images/icons/close.svg'

import withSearch from '../../hoc/withSearch'
import Button from '../../ui/buttons/button'

import CollaboratorAccessSwitcher from './collaboratorAccessSwitcher'

export class InviteTeachersPresenter extends React.Component {
  renderButton(teacher) {
    const { course, onShareWithTeacher, onUnshareWithTeacher } = this.props

    const userID = teacher.id

    if (course.sharedWithByUserID && course.sharedWithByUserID[userID]) {
      return (
        <button
          className="uninvite-teacher-button"
          title={i18n.t('userActions.uninviteTeacher')}
          onClick={() => onUnshareWithTeacher(userID)}
        >
          <CloseIcon />
        </button>
      )
    }

    return (
      <Button
        buttonStyle="secondary"
        label={i18n.t('labels.invite')}
        title={i18n.t('userActions.inviteTeacher')}
        onClick={() => onShareWithTeacher(userID)}
      />
    )
  }

  renderTeacherRow = (t) => {
    const { course, onShareWithTeacher } = this.props
    const teacherID = t.id
    return (
      <li className="teacher" key={teacherID} data-spec="teacher-row">
        <img src={t.avatar} alt={i18n.t('imageAlt.userAvatar')} />
        <span className="name">{t.name}</span>
        <div className="actions">
          {course.sharedWithByUserID &&
            course.sharedWithByUserID[teacherID] && (
              <CollaboratorAccessSwitcher
                teacher={t}
                teacherRole={course.sharedWithByUserID[teacherID]}
                setCollaboratorRole={onShareWithTeacher}
              />
            )}
          {this.renderButton(t)}
        </div>
      </li>
    )
  }

  render() {
    return <div className="invite-teachers">{this.renderBody()}</div>
  }

  renderBody() {
    const { items } = this.props
    if (items.length) {
      return (
        <div>
          <h3>{i18n.t('titles.teachers')}</h3>
          <ul className="teachers">{items.map(this.renderTeacherRow)}</ul>
        </div>
      )
    }

    return (
      <div className="no-results">
        <h4>{i18n.t('labels.noTeachersFound')}</h4>
        <p>{i18n.t('labels.noTeachersFoundExplanation')}</p>
      </div>
    )
  }
}

InviteTeachersPresenter.propTypes = {
  course: courseShape.isRequired,
  items: PropTypes.arrayOf(personShape).isRequired,
  onShareWithTeacher: PropTypes.func.isRequired,
  onUnshareWithTeacher: PropTypes.func.isRequired
}

const WrappedPresenter = withSearch(InviteTeachersPresenter)

export default function InviteTeachersPresenterWithWrappedPresenter(props) {
  const { teachers, ...others } = props
  return (
    <div className="invite-teachers-presenter">
      <WrappedPresenter
        items={teachers}
        searchOn={['name']}
        placeholder={i18n.t('labels.searchTeachers')}
        {...others}
      />
    </div>
  )
}
