import React, { PureComponent } from 'react'

export default class Icon extends PureComponent {
  render() {
    const { className, glyph, title, ...restProps } = this.props

    return (
      <svg className={className} {...restProps}>
        {title && <title>{title}</title>}
        <use xlinkHref={`#${glyph}`} />
      </svg>
    )
  }
}

Icon.defaultProps = {
  glyph: '',
  className: 'icon'
}
