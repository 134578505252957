import { backOff } from 'exponential-backoff'

export const sendRequest = (
  method,
  url,
  body = null,
  headers = {},
  options = {},
  buildRequestBody = (body) => JSON.stringify(body)
) => {
  const returnStructure = {
    response: null,
    error: null
  }

  const retryCodes = [408, 500, 502, 503, 504, 522, 524]
  const backoffOptions = {
    delayFirstAttempt: false,
    numOfAttempts: 20,
    startingDelay: 200,
    jitter: 'full',
    maxDelay: 180000,
    retry: function (error, _attemptNumber) {
      // Indicates a failure to fetch
      // if error object does not have the key: status, then fetch has
      // failed, so we need to retry
      if (!error.status) {
        return true
      }

      // Only retry on certain status codes
      return retryCodes.includes(error.status)
    }
  }

  function doRequest() {
    const request = new Request(url, {
      method,
      body: body && buildRequestBody(body),
      headers,
      ...options
    })

    return fetch(request)
  }

  return backOff(
    () =>
      doRequest()
        .then((response) => {
          if (!response.ok) {
            throw response
          }
          return response.status === 204 ? Promise.resolve({}) : response.json()
        })
        .then((data) => {
          returnStructure.response = data
          return returnStructure
        }),
    backoffOptions
  ).catch((error) => {
    returnStructure.error = error
    return returnStructure
  })
}

export const authSendRequest = (
  method,
  url,
  body = null,
  headers = {},
  options = {},
  buildRequestBody
) => {
  return sendRequest(
    method,
    url,
    body,
    headers,
    {
      ...options,
      credentials: 'include'
    },
    buildRequestBody
  )
}

export default {
  sendRequest,
  authSendRequest
}
