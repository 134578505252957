import React, { useState } from 'react'
import classnames from 'classnames'
// Kiddom Components
import ListView from '../ui/listView/listView'
import ArrowUp from '../../images/icons/arrow-up.svg'
import { SelectAll, SELECTED } from '../ui/listView/selectableListHeader'

import './sisConfigurationOptionPresenter.scss'

function getSelectAllCheckedType(selectedSize, optionSize) {
  if (selectedSize === 0) {
    return SELECTED.NONE
  }

  return selectedSize < optionSize ? SELECTED.SOME : SELECTED.ALL
}

function SISConfigurationOptionPresenter({
  options,
  title,
  selected,
  setSelected
}) {
  const [visible, setVisible] = useState(false)

  const toggleList = () => {
    setVisible(!visible)
  }

  const renderRow = (option) => {
    return (
      <div className="sis-list-view-item" data-spec="sis-list-view-item">
        <div key="option-name" className={classnames('option-name', '')}>
          <h3>{option.name}</h3>
        </div>
      </div>
    )
  }

  const onSelected = (id, selected, setSelected) => {
    const newSelected = new Set(selected)
    if (selected.has(id)) {
      newSelected.delete(id)
    } else {
      newSelected.add(id)
    }

    setSelected(newSelected)
  }

  const onSelectedAll = () => {
    const newSelected = new Set()
    if (selected.size < options.length) {
      // add all options for selected none or partial. Otherwise, reset to empty.
      options.forEach((o) => newSelected.add(o.id))
    }
    setSelected(newSelected)
  }

  return (
    <div className="sis-list-card subject-group-card">
      <header className="subject-group-card-header" title={title}>
        <div className="option-cell list-cell">
          <SelectAll
            onChecked={onSelectedAll}
            checkedType={getSelectAllCheckedType(selected.size, options.length)}
          />
          <h3 className="option-label">{title}</h3>
        </div>

        <div className="arrow-icon-container">
          <ArrowUp
            className={classnames('arrow-icon', {
              flipped: !visible
            })}
            onClick={toggleList}
            data-spec="arrow"
          />
        </div>
      </header>
      <div className="sis-list-view list-view-container selectable-list">
        <div
          className={classnames('course-list', {
            visible: visible
          })}
          data-spec="course-list"
        >
          <ListView
            items={options}
            renderRow={renderRow}
            withSelect
            selected={selected}
            onSelected={(id) => onSelected(id, selected, setSelected)}
          />
        </div>
      </div>
    </div>
  )
}

export default SISConfigurationOptionPresenter
