import React from 'react'
import i18n from '../../../helpers/i18n'
import PropTypes from 'prop-types'

import OptionsIcon from '../../../images/icons/options.svg'
import Popup from '../popup/popup'
import MenuItem from '../menu/menuItem'
import './optionMenu.scss'

const OptionMenu = ({ options }) => {
  if (!options || options.length === 0) {
    return null
  }
  const trigger = (
    <OptionsIcon className="option-icon" title={i18n.t('labels.options')} />
  )
  return (
    <div className="option-menu">
      <Popup
        position="bottom"
        alignment="end"
        isActionSheet
        closeOnClick
        triggerElement={trigger}
      >
        {options.map((option, index) => (
          <MenuItem key={index} {...option} />
        ))}
      </Popup>
    </div>
  )
}

OptionMenu.defaultProps = {
  options: []
}
OptionMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      ...MenuItem.propTypes
    })
  )
}

export default OptionMenu
