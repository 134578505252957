import React from 'react'
import { connect } from 'react-redux'
import { matchPath, withRouter } from 'react-router-dom'

import withToJS from '../hoc/withToJS'
import withDataLoading from '../hoc/withDataLoading'
import * as fromState from '../../store/fromState'
import * as coreActions from '../../store/actions/coreActions'
import * as curriculumActions from '../../store/actions/curriculumActions'
import {
  CURRICULUM_COURSE_ROUTE,
  CURRICULUM_PATH_ROOT,
  DEMO_TO_REAL_SCHOOL_PATHS,
  REAL_TO_DEMO_SCHOOL_PATHS,
  SCHOOL_PATH_ROOT,
  SCHOOL_CURRICULUM_COURSE_PATH_ROOT
} from '../../routes/routes'

import FreeTrialSchoolTogglePresenter from './freeTrialSchoolTogglePresenter'

const WrappedPresenter = withToJS(FreeTrialSchoolTogglePresenter)

export const FreeTrialSchoolToggleContainer = (props) => {
  if (!props.hasLoaded) {
    return null
  }

  const presenterProps = {
    onClickDemoSchool: demoSchoolClickHandler(props),
    onClickRealSchool: realSchoolClickHandler(props),
    ...props
  }

  return <WrappedPresenter {...presenterProps} />
}

const getRealSchoolPath = (props) =>
  getTargetPath(props, props.realSchoolID, DEMO_TO_REAL_SCHOOL_PATHS)

const getDemoSchoolPath = (props) =>
  getTargetPath(props, props.demoSchoolID, REAL_TO_DEMO_SCHOOL_PATHS)

const getSchoolClickHandler = (props, getRoute) => {
  if (!props) {
    return () => {}
  }
  return () => props.history.push(getRoute(props))
}

function demoSchoolClickHandler(props) {
  return getSchoolClickHandler(props, getDemoSchoolPath)
}

function realSchoolClickHandler(props) {
  return getSchoolClickHandler(props, getRealSchoolPath)
}

export function getTargetPath(props, toSchoolID, pathsMap) {
  if (!props) {
    return () => {}
  }

  const path = props.location.pathname
  const { demoSchoolID, realSchoolID } = props
  const allPathsMap = pathsMap(demoSchoolID, realSchoolID)
  const exactRoutes = allPathsMap.exactMatch
  let targetPath = exactRoutes[path]

  // if an exact match wasn't found, try pattern matches
  if (!targetPath) {
    const patternRoutes = allPathsMap.patternMatch
    Object.keys(patternRoutes).forEach((pathPattern) => {
      const match = matchPath(path, { path: pathPattern })
      if (match) {
        targetPath = patternRoutes[pathPattern]
      }
    })
  }

  // if no pattern matches were found, we might be looking at a course
  if (!targetPath) {
    const courseRouteMatch = matchPath(path, CURRICULUM_COURSE_ROUTE)
    if (courseRouteMatch) {
      targetPath = props.coursePaths[toSchoolID]
      if (!targetPath) {
        targetPath = exactRoutes[CURRICULUM_PATH_ROOT(toSchoolID)]
      }
    }
  }

  if (!targetPath) {
    // fallback if no matches found
    targetPath = SCHOOL_PATH_ROOT(toSchoolID)
  }

  return targetPath
}

const getFirstCoursePath = (state, schoolID) => {
  const firstSubjectGroupID = fromState
    .getSubjectGroupsForSchool(state, schoolID)
    .getIn([0, 'id'])
  const courses = fromState.getCoursesFromSubjectGroup(
    state,
    firstSubjectGroupID
  )
  let courseRedirect = CURRICULUM_PATH_ROOT(schoolID)
  if (courses.size) {
    courseRedirect = SCHOOL_CURRICULUM_COURSE_PATH_ROOT(
      schoolID,
      firstSubjectGroupID,
      courses.getIn([0, 'id'])
    )
  }
  return courseRedirect
}

export const mapStateToProps = (state, _ownProps) => {
  const freeTrialSchools = fromState.getFreeTrialSchoolIDs(state)
  const coursePaths = {}
  Object.values(freeTrialSchools).forEach((schoolID) => {
    coursePaths[schoolID] = getFirstCoursePath(state, schoolID)
  })
  return {
    coursePaths,
    ...freeTrialSchools
  }
}

const generateDataLoaders = () => [
  coreActions.schools.read.begin(),
  curriculumActions.fetchTrialSchoolSubjects()
]

const isFreeTrialSchoolsLoaded = (state) => {
  const freeTrialSchools = fromState.getFreeTrialSchoolIDs(state)
  return freeTrialSchools.realSchoolID && freeTrialSchools.demoSchoolID
}

const generateHasLoadedCheckers = () => ({
  hasLoaded: [[isFreeTrialSchoolsLoaded, []]]
})

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, null)(FreeTrialSchoolToggleContainer),
    generateDataLoaders,
    generateHasLoadedCheckers
  )
)
