import React from 'react'
import PropTypes from 'prop-types'

import { teacherItemShape } from '../../helpers/propShapes/listViewShapes'
import i18n from '../../helpers/i18n'
import CardCarousel from '../common/cards/cardCarousel'
import TeacherListView from './teacherListView'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'
import withSearch from '../hoc/withSearch'

import './teachersOverview.scss'

const ListViewWithSearch = withSearch(TeacherListView)

const TeachersOverviewPresenter = (props) => {
  const { cards } = props
  const teacherDashboardLabel = i18n.t('titles.teachersDashboard')
  const topPerformingClassesLabel = i18n.t('titles.topPerformingClasses')
  const allTeachersLabel = i18n.t('titles.allTeachers')
  return (
    <div className="teachers-overview page">
      <div className="page-title-bar">
        <h2>{teacherDashboardLabel}</h2>
      </div>
      <h3>{topPerformingClassesLabel}</h3>
      <CardCarousel cards={cards} />
      <h3 className="teacher-label">{allTeachersLabel}</h3>
      <ListViewWithSearch
        items={props.teachers}
        schoolID={props.schoolID}
        searchOn={['name']}
        trackSearch={props.trackSearch}
        onClickRow={props.trackSingleTeacherViewed}
      />
    </div>
  )
}

TeachersOverviewPresenter.propTypes = {
  teachers: PropTypes.arrayOf(teacherItemShape),
  schoolID: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.node),

  // actions
  trackSearch: PropTypes.func.isRequired,
  trackSingleTeacherViewed: PropTypes.func.isRequired
}

export default withOptionalSidebar(TeachersOverviewPresenter, GuidanceTile)
