// placeholder for actual tranlations
// When we use translations for real, this JSON should be stored on CDN/Service - webtranslateit.com?
const defaultTranslationsObject = {
  'en-US': {
    translation: {
      labels: {
        add: 'Add',
        added: 'Added',
        new: 'New',
        edit: 'Edit',
        viewOnly: 'View only',
        courses: 'courses',
        course: 'course',
        noSubjects: 'No Subjects',
        noTeachersFound: 'No teachers found.',
        noTeachersFoundExplanation:
          'Check to make sure your search is correct or change your filters to find teachers.',
        addSubject: 'Add Subject',
        newSubject: 'New Subject',
        newSubjectInfo: 'New Subject Info',
        poweredByKiddom: 'Powered By Kiddom',
        principalColon: 'Principal: {{principalName}}',
        numTeachers: '{{count}} Teacher',
        numTeachers_plural: '{{count}} Teachers',
        numStudents: '{{count}} Student',
        numStudents_plural: '{{count}} Students',
        percentAreEngaged: '{{activeTeachers}}% are engaged',
        percentAchievedMastery: '{{masteryLevelPercent}}% achieved mastery',
        percentAtGradeOrAbove: '{{gradeLevelPercent}}% at grade level or above',
        percentAtLabelOrHigher: '{{percent}}% at {{label}} or higher',
        last30Days: 'Last 30 days',
        thisMonth: 'This Month',
        last7Days: 'Last 7 Days',
        thisWeek: 'This Week',
        last12Months: 'Last 12 Months',
        year: 'Year',
        term: 'Term',
        month: 'Month',
        week: 'Week',
        name: 'Name',
        nameSubject: 'Name / Subject',
        numAccountsSelected: '{{count}} account selected:',
        numAccountsSelected_plural: '{{count}} accounts selected:',
        classes: 'Classes',
        grade: 'Grade',
        allGradeLevels: 'All Grade Levels',
        kindergarten: 'Kindergarten',
        gradeLevelFilter: 'Grade Level Filter',
        grades: 'Grades',
        students: 'Students',
        studentMastery: 'Student Mastery',
        assignments: 'Assignments',
        assigned: 'Assigned',
        scored: 'Scored',
        search: 'Search',
        signIn: 'Sign In',
        missing: 'Missing',
        due: 'Due',
        submit: 'Submit',
        submitted: 'Submitted',
        recentGrowth: 'Recent Growth',
        requestQuote: 'Request quote',
        mastery: 'Mastery',
        studentsAtAboveMastery: 'Students at or above mastery',
        studentsAtAboveGradeLevel: 'Students at or above grade level',
        teachersBeenActive: 'Teachers have been active in the last 2 weeks',
        teachersHaveActivated: 'Teachers have activated their accounts',
        studentsBeenActive: 'Students have been active in the last 2 weeks',
        studentsHaveActivated: 'Students have activated their accounts',
        usage: 'Usage',
        addAssignment: 'Add Assignment',
        scoreAssignment: 'Score Assignment',
        noTeachersInThisCategory: 'There are no Teachers in this category.',
        noStudentsInThisCategory: 'There are no Students in this category.',
        addCourse: 'Add Course',
        addCoursePlaceholder: 'New Course Title',
        cancel: 'Cancel',
        save: 'Save',
        gradeLevelText: 'Grade Level (optional)',
        days: '{{count}} day',
        days_plural: '{{count}} days',
        daysPlanned: '{{count}} day planned',
        daysPlanned_plural: '{{count}} days planned',
        minutes: '{{count}} minute',
        minutes_plural: '{{count}} minutes',
        unit: 'Unit',
        unitTitle: 'Unit title',
        addSkills: 'Add Skills',
        alignSkill: 'Align to Skill',
        addToUnit: 'Add To Unit',
        unitMoveUp: 'Move Unit Up',
        unitMoveDown: 'Move Unit Down',
        close: 'Close',
        options: 'Options',
        retryNow: 'Retry now',
        howManyDays: 'How many days should this unit take to teach?',
        skillsGradeLevelSelect:
          'Select the grade level(s) of skills you want to cover in this course.',
        skillsElementary: 'Elementary School Skills',
        skillsMiddle: 'Middle School Skills',
        skillsHigh: 'High School Skills',
        goToSkills: 'Go to Skills',
        invite: 'Invite',
        loadMore: 'Load More',
        searchSkills: 'Search Skills',
        searchTeachers: 'Search teachers',
        editor: 'editor',
        customize: 'Customize',
        customizeDirectory: 'Customize Directory',
        selected: 'Selected',
        expand: 'Expand',
        collapse: 'Collapse',
        aCSVFileOr: 'a csv file or ',
        yourFilesOr: 'your files here, or ',
        yesterday: 'yesterday',
        unitsCoveredIn: 'Covered in',
        filterSkillGroup: 'Skill Group Filter',
        editCourse: 'Edit Course',
        undo: 'Undo',
        undoDeleteCourse: 'Undo course deletion',
        next: 'Next',
        optimizeYourTrial: 'Optimize your free trial',
        exploreYourDemoSchool: 'Explore your demo school',
        viewing: 'Viewing:',
        demoSchool: 'Demo School',
        yourSchool: 'Your School',
        noResultsFound: 'No results found',
        typeToSearch: 'Type to Search',
        buildingYourSchool: 'Building your school...',
        creatingYourAccount: 'Creating your account',
        addingDemoTeachers: 'Adding demo teachers and students',
        addingAssignments: 'Adding classes and assignments',
        addingFinishingTouches: 'Adding finishing touches',
        schoolNotFound: "I don't see my school",
        schoolName: 'School Name',
        streetAddress: 'Street Address',
        city: 'City',
        state: 'State',
        country: 'Country',
        done: 'Done',
        kiddomAcademy: 'Kiddom Academy',
        kiddomClassroom: 'Kiddom Classroom',
        aboutKiddom: 'About Kiddom',
        active: 'Active',
        inactive: 'Inactive',
        unactivated: 'Unactivated',
        accountsCreatedSuccess: 'Successfully created {{count}} new account.',
        accountsCreatedSuccess_plural:
          'Successfully created {{count}} new accounts.',
        inviteEmailSent: 'Invite email sent',
        inviteEmailSent_plural: '{{count}} invite emails sent',
        email: 'Email',
        username: 'Username',
        role: 'Role',
        status: 'Status',
        withPeriod: '{{message}}.',
        note: 'Note: ',
        error: 'Error',
        viewAll: 'View all',
        viewLess: 'View less',
        selectedStaffMembers: '{{count}} selected staff member',
        selectedStaffMembers_plural: '{{count}} selected staff members',
        uploadFailed: 'Upload failed!',
        uploadingProgress: `Uploading... {{percent}}%`,
        imageIsUploading: 'An image is uploading...',
        bundledCurriculumIsBestView: `Bundled Curriculum is best viewed in the Classroom App.`,
        clickHere: 'Click here ',
        toViewFullCurriculum: 'to view full curriculum.',
        oneRoster: 'OneRoster Credentials',
        clientID: 'Client ID',
        clientSecret: 'Client Secret',
        host: 'Host',
        scheduledSync: 'Scheduled Sync',
        setSISConfiguration: 'Set SIS Configurations',
        setSISConfigurationSuccess: 'Saved Successfully.',
        confirm: 'Confirm',
        sisSync: '(SIS sync)',
        goToKiddomClassroom: 'Go to Kiddom Classroom'
      },
      errors: {
        fileSizeError: 'File Size should not exceed {{maxFileSize}} MB',
        duplicateCourseError: 'Please provide a unique course title',
        defaultError: 'An error occurred, please try again',
        textTooLong:
          '{{textField}} cannot be longer than {{maxLength}} characters.',
        integerIncrementNumbersOnly: 'Use numbers only',
        integerIncrementMin: 'Min {{value}}',
        integerIncrementMax: 'Max {{value}}',
        unableToSaveChanges: 'Unable to save changes.',
        unableToSaveChangesWithRetry:
          'Unable to save changes. Retry in {{count}} second...',
        unableToSaveChangesWithRetry_plural:
          'Unable to save changes. Retry in {{count}} seconds...',
        retryInXSeconds: 'Retry in {{count}} second...',
        retryInXSeconds_plural: 'Retry in {{count}} seconds...',
        unableToAddSkillToUnit: 'Unable to add skill to unit.',
        unableToRemoveSkillFromUnit: 'Unable to remove skill(s).',
        unableToDeleteCourse: 'Unable to delete course.',
        unableToUndo: 'Unable to undo.',
        retrying: 'Retrying...',
        blank_name: 'Name is empty',
        invalid_email: 'Invalid email format',
        uploadFailed:
          'Error encountered while uploading {{fileName}}. Please try again.',
        numErrors: '{{count}} Error',
        numErrors_plural: '{{count}} Errors',
        inviteEmailSendError:
          'Error encountered while sending invite emails. Please try again',
        mustBeDistrictAdminToUseSIS:
          'You must be a District Administrator to integrate SIS.',
        invalidCredentials: 'Invalid Credentials. Please try again.',
        invalidSISConfigurations:
          'At least one grade and one subject have to be selected.'
      },
      titles: {
        accounts: 'Accounts',
        addSchool: 'Add School',
        addStaff: 'Add Staff',
        addStudents: 'Add Students',
        administrators: 'Administrators',
        attachments: 'Attachments',
        allTeachers: 'All Teachers',
        allStudents: 'All Students',
        allSchools: 'All Schools',
        allGrades: 'All Grades',
        allSubjects: 'All Subjects',
        uploading: 'Uploading {{fileName}}',
        classes: 'Classes',
        classOverview: 'Class Overview',
        comingSoon: 'Coming Soon',
        contentUsage: 'Content Usage',
        curriculum: 'Curriculum',
        dashboard: 'Dashboard',
        curriculumDirectory: 'Curriculum Directory',
        customizeExperience: 'Customize your experience',
        daysLeftInFreeTrial: 'day remaining in your free trial',
        daysLeftInFreeTrial_plural: 'days remaining in your free trial',
        shortDaysLeftInFreeTrial: 'day remaining',
        shortDaysLeftInFreeTrial_plural: 'days remaining',
        sis: {
          integrationSetup: 'SIS Integration Setup',
          matchingSchools: 'Matching Schools',
          matchSchools: 'Match Schools',
          confirmMatching: 'Confirm Matching',
          syncConfiguration: 'SIS Sync Configuration',
          syncScheduling: 'SIS Sync Scheduling',
          confirmSchoolMatches: 'Confirm School Matches'
        },
        decrement: 'Decrement',
        deleteUsers: 'Delete Selected Users',
        dragAndDrop: 'Drag & Drop',
        dropYourFile: 'Drop Your File',
        elementary: 'Elementary',
        high: 'High',
        inactiveStudents: 'Inactive Students',
        inactiveTeachers: 'Inactive Teachers',
        increment: 'Increment',
        middle: 'Middle',
        noDataReceived: 'No Data Received',
        noMatchingSkills: 'No matching skills were found.',
        nationalStandards: 'National Standards',
        school: 'School',
        schoolDirectory: 'School Directory',
        schoolDistrict: 'School District',
        schoolOverview: 'School Overview',
        SELStandards: 'SEL Standards',
        sendEmailInvitesTitle: 'Send Email Invites',
        skills: 'Skills',
        schoolStandards: `School's Standards`,
        staff: 'Staff',
        stateStandards: 'State Standards',
        studentAchievement: 'Student Achievement',
        studentClassOverview: 'Student Class Overview',
        studentOverview: 'Student Overview',
        students: 'Students',
        studentsDashboard: 'Students Dashboard',
        teacherClassOverview: 'Teacher Class Overview',
        teacherEngagement: 'Teacher Engagement',
        teacherNameDotGrade: '{{teacherName}} • {{gradeLabel}}',
        teacherNameDotKindergarten: '{{teacherName}} • Kindergarten',
        teacherOverview: 'Teacher Overview',
        teachers: 'Teachers',
        teachersDashboard: 'Teachers Dashboard',
        toBeginUpload: 'to begin upload',
        topPerformingClasses: 'Top Performing Classes',
        unactivatedTeachers: 'Unactivated Teachers',
        unactivatedStudents: 'Unactivated Students',
        unitDescription: 'Unit Description',
        unitDescriptionError: 'Unit Description Error',
        unitSkills: 'Unit {{unitPosition}} Skills',
        uploadStaff: 'Upload Staff',
        uploadStudents: 'Upload Students',
        whatOption: 'What would you like to do first?',
        whyAmISeeingThis: 'Why am I seeing this?'
      },
      userActions: {
        accountSettings: 'Account Settings',
        addAccounts: 'Add Accounts',
        addAttachments: 'Add Attachments',
        allSchools: 'All Schools',
        browse: 'browse',
        browseToUpload: 'browse to upload',
        clickToUploadFiles: 'Click to Upload Files',
        contactUs: 'Contact Us',
        createAccounts: 'Create Accounts',
        createCurriculum: 'Create Curriculum',
        dashboards: 'Dashboards',
        delete: 'Delete',
        deleteUsers: 'Delete Users',
        dismissAllErrors: 'Dismiss All Errors',
        downloadCSV: 'Download CSV',
        downloadAccounts: 'Download Account Info CSV',
        editFilter: 'Edit Filter',
        exploreDemoData: 'Explore Demo Dashboard',
        inviteTeachers: 'Invite Teachers',
        inviteTeacher: 'Invite Teacher',
        logOut: 'Log Out',
        manualAccountCreation: 'Manual Account Creation',
        rosters: 'Rosters',
        schoolPreferences: 'School Preferences',
        selectSkillGroup: 'Select Skill Group',
        selectSubject: 'Select Subject',
        sendInviteEmails: 'Email invite to new users',
        sendInvite: 'Send Invite',
        sisIntegration: 'SIS Integration',
        startVerificationProcess: 'Start Verification Process',
        uninviteTeacher: 'Uninvite Teacher',
        userManagement: 'Setup',
        viewStudentDashboard: 'View Student Dashboard',
        viewTeacherDashboard: 'View Teacher Dashboard',
        viewInactiveStudents: 'View Inactive Students',
        viewInactiveTeachers: 'View Inactive Teachers',
        viewUnactivated: 'View Unactivated accounts'
      },
      imageAlt: {
        kiddomLogo: 'Kiddom Logo',
        academyIcon: 'Academy Logo',
        schoolLogo: 'School Logo',
        userAvatar: 'User Avatar',
        trashcanLogo: 'Trash Can Logo'
      },
      passages: {
        staffAccountsExplain:
          'Get started by creating accounts for the members of your school community. When you are ready for new members to get started, you can email them to set their password and access their account.',
        studentAccountsExplain_1: 'Create student accounts with ',
        studentAccountsExplain_2: 'emails ',
        studentAccountsExplain_3: 'or with ',
        studentAccountsExplain_4: 'names only',
        studentAccountsExplain_5:
          '. For students without emails, we will generate usernames and temporary passwords which you can download and distribute.',
        betterTogether: 'Contact a Support Team member to learn how',
        contactUsSubject: 'I want to invite teachers to Kiddom Academy',
        /* please keep whitespace as-is in following string interpolation */
        contactUsBody: `Hello, Kiddom.

I recently signed up for a Free Trial and I would like to invite teachers to join my school and view the curriculum map I have developed for them.

This is a list of teachers names and emails in my school:
(First Name, Last Name, Email)`,
        deleteUsers: 'Are you sure you want to delete the selected users?',
        downloadPermissionLetter:
          'Download our sample parent permission letter',
        ensureStaffFileContent:
          "Make sure your file has name and email and we'll handle the rest",
        ensureStudentFileContent:
          "*Email is optional, simply make sure your file has a name column and we'll handle the rest",
        inactiveTeachersDescription:
          'Teachers in this category have activated their Kiddom account, but have not been active in the last two weeks.',
        inactiveStudentsDescription:
          'Students in this category have activated their Kiddom account, but have not been active in the last two weeks.',
        noDataDescription:
          'Academy is currently not receiving your school’s data. Learn how to begin the verification process to start populating your school’s account with data.',
        noInvitedTeachersDescription:
          'You haven’t invited any teachers to access this curriculum.',
        noMatchingSkills:
          'Check to make sure that the formating of the skill title is correct or change the skill directory you are searching to find more options.',
        reviewImportErrors:
          "There is some data on your file we couldn't read. Please correct or dismiss errors below.",
        sendEmailInvitesConfirmation_1: 'You are sending an email to invite',
        sendEmailInvitesConfirmation_2:
          'to activate their account and be part of your school.',
        sis: {
          integrationExplain:
            'Get started by creating a connection to your SIS to sync with Kiddom. This will enable us to create Kiddom Accounts for both students and teachers in the classes they enrolled in. You will not be able to modify the accounts settings through Kiddom or revert to Manual Account Creation.',
          matchingExplain:
            "We have matched your SIS and Kiddom Schools, but some of the names didn't match exactly. Please merge them manually.",
          confirmMatchesDescription:
            'This will determine the schools and accounts that can be managed. This selection cannot be modifed after this sync.',
          syncConfigurationDescription:
            'By default, all of your schools, grades and subjects will be synced. If you would like to choose a subset of any of these categories, expand the areas and select',
          syncSchedulingDescription:
            'The sync will occur nightly. You can choose a new date to begin and also modify it in the future.'
        },
        unactivatedStudentsDescription:
          'Students in this category have never activated their Kiddom account.',
        unactivatedTeachersDescription:
          'Teachers in this category have never activated their Kiddom account.',
        uploadStaffDisclaimer:
          'You can only share personally identifiable information with staff within your learning community. Please confirm the person you’re adding is allowed to view education records under your technology policy',
        uploadStudentsDisclaimer:
          'By using student accounts, you indicate that you have parent permission for all students under 13 to setup and use Kiddom accounts'
      },
      placeholders: {
        unitDescription: 'Add unit description...'
      },
      guidanceSteps: {
        freeTrialYourSchoolStepOne:
          'Design a responsive, real time curriculum plan',
        freeTrialYourSchoolStepTwo:
          'Build consistent expectations across classrooms',
        freeTrialDemoSchoolStepOne: 'Explore school and classroom insights',
        freeTrialDemoSchoolStepTwo: 'View student achievement details',
        freeTrialDemoSchoolStepThree:
          'See how teachers use your curriculum in the classroom'
      }
    }
  },
  'fr-CA': {
    translation: {
      labels: {
        poweredByKiddom: "L'Powered By Kiddom",
        principalColon: "L'Principal: {{principalName}}"
      },
      titles: {},
      userActions: {}
    }
  }
}

export default defaultTranslationsObject
