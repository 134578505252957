import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { SIS_INTEGRATION_ROUTE } from './routes'

import SISIntegrationContainer from '../components/sisIntegration/sisIntegrationContainer'
import SISMatchingContainer from '../components/sisIntegration/sisMatchingContainer'
import SISConfigurationContainer from '../components/sisIntegration/sisConfigurationContainer'

const integrationPathPrefix = `${SIS_INTEGRATION_ROUTE}/integration`
const matchingPathPrefix = `${SIS_INTEGRATION_ROUTE}/matching`
const configPathPrefix = `${SIS_INTEGRATION_ROUTE}/configurations`

const SISIntegrationRouter = () => (
  <Switch>
    <Route path={integrationPathPrefix} component={SISIntegrationContainer} />
    <Route path={matchingPathPrefix} component={SISMatchingContainer} />
    <Route path={configPathPrefix} component={SISConfigurationContainer} />
  </Switch>
)

export default SISIntegrationRouter
