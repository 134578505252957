import React from 'react'
import { Iterable } from 'immutable'

// copy-pasta'd
// https://redux.js.org/recipes/usingimmutablejs#use-a-higher-order-component-to-convert-your-smart-components-immutable-js-props-to-your-dumb-components-javascript-props
// between sending data from connected components, we convert the data toJS
// if there are changes props from mapStateToProps

export const withToJS = (WrappedComponent) =>
  React.forwardRef((wrappedComponentProps, ref) => {
    const KEY = 0
    const VALUE = 1
    const propsJS = Object.entries(wrappedComponentProps).reduce(
      (newProps, wrappedComponentProp) => {
        newProps[wrappedComponentProp[KEY]] = Iterable.isIterable(
          wrappedComponentProp[VALUE]
        )
          ? wrappedComponentProp[VALUE].toJS()
          : wrappedComponentProp[VALUE]
        return newProps
      },
      {}
    )
    return <WrappedComponent ref={ref} {...propsJS} />
  })

export default withToJS
