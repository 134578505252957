import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import { HOME_PATH } from '../../routes/routes'
import SchoolsIcon from '../../images/icons/schools.svg'
import LogOutIcon from '../../images/icons/log-out.svg'
import SettingsIcon from '../../images/icons/settings.svg'
import Popup from '../ui/popup/popup'
import CircledIcon from '../common/circledIcon'
import FreeTrialContainer from '../freeTrial/freeTrialContainer'

import * as SessionClient from '../../helpers/sessionClient'
import { redirectToClassroom } from '../../helpers/utils'
import { withSchoolOrDistrictAdminRoleCheck } from '../hoc/withRoleCheckFor'
import { LinkToKiddomClassroom } from './linkToKiddomClassroom'

const allSchoolsText = i18n.t('userActions.allSchools')

const AllSchoolsButton = (props) => {
  if (!props.inSchool || !props.isDistrictAdmin) {
    return null
  }
  return (
    <div
      data-spec="all-schools-action"
      className="all-schools-action"
      onClick={() => props.history.push(HOME_PATH)}
    >
      <SchoolsIcon className="school-icon" />
      <span>{allSchoolsText}</span>
    </div>
  )
}

export const UserActions = (props) => {
  let userIconMenu = null
  if (props.userIcon) {
    const userIcon = (
      <CircledIcon>
        <img
          data-spec="user-icon"
          alt={i18n.t('imageAlt.userAvatar')}
          src={props.userIcon}
          className="user-icon"
        />
      </CircledIcon>
    )
    const popupMenu = (
      <ul className="nav-links">
        {props.onClickAccountSettings && (
          <li key="account-settings">
            <button className="action" onClick={props.onClickAccountSettings}>
              <SettingsIcon title={i18n.t('userActions.accountSettings')} />
              {i18n.t('userActions.accountSettings')}
            </button>
          </li>
        )}
        <li key="logout">
          <button
            data-spec="logout-action"
            className="action logout-action"
            tabIndex="0"
            onClick={() => SessionClient.remove().then(redirectToClassroom)}
          >
            <LogOutIcon title="Log Out" />
            <span>{i18n.t('userActions.logOut')}</span>
          </button>
        </li>
      </ul>
    )
    userIconMenu = <Popup triggerElement={userIcon}>{popupMenu}</Popup>
  }
  let allSchoolButton = null
  if (props.showAllSchoolButton) {
    allSchoolButton = (
      <AllSchoolsButton
        inSchool={props.inSchool}
        isDistrictAdmin={props.isDistrictAdmin}
        history={props.history}
      />
    )
  }
  const actionsElement = props.isUserInFreeTrial ? (
    <FreeTrialContainer />
  ) : (
    allSchoolButton
  )

  return (
    <div className="user-actions" data-spec="user-actions">
      {/* inSchool means that the user is viewing a school. if this is the case, 
      the nav bar changes. we only want to show the redirect button at the district level */}
      {!props.inSchool && <LinkToKiddomClassroom />}
      {actionsElement}
      <div className="user-icon-container">{userIconMenu}</div>
    </div>
  )
}

UserActions.propTypes = {
  userIcon: PropTypes.string,
  history: PropTypes.object,
  inSchool: PropTypes.bool,
  isUserInFreeTrial: PropTypes.bool,
  userFreeTrialExpiration: PropTypes.number,
  onClickAccountSettings: PropTypes.func
}

export default withSchoolOrDistrictAdminRoleCheck(
  UserActions,
  true // force render
)
