import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  didSendInviteEmailFail,
  getImportIngestionID,
  isSendingInviteEmail
} from '../../store/fromState'
import * as userManagementActions from '../../store/actions/userManagementActions'
import { ROLE } from '../../helpers/constants'

import InviteEmailButtonPresenter from './inviteEmailButtonPresenter'

export const InviteEmailButtonContainer = (props) => (
  <InviteEmailButtonPresenter {...props} />
)

export const mapStateToProps = (state) => {
  const ingestionID = getImportIngestionID(state, ROLE.TEACHER)
  return {
    ingestionID,
    isFailed: didSendInviteEmailFail(state, ingestionID),
    isLoading: isSendingInviteEmail(state, ingestionID)
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    sendEmail: (ingestionID) =>
      dispatch(
        userManagementActions.bulkImportUsers.sendIngestionInviteEmail.begin(
          ingestionID,
          schoolID
        )
      )
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InviteEmailButtonContainer)
)
