import React from 'react'
import PropTypes from 'prop-types'
import { Chart, Doughnut } from 'react-chartjs-2'
import { doughnutCenterText } from '../../helpers/chartPlugins'

import './doughnutChart.scss'

class DoughnutChart extends React.Component {
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    Chart.pluginService.register(doughnutCenterText)
  }

  renderChartData = () => {
    const filled = this.props.percentFilled
    const empty = 100 - filled
    // TODO - the border added here makes it look close to design, but
    // not exact, and creates weirdness when around 0, or 100%.
    const unFilledBorder = filled >= 10 ? 8 : 0
    return {
      labels: ['Fill', 'Value'],
      datasets: [
        {
          data: [filled, empty],
          backgroundColor: [this.props.filledColor, '#D6DCDB'],
          borderWidth: [4, unFilledBorder]
        }
      ]
    }
  }

  render() {
    const percentLabel = this.props.percentFilled + '%'
    const loadingLabel = '-'
    const useLoading = typeof this.props.percentFilled !== 'number'
    const progressLabel = useLoading ? loadingLabel : percentLabel
    const doughnutProps = {
      key: 'key-doughnut',
      data: this.renderChartData,
      tooltips: false,
      width: this.props.diameter,
      height: this.props.diameter,
      options: {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        cutoutPercentage: 70,
        events: [],
        animateRotate: false,
        elements: {
          center: {
            // the longest text that could appear in the center
            maxText: ` ${progressLabel} `,
            text: progressLabel,
            fontColor: '#000000',
            minFontSize: 6,
            maxFontSize: 80
          }
        }
      }
    }

    return (
      <div className="doughnut-container">
        <Doughnut {...doughnutProps} />
      </div>
    )
  }
}

DoughnutChart.defaultProps = {
  filledColor: '#8773FF',
  diameter: 90
}

DoughnutChart.propTypes = {
  percentFilled: PropTypes.number,
  filledColor: PropTypes.string,
  diameter: PropTypes.number
}

export default DoughnutChart
