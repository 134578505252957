import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './textInput.scss'

export class TextInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFocused: this.props.autoFocus
    }
    this.handleFocus = this.handleFocus.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleFocus(e) {
    this.setState({ isFocused: true })
    this.props.onFocus && this.props.onFocus(e)
  }

  handleBlur(e) {
    this.setState({ isFocused: false })
    this.props.onBlur && this.props.onBlur(e)
  }

  render() {
    const {
      className,
      error,
      label,
      value,
      defaultValue,
      secondary,
      underline,
      disableErrorLine,
      ...inputProps
    } = this.props
    const classNames = classnames('text-input-wrapper', {
      error: !disableErrorLine && !!error,
      secondary,
      focus: this.state.isFocused,
      underline,
      'input-filled': !!value
    })
    const inputValue = typeof value === 'string' ? value : defaultValue
    const inputPlaceHolder = this.state.isFocused ? null : label
    return (
      <div className={`text-input-container ${className}`}>
        <div className={classNames}>
          {secondary && label && <div className="label">{label}</div>}
          <input
            className="text-input"
            data-spec="text-input"
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            title={inputValue}
            value={inputValue}
            placeholder={inputPlaceHolder}
            {...inputProps}
          />
        </div>
        {error && <div className="error-text">{error}</div>}
      </div>
    )
  }
}

TextInput.defaultProps = {
  secondary: false,
  error: null,
  className: ''
}

TextInput.propTypes = {
  secondary: PropTypes.bool,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,

  onBlur: PropTypes.func,
  onFocus: PropTypes.func
}

export default TextInput
