import React from 'react'

import SubjectSelectContainer from './subjectSelectContainer'

import i18n from '../../../../../helpers/i18n'

export default function subjectSelectFilter(subject, selectSubject) {
  return {
    key: `subjects`,
    label: subject ? subject.get('name') : i18n.t('titles.allSubjects'),
    showClose: true,
    FilterComponent: function FilterComponent({ postSelect }) {
      return (
        <SubjectSelectContainer
          value={subject ? subject.get('id') : undefined}
          postSelect={postSelect}
          onClickSubject={selectSubject}
        />
      )
    }
  }
}
