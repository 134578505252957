import Immutable from 'immutable'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import { STUDENTS } from '../../store/models/resources'
import {
  STUDENTS_VIEW_EVENT,
  SINGLE_STUDENT_VIEW_EVENT
} from '../../store/eventIDs'
import { DEFAULT_CARD_PAGE_SIZE } from '../../helpers/constants'
import StudentActivityListPagePresenter from './studentActivityListPagePresenter'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'

const INACTIVE_LIST_TYPE = 'inactive'
const UNACTIVATED_LIST_TYPE = 'unactivated'
const WrappedPresenter = withToJS(StudentActivityListPagePresenter)

const divideIDsIntoPages = (IDs, pageSize = DEFAULT_CARD_PAGE_SIZE) => {
  const pages = {}
  let totalPages = 0
  IDs.forEach((ID, index) => {
    const page = Math.floor(index / pageSize).toString()
    if (!pages[page]) {
      totalPages += 1
      pages[page] = []
    }
    pages[page].push(ID)
  })

  return { pages, totalPages, currentPage: 0 }
}

export class StudentActivityListPageContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      totalPages: 0,
      pages: null,
      currentPage: 0,
      students: []
    }
  }

  componentDidMount() {
    this.props.trackPageViewed(this.state.currentPage)
    if (
      !this.props.isLoading &&
      this.props.activityList &&
      this.props.activityList.size
    ) {
      this.divideAndLoadStudents()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const freshlyLoaded =
      !this.props.isLoading &&
      !prevProps.activityList &&
      this.props.activityList &&
      this.props.activityList.size
    const switchedListTypes = prevProps.listType !== this.props.listType
    if (freshlyLoaded || switchedListTypes) {
      this.divideAndLoadStudents()
    }
    if (
      prevState.currentPage !== this.state.currentPage ||
      prevProps.studentsByIDs.size !== this.props.studentsByIDs.size ||
      prevState.pages !== this.state.pages
    ) {
      this.setStudents()
    }
  }

  divideAndLoadStudents() {
    const paginated = divideIDsIntoPages(this.props.activityList)
    this.setState(paginated)
    // fetch the first page
    this.props.loadStudentData(paginated.pages[0])
  }

  setStudents = () => {
    let isLoading = this.props.isLoading
    const { studentsByIDs } = this.props
    const { pages, currentPage } = this.state

    const studentIDs = (pages && Immutable.List(pages[currentPage])) || []
    if (studentsByIDs.size) {
      isLoading = isLoading || !studentIDs.every((id) => studentsByIDs.get(id))
      if (!isLoading) {
        const students = studentIDs.map((id) => studentsByIDs.get(id))
        this.setState({ students })
      }
    }
  }

  updateCurrentPage = (pageNum) => {
    this.setState({ currentPage: pageNum }, () => {
      this.props.loadStudentData(this.state.pages[pageNum])
      this.props.trackPageViewed(pageNum)
    })
  }

  render() {
    const props = {
      ...this.props,
      ...this.state,
      updateCurrentPage: this.updateCurrentPage
    }
    return <WrappedPresenter {...props} />
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const listType = ownProps.listType
  const numInactive = fromState.getInactiveStudentCount(state)
  const numUnactivated = fromState.getUnactivatedStudentCount(state)
  const studentsByIDs = fromState.getResource(state, STUDENTS)
  const activityList = fromState.getStudentActivityListIDs(state, listType)

  return {
    schoolID,
    studentsByIDs,
    activityList,
    numInactive,
    numUnactivated,
    listType
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    loadStudentData: (studentIDs) => {
      dispatch(coreActions.studentsBatch.read.begin(schoolID, studentIDs))
    },
    trackPageViewed: (page) => {
      return dispatch(
        coreActions.trackEvent.create.begin(STUDENTS_VIEW_EVENT, {
          schoolID,
          filter: {
            [ownProps.listType]: true,
            page
          }
        })
      )
    },
    trackSingleStudentViewed: (id) => {
      const oppositeList =
        ownProps.listType === INACTIVE_LIST_TYPE
          ? UNACTIVATED_LIST_TYPE
          : INACTIVE_LIST_TYPE
      const data = {
        schoolID,
        fromWhere: ownProps.listType,
        student: {
          id,
          [ownProps.listType]: true,
          [oppositeList]: false
        }
      }
      return dispatch(
        coreActions.trackEvent.create.begin(SINGLE_STUDENT_VIEW_EVENT, data)
      )
    }
  }
}

const generateDataLoaders = (props) => [
  adminActions.studentActivityLists.read.begin(props.match.params.schoolID)
]
const generateLoadingProps = (props) => ({
  isLoading: [
    [fromState.isFetchingStudentActivityLists, []],
    [fromState.getStudentActivityListIDs, [props.listType]],
    [fromState.isFetching, [STUDENTS]]
  ]
})

const reloadProps = ['schoolID', 'listType']

export default withRouter(
  withDataLoading(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(StudentActivityListPageContainer),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
