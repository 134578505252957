import { fromJS } from 'immutable'
import * as reducerCreators from './reducerCreators'
import { mapArrayObjectsByKey } from '../../helpers/utils'
import { GRADES } from '../models/resources'

export const initialState = fromJS({
  byID: {},
  fetching: false,
  error: null
})

const getOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching'], fetching),
  errorLocation: (state, action, error) =>
    state.setIn(['error'], fromJS(error)),
  placementFunction: (state, action) =>
    mapArrayObjectsByKey(state, action.grades, 'gradeLevelID', ['byID'])
}

const grades = reducerCreators.reducerCreator(
  initialState,
  reducerCreators.getHandlers(GRADES, {}, getOptions)
)

export default grades
