import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './loadingDots.scss'

const LoadingDots = (props) => {
  return (
    <div
      {...props}
      className={classnames('loading-dots', props.theme, props.className)}
    >
      <div className="dot" />
      <div className="dot" />
      <div className="dot" />
    </div>
  )
}

LoadingDots.defaultProps = {
  theme: 'light'
}

LoadingDots.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark'])
}

export default LoadingDots
