import { call } from 'redux-saga/effects'
import { fetchResourceEngine } from './helperSagas'
import * as sisIntegrationActions from '../actions/sisIntegrationActions'
import * as sisIntegrationModels from '../models/sisIntegrationModels'

export function* sendSISCredentials(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [
      action.districtID,
      action.clientID,
      action.clientSecret,
      action.clientHost
    ]
  }
  yield call(
    fetchResourceEngine,
    sisIntegrationActions.sendSISCredentials,
    () =>
      sisIntegrationModels.sendSISCredentials(
        action.districtID,
        action.clientID,
        action.clientSecret,
        action.clientHost
      ),
    engineOptions
  )
}

export function* fetchSISCredentials() {
  const engineOptions = {
    needToFetch: true
  }
  yield call(
    fetchResourceEngine,
    sisIntegrationActions.sisCredentials.read,
    () => sisIntegrationModels.fetchSISCredentials(),
    engineOptions
  )
}

export function* fetchSISMatches(action) {
  const engineOptions = {
    transformer: sisIntegrationModels.transformMatches,
    needToFetch: true,
    initializeArgs: [action.districtID]
  }
  yield call(
    fetchResourceEngine,
    sisIntegrationActions.sisSchoolMatches.read,
    () => sisIntegrationModels.fetchSISMatches(action.districtID),
    engineOptions
  )
}

export function* updateSISMatches(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.districtID, action.pairs]
  }
  yield call(
    fetchResourceEngine,
    sisIntegrationActions.sisSchoolMatches.update,
    () =>
      sisIntegrationModels.updateSISMatches(action.districtID, action.pairs),
    engineOptions
  )
}

export function* fetchSISConfigurations(action) {
  const engineOptions = {
    needToFetch: true
  }

  yield call(
    fetchResourceEngine,
    sisIntegrationActions.fetchSISConfigurations,
    () => sisIntegrationModels.fetchSISConfigurations(action.districtID),
    engineOptions
  )
}

export function* updateSISConfigurations(action) {
  const engineOptions = {
    needToFetch: true,
    successArgs: [
      action.districtID,
      action.schedule,
      action.schoolIDs,
      action.gradeIDs,
      action.subjectIDs
    ]
  }

  yield call(
    fetchResourceEngine,
    sisIntegrationActions.updateSISConfigurations,
    () =>
      sisIntegrationModels.updateSISConfigurations(
        action.districtID,
        action.schedule,
        action.schoolIDs,
        action.gradeIDs,
        action.subjectIDs
      ),
    engineOptions
  )
}
