import * as actionIDs from './actionIDs'
import { makeActionCreator, makeResourceActionsCreators } from './actionHelpers'

export const user = {
  read: makeResourceActionsCreators(actionIDs.user.read, {
    success: ['user']
  }),
  readPrefs: makeResourceActionsCreators(actionIDs.user.readPrefs, {
    success: ['userPreferences']
  }),
  writePrefs: makeResourceActionsCreators(actionIDs.user.writePrefs, {
    begin: ['preferences', 'shouldSetUserPref'],
    initialize: ['preferences'],
    error: ['preferences', 'originalPreferences']
  })
}

export const district = {
  read: makeResourceActionsCreators(actionIDs.district.read, {
    begin: ['districtID'],
    success: ['district']
  })
}

export const classes = {
  read: makeResourceActionsCreators(actionIDs.classes.read, {
    begin: ['schoolID'],
    success: ['classes', 'statistics', 'schoolID']
  })
}

export const teachers = {
  read: makeResourceActionsCreators(actionIDs.teachers.read, {
    begin: ['schoolID'],
    success: ['schoolID', 'teachers']
  })
}

export const grades = {
  read: makeResourceActionsCreators(actionIDs.grades.read, {
    success: ['grades']
  })
}

// fetching a single student
export const student = {
  read: makeResourceActionsCreators(actionIDs.student.read, {
    begin: ['schoolID', 'studentID'],
    success: ['student']
  })
}

// fetching a single student class performance
export const studentClassPerformance = {
  read: makeResourceActionsCreators(actionIDs.studentClassPerformance.read, {
    begin: ['classID'],
    success: ['studentID', 'childID', 'classID', 'performance']
  })
}

export const students = {
  read: {
    ...makeResourceActionsCreators(actionIDs.students.read, {
      begin: ['schoolID', 'query', 'offset', 'limit'],
      success: ['schoolID', 'students']
    }),
    updatePagination: makeActionCreator(
      actionIDs.UPDATE_STUDENT_PAGINATION,
      'studentIDs',
      'query',
      'offset',
      'totalPages',
      'schoolID'
    ),
    updateCurrentPage: makeActionCreator(
      actionIDs.UPDATE_STUDENT_CURRENT_PAGE,
      'currentPage'
    )
  },
  readAssignmentStatistics: {
    ...makeResourceActionsCreators(
      actionIDs.students.readAssignmentStatistics,
      {
        begin: ['schoolID', 'studentIDs'],
        success: ['statistics']
      }
    )
  },
  readPerformance: {
    ...makeResourceActionsCreators(actionIDs.students.readPerformance, {
      begin: ['schoolID', 'studentIDs'],
      success: ['performances']
    })
  },
  readWithStatistics: makeActionCreator(
    actionIDs.students.readWithStatistics,
    'schoolID',
    'query',
    'offset',
    'limit'
  )
}

export const studentsBatch = {
  read: makeResourceActionsCreators(actionIDs.studentsBatch.read, {
    begin: ['schoolID', 'studentIDs'],
    success: ['students', 'student']
  })
}

export const studentClassesStatistics = {
  read: makeResourceActionsCreators(actionIDs.studentClassesStatistics.read, {
    begin: ['schoolID', 'studentID'],
    success: ['studentID', 'classesStatistics']
  })
}

export const schools = {
  read: makeResourceActionsCreators(actionIDs.schools.read, {
    begin: ['force'],
    success: ['schools']
  })
}

export const reportingMethods = {
  read: makeResourceActionsCreators(actionIDs.reportingMethods.read, {
    begin: ['schoolID'],
    initialize: ['schoolID'],
    success: ['schoolID', 'reportingMethods']
  })
}

export const subjects = {
  read: makeResourceActionsCreators(actionIDs.subjects.read, {
    success: ['subjects']
  })
}

export const teacherAggregate = {
  read: {
    begin: makeActionCreator(actionIDs.teacherAggregate.read.begin, 'schoolID')
  }
}

export const trackEvent = {
  create: makeResourceActionsCreators(actionIDs.trackEvent.create, {
    begin: ['eventName', 'data']
  })
}

export const localStorage = {
  get: {
    begin: makeActionCreator(actionIDs.localStorage.get.begin, 'key'),
    success: (key) =>
      makeActionCreator(actionIDs.localStorage.get.success(key), 'key', 'value')
  },
  set: {
    begin: makeActionCreator(actionIDs.localStorage.set.begin, 'key', 'value'),
    success: (key) =>
      makeActionCreator(actionIDs.localStorage.set.success(key), 'key', 'value')
  }
}
