import React from 'react'

import i18n from '../../../helpers/i18n'
import SearchIcon from '../../../images/icons/search.svg'

import './search.scss'

class Search extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: ''
    }

    this.searchInputChanged = this.searchInputChanged.bind(this)
  }

  searchInputChanged(evt) {
    this.setState({ searchValue: evt.target.value })
    this.props.searched(evt.target.value)
  }

  render() {
    const searchLabel = this.props.placeholder || i18n.t('labels.search')
    return (
      <div className="search-container" data-spec="search-ui">
        <input
          type="text"
          className="search-input"
          value={this.state.searchValue}
          onChange={this.searchInputChanged}
          placeholder={searchLabel}
        />
        <SearchIcon />
      </div>
    )
  }
}

export default Search
