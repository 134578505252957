import i18n from './i18n'
import { List } from 'immutable'
import { NO, NOT_APPLICABLE } from './constants'
export const ELEMENTARY = 'Elementary'
export const MIDDLE = 'Middle'
export const HIGH = 'High'

export const filterGradeLevelsByCategory = (gradeLevels, category) => {
  let newGradeLevels = gradeLevels
  switch (category) {
    case ELEMENTARY:
      newGradeLevels = gradeLevels.filter(
        (val) => val.get('gradeOrdinality') <= 6
      )
      break
    case MIDDLE:
      newGradeLevels = gradeLevels.filter(
        (val) =>
          val.get('gradeOrdinality') >= 7 && val.get('gradeOrdinality') <= 9
      )
      break
    case HIGH:
      newGradeLevels = gradeLevels.filter(
        (val) => val.get('gradeOrdinality') >= 10
      )
      break
    default:
      break
  }
  return newGradeLevels
}

// adapted from
// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const gradeOrdinality = (grade) => {
  const i = Number(grade)
  if (grade === 'K') {
    return 'K'
  } else if (isNaN(i)) {
    return NO
  }
  const j = i % 10
  const k = i % 100
  let suffix = 'th'
  if (j === 1 && k !== 11) {
    suffix = 'st'
  }
  if (j === 2 && k !== 12) {
    suffix = 'nd'
  }
  if (j === 3 && k !== 13) {
    suffix = 'rd'
  }
  return `${i}${suffix}`
}

export const sortGrades = (a, b) => {
  const aGrade = parseInt(a, 10) || 0
  const bGrade = parseInt(b, 10) || 0
  return aGrade - bGrade
}

export const gradesLabel = (grades) => {
  let displayGrades = grades.sort(sortGrades).map(gradeOrdinality)
  displayGrades = displayGrades.join(', ')
  if (grades.length === 1 && grades[0] === 'K') {
    return `${i18n.t('labels.kindergarten')}`
  }
  return `${displayGrades} ${i18n.t('labels.grade')}`
}

export const gradeSymbol = (gradeID) => {
  if (gradeID === 1) {
    return 'K'
  }
  return `${gradeID - 1}`
}

/*  abridgeNumberRanges
    input: ['1','2','3']  input: ['1','2','3','7','8']
    output: [['1','3']]  output: [['1','3'], ['7','8']]
    Take a list of numbers, and pair them by start/end of numerical sequences.
    Start with a blank List, iterate over list of values:
      - When current grade is 1 above previous grade, we're in a sequence, and set end = grade
      - When current grade is different, we're out of a sequence:
        - If start/end equal, add grade as singleton sequence
        - If start/end inequal, add start/end pair to list of sequences
        - reset start/end to be current grade
    - After loop, tie up last start/end values with same logic once.
*/
export const abridgeNumberRanges = (values) => {
  let newValues = new List()
  values = new List(values)
  let start, end
  start = end = values.get(0)
  values.forEach((grade, index) => {
    if (!index) {
      return
    }
    const valueInt = parseInt(grade, 10)
    const lastValueInt = parseInt(values.get(index - 1), 10) + 1
    if (valueInt === lastValueInt) {
      end = grade
    } else {
      if (start === end) {
        newValues = newValues.push(new List([start]))
      } else {
        newValues = newValues.push(new List([start, end]))
      }
      start = end = grade
    }
  })
  if (start === end) {
    newValues = newValues.push(new List([start]))
  } else {
    newValues = newValues.push(new List([start, end]))
  }
  return newValues
}

export const stringifyAbridgedSequence = (values, isGrade = false) => {
  const abridgedSequence = abridgeNumberRanges(values)
  const flatGradesList = abridgedSequence.map((group) => {
    const beginInt = parseInt(group.get(0), 10)
    let begin = beginInt
    if (isGrade) {
      begin = gradeOrdinality(gradeSymbol(beginInt))
    }
    if (group.size > 1) {
      const endInt = parseInt(group.get(1), 10)
      let end = endInt
      if (isGrade) {
        end = gradeOrdinality(gradeSymbol(endInt))
      }
      // handle if not space between sequence start/end
      if (endInt - beginInt === 1) {
        return `${begin}, ${end}`
      }
      return `${begin}-${end}`
    } else {
      return `${begin}`
    }
  })
  return flatGradesList.join(', ')
}

/*  gradeFilterString
    input: [['1'],['3','5']]  [['1','3']] [['1','13']]
    output: 'K, 2nd-4th'      'K-3rd'         'All Grade Levels'
    Takes an abridged Grade Level paired list from output of abridgeNumberRanges.
    - If only grade level 1 is selcted, output 'Kindergarten'
    - If all grade levels selected, output 'All Grade Levels'
    - Otherwise output:
      - transform each sequence set into grade_ordinalities, separated by '-',
        Ex: '3rd-5th'
      - If start/end are off by 1, list the grade_ordinalities, separated by ', ',
        Ex: '2nd, 3rd'
      - If start/end are same, list the grade_ordinality once.
        Ex: '1st'
*/
export const gradeFilterString = (grades) => {
  const sortedGrades = [...grades].sort(sortGrades)
  // handle easy cases before using expensive functions
  // handle no grades selected
  const gradeIsNA =
    sortedGrades.length === 1 && sortedGrades[0] === NOT_APPLICABLE
  const noGrades = !sortedGrades.length
  if (gradeIsNA || noGrades) {
    return i18n.t('labels.gradeLevelFilter')
  }

  // handle single grade of 1 (K since no grade-ordinality yet)
  if (sortedGrades.length === 1 && sortedGrades[0] === '1') {
    return i18n.t('labels.kindergarten')
  }

  // handle all grades
  if (sortedGrades.length === 13) {
    return i18n.t('labels.allGradeLevels')
  }

  // abrdige array by sequences

  return stringifyAbridgedSequence(
    sortedGrades, // values
    true // isGrades
  )
}
