// Client for local storage
// everything is stored as a JSON

// getFromStore gets from localStorage
// parse value recieved
// if parsing fails or there is no local storage available, returns null
export const getFromStore = (key) => {
  if (window.localStorage) {
    try {
      const value = window.localStorage.getItem(key)
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  return null
}

// setFromStore sets localStorage
// stringifys value recieved
export const setInStore = (key, value) => {
  if (window.localStorage) {
    window.localStorage.setItem(key, JSON.stringify(value.toJS()))
    return value
  }
  return null
}
