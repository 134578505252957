import React from 'react'
import i18n from '../../../helpers/i18n'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LoadingDots from '../loading/loadingDots'
import CheckMark from '../../../images/icons/checkmark.svg'

import './badges.scss'

export const Badge = (props) => (
  <div className={classnames('badge', props.className)}>{props.label}</div>
)

export const NewBadge = () => {
  const newLabel = i18n.t('labels.new')
  return <Badge className="new-course-badge" label={newLabel} />
}

export const LoadingBadge = () => {
  const loadingLabel = <LoadingDots theme="light" />
  return <Badge className="loading-badge" label={loadingLabel} />
}

export const SavedBadge = () => {
  const savedLabel = <CheckMark className="checkMark" />
  return <Badge className="saved-badge" label={savedLabel} />
}

export const ErrorBadge = () => <Badge className="errored-badge" label="!" />

export const RetryingAfterErrorBadge = () => {
  const loadingLabel = <LoadingDots theme="light" />
  return <Badge className="errored-badge" label={loadingLabel} />
}

Badge.propTypes = {
  className: PropTypes.string
}

export default Badge
