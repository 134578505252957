import React from 'react'
import classnames from 'classnames'

import './button.scss'

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
  FlatGreen = 'flat-green',
  Blue = 'blue'
}

export interface IButtonProps {
  buttonStyle?: ButtonVariant
  className?: string
  label?: string
  mobileLabel?: string
  onClick?: () => void
}

export const Button = ({
  buttonStyle = ButtonVariant.Primary,
  className,
  label,
  onClick
}: IButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={classnames('academy-button', buttonStyle, className)}
    >
      <span className="label">{label}</span>
    </button>
  )
}

export default Button
