import React from 'react'
import { KEY_CODES } from '../../helpers/constants'

class WithVisibleStateToggle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false
    }

    this.toggleVisible = this.toggleVisible.bind(this)
    this.showVisible = this.showVisible.bind(this)
    this.hideVisible = this.hideVisible.bind(this)
    this.hideOnEscape = this.hideOnEscape.bind(this)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.hideOnEscape, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.hideOnEscape, false)
  }

  hideOnEscape(event) {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      this.hideVisible()
    }
  }

  toggleVisible() {
    if (this.state.visible) {
      return this.hideVisible()
    }
    this.showVisible()
  }

  hideVisible() {
    this.setState({ visible: false }, () => {
      if (this.props.onHidden) {
        this.props.onHidden()
      }
    })
  }

  showVisible() {
    this.setState({ visible: true }, () => {
      if (this.props.onShown) {
        this.props.onShown()
      }
    })
  }

  // Tests blow up if this doesn't have a render function
  render() {
    return null
  }
}

export default WithVisibleStateToggle
