import { select, call, all, put } from 'redux-saga/effects'

import * as helperSagas from './helperSagas'
import * as coreSagas from './coreSagas'
import * as fromState from '../fromState'
import * as resources from '../models/resources'
import * as curriculumActions from '../actions/curriculumActions'
import * as curriculumModels from '../models/curriculumModels'
import * as curriculumTransform from '../models/curriculumTransform'
import * as skillsHelpers from '../stateHelpers/skills'

export function* fetchSchoolSubjectGroups(action) {
  const loaded = yield select(
    fromState.subjectGroupsLoadedForSchool,
    action.schoolID
  )
  const engineOptions = {
    transformer: curriculumTransform.subjectGroups,
    needToFetch: action.forceFetch || !loaded,
    initializeArgs: [action.schoolID],
    successArgs: [action.schoolID],
    errorArgs: [action.schoolID]
  }

  yield helperSagas.fetchResourceEngine(
    curriculumActions.schoolSubjectGroups.read,
    () => curriculumModels.getSubjectGroupsForSchool(action.schoolID),
    engineOptions
  )
}

export function* createSchoolSubjectGroup(action) {
  const engineOptions = {
    transformer: curriculumTransform.transformSubjectGroup,
    needToFetch: true,
    initializeArgs: [
      action.schoolID,
      action.subjectID,
      action.subjectType,
      action.tempID
    ],
    successArgs: [
      action.schoolID,
      action.subjectID,
      action.subjectType,
      action.tempID
    ],
    errorArgs: [action.tempID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.schoolSubjectGroups.create,
    () =>
      curriculumModels.createSubjectGroup(
        action.schoolID,
        action.subjectID,
        action.subjectType
      ),
    engineOptions
  )
}

export function* fetchCourses(action) {
  const loaded = yield select(
    fromState.coursesLoaded,
    action.schoolSubjectGroupID
  )
  const engineOptions = {
    transformer: curriculumTransform.courses,
    needToFetch: action.forceFetch || !loaded,
    initializeArgs: [action.schoolSubjectGroupID],
    successArgs: [action.schoolSubjectGroupID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.read,
    () =>
      curriculumModels.getCoursesForSubjectGroup(action.schoolSubjectGroupID),
    engineOptions
  )
}

export function* fetchSchoolSubjectsAndCourses(action) {
  const engineOptions = {
    transformer: curriculumTransform.subjectsAndCourses,
    needToFetch: true,
    initializeArgs: [action.schoolID],
    successArgs: [action.schoolID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.subjectsAndCourses.read,
    () => curriculumModels.getSubjectsAndCourses(action.schoolID),
    engineOptions
  )
}

// Fetches everything necessary for calling fetchSkills if you only
// have the school ID, course ID and the course's subjectGroupID
// - fetches the school's subject groups and gets the subject IDs associated with the subject group
// - fetches courses and gets the grade level IDs associated with that course
// - if the course does not have grade levels associated, don't fetch for skills
// - fetches skill groups and gets the initial skill group from the course's subject group's subject IDs
// - fetches skills with the default skill group IDs, the default subject IDs, and the default grade level IDs
export function* fetchSkillsFromSubjectGroupAndCourse(action) {
  yield call(fetchSchoolSubjectGroups, { schoolID: action.schoolID })
  const subjectGroup = yield select(
    fromState.getResourceByID,
    resources.SCHOOL_SUBJECT_GROUPS,
    action.subjectGroupID
  )
  const defaultSubjectIDs = subjectGroup && subjectGroup.get('subjectIDs')
  yield call(fetchCourses, {
    schoolSubjectGroupID: action.subjectGroupID
  })
  const defaultGradeLevelIDs = yield select(
    fromState.getCourseGradesWithoutNA,
    action.courseID
  )
  if (defaultGradeLevelIDs.size > 0) {
    yield call(fetchSkillGroups)
    const defaultSkillGroupID = yield select(
      skillsHelpers.getInitialSkillGroup,
      defaultSubjectIDs
    )
    yield call(fetchSkills, {
      schoolID: action.schoolID,
      unitID: action.unitID,
      skillGroupIDs: [defaultSkillGroupID],
      subjectIDs: defaultSubjectIDs,
      gradeIDs: new Set(defaultGradeLevelIDs.toJS())
    })
  }
}

export function* fetchSkills(action) {
  const engineOptions = {
    transformer: curriculumTransform.transformSkills,
    needToFetch: true,
    initializeArgs: [action.unitID, action.searchQuery],
    successArgs: [
      action.unitID,
      action.skillGroupIDs,
      action.searchQuery,
      action.gradeIDs
    ]
  }

  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.skills.read,
    () =>
      curriculumModels.getSkillsFiltered(
        action.schoolID,
        action.searchQuery,
        action.skillGroupIDs,
        action.subjectIDs,
        action.gradeIDs,
        action.pageNumber
      ),
    engineOptions
  )
}

export function* fetchIndividualSkills(action) {
  const engineOptions = {
    transformer: curriculumTransform.transformIndividualSkills,
    needToFetch: true,
    initializeArgs: [action.skillIDs],
    successArgs: [action.skillIDs, action.unitID]
  }

  /* 
    - allow the saga to complete correctly by providing async return of empty standards
    - adding this hear as to prevent making the model more complex
  */
  const standardCallback = action.skillIDs?.length
    ? () => curriculumModels.getIndividualSkills(action.skillIDs)
    : async () => ({
        response: {
          standards: []
        },
        error: null
      })

  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.skills.readIndividualSkills,
    standardCallback,
    engineOptions
  )
}

export function* fetchUnitsOfCurriculum(action) {
  const loaded = yield select(fromState.unitsLoadedForCourseID, action.courseID)

  if (!action.forceFetch && loaded) {
    return
  }

  yield put(
    curriculumActions.units.read.initialize(
      action.curriculumID,
      action.courseID
    )
  )
  const { response, error } = yield call(() =>
    curriculumModels.getUnitsFromCurriculumID(action.curriculumID)
  )
  if (error) {
    const errorArgs = [action.curriculumID, action.courseID]
    yield call(
      helperSagas.sagaErrorHandling,
      curriculumActions.units.read.error,
      errorArgs,
      error,
      'Error Fetching Curriculum Tree:'
    )
    return
  }

  // response for UNITS reducer
  const transformedUnits = curriculumTransform.getUnitsFromCurriculumTree(
    response
  )
  yield put(
    curriculumActions.units.read.success(action.courseID, transformedUnits, [])
  )
}

export function* fetchUnitsOfCourse(action) {
  let course = yield select(
    fromState.getResourceByID,
    resources.COURSES,
    action.courseID
  )
  if (!course) {
    yield call(fetchCourses, {
      schoolSubjectGroupID: action.subjectGroupID
    })
    course = yield select(
      fromState.getResourceByID,
      resources.COURSES,
      action.courseID
    )
  }
  yield call(fetchUnitsOfCurriculum, {
    curriculumID: course.get('curriculumID'),
    courseID: course.get('id')
  })
}

export function* fetchSkillGroups() {
  const loaded = yield select(fromState.resourcesLoaded, [
    resources.SKILL_GROUPS
  ])
  const engineOptions = {
    transformer: curriculumTransform.transformSkillGroups,
    needToFetch: !loaded
  }

  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.skillGroups.read,
    curriculumModels.getSkillGroups,
    engineOptions
  )
}

export function* updateCourse(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.courseID],
    successArgs: [action.courseID, action.courseName, action.gradeLevelIDs],
    errorArgs: [action.courseID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.update,
    () =>
      curriculumModels.updateCourse(
        action.courseID,
        action.courseName,
        action.gradeLevelIDs
      ),
    engineOptions
  )
}

export function* shareCurriculumWithUser(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.courseID, action.userID, action.role],
    successArgs: [action.courseID, action.userID, action.role],
    errorArgs: [action.courseID, action.userID, action.role]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.share,
    () =>
      curriculumModels.shareCurriculumWithUser(
        action.curriculumID,
        action.userID,
        action.role
      ),
    engineOptions
  )
}

export function* unshareCurriculumWithUser(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.courseID, action.userID],
    successArgs: [action.courseID, action.userID],
    errorArgs: [action.courseID, action.userID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.unshare,
    () =>
      curriculumModels.unshareCurriculumWithUser(
        action.curriculumID,
        action.userID
      ),
    engineOptions
  )
}

export function* deleteCourse(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.courseID],
    successArgs: [action.courseID],
    errorArgs: [action.courseID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.delete,
    () => curriculumModels.deleteCourse(action.courseID),
    engineOptions
  )
}

export function* undeleteCourse(action) {
  const engineOptions = {
    needToFetch: true,
    initializeArgs: [action.courseID],
    successArgs: [action.courseID],
    errorArgs: [action.courseID]
  }
  yield call(
    helperSagas.fetchResourceEngine,
    curriculumActions.courses.undelete,
    () => curriculumModels.undeleteCourse(action.courseID),
    engineOptions
  )
}

// fetch schools and subject groups for free trial schools
export function* fetchTrialSchoolSubjects(action) {
  yield call(coreSagas.fetchSchools, action)
  const freeTrialSchools = yield select(fromState.getFreeTrialSchoolIDs)
  const tasks = Object.values(freeTrialSchools).map((schoolID) =>
    call(fetchSchoolSubjectsAndCourses, { schoolID })
  )
  yield all(tasks)
}
