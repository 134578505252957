import React from 'react'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import { ROLE } from '../../helpers/constants'

import CloseIcon from '../../images/icons/close.svg'
import UserFileUpload from './userFileUpload'
import UserFileUploadProgress from './userFileUploadProgress'
import UserFileUploadFailure from './userFileUploadFailure'

import './bulkUserUpload.scss'
import { boldText, joinLabels } from '../../helpers/text_util'

const addStaffLabel = i18n.t('titles.addStaff')
const addStudentsLabel = i18n.t('titles.addStudents')
const closeLabel = i18n.t('labels.close')
const staffAccountsExplainText = i18n.t('passages.staffAccountsExplain')
const studentAccountsExplainText1 = i18n.t('passages.studentAccountsExplain_1')
const studentAccountsExplainText2 = i18n.t('passages.studentAccountsExplain_2')
const studentAccountsExplainText3 = i18n.t('passages.studentAccountsExplain_3')
const studentAccountsExplainText4 = i18n.t('passages.studentAccountsExplain_4')
const studentAccountsExplainText5 = i18n.t('passages.studentAccountsExplain_5')

const labelsByRole = {
  [ROLE.TEACHER]: {
    addUsers: [addStaffLabel],
    explainText: [staffAccountsExplainText]
  },
  [ROLE.STUDENT]: {
    addUsers: [addStudentsLabel],
    explainText: [
      studentAccountsExplainText1,
      boldText(studentAccountsExplainText2, 'accounts-explain-text-2'),
      studentAccountsExplainText3,
      boldText(studentAccountsExplainText4, 'accounts-explain-text-4'),
      studentAccountsExplainText5
    ]
  }
}

const BulkUserUploadPresenter = (props) => {
  const {
    didUploadFail,
    isImportingFile,
    parseImportFile,
    role,
    schoolID,
    selectedFile,
    handleClose,
    isInline
  } = props
  const labels = labelsByRole[role]
  const addUsersLabel = joinLabels(labels.addUsers)
  const explainTextLabel = joinLabels(labels.explainText)
  const className = classnames('bulk-user-upload', {
    inline: isInline
  })
  return (
    <div className={className}>
      <div className="header">
        <h3>{addUsersLabel}</h3>
        <button
          onClick={handleClose}
          className="close-button"
          data-spec="close-button"
          title={closeLabel}
        >
          <CloseIcon title={closeLabel} />
        </button>
      </div>
      {!isImportingFile && (
        <div className="content">
          <p className="accounts-explain-text">{explainTextLabel}</p>
          <UserFileUpload
            schoolID={schoolID}
            role={role}
            parseImportFile={parseImportFile}
          />
        </div>
      )}
      {isImportingFile && (
        <UserFileUploadProgress selectedFile={selectedFile} />
      )}
      {!isImportingFile && didUploadFail && (
        <UserFileUploadFailure
          selectedFile={selectedFile}
          didUploadFail={didUploadFail}
        />
      )}
    </div>
  )
}

export default BulkUserUploadPresenter
