import React from 'react'

import { DEFAULT_RETRY_DELAY } from '../../helpers/constants'

// HOC to add retrying to a container component
// (param) WrappedComponent: container component
// (returns) 1 retry-enhanced component
// Props:
// - modelID
// - error, from store, did the response error?
// - clearErrors, an action, clears error
// - ...others will be passed onto the WrappedComponent
const withRetry = (WrappedComponent) => {
  return class WithRetryWithWrapped extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        retryCountdown: undefined
      }

      this.retryTimerID = null
      this.isAutoRetryAvailable = true
      this.componentRef = React.createRef()

      this.clearRetryCountdown = this.clearRetryCountdown.bind(this)
      this.countdownAndRetry = this.countdownAndRetry.bind(this)
    }

    componentDidUpdate(prevProps) {
      if (prevProps.modelID !== this.props.modelID) {
        // Component is being reused for another unit so we need to completely
        // reset it.
        this.prepareForReuse(prevProps.modelID, this.props.modelID)

        return
      }

      if (!prevProps.error && this.props.error && this.props.shouldRetry) {
        this.handleSaveFailed()
      }
    }

    componentWillUnmount() {
      // pass ID thing to clear
      this.prepareForReuse(this.props.modelID)
    }

    prepareForReuse(...args) {
      this.clearRetryCountdown()
      this.isAutoRetryAvailable = true

      this.props.clearErrors(args)
    }

    countdownAndRetry() {
      const newCountdownValue = this.state.retryCountdown - 1
      if (newCountdownValue > 0) {
        this.setState({
          retryCountdown: newCountdownValue
        })
        return
      }

      // When the timer reaches 0, retry!
      this.componentRef.current.saveModel()
    }

    handleSaveFailed() {
      if (!this.isAutoRetryAvailable) {
        // We only auto-retry once, so do nothing here.
        return
      }

      // Set auto retry to false so subsequent failures don't result in a fresh timer.
      this.isAutoRetryAvailable = false

      this.setState(
        {
          retryCountdown: DEFAULT_RETRY_DELAY
        },
        () => {
          this.retryTimerID = setInterval(this.countdownAndRetry, 1000)
        }
      )
    }

    clearRetryCountdown() {
      if (this.retryTimerID) {
        clearTimeout(this.retryTimerID)
        this.retryTimerID = null

        this.setState({
          retryCountdown: null
        })
      }
    }

    render() {
      const {
        clearErrors: _clearErrors,
        modelID: _modelID,
        ...otherProps
      } = this.props
      return (
        <WrappedComponent
          retryCountdown={this.state.retryCountdown}
          clearRetryCountdown={this.clearRetryCountdown}
          ref={this.componentRef}
          {...otherProps}
        />
      )
    }
  }
}

export default withRetry
