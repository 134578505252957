import React from 'react'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'

import { ROLE } from '../../../helpers/constants'

import CheckGlyph from '../../../images/glyphs/checkmark-glyph.svg'
import TextInput from '../../common/inputs/textInput'

import './editableList.scss'

const emailLabel = i18n.t('labels.email')
const errorLabel = i18n.t('labels.error')
const nameLabel = i18n.t('labels.name')

const shouldHideBlankEmailError = (item, role) =>
  role === ROLE.STUDENT && item.email.value.length === 0

const renderListItems = (props) => {
  const { items, changeName, changeEmail, role } = props
  const rows = []
  rows.push(
    <div className="row row-header" key={`user-row-header`}>
      <span />
      <span>{nameLabel}</span>
      <span>{emailLabel}</span>
      <span>{errorLabel}</span>
    </div>
  )
  items.forEach((item, index) => {
    const correctedClass = classnames('corrected', {
      hidden: item.email.error || item.name.error
    })
    const errorText = item.name.error || item.email.error
    rows.push(
      <div className="row" key={`user-row-${index}`}>
        <span className={correctedClass} data-spec="corrected-check">
          <CheckGlyph />
        </span>
        <TextInput
          className="user-name"
          data-spec="name-input"
          value={item.name.value}
          underline
          onChange={(e) => {
            changeName(index, e.currentTarget.value)
          }}
          error={item.name.error}
        />
        <TextInput
          className="user-email"
          data-spec="email-input"
          value={item.email.value}
          underline
          onChange={(e) => {
            changeEmail(index, e.currentTarget.value)
          }}
          error={
            (shouldHideBlankEmailError(item, role) && '') || item.email.error
          }
        />
        <p className="error-text" data-spec="error-text">
          {!!errorText && i18n.t(`errors.${errorText}`)}
        </p>
      </div>
    )
  })
  return rows
}

const EditableList = (props) => (
  <div className="editable list-view">{renderListItems(props)}</div>
)

export default EditableList
