import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Set as ImmutableSet } from 'immutable'

import { intersect, updateItemInSet } from '../../../helpers/set'
import i18n from '../../../helpers/i18n'
import { havePropsChanged } from '../../../helpers/utils'
import { SUPPORT_EMAIL } from '../../../helpers/constants'
import Button from '../../ui/buttons/button'
import Loading from '../../kiddom/loading/loading'

import * as coreActions from '../../../store/actions/coreActions'
import * as curriculumActions from '../../../store/actions/curriculumActions'
import {
  aggregateClassData,
  coursesLoaded,
  getResource,
  getResourceByID,
  resourcesLoaded,
  subjectGroupsLoadedForSchool,
  getTeachersBySchoolID
} from '../../../store/fromState'
import * as resources from '../../../store/models/resources'

import InviteTeachersPresenter from './inviteTeachersPresenter'
import withDataLoading from '../../hoc/withDataLoading'
import withRoleCheckFor from '../../hoc/withRoleCheckFor'
import withToJS from '../../hoc/withToJS'
import gradeLevelFilter from '../../ui/search/filters/gradeLevelFilter'
import subjectSelectFilter from '../../ui/search/filters/subject/subjectFilter'

const WrappedInviteTeachersPresenter = withToJS(InviteTeachersPresenter)
WrappedInviteTeachersPresenter.displayName = 'WrappedInviteTeachersPresenter'

const BetterTogether =
  '../../../../../../../../images/illustrations/better-together.png'

const betterTogetherMessage = i18n.t('passages.betterTogether')
const contactUs = i18n.t('userActions.contactUs')
const contactUsSubject = i18n.t('passages.contactUsSubject')
const contactUsBody = i18n.t('passages.contactUsBody')

const contactUsMailTo = encodeURI(
  `mailto:${SUPPORT_EMAIL}?subject=${contactUsSubject}&body=${contactUsBody}`
)

const initialState = {
  gradeIDs: new Set(),
  subjectID: null
}

class InviteTeachersContainer extends React.Component {
  constructor(props) {
    super(props)

    this.toggleGradeLevel = this.toggleGradeLevel.bind(this)
    this.setSubject = this.setSubject.bind(this)

    this.state = initialState
  }

  componentDidUpdate(prevProps) {
    let shouldResetState = false

    // Data went from being unloaded to loaded.
    if (!prevProps.hasLoaded && this.props.hasLoaded) {
      shouldResetState = true
    } else {
      shouldResetState = havePropsChanged(prevProps, this.props, [
        'courseID',
        'schoolID',
        'subjectGroupID'
      ])
    }

    if (shouldResetState) {
      this.setState(initialState)
    }
  }

  toggleGradeLevel(gradeLevel) {
    const newGradeIDs = updateItemInSet(this.state.gradeIDs, gradeLevel)
    this.setState({
      gradeIDs: newGradeIDs
    })
  }

  setSubject(subjectID) {
    this.setState({ subjectID })
  }

  getTeachersMatchingClasses() {
    const { allClasses, allSubjects } = this.props
    const { gradeIDs, subjectID } = this.state

    const matchingClasses = allClasses.filter((clazz) => {
      if (gradeIDs.size) {
        const classGrades = new Set(clazz.get('gradeIDs').toSeq())
        // Ensure that at least one grade matches.
        if (intersect(gradeIDs, classGrades).size === 0) {
          return false
        }
      }

      // Filter by subject if one is specified.
      if (subjectID) {
        const classSubjectIDs = new Set()
        clazz.get('subjectIDs').forEach((subjectID) => {
          const subjectObj = allSubjects.get(subjectID)
          const parentID = subjectObj.get('parentID')

          classSubjectIDs.add(subjectObj.get('id'))
          if (parentID) {
            classSubjectIDs.add(parentID)
          }
        })

        // Ensure that at least one subject matches.
        if (!classSubjectIDs.has(subjectID)) {
          return false
        }
      }

      return true
    })
    // Get the teachers from the matching classes.
    return matchingClasses.reduce((teachers, clazz) => {
      const teacher = clazz.get('teacher')
      return teacher ? teachers.add(teacher) : teachers
    }, ImmutableSet())
  }

  isEmptyFilters = () => {
    return this.state.gradeIDs.size === 0 && this.state.subjectID === null
  }

  render() {
    if (!this.props.hasLoaded) {
      return <Loading type="academy" />
    }
    if (this.props.noTeachers) {
      return (
        <div className="better-together">
          <img src={BetterTogether} alt={betterTogetherMessage} />
          <h4>{betterTogetherMessage}</h4>
          <a href={contactUsMailTo}>
            <Button label={contactUs} />
          </a>
        </div>
      )
    }

    let teachers
    let filters
    if (this.props.isSchoolAdmin || this.props.isDistrictAdmin) {
      teachers = this.isEmptyFilters()
        ? this.props.allTeachers
        : this.getTeachersMatchingClasses()
      filters = [
        gradeLevelFilter(new Set(this.state.gradeIDs), this.toggleGradeLevel),
        subjectSelectFilter(
          this.props.allSubjects.get(this.state.subjectID),
          this.setSubject
        )
      ]
    } else {
      teachers = this.props.allTeachers
    }

    return (
      <WrappedInviteTeachersPresenter
        course={this.props.course}
        items={teachers}
        filters={filters}
        onShareWithTeacher={this.props.onShareWithTeacher}
        onUnshareWithTeacher={this.props.onUnshareWithTeacher}
      />
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const { schoolID } = ownProps
  if (!ownProps.hasLoaded) {
    return { hasLoaded: false }
  }

  const course = getResourceByID(state, resources.COURSES, ownProps.courseID)

  const subjectGroup = getResourceByID(
    state,
    resources.SCHOOL_SUBJECT_GROUPS,
    course.get('schoolSubjectGroupID')
  )

  const classes = aggregateClassData(state, schoolID)
  const teachers = getTeachersBySchoolID(state, schoolID)
  const noTeachers = teachers && !teachers.size

  return {
    hasLoaded: true,
    course,
    subjectGroup,
    allSubjects: getResource(state, resources.SUBJECTS),
    allClasses: classes,
    allTeachers: teachers,
    noTeachers
  }
}

const mapDispatchToProps = (dispatch) => ({
  onShareWithTeacher: (curriculumID, courseID, userID, role) =>
    dispatch(
      curriculumActions.courses.share.begin(
        curriculumID,
        courseID,
        userID,
        role
      )
    ),
  onUnshareWithTeacher: (curriculumID, courseID, userID) =>
    dispatch(
      curriculumActions.courses.unshare.begin(curriculumID, courseID, userID)
    )
})

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onShareWithTeacher: (userID, role) =>
    dispatchProps.onShareWithTeacher(
      stateProps.course.get('curriculumID'),
      ownProps.courseID,
      userID,
      role
    ),
  onUnshareWithTeacher: (userID) =>
    dispatchProps.onUnshareWithTeacher(
      stateProps.course.get('curriculumID'),
      ownProps.courseID,
      userID
    ),
  ...stateProps,
  ...ownProps
})

const generateDataLoaders = (props) => {
  const loaders = [
    coreActions.subjects.read.begin(),
    coreActions.teachers.read.begin(props.schoolID),
    curriculumActions.courses.read.begin(props.subjectGroupID),
    curriculumActions.schoolSubjectGroups.read.begin(props.schoolID)
  ]
  if (props.isSchoolAdmin || props.isDistrictAdmin) {
    loaders.push(coreActions.classes.read.begin(props.schoolID))
  }
  return loaders
}

const generateHasLoadedCheckers = (props) => {
  const resourcesList = [resources.TEACHERS, resources.SUBJECTS]
  if (props.isSchoolAdmin || props.isDistrictAdmin) {
    resourcesList.push(resources.CLASSES)
  }
  return {
    hasLoaded: [
      [resourcesLoaded, [resourcesList, props.schoolID]],
      [subjectGroupsLoadedForSchool, [props.schoolID]],
      [coursesLoaded, [props.subjectGroupID]]
    ]
  }
}

const reloadProps = ['schoolID', 'subjectGroupID', 'courseID']

export default withRoleCheckFor(
  withDataLoading(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(InviteTeachersContainer),
    generateDataLoaders,
    generateHasLoadedCheckers,
    reloadProps
  ),
  [], // will set user roles as component props
  true // force render
)

InviteTeachersContainer.propTypes = {
  courseID: PropTypes.string.isRequired,
  schoolID: PropTypes.string.isRequired,
  subjectGroupID: PropTypes.string.isRequired
}
