import React from 'react'
import { connect } from 'react-redux'

import SubjectGroupCard from './subjectGroupCard'
import { withRoleCheckFor } from '../hoc/withRoleCheckFor'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'

import { numCoursesInSubjectGroup } from '../../helpers/subjectGroups'

import { getSubjectGroupsByID, resourcesLoaded } from '../../store/fromState'

import * as coreActions from '../../store/actions/coreActions'
import * as resources from '../../store/models/resources'

const WrappedPresenter = withToJS(SubjectGroupCard)
const SubjectGroupCardContainer = (props) => {
  const presenterProps = {
    ...props
  }
  return <WrappedPresenter {...presenterProps} />
}

export const mapStateToProps = (state, ownProps) => {
  const subjectGroup = getSubjectGroupsByID(state, ownProps.subjectGroupID)
  const courseCount = numCoursesInSubjectGroup(subjectGroup)

  return {
    subjectGroup,
    courseCount
  }
}

const generateDataLoaders = (_props) => [coreActions.subjects.read.begin()]

const generateHasLoadedCheckers = (_props) => ({
  hasLoaded: [[resourcesLoaded, [[resources.SUBJECTS]]]]
})

const reloadProps = ['subjectGroupID']

export default withRoleCheckFor(
  withDataLoading(
    connect(mapStateToProps, null)(SubjectGroupCardContainer),
    generateDataLoaders,
    generateHasLoadedCheckers,
    reloadProps
  ),
  [], // adds all the user roles
  true // force render
)
