import React from 'react'
import i18n from '../../../helpers/i18n'

import InviteTeachersContainer from './inviteTeachersContainer'
import Modal from '../../ui/modal/modal'
import ShareIcon from '../../../images/icons/share.svg'
import CollaboratorListContainer from './collaboratorListContainer'

import './curriculumSharing.scss'

const CurriculumSharingPresenter = (props) => {
  const teachersList = (
    <CollaboratorListContainer
      schoolID={props.schoolID}
      subjectGroupID={props.subjectGroupID}
      courseID={props.courseID}
    />
  )

  const inviteTeachersAction = i18n.t('userActions.inviteTeachers')
  const inviteTeachersModalTrigger = (
    <button className="add-teacher" title={inviteTeachersAction}>
      <ShareIcon className="share-icon" />
    </button>
  )

  const shareModal = (
    <Modal
      title={inviteTeachersAction}
      triggerElement={inviteTeachersModalTrigger}
    >
      <InviteTeachersContainer
        courseID={props.courseID}
        schoolID={props.schoolID}
        subjectGroupID={props.subjectGroupID}
      />
    </Modal>
  )

  return (
    <div className="curriculum-sharing">
      <div className="header">
        <h3>{i18n.t('titles.teachers')}</h3>
        {shareModal}
      </div>
      {teachersList}
    </div>
  )
}

export default CurriculumSharingPresenter
