import { authSendRequest } from '../../helpers/sendRequest'

export const parseImportFile = (file, schoolID, role) => {
  const formData = new FormData()
  formData.append('file', file[0])

  return authSendRequest(
    'POST',
    `${window.API_SERVER}/v1/ingestion/users/parse?school_id=${schoolID}&role=${role}`,
    formData,
    {},
    {},
    (body) => body
  )
}

export const addUsers = (users, schoolID, ingestionID, role) => {
  const userInfo = users.map((user) => ({
    name: user.name.value,
    email: user.email.value,
    school_id: parseInt(schoolID, 10)
  }))
  const request = {
    ingestion_id: parseInt(ingestionID, 10),
    [`${role}s`]: userInfo
  }
  return authSendRequest(
    'POST',
    `${window.API_SERVER}/api/school/${schoolID}/${role}s`,
    request
  )
}

export const sendIngestionInviteEmail = (ingestionID, schoolID) =>
  authSendRequest(
    'POST',
    `${window.API_SERVER}/v1/ingestion/${ingestionID}/users/email?school_id=${schoolID}`
  )

export const getStudentAccounts = (ingestionID, schoolID) =>
  authSendRequest(
    'GET',
    `${window.API_SERVER}/v1/ingestion/${ingestionID}/students?school_id=${schoolID}`
  )

export const sendInviteEmails = (schoolID, userIDs) => {
  return authSendRequest(
    'POST',
    `${window.API_SERVER}/v1/school/${schoolID}/users/email`,
    { user_ids: userIDs.map((id) => parseInt(id, 10)) }
  )
}

export const getStudentAccountsByID = (schoolID, studentIDs) => {
  const ids = studentIDs.map((id) => parseInt(id, 10))
  return authSendRequest(
    'POST',
    `${window.API_SERVER}/api/schools/${schoolID}/students/accounts`,
    ids
  )
}
