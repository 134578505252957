import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../../helpers/i18n'
import classnames from 'classnames'

import './noChartDataMessage.scss'

const NoChartDataMessage = (props) => {
  const noDataClassNames = classnames('no-data', {
    active: props.active
  })
  const noDataLabel = i18n.t('titles.noDataReceived')
  return (
    <div className={noDataClassNames} data-spec="no-chart-data-message">
      <div className="backdrop">
        <div className="content-box">
          <p>{noDataLabel}</p>
        </div>
      </div>
    </div>
  )
}

NoChartDataMessage.defaultProps = {
  active: false
}

NoChartDataMessage.propTypes = {
  active: PropTypes.bool
}

export default NoChartDataMessage
