import Chart from 'chart.js'

export const doughnutCenterText = {
  afterUpdate(chart) {
    if (chart.config.options.elements.center) {
      const helpers = Chart.helpers
      const centerConfig = chart.config.options.elements.center
      const ctx = chart.chart.ctx

      const fontStyle = 'bold'
      const fontFamily = "'Istok Web', 'Arial', sans-serif"

      ctx.save()
      let fontSize = 0
      let textWidth
      const maxFontSize = centerConfig.maxFontSize || 24
      const maxText = centerConfig.maxText || '100%'

      do {
        fontSize += 1
        ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily)
        textWidth = ctx.measureText(maxText).width
      } while (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize)

      // Undo last step (the one that made conditions falsy)
      fontSize -= 1

      ctx.restore()

      // save properties
      chart.center = {
        font: helpers.fontString(fontSize, fontStyle, fontFamily),
        fillStyle: centerConfig.fontColor
      }
    }
  },
  afterDraw(chart) {
    if (chart.center) {
      const centerConfig = chart.config.options.elements.center
      const ctx = chart.chart.ctx

      ctx.save()
      ctx.font = chart.center.font
      ctx.fillStyle = chart.center.fillStyle
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2
      const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
      ctx.fillText(centerConfig.text, centerX, centerY)
      ctx.restore()
    }
  }
}
