/* Reducers specific to Admin */
import Immutable from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import * as resources from '../models/resources'
import * as reducerCreators from './reducerCreators'

/* Initial Store State for AdminStore */
import { contentUsageMock } from '../mockData/schoolOverview'

export const chartDataInitialState = Immutable.fromJS({
  teacher: {
    activity: { year: {}, term: {}, month: {}, week: {} }
  },
  student: {
    achievement: {
      overall: { year: {}, term: {}, month: {}, week: {} }
    }
  },
  contentUsage: {
    overall: contentUsageMock
  }
})

export const initialState = Immutable.fromJS({
  chartData: {
    bySchoolID: {}
  },
  teacherReports: {
    bySchoolID: {},
    fetching: false
  }
})

const getTeacherEngagementSuccess = (state, action) => {
  let newState = verifyAdminInitialState(state, action.schoolID)
  const objectKeys = Object.keys(action.response)
  objectKeys.forEach((key) => {
    const response = action.response[key][0]
    const { start, end } = response.timeFrame
    const timeFrame = `${start},${end}`
    newState = newState.setIn(
      [
        'chartData',
        'bySchoolID',
        action.schoolID,
        'teacher',
        'activity',
        key,
        timeFrame
      ],
      Immutable.fromJS(response)
    )
  })
  return newState
}

// verifyAdminInitialState
// This function verifies there is an itinitalState for a schoolID. If there is
// not, this function will create it. Any other reducers storing chart Data
// should use this function first.
function verifyAdminInitialState(state, schoolID) {
  const newState = state
  const schoolPath = ['chartData', 'bySchoolID', schoolID]
  const adminBySchoolID = state.getIn(schoolPath)
  if (!adminBySchoolID) {
    return newState.setIn(schoolPath, chartDataInitialState)
  }
  return newState
}

const getStudentAchievementSuccess = (state, action) => {
  let newState = verifyAdminInitialState(state, action.schoolID)
  // chart data
  const objectKeys = Object.keys(action.chartData)
  objectKeys.forEach((key) => {
    const chartData = action.chartData[key][0]
    const { start, end } = chartData.timeFrame
    const timeFrame = `${start},${end}`
    newState = newState.setIn(
      [
        'chartData',
        'bySchoolID',
        action.schoolID,
        'student',
        'achievement',
        'overall',
        key,
        timeFrame
      ],
      Immutable.fromJS(chartData)
    )
  })

  return newState
}

const getTeacherReportsInitialize = (state) => {
  return state.setIn(['teacherReports', 'fetching'], true)
}

const getTeacherReportsSuccess = (state, action) => {
  let newState = state
  newState = newState.setIn(['teacherReports', 'fetching'], false)
  action.response.forEach((report) => {
    Object.keys(report.values).forEach((teacherID) => {
      newState = newState.setIn(
        [
          'teacherReports',
          'bySchoolID',
          action.schoolID,
          teacherID,
          'report',
          report.name
        ],
        Immutable.fromJS(report.values[teacherID])
      )
    })
  })
  return newState
}

const teacherEngagementHandlers = reducerCreators.getHandlers(
  resources.TEACHER_ENGAGEMENT,
  { [actionIDs.teacherEngagement.read.success]: getTeacherEngagementSuccess }
)
const studentAchievementHandlers = reducerCreators.getHandlers(
  resources.STUDENT_ACHIEVEMENT,
  { [actionIDs.studentAchievement.read.success]: getStudentAchievementSuccess }
)

const teacherReportsHandler = reducerCreators.getHandlers(
  resources.TEACHER_REPORTS,
  {
    [actionIDs.teacherReports.read.success]: getTeacherReportsSuccess,
    [actionIDs.teacherReports.read.initialize]: getTeacherReportsInitialize
  }
)

export const handlers = {
  ...teacherEngagementHandlers,
  ...studentAchievementHandlers,
  ...teacherReportsHandler
}

const admin = reducerCreators.reducerCreator(initialState, handlers)
export default admin
