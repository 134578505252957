import React from 'react'
import './loading.scss'

export enum LoadingType {
  DEFAULT = 'default',
  ACADEMY = 'academy',
  CLASSROOM = 'classroom',
  STUDENT = 'student',
  LIBRARY = 'library'
}

export interface ILoadingProps {
  type?: LoadingType
}

export default function Loading({ type = LoadingType.DEFAULT }: ILoadingProps) {
  const classname = `loading-icon ${type}`

  return (
    <div className="loading-container">
      <svg viewBox="0 0 75 75" className={classname}>
        <circle className="circle-bg" cx="37.5" cy="37.5" r="37.5" />
        <path
          className="gear gear-1"
          d="M37.10577,23.88293,33.6623,22.728a10.44787,10.44787,0,0,0-1.00657-2.30976l1.50458-3.30573-2.76542-2.68064L28.14219,16.053A11.33791,11.33791,0,0,0,25.79,15.1206l-1.27145-3.39051-3.85663.06357L19.507,15.23713a10.97243,10.97243,0,0,0-2.30976,1.00657l-3.30573-1.50451-2.68064,2.776L12.832,20.76791a11.018,11.018,0,0,0-.93239,2.34152L8.50908,24.39148l.06357,3.8461,3.44347,1.15485a10.44963,10.44963,0,0,0,1.00657,2.30983L11.51817,35.008l2.776,2.68057,3.24215-1.6105a11.30515,11.30515,0,0,0,2.35213.92185l1.27144,3.401,3.85671-.07417,1.15485-3.43287a11.54466,11.54466,0,0,0,2.30976-1.00656l3.30579,1.494L34.46755,34.616,32.8465,31.3632a11.33385,11.33385,0,0,0,.93233-2.35219l3.32694-1.25023h.0106l.06357-.02121v-.02115ZM22.84454,34.012A7.94647,7.94647,0,1,1,30.791,26.06556,7.9426,7.9426,0,0,1,22.84454,34.012Z"
        />
        <path
          className="gear gear-3"
          d="M59.091,51.49426a11.653,11.653,0,0,0-.67812-2.42636l1.94957-3.062L57.98911,42.965l-3.44347,1.14431a11.53113,11.53113,0,0,0-2.19323-1.23964l-.79465-3.53885-3.82488-.47674-1.62111,3.2527a11.72431,11.72431,0,0,0-2.43691.66751l-3.062-1.93889-3.03024,2.37333,1.14431,3.44347a11.52373,11.52373,0,0,0-1.2397,2.19324l-3.54939.79464-.4662,3.82489,3.25276,1.621a10.85448,10.85448,0,0,0,.66751,2.42637l-1.94956,3.062,2.37333,3.04085L41.2591,62.471a11.29648,11.29648,0,0,0,2.20384,1.23963l.784,3.53885,3.825.4768,1.621-3.25276a11.09947,11.09947,0,0,0,2.4369-.66751l3.06206,1.93889,3.04084-2.37333-1.15485-3.44347a10.69287,10.69287,0,0,0,1.23963-2.19323l3.5494-.784.4662-3.82489ZM47.90237,59.65258a6.35718,6.35718,0,1,1,6.35718-6.35717A6.36217,6.36217,0,0,1,47.90237,59.65258Z"
        />
        <path
          className="gear gear-2"
          d="M61.78224,24.99542,64.62178,22.728l-1.26085-3.63417-3.62363-.04236a11.39933,11.39933,0,0,0-1.674-1.886l.40262-3.613-3.46468-1.68468L52.416,14.41073a11.13963,11.13963,0,0,0-2.52169-.15895l-2.26741-2.83953-3.63417,1.26084-.04236,3.62357a11.39928,11.39928,0,0,0-1.886,1.67408l-3.613-.40263-1.68468,3.46468,2.5429,2.58526a11.13849,11.13849,0,0,0-.159,2.52168l-1.97071,1.57869-.06357.04236h-.0106l-.79465.64629L37.572,32.04125l3.62357.04242a11.39845,11.39845,0,0,0,1.67408,1.88593l-.40263,3.613,3.46468,1.68462,2.58526-2.54283a11.13926,11.13926,0,0,0,2.52168.15889L53.306,39.72284,56.94019,38.462l.04236-3.62357a11.902,11.902,0,0,0,1.886-1.67408l3.613.40263,1.68468-3.46462-2.5429-2.58525A11.139,11.139,0,0,0,61.78224,24.99542Zm-11.31582,5.34a4.76789,4.76789,0,1,1,4.76788-4.76789A4.76825,4.76825,0,0,1,50.46642,30.33543Z"
        />
        <path
          className="cls-4"
          d="M22.73284,23.45408V51.5459A2.4541,2.4541,0,0,0,25.18694,54h14.0459V37.5L51.54309,25.18975A2.4543,2.4543,0,0,0,49.80764,21H25.18692A2.45408,2.45408,0,0,0,22.73284,23.45408Z"
        />
        <path
          className="cls-5"
          d="M39.23284,37.5,51.54346,49.81062A2.45408,2.45408,0,0,1,49.80816,54H28.658a2.4543,2.4543,0,0,1-1.73545-4.18975Z"
        />
      </svg>
    </div>
  )
}
