import React from 'react'

import Filters from '../ui/search/filters'
import Search from '../ui/search/search'
import * as utils from '../../helpers/utils'

// HOC to add Searching to a list of items!
// (param) WrappedComponent: component to search on
// (returns) 1 searchably enhanced component
// Props:
// - searchOn: value to search on (like a name, or email or w/e)
// - items: list of items to search on
// - filters: list of objects that look like { label: '1st, 2nd', FilterComponent: function to run on edit }
// - ...others will be passed onto the WrappedComponent
// will pass on items to listItems in the WrappedComponent
// Will add the Search bar above it.

const withSearch = (WrappedComponent) => {
  return class WithSearchWithWrapped extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        searchTerm: '',
        filteredItems: props.items
      }
      this.searched = this.searched.bind(this)
      this.localSearch = this.localSearch.bind(this)
    }

    // Perform a filter and sort alphabetically every time data comes back
    componentDidUpdate(prevState) {
      if (prevState.items !== this.props.items) {
        return this.localSearch(this.state.query, this.props.items)
      }
    }

    localSearch(query, items) {
      let searchTerm = this.state.searchTerm
      if (query || query === '') {
        searchTerm = query
      }
      searchTerm = searchTerm.trim()
      let filteredItems = items || []
      const searchOn = this.props.searchOn
      const lowerCaseSearch = searchTerm.toLowerCase()
      filteredItems = filteredItems.filter((item) => {
        return searchOn.some((f) => {
          const text = item[f]
          if (!text) {
            return false
          }
          const lowerCaseName = text.toLowerCase()
          return lowerCaseName.search(lowerCaseSearch) > -1
        })
      })
      filteredItems = filteredItems.sort(utils.sortObjectsByField(searchOn))
      this.setState({ filteredItems, searchTerm: searchTerm })
    }

    // Request a new search
    searched = utils.debounce((searchTerm) => {
      // track if user has performed their first search
      if (this.props.trackSearch) {
        this.props.trackSearch(searchTerm)
      }
      const isSpaces = searchTerm.replace(/ /gi, '') === ''
      this.setState({ searchTerm })
      if (this.props.search && (!isSpaces || searchTerm.length === 0)) {
        return this.props.search(searchTerm)
      }
      return this.localSearch(searchTerm, this.props.items)
    }, 250)

    render() {
      const { filters, placeholder, filtersState, ...otherProps } = this.props
      return (
        <div>
          <Search searched={this.searched} placeholder={placeholder} />
          <Filters filters={filters} filtersState={filtersState} />
          <WrappedComponent items={this.state.filteredItems} {...otherProps} />
        </div>
      )
    }
  }
}

export default withSearch
