import React from 'react'

import Popup from '../popup/popup'
import Triangle from '../triangle/triangle'

import './dropDown.scss'

class DropDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIndex: props.defaultIndex || 0
    }
  }

  clickOption(index) {
    if (this.state.currentIndex !== index) {
      this.setState({ currentIndex: index })
      if (this.props.onChange) {
        this.props.onChange(index)
      }
    }
  }

  renderOptions() {
    return this.props.options.map((option, index) => {
      const selectedClass = index === this.state.currentIndex ? 'selected' : ''
      return (
        <li className={selectedClass} key={`${option.text + index}`}>
          <button
            onClick={() => {
              this.clickOption(index)
            }}
          >
            {option.text}
          </button>
        </li>
      )
    })
  }

  render() {
    const currentOption = this.props.options[this.state.currentIndex]
    const currentText = currentOption && currentOption.text
    const dropdownTrigger = this.props.trigger || (
      <span className="trigger-container">
        <span data-spec="dropdown-selected">{currentText}</span>
        <Triangle width={32} direction={'down'} />
      </span>
    )
    return (
      <div className="drop-down-container">
        <Popup
          position="bottom"
          alignment="end"
          initialState={this.props.initialState}
          triggerElement={dropdownTrigger}
        >
          <ul className="nav-links">{this.renderOptions()}</ul>
        </Popup>
      </div>
    )
  }
}

export default DropDown
