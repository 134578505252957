import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import i18n from '../../helpers/i18n'
import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import { TEACHERS, STUDENTS } from '../../store/models/resources'
import { ROLE } from '../../helpers/constants'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'
import AccountListPresenter from './accountListPresenter'

const WrappedPresenter = withToJS(AccountListPresenter)
const AccountListContainer = (props) => {
  let { usersByID, ...otherProps } = props
  const userIDs = usersByID.keySeq()

  if (props.hasLoaded) {
    userIDs.forEach((id) => {
      usersByID = usersByID.set(
        id,
        addAccountStatus(
          props.role,
          props.activityLists,
          props.usersByID.get(id)
        )
      )
    })
  }
  return <WrappedPresenter usersByID={usersByID} {...otherProps} />
}

const activeLabel = i18n.t('labels.active')
const unactivatedLabel = i18n.t('labels.unactivated')
const inactiveLabel = i18n.t('labels.inactive')

export function addAccountStatus(role, activityLists, user) {
  let accountStatus = activeLabel
  const userID = user.get('id')
  if (activityLists.get('inactive').includes(userID)) {
    accountStatus = inactiveLabel
  }
  if (activityLists.get('unactivated').includes(userID)) {
    accountStatus = unactivatedLabel
  }

  if (role === ROLE.STUDENT) {
    return user.merge({ accountStatus })
  }
  return user.merge({ accountStatus, role: 'Teacher' })
}

const mapStateToProps = (state, ownProps) => {
  const resource = ownProps.role === ROLE.STUDENT ? STUDENTS : TEACHERS
  const activityLists = fromState.getActivityLists(state, resource)
  const usersByID = fromState.getResource(state, resource)
  const pagination = fromState.getStudentsPagination(state)

  return {
    usersByID,
    activityLists,
    pagination
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const fetchStudents = (query = '', offset = 0) =>
    dispatch(
      coreActions.students.read.begin(schoolID, query, offset, ownProps.limit)
    )

  return {
    search: (query = '') => fetchStudents(query),
    fetchStudents,
    updateCurrentPage: (currentPage) =>
      dispatch(coreActions.students.read.updateCurrentPage(currentPage))
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    fetchPage: (pageNumber) =>
      dispatchProps.fetchStudents(stateProps.query, pageNumber),
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

const generateDataLoaders = (props) => {
  if (props.role === ROLE.STUDENT) {
    return [
      coreActions.students.read.begin(
        props.match.params.schoolID,
        '', // search
        0, // page
        50 // limit
      ),
      adminActions.studentActivityLists.read.begin(props.match.params.schoolID)
    ]
  }
  return [
    coreActions.teachers.read.begin(props.match.params.schoolID),
    adminActions.inactiveTeachers.read.begin(props.match.params.schoolID),
    adminActions.unactivatedTeachers.read.begin(props.match.params.schoolID)
  ]
}

const hasLoaded = (state, props) => {
  if (props.role === ROLE.STUDENT) {
    return (
      !fromState.isFetchingStudentActivityLists(state) &&
      !fromState.isFetchingStudents(state)
    )
  }
  const schoolID = props.match.params.schoolID
  const teachersLoaded =
    fromState.getIDsList(state, TEACHERS, schoolID).size ||
    fromState.didFetchSchoolID(state, TEACHERS, schoolID)
  const statusLoaded = fromState.accountStatusLoaded(state, TEACHERS, schoolID)
  return teachersLoaded && statusLoaded
}

const generateLoadingProps = (props) => ({
  hasLoaded: [[hasLoaded, [props]]]
})

const reloadProps = [(props) => props.match.params.schoolID, 'role']

export default withRouter(
  withDataLoading(
    connect(
      mapStateToProps,
      mapDispatchToProps,
      mergeProps
    )(AccountListContainer),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
