import React from 'react'
import { connect } from 'react-redux'

// Kiddom helpers
import * as curriculumActions from '../../store/actions/curriculumActions'
import * as fromState from '../../store/fromState'
import { COURSES } from '../../store/models/resources'
import { maybeRetry } from '../../helpers/utils'
import i18n from '../../helpers/i18n'

// Kiddom Components
import withToJS from '../hoc/withToJS'
import withRetry from '../hoc/withRetry'
import CourseListViewItemPresenter from './courseListViewItemPresenter'

const WrappedPresenter = withToJS(CourseListViewItemPresenter)

export class CourseListViewItemContainer extends React.Component {
  saveModel() {
    const { clearRetryCountdown, deleteCourse } = this.props
    clearRetryCountdown()
    deleteCourse()
  }

  render() {
    return (
      <WrappedPresenter
        {...this.props}
        undoAltText={i18n.t('labels.undoDeleteCourse')}
      />
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const deleteError = fromState.getCourseDeleteError(state, ownProps.courseID)
  const undoError = fromState.getCourseUndeleteError(state, ownProps.courseID)
  const shouldRetry =
    deleteError && deleteError.status ? maybeRetry(deleteError.status) : false
  let course = fromState.getResourceByID(state, COURSES, ownProps.courseID)
  const deletedIDs = fromState.getDeletedCourseIDs(state)
  course =
    course &&
    course.merge({
      isDeleted: deletedIDs.contains(course.get('id'))
    })
  const showUndo = !deleteError && course && course.get('isDeleted')
  const isFetching = fromState.getCourseFormFetchingAtID(state, ownProps.formID)
  return {
    undoError,
    shouldRetry,
    showUndo,
    course,
    isFetching,
    error: deleteError,
    modelID: ownProps.courseID,
    isDeleting: fromState.isDeletingCourse(state, ownProps.courseID)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteCourse: () =>
    dispatch(curriculumActions.courses.delete.begin(ownProps.courseID)),
  onUndo: () =>
    dispatch(curriculumActions.courses.undelete.begin(ownProps.courseID)),
  clearErrors: () => {
    dispatch(curriculumActions.courses.delete.error(ownProps.courseID, null))
    dispatch(curriculumActions.courses.undelete.error(ownProps.courseID, null))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRetry(CourseListViewItemContainer))
