import { fromJS, Set, List, is } from 'immutable'

import { INDIVIDUAL_SKILLS, SKILLS } from '../models/resources'
import * as reducerCreators from './reducerCreators'
import { mapSkillObjectsByKey } from '../../helpers/utils'
import { DEFAULT_PAGE_SIZE } from '../../helpers/constants'

export const initialState = fromJS({
  byID: {},
  byCorrelatedOriginalID: {},
  fetching: false,
  error: {},
  filter: {}
})

const resetFilter = (state, action, searchQuery) =>
  state.mergeIn(['filter', action.unitID, searchQuery], {
    searchGrades: new List(),
    enableLoadMore: true,
    IDs: new List()
  })

const setFilters = (state, action, searchQuery) => {
  const filteredIDs = (
    state.getIn(['filter', action.unitID, searchQuery, 'IDs']) || new List()
  )
    .toSet()
    .union(Set(action.skills.map(({ id }) => id)))
    .toList()
  return state.mergeIn(['filter', action.unitID, searchQuery], {
    searchGrades: action.gradeIDs,
    enableLoadMore: !(action.skills.length < DEFAULT_PAGE_SIZE),
    IDs: filteredIDs
  })
}

const options = {
  placementFunction: (state, action) => {
    let newState = mapSkillObjectsByKey(state, action.skills)
    const searchQuery = action.searchQuery || ''

    // reasons to reset the filter
    const gradesChanged = !is(
      action.gradeIDs,
      state.getIn(['filter', action.unitID, searchQuery, 'gradeIDs'])
    )

    if (gradesChanged) {
      newState = resetFilter(newState, action, searchQuery)
    }

    return setFilters(newState, action, searchQuery)
  }
}

const individualSkillsOptions = {
  placementFunction: (state, action) => {
    return mapSkillObjectsByKey(state, action.skills)
  }
}

const getHandlers = reducerCreators.getHandlers(SKILLS, {}, options)
const skillGetHandlers = reducerCreators.getHandlers(
  INDIVIDUAL_SKILLS,
  {},
  individualSkillsOptions
)
const handlers = { ...getHandlers, ...skillGetHandlers }
const skills = reducerCreators.reducerCreator(initialState, handlers)

export default skills
