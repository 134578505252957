import React from 'react'
import i18n from '../../../helpers/i18n'

import { gradesLabel } from '../../../helpers/gradeLevels'
import './userInfoHeader.scss'

function generateSubtitle(grades, subjects) {
  const subject = (subjects && subjects[0]) || 'No Subject'
  return (
    <span className="user-info-sub-line">
      {i18n.t('titles.teacherNameDotGrade', {
        teacherName: subject,
        gradeLabel: gradesLabel(grades)
      })}
    </span>
  )
}

const UserInfoHeader = (props) => {
  // TODO: loading state thats not garbage
  if (!props.avatar) {
    return 'Loading...'
  }
  return (
    <div className="user-info-header">
      <img
        src={props.avatar}
        alt={i18n.t('imgAlt.userAvatar')}
        className="user-avatar"
      />
      <div className="user-info-labels">
        <h1>{props.name}</h1>
        {!props.excludeSubtitle &&
          generateSubtitle(props.grades, props.subjects)}
      </div>
    </div>
  )
}

UserInfoHeader.defaultProps = {
  grades: [],
  subjects: null
}

export default UserInfoHeader
