import React from 'react'
import { connect } from 'react-redux'

import Loading from '../kiddom/loading/loading'
import BarChart from './barChart'
import ChartControls from './chartControls'
import {
  dateRangeModel,
  generateTimeframeIDFromDates
} from '../../helpers/chartDateHelpers'
import * as colors from '../../helpers/colors'
import { getContentUsage } from '../../store/fromState'

class ContentUsageChart extends React.Component {
  constructor(props) {
    super(props)
    const defaultEnd = dateRangeModel.month.lastXRange.end
    const defaultStart = dateRangeModel.month.lastXRange.start
    this.state = {
      currentDataIndex: generateTimeframeIDFromDates(defaultStart, defaultEnd),
      currentDataType: 'month'
    }
  }

  generateChartData() {
    const { currentDataType, currentDataIndex } = this.state
    const { contentUsage } = this.props
    const currentData = contentUsage.getIn([currentDataType, currentDataIndex])
    let chartLength
    if (currentData && currentData.get('timeFrame')) {
      chartLength = currentData.getIn(['timeFrame', 'length'])
    } else {
      return null
    }

    return {
      ...ChartControls.buildChartLabels(currentData, chartLength),
      datasets: [
        {
          label: currentData.getIn(['assignments_total', 'name']),
          data: [...currentData.getIn(['assignments_total', 'values'])],
          backgroundColor: colors.hexToRGBA(colors.academyBase, 0.4)
        },
        {
          label: currentData.getIn(['assignments_paid', 'name']),
          data: [...currentData.getIn(['assignments_paid', 'values'])],
          backgroundColor: colors.hexToRGBA(colors.academyBase, 0.4)
        },
        {
          label: currentData.getIn(['assignments_free', 'name']),
          data: [...currentData.getIn(['assignments_free', 'values'])],
          backgroundColor: colors.hexToRGBA(colors.academyBase, 0.4)
        }
      ]
    }
  }

  componentDidMount() {
    this.fetchDataIfNecessary(
      this.state.currentDataType,
      this.state.currentDataIndex
    )
  }

  updateChartDataView = (currentDataView) => {
    const { timeFrame, nextTimeframeID } = currentDataView
    this.fetchDataIfNecessary(timeFrame, nextTimeframeID)
    this.setState({
      currentDataType: timeFrame,
      currentDataIndex: nextTimeframeID
    })
  }

  fetchDataIfNecessary(timeframe, timeframeID) {
    const nextTimeFrame = this.props.contentUsage.getIn([
      timeframe,
      timeframeID
    ])
    if (!nextTimeFrame) {
      this.props.fetchContentUsage(timeframe, timeframeID)
    }
  }

  renderLoading() {
    return <Loading type="academy" />
  }

  renderChart() {
    const chartControlProps = {
      allData: this.props.contentUsage,
      currentTimeframeID: this.state.currentDataIndex,
      currentTimeFrame: this.state.currentDataType,
      updateChartDataView: this.updateChartDataView
    }
    return (
      <div className="content-usage-chart">
        <BarChart chartData={this.generateChartData()} />
        <ChartControls {...chartControlProps} />
      </div>
    )
  }

  render() {
    const chartData = this.generateChartData()
    const content = chartData
      ? this.renderChart(chartData)
      : this.renderLoading()
    return content
  }
}

const mapDispatchToProps = (_dispatch, _ownProps) => ({
  fetchContentUsage: () => {}
})

export default connect((state, ownProps) => {
  return {
    contentUsage: getContentUsage(state, ownProps.schoolID)
  }
}, mapDispatchToProps)(ContentUsageChart)
