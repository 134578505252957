import Immutable from 'immutable'

import * as actionIDs from '../actions/actionIDs'
import { mapArrayObjectsByKey } from '../../helpers/utils'
import { SCHOOLS, REPORTING_METHODS } from '../models/resources'
import * as reducerCreators from './reducerCreators'
import { storeError } from './reducerHelpers'

export const initialState = Immutable.fromJS({
  byID: {},
  fetching: false,
  error: null
})

const getSchoolsSuccess = (state, action) => {
  let newState = state.set('fetching', false)
  action.schools.forEach((school) => {
    newState = newState.mergeIn(['byID', school.id], Immutable.fromJS(school))
    // fromJS converts admins to a List, since it's an array, but we replace it
    // with a fresh Map, then set the obejcts of the array based on their ID.
    newState = newState.setIn(['byID', school.id, 'admins'], Immutable.Map())
    newState = mapArrayObjectsByKey(newState, school.admins, 'id', [
      'byID',
      school.id,
      'admins'
    ])
  })
  return newState
}

const getReportingMethodsSuccess = (state, action) => {
  let newState = state
  newState = newState.setIn(
    ['byID', action.schoolID, 'fetchingReportingMethods'],
    false
  )
  const setMethodID = action.reportingMethods.find((method) => {
    return method.isSelected
  }).id
  // set all reporting methods by ID under the school
  newState = mapArrayObjectsByKey(newState, action.reportingMethods, 'id', [
    'byID',
    action.schoolID,
    'reportingMethods'
  ])

  // set the currently set method for easier access
  newState = newState.setIn(
    ['byID', action.schoolID, 'reportingMethods', 'setMethodID'],
    Immutable.fromJS(setMethodID)
  )
  return newState
}

const initReportingMethods = (state, action) => {
  return state.mergeIn(['byID', action.schoolID], {
    fetchingReportingMethods: true
  })
}

const schoolsHandlers = reducerCreators.getHandlers(SCHOOLS, {
  [actionIDs.schools.read.success]: getSchoolsSuccess,
  [actionIDs.schools.read.error]: storeError
})

const reportingMethodsHandlers = reducerCreators.getHandlers(
  REPORTING_METHODS,
  {
    [actionIDs.reportingMethods.read.initialize]: initReportingMethods,
    [actionIDs.reportingMethods.read.success]: getReportingMethodsSuccess,
    [actionIDs.reportingMethods.read.error]: storeError
  }
)

const getSchoolSubjectGroupsSuccess = (state, action) => {
  return state.setIn(
    ['byID', action.schoolID, 'subjectGroupIDs'],
    Immutable.List(action.subjectGroups.map((group) => group.id))
  )
}

const getSubjectsAndCoursesSuccess = (state, action) => {
  return state.setIn(
    ['byID', action.schoolID, 'subjectGroupIDs'],
    Immutable.List(action.subjectsAndCourses.map((sac) => sac.subject.id))
  )
}

const createSchoolSubjectGroupSuccess = (state, action) => {
  const subjectGroup = action.schoolSubjectGroup
  let newState = state

  if (subjectGroup) {
    const schoolSubjectGroupIDs = newState.getIn([
      'byID',
      action.schoolID,
      'subjectGroupIDs'
    ])
    newState = newState.setIn(
      ['byID', action.schoolID, 'subjectGroupIDs'],
      schoolSubjectGroupIDs
        ? schoolSubjectGroupIDs.push(subjectGroup.id)
        : Immutable.List([subjectGroup.id])
    )
  }

  return newState
}

const subjectGroupHandlers = {
  [actionIDs.schoolSubjectGroups.read.success]: getSchoolSubjectGroupsSuccess,
  [actionIDs.schoolSubjectGroups.create
    .success]: createSchoolSubjectGroupSuccess,
  [actionIDs.subjectsAndCourses.read.success]: getSubjectsAndCoursesSuccess
}

const getStudentsSuccess = (state, action) => {
  const studentIDs = Immutable.Set(action.students.map((s) => s.id))
  const schoolStudentIDs =
    state.getIn(['byID', action.schoolID, 'studentIDs']) || Immutable.Set()
  return state.setIn(
    ['byID', action.schoolID, 'studentIDs'],
    schoolStudentIDs.union(studentIDs)
  )
}

const handlers = Object.assign(
  {
    [actionIDs.students.read.success]: getStudentsSuccess
  },
  schoolsHandlers,
  reportingMethodsHandlers,
  subjectGroupHandlers
)

const schools = reducerCreators.reducerCreator(initialState, handlers)

export default schools
