import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'
import WithVisibleStateToggle from '../../hoc/withVisibleStateToggle'
import CloseIcon from '../../../images/icons/close.svg'
import './modal.scss'

class Modal extends WithVisibleStateToggle {
  constructor(props) {
    super(props)
    this.ref = React.createRef()
  }

  renderModalContent() {
    const modalClasses = classnames(
      'modal-container',
      {
        visible: this.state.visible
      },
      this.props.className
    )
    const closeLabel = i18n.t('labels.close')
    const modalHeaderClasses = classnames('modal-header', {
      'modal-header-dark': this.props.title,
      'modal-header-no-title': !this.props.title
    })
    const closeIconClasses = classnames('close-modal', {
      'close-modal-no-title': !this.props.title
    })
    const closeButtonClasses = classnames('close-button', {
      'close-button-no-title': !this.props.title
    })
    return (
      <div className={modalClasses} data-spec="modal-ui">
        <div
          className="modal-backdrop"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            this.hideVisible()
          }}
        />
        <div className="modal">
          <div className={modalHeaderClasses}>
            <h2 className="modal-title">{this.props.title}</h2>
            <button
              onClick={this.hideVisible}
              className={closeButtonClasses}
              title={closeLabel}
            >
              <CloseIcon data-spec="close-icon" className={closeIconClasses} />
            </button>
          </div>
          <div className="modal-content">{this.props.children}</div>
        </div>
      </div>
    )
  }

  render() {
    const renderContent = this.props.prerender
      ? this.renderModalContent()
      : this.state.visible && this.renderModalContent()
    return (
      <div ref={this.ref} className="modal-trigger-container">
        {!this.props.skipTriggerElement &&
          React.cloneElement(this.props.triggerElement, {
            onClick: (e) => {
              e.stopPropagation()
              this.toggleVisible()
            },
            'data-spec': 'trigger-element'
          })}
        {renderContent}
      </div>
    )
  }
}

Modal.defaultProps = {
  title: null,
  prerender: true,
  skipTriggerElement: false
}

Modal.propTypes = {
  title: PropTypes.string,
  triggerElement: PropTypes.element,
  prerender: PropTypes.bool,
  skipTriggerElement: PropTypes.bool
}

export default Modal
