import React from 'react'

import Notification from '../ui/notification/notification'

import './bulkActionNotification.scss'

const BulkActionNotification = (props) => {
  const { message, type, dismiss, visible } = props

  if (!visible) {
    return null
  }

  return (
    <Notification
      type={type}
      className="bulk-action-notification"
      dismissNotification={dismiss}
    >
      {message}
    </Notification>
  )
}

export default BulkActionNotification
