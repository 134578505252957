import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import ListView from './listView'
import SelectableListHeader from './selectableListHeader'
import StickyContainer from '../stickyContainer/stickyContainer'

import { listHeadingsShape } from '../../../helpers/propShapes/listViewShapes'

import './selectableList.scss'

export const onSelected = (id, selected, setSelected) => {
  const newSelected = new Set(selected)
  if (selected.has(id)) {
    newSelected.delete(id)
  } else {
    newSelected.add(id)
  }

  setSelected(newSelected)
}

/**
 * Renders a ListView with checkboxes to select list items, and a list header that includes a
 * checkbox to select all items in the list (or the current page of the list, if combined with
 * the withPagination HOC).
 *
 * To render an element that receives the set of selected list item IDs as a prop, simply wrap
 * in this component. Example:
 * <SelectableList>
 *   <ThisGetsSelectedItemIdsAsProp />
 * </SelectableList>
 */
const SelectableList = (props) => {
  const {
    basicDataKeys,
    items,
    listStyles,
    listHeadings,
    selected,
    setSelected
  } = props
  const containerRef = useRef(null)

  return (
    <React.Fragment>
      {props.children && props.children(selected)}
      <div ref={containerRef} className="list-view-container selectable-list">
        <StickyContainer containerRef={containerRef}>
          <SelectableListHeader
            listHeadings={listHeadings}
            selected={selected}
            setSelected={setSelected}
            items={items}
          />
        </StickyContainer>
        <ListView
          items={items}
          listStyles={listStyles}
          basicDataKeys={basicDataKeys}
          withSelect
          selected={selected}
          onSelected={(id) => onSelected(id, selected, setSelected)}
        />
      </div>
    </React.Fragment>
  )
}

SelectableList.defaultProps = {
  items: []
}

SelectableList.propTypes = {
  basicDataKeys: PropTypes.arrayOf(PropTypes.string),
  listStyles: PropTypes.object.isRequired,
  listHeadings: listHeadingsShape.isRequired
}

export default SelectableList
