import { onSelected as updateSelected } from '../ui/listView/selectableList'
import {
  SIS_ID_PREFIX,
  KIDDOM_SCHOOL,
  SIS_SCHOOL
} from '../../helpers/constants'

// makes sure user selects one sis school and one kiddom school
export const selectionIsOkay = (selected, schoolsMap) => {
  if (selected.size !== 2) {
    return false
  } else {
    const status = {
      [KIDDOM_SCHOOL]: false,
      [SIS_SCHOOL]: false
    }
    // check which id belongs to which school type
    selected.forEach((id) => {
      const school = schoolsMap[id].type
      status[school] = true
    })
    return status[KIDDOM_SCHOOL] && status[SIS_SCHOOL]
  }
}

// removes paired row from schoolsMap, then retrieve the two schools that forms
// it and add those back to schoolsMap
export const undoPair = (schoolsMap, setSchoolsMap, selected, setSelected) => (
  id
) => () => {
  const pair = schoolsMap[id]
  const newSchoolsMap = { ...schoolsMap }
  delete newSchoolsMap[id]

  // add back items from pair separately
  Object.values(pair.pairData).forEach((school) => {
    newSchoolsMap[school.id] = { ...school }
  })

  // to have split objects have checked box
  setSelected(new Set(Object.keys(schoolsMap[id].pairData)))
  setSchoolsMap(newSchoolsMap)
}

// use kiddom school id as id for hashmap (arbitrary choice, could have been sis school id)
export const getKiddomSchoolID = (schoolOne, schoolTwo) => {
  if (schoolOne.slice(0, SIS_ID_PREFIX.length) === SIS_ID_PREFIX) {
    return schoolTwo
  } else {
    return schoolOne
  }
}

// removes the two selected rows from the list, then combine them into one
// and add back into the list
export const combinePair = (
  schoolsMap,
  setSchoolsMap,
  selected,
  setSelected
) => () => {
  const [schoolOneID, schoolTwoID] = selected

  // new updated map
  const newSchoolsMap = { ...schoolsMap }
  delete newSchoolsMap[schoolOneID]
  delete newSchoolsMap[schoolTwoID]

  // useful for undo operator
  const id = getKiddomSchoolID(schoolOneID, schoolTwoID)

  // add combinedPair to newSchoolsMap
  newSchoolsMap[id] = {
    id,
    name: schoolsMap[id].name,
    pairData: {
      [schoolOneID]: schoolsMap[schoolOneID],
      [schoolTwoID]: schoolsMap[schoolTwoID]
    }
  }
  setSelected(new Set())
  setSchoolsMap(newSchoolsMap)
}

export const getNumPairs = (schoolsMap) => {
  let pairs = 0
  Object.values(schoolsMap).forEach((value) => {
    value.pairData && pairs++
  })
  return pairs
}

export const getPairsArray = (schoolsMap) => {
  const pairsArr = []
  Object.values(schoolsMap).forEach((value) => {
    if (value.pairData) {
      const pair = {}
      Object.values(value.pairData).forEach((school) => {
        if (school.id.slice(0, SIS_ID_PREFIX.length) === SIS_ID_PREFIX) {
          pair.sis_id = school.id.slice(SIS_ID_PREFIX.length)
        } else {
          pair.id = parseInt(school.id, 10)
        }
      })
      pairsArr.push(pair)
    }
  })
  return pairsArr
}

// updates selected value -> this determines the color of the font
const updateSchoolsMapSelection = (id, selected, schoolsMap, setSchoolsMap) => {
  const copy = { ...schoolsMap[id] }
  copy.selected = !selected.has(id)
  const newSchoolsMap = { ...schoolsMap, [id]: copy }
  setSchoolsMap(newSchoolsMap)
}

// for when user clicks on checkbox
export const onSelected = (
  id,
  selected,
  setSelected,
  schoolsMap,
  setSchoolsMap
) => {
  updateSelected(id, selected, setSelected)
  updateSchoolsMapSelection(id, selected, schoolsMap, setSchoolsMap)
}

const sortAlphabetically = (a, b) => a.name.localeCompare(b.name)

// turns hashmap into array for list rendering
export const transformMapToList = (schoolsMap) => {
  const newSchoolsList = []
  Object.values(schoolsMap).forEach((value) => {
    newSchoolsList.push(value)
  })
  newSchoolsList.sort(sortAlphabetically)
  return newSchoolsList
}

export const isSISSchool = (school) => {
  return school.id.slice(0, SIS_ID_PREFIX.length) === SIS_ID_PREFIX
}
