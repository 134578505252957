import React from 'react'
import i18n from '../../../helpers/i18n'

import * as fromState from '../../../store/fromState'
import './guidanceTileStep.scss'
import ProgressCircle from '../../ui/progressCircle/progressCircle'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

export const GuidanceTileStep = (props) => {
  const stepLabel = i18n.t('guidanceSteps.' + props.guidanceStepLabel)
  const heightPercentage =
    (props.requiredUserPrefStepsCount / props.requiredUserPrefSteps.length) *
    100
  return (
    <div className="step-container">
      <ProgressCircle innerHeight={heightPercentage} />
      <div className="step-label">{stepLabel}</div>
    </div>
  )
}

export const mapStateToProps = (state, ownProps) => {
  const requiredUserPrefStepsCount = ownProps.requiredUserPrefSteps.filter(
    (userPref) => fromState.getUserPreference(state, userPref) === 'true'
  ).length
  return {
    requiredUserPrefStepsCount
  }
}

GuidanceTileStep.defaultProps = {
  guidanceStepLabel: '',
  requiredUserPrefSteps: [],
  requiredUserPrefStepsCount: 0
}
GuidanceTileStep.propTypes = {
  guidanceStepLabel: PropTypes.string.isRequired,
  requiredUserPrefSteps: PropTypes.arrayOf(PropTypes.string),
  requiredUserPrefStepsCount: PropTypes.number
}

export default connect(mapStateToProps, null)(GuidanceTileStep)
