import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'

import DownArrow from '../../images/icons/arrow-down.svg'
import CircledIcon from '../common/circledIcon'
import SubjectIcon from '../ui/subjectIcon'
import Popup from '../ui/popup/popup'
import * as resources from '../../store/models/resources'
import { getSubjectsAsTitle } from '../../helpers/subjects'
import './coursesSelect.scss'
import * as fromState from '../../store/fromState'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'
import * as curriculumActions from '../../store/actions/curriculumActions'
import { FEATURE_ACCESS_ID } from '../../helpers/constants'

const generatePopupMenuBody = (subjectName, courses, selectedCourseID) => {
  const links = courses.map((course) => {
    const courseID = course.id
    const courseName = course.name
    return (
      <li key={`${courseID}`}>
        <Link
          data-spec="course-link"
          className={classnames({
            'selected-course': courseID === selectedCourseID
          })}
          to={`${courseID}`}
        >
          <div title={courseName}>{courseName}</div>
        </Link>
      </li>
    )
  })

  return (
    <div className="popup-body">
      <h4>{subjectName}</h4>
      <ul className="nav-links">{links}</ul>
    </div>
  )
}

class CoursesSelect extends React.Component {
  render() {
    const {
      selectedCourseID,
      courses,
      subjectGroup,
      isViewingDemoSchool
    } = this.props
    const selectedCourse = courses.find(
      (course) => course.id === selectedCourseID
    )
    const subjects = subjectGroup.subjects.map((subject) => subject.name)
    const subjectName = getSubjectsAsTitle(subjects)
    const content = (
      <CircledIcon className="circled-icon-trigger">
        <DownArrow title="down-arrow" className="arrow" />
      </CircledIcon>
    )
    const showDropDown = courses && courses.length > 1

    const selectedClassnames = classnames('courses-select-selected', {
      'is-demo': isViewingDemoSchool
    })
    return (
      <div className="courses-select">
        <div className={selectedClassnames}>
          <SubjectIcon names={subjects} />
          <h1>{selectedCourse.name}</h1>
          {showDropDown && (
            <Popup position="bottom" alignment="end" triggerElement={content}>
              {generatePopupMenuBody(subjectName, courses, selectedCourseID)}
            </Popup>
          )}
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state, ownProps) => {
  const isUserInFreeTrial = fromState.userInFeature(
    state,
    FEATURE_ACCESS_ID.fullAcademy
  )
  const demoSchoolID = fromState.getFreeTrialSchoolIDs(state).demoSchoolID
  const isViewingDemoSchool =
    isUserInFreeTrial && ownProps.match.params.schoolID === demoSchoolID
  return {
    subjectGroup: fromState.getSubjectGroupByID(state, ownProps.subjectGroupID),
    courses: fromState.getCoursesFromSubjectGroup(
      state,
      ownProps.subjectGroupID
    ),
    isViewingDemoSchool
  }
}

const generateDataLoaders = (props) => [
  curriculumActions.courses.read.begin(props.subjectGroupID)
]
const hasSubjectGroupAndCoursesLoaded = (state, subjectGroupID) => {
  return (
    !fromState.coursesLoaded(state, subjectGroupID) ||
    !fromState.resourcesLoaded(state, [resources.COURSES])
  )
}
const generateLoadingProps = (props) => ({
  isLoading: [[hasSubjectGroupAndCoursesLoaded, [props.subjectGroupID]]]
})

const CoursesSelectWrapper = withToJS(CoursesSelect)
export default withRouter(
  withDataLoading(
    connect(mapStateToProps)(CoursesSelectWrapper),
    generateDataLoaders,
    generateLoadingProps,
    []
  )
)
