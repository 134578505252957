import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'

import './barChart.scss'

class BarChart extends React.Component {
  render() {
    const chartProps = {
      data: this.props.chartData,
      options: {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          xAxes: [{ stacked: true }]
        }
      }
    }

    return (
      <div className="bar-container">
        <Bar {...chartProps} />
      </div>
    )
  }
}

BarChart.propTypes = {
  chartData: PropTypes.object.isRequired
}

export default BarChart
