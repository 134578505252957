import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import { studentItemShape } from '../../helpers/propShapes/listViewShapes'
import StickyContainer from '../ui/stickyContainer/stickyContainer'
import { reportingMethodShape } from '../../helpers/propShapes/chartDataShapes'
import ListView from '../ui/listView/listView'
import { STUDENT_ID_PATH } from '../../routes/routes'

import './studentListView.scss'

class StudentListView extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
  }

  renderPreHeadingRow() {
    const assignmentsLabel = i18n.t('labels.assignments')
    return (
      <div
        className="list-row pre-headers"
        key="pre-headers"
        data-spec="header-row"
      >
        <div className="list-cell" />
        <div className={'list-cell lg wide'} data-spec="heading-assignments">
          {assignmentsLabel}
        </div>
        <div className={'list-cell md wide'} />
        <div className={'list-cell lg'} />
      </div>
    )
  }

  renderHeadings() {
    const nameLabel = i18n.t('labels.name')
    const submittedLabel = i18n.t('labels.submitted')
    const dueLabel = i18n.t('labels.due')
    const missingLabel = i18n.t('labels.missing')
    const masterylabel = i18n.t('labels.studentMastery')
    const growthLabel = i18n.t('labels.recentGrowth')

    return (
      <div className="list-row headers" key="headers" data-spec="header-row">
        <div className="list-cell">{nameLabel}</div>
        <div className={'list-cell sub-header lg'}>{submittedLabel}</div>
        <div className={'list-cell sub-header lg'}>{dueLabel}</div>
        <div className={'list-cell sub-header lg'}>{missingLabel}</div>
        <div className={'list-cell chart-section md'}>{masterylabel}</div>
        <div className={'list-cell growth-container-header lg'}>
          {growthLabel}
        </div>
      </div>
    )
  }

  render() {
    const listStyles = {
      name: '',
      assignments: 'lg',
      assignmentsDue: 'lg',
      assignmentsMissing: 'lg',
      assignmentsSubmitted: 'lg',
      studentMastery: 'md',
      recentGrowth: 'lg'
    }

    const basicDataKeys = [
      'assignmentsSubmitted',
      'assignmentsDue',
      'assignmentsMissing'
    ]

    const { items, schoolID, reportingMethod, onClickRow } = this.props

    return (
      <div
        ref={this.containerRef}
        className="list-view-container student-list-view-container"
        data-spec="student-listview-ui"
      >
        <StickyContainer containerRef={this.containerRef}>
          {this.renderPreHeadingRow()}
          {this.renderHeadings()}
        </StickyContainer>
        <ListView
          items={items}
          listStyles={listStyles}
          basicDataKeys={basicDataKeys}
          rowLink={(studentID) => STUDENT_ID_PATH(schoolID, studentID)}
          reportingMethod={reportingMethod}
          onClickRow={onClickRow}
          showMastery
        />
      </div>
    )
  }
}

StudentListView.defaultProps = {
  items: []
}

StudentListView.propTypes = {
  items: PropTypes.arrayOf(studentItemShape),
  schoolID: PropTypes.string,
  reportingMethod: reportingMethodShape
}

export default StudentListView
