import { all } from 'redux-saga/effects'

import coreSagaWatchers from './coreSagaWatchers'
import curriculumSagaWatchers from './curriculumSagaWatchers'
import userManagementSagaWatchers from './userManagementSagaWatchers'
import adminSagaWatchers from './adminSagaWatchers'
import sisIntegrationSagaWatchers from './sisIntegrationSagaWatchers'

function* watchResources() {
  yield all(coreSagaWatchers)
  yield all(curriculumSagaWatchers)
  yield all(userManagementSagaWatchers)
  yield all(adminSagaWatchers)
  yield all(sisIntegrationSagaWatchers)
}

export default function* rootSaga() {
  yield all([watchResources()])
}
