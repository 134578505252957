import { fromJS, Map } from 'immutable'
import * as reducerCreators from './reducerCreators'
import { mapArrayObjectsByKey } from '../../helpers/utils'
import { COURSES } from '../models/resources'
import * as actionIDs from '../actions/actionIDs'
import { CURRICULUM_COLLABORATOR } from '../../helpers/constants'

export const initialState = fromJS({
  byID: {},
  fetching: {},
  error: {
    deleteByID: {},
    undeleteByID: {}
  },
  deleting: {},
  deletedID: []
})

const getOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(
      ['fetching', 'bySubjectGroupID', action.schoolSubjectGroupID],
      fetching
    ),
  errorLocation: (state, action, error) =>
    state.setIn(
      ['error', 'bySubjectGroupID', action.schoolSubjectGroupID],
      error
    ),
  placementFunction: (state, action) =>
    mapArrayObjectsByKey(state, action.courses, 'id', ['byID'])
}

const createOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['error', 'byID', action.tempID], false)
    return newState.setIn(['fetching', 'byID', action.tempID], fetching)
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'byID', action.tempID], fromJS(error))
  },
  placementFunction: (state, action, tempObj = null) => {
    let newState = state
    const course = tempObj || action.course
    // placing a real api response
    if (!tempObj) {
      newState = newState.removeIn(['byID', action.tempID])
      newState = newState.setIn(['byID', course.id], fromJS(course))
      newState = newState.setIn(['byID', course.id, 'tempID'], action.tempID)
    } else {
      // placing a temporary object
      newState = newState.setIn(['byID', course.id], fromJS(course))
    }
    return newState
  },
  template: (state, action) => {
    return {
      id: action.tempID,
      schoolSubjectGroupID: action.schoolSubjectGroupID
    }
  }
}

const updateCourseOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.setIn(['fetching', 'byCourseID', action.courseID], fetching),
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'byCourseID', action.courseID], fromJS(error)),
  placementFunction: (state, action) => {
    const newGradeLevelIDs = [...action.gradeLevelIDs].map((x) => x.toString())
    const toMergeCourse = {
      grades: newGradeLevelIDs
    }
    if (action.courseName) {
      toMergeCourse.name = action.courseName
    }
    const newCourseState = state
      .getIn(['byID', action.courseID])
      .merge(toMergeCourse)
    return state.setIn(['byID', action.courseID], newCourseState)
  }
}
const shareOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    if (fetching) {
      const sharedWith = new Map({
        id: action.userID,
        role: action.role || CURRICULUM_COLLABORATOR
      })
      newState = newState.updateIn(
        ['byID', action.courseID, 'sharedWithByUserID'],
        (existing) =>
          existing
            ? existing.set(action.userID, sharedWith)
            : new Map({
                [action.userID]: sharedWith
              })
      )
    }

    return newState.setIn(
      ['sharingByID', action.courseID, 'byUserID', action.userID],
      fetching
    )
  },
  errorLocation: (state, action, error) => {
    let newState = state
    if (error) {
      // There was an error in sharing, so remove the user from the set.
      newState = state.deleteIn([
        'byID',
        action.courseID,
        'sharedWithByUserID',
        action.userID
      ])
    }

    return newState.setIn(
      ['sharingErrorByID', action.courseID, 'byUserID', action.userID],
      error
    )
  }
}

const unshareOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    if (fetching) {
      // Eagerly update state as if the request is successful.
      newState = newState.updateIn(
        ['byID', action.courseID, 'sharedWithByUserID'],
        (existing) => (existing ? existing.remove(action.userID) : new Map())
      )
    }

    return newState.setIn(
      ['sharingByID', action.courseID, 'byUserID', action.userID],
      fetching
    )
  },
  errorLocation: (state, action, error) => {
    let newState = state
    if (error) {
      // There was an error in unsharing, so add the user back
      newState = state.setIn(
        ['byID', action.courseID, 'sharedWithByUserID', action.userID],
        new Map({
          id: action.userID,
          role: CURRICULUM_COLLABORATOR
        })
      )
    }

    return newState.setIn(
      ['sharingErrorByID', action.courseID, 'byUserID', action.userID],
      error
    )
  }
}

const deleteCourseOptions = {
  fetchingLocation: (state, action, fetching) => {
    return state.setIn(['deleting', 'byID', action.courseID], fetching)
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'deleteByID', action.courseID], error)
  },
  placementFunction: (state, action) => {
    return state
      .update('deletedID', (list) => list.push(action.courseID))
      .setIn(['error ', 'deleteByID', action.courseID], null)
  }
}

const undeleteCourseOptions = {
  fetchingLocation: (state, action, fetching) => {
    return state.setIn(['deleting', 'byID', action.courseID], fetching)
  },
  errorLocation: (state, action, error) => {
    return state.setIn(['error', 'undeleteByID', action.courseID], error)
  },
  placementFunction: (state, action) => {
    return state
      .update('deletedID', (list) =>
        list.filter((id) => id !== action.courseID)
      )
      .setIn(['error', 'undeleteByID', action.courseID], null)
  }
}

const coursesGetHandlers = reducerCreators.getHandlers(COURSES, {}, getOptions)

const coursesCreateHandlers = reducerCreators.createHandlers(
  COURSES,
  {},
  createOptions
)
const courseUpdateHandlers = reducerCreators.handleCreator(
  actionIDs.courses.update,
  {},
  updateCourseOptions
)

const coursesShareHandlers = reducerCreators.handleCreator(
  actionIDs.courses.share,
  {},
  shareOptions
)

const coursesUnshareHandlers = reducerCreators.handleCreator(
  actionIDs.courses.unshare,
  {},
  unshareOptions
)

const courseCollaboratorPlacement = (state, action) =>
  mapArrayObjectsByKey(state, action.curriculum.sharedWith, 'id', [
    'byID',
    action.courseID,
    'sharedWithByUserID'
  ])

const getSubjectsAndCoursesSuccess = (state, action) => {
  let courses = []
  action.subjectsAndCourses.forEach(
    (sac) => (courses = courses.concat(sac.courses))
  )
  return mapArrayObjectsByKey(state, courses, 'id', ['byID'])
}

// After we are done adding new courses we need unset tempID when we navigate to other view
// Removing those tempID would show them as normal courses is list
const removeCourseTempID = (state, action) => {
  let newState = state
  newState
    .get('byID')
    .filter(
      (course) =>
        course.get('tempID') &&
        course.get('schoolSubjectGroupID') === action.subjectGroupID
    )
    .forEach((course) => {
      newState = newState.updateIn(['byID', course.get('id')], (course) =>
        course.merge({
          tempID: null
        })
      )
    })
  return newState
}

const courseDeleteHandlers = reducerCreators.handleCreator(
  actionIDs.courses.delete,
  {},
  deleteCourseOptions
)
const courseUndeleteHandlers = reducerCreators.handleCreator(
  actionIDs.courses.undelete,
  {},
  undeleteCourseOptions
)

const handlers = Object.assign(
  {
    [actionIDs.units.read.success]: courseCollaboratorPlacement,
    [actionIDs.subjectsAndCourses.read.success]: getSubjectsAndCoursesSuccess,
    [actionIDs.REMOVE_COURSE_TEMP_ID]: removeCourseTempID
  },
  coursesGetHandlers,
  coursesCreateHandlers,
  courseUpdateHandlers,
  coursesShareHandlers,
  coursesUnshareHandlers,
  courseDeleteHandlers,
  courseUndeleteHandlers
)

const courses = reducerCreators.reducerCreator(initialState, handlers)

export default courses
