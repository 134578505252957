import React from 'react'

import i18n from '../../helpers/i18n'

const TeacherClassOverview = (props) => {
  const teacherClassOverview = i18n.t('titles.teacherClassOverview')
  return (
    <div className="teacher-class-overview page">
      <h2>{teacherClassOverview}</h2>
      <h3>{props.match.params.teacherID}</h3>
      <h3>{props.match.params.classID}</h3>
    </div>
  )
}

export default TeacherClassOverview
