import Immutable from 'immutable'

import { USER, EVENT } from '../models/resources'
import * as reducerCreators from './reducerCreators'
import * as actionIDs from '../actions/actionIDs'

export const initialState = Immutable.Map({
  userInfo: null,
  fetching: false,
  fetchingUserPrefs: false,
  error: null,
  errorUserPrefs: null,
  trackingError: null
})

const userOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.set('fetching', fetching),
  errorLocation: (state, action, error) => state.set('error', error),
  placementFunction: (state, action) =>
    state.setIn(['userInfo'], Immutable.fromJS(action.user))
}

const readPrefsOptions = {
  fetchingLocation: (state, action, fetching) =>
    state.set('fetchingUserPrefs', fetching),
  errorLocation: (state, action, error) => state.set('errorUserPrefs', error),
  placementFunction: (state, action) => {
    let stateMap = Immutable.Map({})
    action.userPreferences.prefs.forEach((pref) => {
      stateMap = stateMap.set(pref.key, pref.value)
    })
    return state.setIn(['userInfo', 'userPreferences'], stateMap)
  }
}

const writePrefsOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    if (action.preferences) {
      // We can set this at the time of intialization since the post call
      // /api2/user/preferences doesn't actually return anything on success
      // (this also slightly improves performance)
      let preferencesMap = state.getIn(['userInfo', 'userPreferences'])
      action.preferences.forEach(
        (pref) => (preferencesMap = preferencesMap.set(pref.key, pref.value))
      )
      newState = newState.setIn(['userInfo', 'userPreferences'], preferencesMap)
    }
    return newState.set('fetchingUserPrefs', fetching)
  },
  errorLocation: (state, action, error) => {
    let newState = state
    if (action.preferences) {
      // If there was an error setting preferences, we roll back the changes here
      let preferencesMap = state.getIn(['userInfo', 'userPreferences'])
      action.preferences.forEach((pref) => {
        if (
          action.originalPreferences &&
          action.originalPreferences.get(pref.key)
        ) {
          preferencesMap = preferencesMap.set(
            pref.key,
            action.originalPreferences.get(pref.key)
          )
        } else {
          preferencesMap = preferencesMap.delete(pref.key)
        }
      })
      newState = newState.setIn(['userInfo', 'userPreferences'], preferencesMap)
    }
    return newState.set('errorUserPrefs', error)
  }
}

const trackingOptions = {
  errorLocation: (state, action, error) => state.set('trackingError', error)
}

const userHandlers = reducerCreators.getHandlers(USER, {}, userOptions)
const userReadPrefsHandlers = reducerCreators.handleCreator(
  actionIDs.user.readPrefs,
  {},
  readPrefsOptions
)
const userWritePrefsHandlers = reducerCreators.handleCreator(
  actionIDs.user.writePrefs,
  {},
  writePrefsOptions
)
const trackingHandlers = reducerCreators.getHandlers(EVENT, {}, trackingOptions)

const handlers = Object.assign(
  {},
  userHandlers,
  userReadPrefsHandlers,
  userWritePrefsHandlers,
  trackingHandlers
)

const user = reducerCreators.reducerCreator(initialState, handlers)

export default user
