import { fork, takeEvery, takeLatest } from 'redux-saga/effects'
import * as helperSagas from './helperSagas'
import * as curriculumSagas from './curriculumSagas'
import * as actionIDs from '../actions/actionIDs'

const curriculumSagaWatchers = [
  // basic resource fetches
  fork(
    helperSagas.resourceWatcher(
      actionIDs.schoolSubjectGroups.read,
      curriculumSagas.fetchSchoolSubjectGroups
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.schoolSubjectGroups.create,
      curriculumSagas.createSchoolSubjectGroup
    )
  ),

  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.read,
      curriculumSagas.fetchCourses
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.units.read,
      curriculumSagas.fetchUnitsOfCourse
    )
  ),
  // takeLatest ensures that the most recent request for skills is
  // processed so that an older request won't overwrite a newer one
  takeLatest(actionIDs.skills.read.begin, curriculumSagas.fetchSkills),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.skills.readIndividualSkills,
      curriculumSagas.fetchIndividualSkills
    )
  ),

  fork(
    helperSagas.resourceWatcher(
      actionIDs.skillGroups.read,
      curriculumSagas.fetchSkillGroups
    )
  ),

  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.share,
      curriculumSagas.shareCurriculumWithUser
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.unshare,
      curriculumSagas.unshareCurriculumWithUser
    )
  ),

  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.update,
      curriculumSagas.updateCourse
    )
  ),

  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.delete,
      curriculumSagas.deleteCourse
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.courses.undelete,
      curriculumSagas.undeleteCourse
    )
  ),

  // composite resource fetches
  takeEvery(
    actionIDs.FETCH_SCHOOL_SUBJECTS_COURSES,
    curriculumSagas.fetchSchoolSubjectsAndCourses
  ),

  takeEvery(
    actionIDs.skills.fetchSkillsFromSubjectGroupAndCourse,
    curriculumSagas.fetchSkillsFromSubjectGroupAndCourse
  ),

  takeEvery(
    actionIDs.FETCH_TRIAL_SCHOOL_SUBJECTS,
    curriculumSagas.fetchTrialSchoolSubjects
  )
]

export default curriculumSagaWatchers
