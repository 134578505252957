import React from 'react'

class StickyContainer extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (_e) => {
    const scrolled = window.scrollY
    let headerHeight = 125 + 10
    if (Number.isInteger(this.props.triggerHeight)) {
      headerHeight = this.props.triggerHeight
    }
    const container = this.props.containerRef
    let containerOffset = null
    if (container) {
      containerOffset = container.current.offsetTop
    }
    if (containerOffset) {
      if (scrolled > containerOffset - headerHeight) {
        container.current.classList.add('fixed')
      } else {
        container.current.classList.remove('fixed')
      }
    }
  }

  render() {
    return this.props.children
  }
}

export default StickyContainer
