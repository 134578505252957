import React from 'react'
import ClassOverviewPresenter from './classOverviewPresenter'

class ClassOverviewContainer extends React.Component {
  render() {
    return <ClassOverviewPresenter {...this.props} />
  }
}

export default ClassOverviewContainer
