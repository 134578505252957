import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'
import {
  chartDataShape,
  timeframeTypesShape
} from '../../helpers/propShapes/chartDataShapes'
import ButtonGroup from '../ui/buttonGroup/buttonGroup'
import LeftRight from '../ui/buttons/leftRight'
import {
  getStartAndEndDateForChart,
  generateTimeframeIDFromDates,
  dateRangeModel
} from '../../helpers/chartDateHelpers'

import './chartControls.scss'

class ChartControls extends React.Component {
  constructor(props) {
    super(props)
    // these should be the order of the buttonGroup labels
    this.timeframeLabels = ['month', 'week']
    this.state = {
      currentTimeFrame: props.currentTimeFrame || 'month'
    }
  }

  static buildChartLabels(currentData, length) {
    const timeFrame = currentData.get('timeFrame')
    const timeFrameType = timeFrame.get('type')

    let incrementUnit, format
    if (['week', 'month'].indexOf(timeFrameType) > -1) {
      if (timeFrameType === 'week') {
        length = 7
      }
      incrementUnit = 'day'
      format = 'M/D'
    } else if (timeFrameType === 'term') {
      incrementUnit = 'week'
      format = 'MM/DD'
    } else if (timeFrameType === 'year') {
      incrementUnit = 'month'
      format = 'MMMM'
    }

    const dateObjects = new Array(length)
      .fill(undefined)
      .map((scratch, index) => {
        return moment(timeFrame.get('start')).add(index, incrementUnit)
      })

    return {
      dateObjects,
      labels: dateObjects.map((dateObject) => dateObject.format(format))
    }
  }

  setTimeFrame(timeFrame = this.state.currentTimeFrame, direction = null) {
    let nextTimeframeID
    const currentTimeFrameData = this.props.allData.getIn([
      timeFrame,
      this.props.currentTimeframeID
    ])
    if (direction && currentTimeFrameData) {
      const currentTimeFrame = currentTimeFrameData.get('timeFrame')
      const nextDates = getStartAndEndDateForChart(direction, currentTimeFrame)
      nextTimeframeID = generateTimeframeIDFromDates(
        nextDates.startDate,
        nextDates.endDate
      )
    } else {
      const defaultRange = dateRangeModel[timeFrame].lastXRange
      nextTimeframeID = generateTimeframeIDFromDates(
        defaultRange.start,
        defaultRange.end
      )
    }

    this.props.updateChartDataView({
      nextTimeframeID,
      timeFrame
    })
    this.setState({
      currentTimeFrame: timeFrame
    })
  }

  getTimeFrameLabel(timeFrame, timeSlot) {
    const currentData = this.props.allData.getIn([timeFrame, timeSlot])
    const currentTimeFrame = currentData && currentData.get('timeFrame')
    if (!currentTimeFrame) {
      return '...'
    }
    let label
    const startMoment = moment(currentTimeFrame.get('start'))
    const endMoment = moment(currentTimeFrame.get('end'))
    const endIsToday = moment()
      .add(1, 'day')
      .isSame(currentTimeFrame.end, 'day')

    switch (timeFrame) {
      case 'week':
        label = this.weekTimeFrameLabel(
          currentTimeFrame,
          startMoment,
          endMoment
        )
        break
      case 'month':
        label = this.monthTimeFrameLabel(
          currentTimeFrame,
          startMoment,
          endMoment
        )
        break
      case 'year':
        label = startMoment.format('YYYY')
        if (endIsToday) {
          label = i18n.t('labels.last12Months')
        }
        break
      default:
        // TODO - format dates for current Locale using Moment/i18next
        label = `${startMoment.format('MM/DD')} - ${endMoment.format('MM/DD')}`
    }
    return label
  }

  weekTimeFrameLabel(currentTimeFrame, startMoment, endMoment) {
    let label
    const endIsToday = moment()
      .add(1, 'day')
      .isSame(currentTimeFrame.get('end'), 'day')
    const endOfThisWeek = moment(dateRangeModel.week.thisRange.end)
    const endIsThisWeek = endOfThisWeek.isSame(
      currentTimeFrame.get('end'),
      'day'
    )
    if (endIsToday || endIsThisWeek) {
      if (endIsToday && !endIsThisWeek) {
        label = i18n.t('labels.last7Days')
      } else {
        label = i18n.t('labels.thisWeek')
      }
    } else {
      label = `${startMoment.format('MM/DD')} - ${endMoment.format('MM/DD')}`
    }
    return label
  }

  monthTimeFrameLabel(currentTimeFrame, startMoment, _endMoment) {
    let label
    const endIsToday = moment()
      .add(1, 'day')
      .isSame(currentTimeFrame.get('end'), 'day')
    const endOfThisRange = moment(dateRangeModel.month.thisRange.end)
    const endIsThisRange = endOfThisRange.isSame(
      currentTimeFrame.get('end'),
      'day'
    )
    if (endIsToday || endIsThisRange) {
      if (endIsToday && !endIsThisRange) {
        label = i18n.t('labels.last30Days')
      } else {
        label = i18n.t('labels.thisMonth')
      }
    } else {
      label = `${startMoment.format('MMMM')} ${startMoment.format('YYYY')}`
    }
    return label
  }

  timeFrameClick(timeFrame) {
    this.setTimeFrame(timeFrame, null)
  }

  moveTimeFrame(direction) {
    this.setTimeFrame(this.state.currentTimeFrame, direction)
  }

  render() {
    const buttons = [
      // Year/Term Feature hidden until data proves more valuable,
      // some bugs/polish remain on both BE/FE
      // {
      //   label: i18n.t('labels.year'),
      //   onClick: this.timeFrameClick.bind(this, 'year'),
      // },
      // {
      //   label: i18n.t('labels.term'),
      //   onClick: this.timeFrameClick.bind(this, 'term'),
      // },
      {
        label: i18n.t('labels.month'),
        onClick: this.timeFrameClick.bind(this, 'month')
      },
      {
        label: i18n.t('labels.week'),
        onClick: this.timeFrameClick.bind(this, 'week')
      }
    ]

    const leftRightProps = {
      leftClick: this.moveTimeFrame.bind(this, 'prev'),
      rightClick: this.moveTimeFrame.bind(this, 'next')
    }

    const timeframeLabel = this.getTimeFrameLabel(
      this.state.currentTimeFrame,
      this.props.currentTimeframeID
    )

    const selectedButtonIndex = this.timeframeLabels.indexOf(
      this.state.currentTimeFrame
    )

    const containerClass = classnames('chart-controls', {
      disabled: this.props.disabled
    })

    return (
      <div className={containerClass} data-spec="chart-controls">
        <ButtonGroup buttons={buttons} defaultSelected={selectedButtonIndex} />
        <div className="label-leftright-container">
          <span className="timeframe-label" data-spec="timeframe-label">
            {timeframeLabel}
          </span>
          <LeftRight {...leftRightProps} />
        </div>
      </div>
    )
  }
}

ChartControls.propTypes = {
  allData: chartDataShape,
  currentTimeframeID: PropTypes.string,
  currentTimeFrame: timeframeTypesShape,
  updateChartDataView: PropTypes.func.isRequired
}

export default ChartControls
