import React, { useState, createRef } from 'react'
import { Modal, ModalProvider } from '../kiddom/modal/modal'
import Button from '../kiddom/button/button'
import './sisMatchesModal.scss'
import i18n from '../../helpers/i18n'

const confirmMatchingLabel = i18n.t('titles.sis.confirmMatching')
const cancelLabel = i18n.t('labels.cancel')
const confirmLabel = i18n.t('labels.confirm')
const confirmSchoolMatchesTitle = i18n.t('titles.sis.confirmSchoolMatches')
const confirmMatchesDescription = i18n.t(
  'passages.sis.confirmMatchesDescription'
)

const SISMatchesModal = ({ modalState, toggleModal, submitMatchesHandler }) => {
  const modalRef = createRef()
  const appRootRef = createRef()
  return (
    <ModalProvider appRootRef={appRootRef} modalRootRef={modalRef}>
      <div ref={appRootRef}>
        <Modal
          className="sis-matches-modal"
          isOpen={modalState}
          title={confirmSchoolMatchesTitle}
          closeActionHandle={toggleModal}
          primaryActionText={confirmLabel}
          secondaryActionText={cancelLabel}
          primaryActionHandle={submitMatchesHandler}
        >
          <p> {confirmMatchesDescription} </p>
        </Modal>
      </div>
      <div className="modal-root" ref={modalRef}></div>
    </ModalProvider>
  )
}

const SubmitMatchesButton = ({ submitMatchesHandler }) => {
  const [modalState, setModalState] = useState(false)
  const toggleModal = () => {
    setModalState(!modalState)
  }
  return (
    <div>
      <Button
        onClick={toggleModal}
        modalState={modalState}
        className="sis-confirm-matches"
      >
        {confirmMatchingLabel}
      </Button>
      <SISMatchesModal
        modalState={modalState}
        toggleModal={toggleModal}
        submitMatchesHandler={submitMatchesHandler}
      />
    </div>
  )
}

export default SubmitMatchesButton
