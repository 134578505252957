import FeatureContext from '../context/featureContext'
import { FeatureKey } from './useFeatures'
import { useContext } from 'react'

export type GetFeatureVariantFn = (feature: FeatureKey) => any

export default function useGetFeatureVariant(): GetFeatureVariantFn {
  const features = useContext(FeatureContext)
  const featureEnabled = (feature: FeatureKey) => {
    if (!features) return undefined
    return features?.[feature]
  }
  return featureEnabled
}
