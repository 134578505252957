import * as actionIDs from './actionIDs'
import { makeResourceActionsCreators } from './actionHelpers'

export const sendSISCredentials = makeResourceActionsCreators(
  actionIDs.sisIntegration.sendSISCredentials,
  {
    begin: ['districtID', 'clientID', 'clientSecret', 'clientHost'],
    initialize: ['districtID', 'clientID', 'clientSecret', 'clientHost'],
    success: ['response'],
    error: ['error']
  }
)

export const sisCredentials = {
  read: makeResourceActionsCreators(actionIDs.sisCredentials.read, {
    begin: [],
    initialize: [],
    success: ['response'],
    error: ['error']
  })
}

export const sisSchoolMatches = {
  read: makeResourceActionsCreators(actionIDs.sisSchoolMatches.read, {
    begin: ['districtID'],
    initialize: ['districtID'],
    success: ['response'],
    error: ['error']
  }),
  update: makeResourceActionsCreators(actionIDs.sisSchoolMatches.update, {
    begin: ['districtID', 'pairs'],
    initialize: ['districtID', 'pairs'],
    success: ['response'],
    error: ['error']
  })
}

export const fetchSISConfigurations = makeResourceActionsCreators(
  actionIDs.sisConfigurations.read,
  {
    begin: ['districtID'],
    success: ['response']
  }
)

export const updateSISConfigurations = makeResourceActionsCreators(
  actionIDs.sisConfigurations.update,
  {
    begin: ['districtID', 'schedule', 'schoolIDs', 'gradeIDs', 'subjectIDs'],
    success: ['districtID', 'schedule', 'schoolIDs', 'gradeIDs', 'subjectIDs'],
    error: ['error']
  }
)
