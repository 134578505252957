import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { gradeSymbol } from '../../helpers/gradeLevels'
import { gradeLevelObjectsShape } from '../../helpers/propShapes/common'

import './gradeLevelSelect.scss'

export const GradeLevelSelectPresenter = ({
  gradeLevels,
  toggleGradeLevel,
  values,
  size
}) => {
  const containerClass = classnames('grade-levels-input-row', {
    smaller: size === 'smaller'
  })
  return (
    <div className={containerClass}>
      {gradeLevels.map((gradeLevel) => {
        const gradeLevelID = gradeLevel.id
        const checked = values && values.has(gradeLevelID)
        const circleClass = classnames('grade-level-input-circle', {
          smaller: size === 'smaller',
          checked
        })
        return (
          <div
            key={gradeLevelID}
            className="grade-level-input-container"
            onClick={() => toggleGradeLevel(gradeLevelID)}
          >
            <label className="grade-level-input">
              <div className={circleClass}>
                {gradeSymbol(gradeLevel.gradeOrdinality)}
              </div>
            </label>
          </div>
        )
      })}
    </div>
  )
}

GradeLevelSelectPresenter.propTypes = {
  toggleGradeLevel: PropTypes.func.isRequired,
  gradeLevels: gradeLevelObjectsShape,
  values: PropTypes.instanceOf(Set),
  size: PropTypes.oneOf(['smaller'])
}

export default GradeLevelSelectPresenter
