import { fromJS, List } from 'immutable'

import { SKILL_GROUPS, SKILLS } from '../models/resources'
import * as reducerCreators from './reducerCreators'
import { mapArrayObjectsByKey } from '../../helpers/utils'

export const initialState = fromJS({
  byID: {},
  fetching: false,
  error: {}
})

const options = {
  placementFunction: (state, action) =>
    mapArrayObjectsByKey(state, action.skillGroups, 'id', ['byID'])
}

// extra reducer case hooking onto SKILLS getSkills action
const skillOptions = {
  placementFunction: (state, action) => {
    const groupID = action.skillGroupIDs && action.skillGroupIDs[0]
    if (!groupID) {
      return state
    }
    let newState = state
    if (action.skills && action.skills.length) {
      const hasList = newState.get(['byID', groupID, 'skillsIDs'])
      if (!hasList) {
        newState = newState.setIn(['byID', groupID, 'skillsIDs'], new List())
      }
      action.skills.forEach((skill) => {
        newState = newState.updateIn(['byID', groupID, 'skillsIDs'], (list) =>
          list.push(skill.id)
        )
      })
    }
    return newState
  }
}

const getHandlers = reducerCreators.getHandlers(SKILL_GROUPS, {}, options)
const getSkillHandlers = reducerCreators.getHandlers(SKILLS, {}, skillOptions)
const handlers = Object.assign({}, getHandlers, getSkillHandlers)
const skillGroups = reducerCreators.reducerCreator(initialState, handlers)

export default skillGroups
