import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'

import './userFileUploadFailure.scss'

const UserFileUploadFailure = (props) => (
  <div className="upload-failure-wrapper">
    <p>{i18n.t('errors.uploadFailed', { fileName: props.selectedFile })}</p>
  </div>
)

UserFileUploadFailure.propTypes = {
  selectedFile: PropTypes.string.isRequired
}

export default UserFileUploadFailure
