import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../helpers/i18n'

import ButtonGroup from '../ui/buttonGroup/buttonGroup'

import './freeTrialSchoolToggle.scss'

const viewingLabel = i18n.t('labels.viewing')

export const FreeTrialSchoolTogglePresenter = (props) => {
  const isViewingDemoSchool = props.schoolID === props.demoSchoolID
  const className = classnames({
    'is-demo': isViewingDemoSchool,
    'is-real': !isViewingDemoSchool
  })
  const selected = isViewingDemoSchool ? 1 : 0

  return (
    <div className="school-toggle">
      <span className="viewing-label">{viewingLabel}</span>
      <ButtonGroup
        buttons={schoolButtons(props)}
        defaultSelected={selected}
        className={className}
      />
    </div>
  )
}

function schoolButtons(props) {
  return [
    {
      label: i18n.t('labels.yourSchool'),
      onClick: props.onClickRealSchool
    },
    {
      label: i18n.t('labels.demoSchool'),
      onClick: props.onClickDemoSchool
    }
  ]
}

FreeTrialSchoolTogglePresenter.propTypes = {
  schoolID: PropTypes.string.isRequired,
  realSchoolID: PropTypes.string.isRequired,
  demoSchoolID: PropTypes.string.isRequired,
  onClickDemoSchool: PropTypes.func.isRequired,
  onClickRealSchool: PropTypes.func.isRequired
}

export default FreeTrialSchoolTogglePresenter
