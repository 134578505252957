import PropTypes from 'prop-types'

export const personShape = PropTypes.shape({
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string
})

export const classShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  scoredPercent: PropTypes.number,
  sutdentCount: PropTypes.number,
  subjectIDs: PropTypes.arrayOf(PropTypes.string),
  teacherID: PropTypes.string,
  instructorIDs: PropTypes.arrayOf(PropTypes.string),
  masterylevelPercent: PropTypes.number,
  missingPercent: PropTypes.number,
  assignmentsAssigned: PropTypes.number,
  assignmentsScored: PropTypes.number,
  grades: PropTypes.arrayOf(PropTypes.string),
  subjects: PropTypes.arrayOf(PropTypes.string),
  gradeLevelPercent: PropTypes.number,
  teacher: personShape
})

export const gradesShape = PropTypes.arrayOf(
  PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13'
  ])
)

export const gradeLevelObjectsShape = PropTypes.arrayOf(
  PropTypes.shape({
    gradeLevelID: PropTypes.number,
    gradeName: PropTypes.string,
    gradeOrdinality: PropTypes.number,
    id: PropTypes.string
  })
)

export const paginationShape = PropTypes.shape({
  totalPages: PropTypes.number,
  pages: PropTypes.object,
  currentPage: PropTypes.number
})

export const subjectShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  parentID: PropTypes.string,
  isCore: PropTypes.bool
})

export const subjectGroupShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  subjectIDs: PropTypes.arrayOf(PropTypes.string),
  subjects: PropTypes.arrayOf(subjectShape),
  courseIDs: PropTypes.arrayOf(PropTypes.string)
})

export const attachmentShape = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  tempID: PropTypes.string,
  name: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
})

export const errorShape = PropTypes.shape({
  status: PropTypes.number
})
