import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import {
  FILE_TYPE_CSV,
  PARENT_PERMISSION_LETTER_LINK,
  ROLE,
  USER_MANAGEMENT_ROLES
} from '../../helpers/constants'

import FileUpload from '../ui/dragDrop/fileUpload'
import StudentFileFormatIcon from '../../images/logos/student-file-format.svg'
import TeacherFileFormatIcon from '../../images/logos/teacher-file-format.svg'
import UploadIcon from '../../images/icons/upload-icon.svg'

import './userFileUpload.scss'
import { boldText, joinLabels } from '../../helpers/text_util'

const withPeriod = (message) =>
  i18n.t('labels.withPeriod', {
    message
  })
const browseLabel = withPeriod(i18n.t('userActions.browseToUpload'))
const ensureStaffFileContent = withPeriod(
  i18n.t('passages.ensureStaffFileContent')
)
const ensureStudentFileContent = withPeriod(
  i18n.t('passages.ensureStudentFileContent')
)
const uploadStaffDisclaimerText = withPeriod(
  i18n.t('passages.uploadStaffDisclaimer')
)
const uploadStudentsDisclaimerText = withPeriod(
  i18n.t('passages.uploadStudentsDisclaimer')
)
const notePrefix = i18n.t('labels.note')
const yourFileLabel = i18n.t('labels.aCSVFileOr')
const uploadStaffTitle = i18n.t('titles.uploadStaff')
const uploadStudentsTitle = i18n.t('titles.uploadStudents')

const roleLabelIcon = {
  [ROLE.TEACHER]: (
    <TeacherFileFormatIcon
      className="file-format-icon"
      title={uploadStaffTitle}
    />
  ),
  [ROLE.STUDENT]: (
    <StudentFileFormatIcon
      className="file-format-icon"
      title={uploadStudentsTitle}
    />
  )
}

const labelIcon = (role) => (
  <React.Fragment>
    {roleLabelIcon[role]}
    <UploadIcon className="upload-icon" />
  </React.Fragment>
)
const downloadPermissionLetter = i18n.t('passages.downloadPermissionLetter')

const linkText = (label, link, className) => (
  <span className={className} key="link">
    <a href={link} target="_blank" rel="noreferrer">
      {label}
    </a>
  </span>
)

const roleToLabels = {
  [ROLE.TEACHER]: {
    ensureFileContent: ensureStaffFileContent,
    uploadDisclaimer: [uploadStaffDisclaimerText]
  },
  [ROLE.STUDENT]: {
    ensureFileContent: ensureStudentFileContent,
    uploadDisclaimer: [
      boldText(notePrefix, ''),
      uploadStudentsDisclaimerText,
      linkText(
        downloadPermissionLetter,
        PARENT_PERMISSION_LETTER_LINK,
        'download-permission-letter'
      )
    ]
  }
}

const UserFileUpload = (props) => {
  const { parseImportFile, role, schoolID } = props
  const { ensureFileContent, uploadDisclaimer } = roleToLabels[role]

  return (
    <div className="user-file-upload-wrapper">
      <FileUpload
        className="visible"
        keyProp="user-file"
        upload={(file) => parseImportFile(file, schoolID, role)}
        allowMultiple={false}
        acceptTypes={FILE_TYPE_CSV}
        yourFilesLabel={yourFileLabel}
        browseLabel={browseLabel}
        flavorTextLabel={ensureFileContent}
        labelIcon={labelIcon(role)}
      />
      <p className="upload-disclaimer-text">{joinLabels(uploadDisclaimer)}</p>
    </div>
  )
}

UserFileUpload.propTypes = {
  schoolID: PropTypes.string.isRequired,
  role: PropTypes.oneOf(USER_MANAGEMENT_ROLES).isRequired,
  parseImportFile: PropTypes.func.isRequired
}

export default UserFileUpload
