import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import TrashIcon from '../../images/icons/trash_med.svg'
import i18n from '../../helpers/i18n'
import Modal from '../ui/modal/modal'
import Button from '../ui/buttons/buttonV2'
import { softDeleteUserAccounts } from '../../store/models/userListModels'

import './deleteUsers.scss'

const deleteUsersLabel = i18n.t('userActions.deleteUsers')
const trashcanAlt = i18n.t('imageAlt.trashcanLogo')

const DeleteUsersButton = ({
  schoolID,
  selected
}: {
  schoolID: string
  selected?: Set<string>
}) => {
  const history = useHistory()
  const modalRef = useRef<any>(null)

  const submit = async () => {
    modalRef.current.hideVisible()
    // hit endpoint to delete users
    if (schoolID && selected) {
      await softDeleteUserAccounts(schoolID, Array.from(selected))
      history.go(0)
    }
  }

  const deleteButton = (
    <div className="delete-users">
      <TrashIcon title={deleteUsersLabel} alt={trashcanAlt} />
    </div>
  )
  return (
    <div className="delete-users-modal">
      <Modal triggerElement={deleteButton} ref={modalRef}>
        <div className="delete-users-confirmation">
          <h1>{i18n.t('titles.deleteUsers')}</h1>
          <div className="underline" />
          <p>{i18n.t('passages.deleteUsers')}</p>
        </div>
        <Button
          label={deleteUsersLabel}
          className="delete-users-button"
          onClick={submit}
        />
      </Modal>
    </div>
  )
}

export default DeleteUsersButton
