import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { List } from 'immutable'

import {
  getImportIngestionID,
  getResourceByIDs,
  getStudentAccountsBatch,
  getStudentAccountsBatchFetching,
  getStudentAccountsByIngestionID,
  isFetchingStudentAccountsByIngestionID
} from '../../../store/fromState'
import { STUDENTS } from '../../../store/models/resources'
import * as userManagementActions from '../../../store/actions/userManagementActions'
import BulkStudentAccountsButtonPresenter from './bulkStudentAccountsButtonPresenter'
import { ROLE } from '../../../helpers/constants'

export const BulkStudentAccountsButtonContainer = (props) => (
  <BulkStudentAccountsButtonPresenter {...props} />
)

export const mapStateToProps = (state, ownProps) => {
  const ingestionID = getImportIngestionID(state, ROLE.STUDENT)

  return {
    ingestionID,
    selectedStudentAccounts:
      ownProps.userIDs && ownProps.userIDs.size
        ? getResourceByIDs(state, STUDENTS, new List(ownProps.userIDs))
        : null,
    studentAccountsBatch: getStudentAccountsBatch(state),
    studentAccountsIngestion: getStudentAccountsByIngestionID(
      state,
      ingestionID
    ),
    isFetchingBatch: getStudentAccountsBatchFetching(state),
    isFetchingIngestion: isFetchingStudentAccountsByIngestionID(
      state,
      ingestionID
    )
  }
}

export const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    getBatchAccounts: () => {
      return dispatch(
        userManagementActions.studentAccounts.readBatch.begin(
          schoolID,
          ownProps.userIDs
        )
      )
    },
    getStudentAccountsByIngestion: (ingestionID, schoolID) =>
      dispatch(
        userManagementActions.studentAccounts.read.begin(ingestionID, schoolID)
      )
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const ingestionID = stateProps.ingestionID

  const getStudentAccountsByIngestion = () =>
    dispatchProps.getStudentAccountsByIngestion(ingestionID, schoolID)

  return {
    ...stateProps,
    ...ownProps,
    isFetching: ownProps.isLink
      ? stateProps.isFetchingIngestion
      : stateProps.isFetchingBatch,
    studentAccounts: ownProps.isLink
      ? stateProps.studentAccountsIngestion
      : stateProps.studentAccountsBatch,
    getAccounts: ownProps.isLink
      ? getStudentAccountsByIngestion
      : dispatchProps.getBatchAccounts
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(BulkStudentAccountsButtonContainer)
)
