import React from 'react'
import DropDown from '../../ui/dropDown/dropDown'

import './collaboratorAccessSwitcher.scss'
import { CURRICULUM_ACCESS_ROLES } from '../../../helpers/constants'

class CollaboratorAccessSwitcher extends React.Component {
  constructor(props) {
    super(props)
    const { teacherRole } = props
    const currentRole = teacherRole && teacherRole.role
    this.currentIndex = CURRICULUM_ACCESS_ROLES.findIndex(
      (role) => role.role === currentRole
    )
  }

  onChange = (index) => {
    const role = CURRICULUM_ACCESS_ROLES[index].role
    this.props.setCollaboratorRole(this.props.teacher.id, role)
  }

  render() {
    return (
      <DropDown
        className="access-switcher"
        options={CURRICULUM_ACCESS_ROLES}
        defaultIndex={this.currentIndex}
        onChange={this.onChange}
      />
    )
  }
}

export default CollaboratorAccessSwitcher
