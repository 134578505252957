import React from 'react'
import i18n from '../../helpers/i18n'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { CURRICULUM_PATH_ROOT, SCHOOL_PATH_ROOT } from '../../routes/routes'
import * as userPreferences from '../../store/userPreferences'
import { withRouter } from 'react-router-dom'
import withDataLoading from '../hoc/withDataLoading'

import Loading from '../kiddom/loading/loading'
import ProgressCircle from '../ui/progressCircle/progressCircle'
import './onboardingLoading.scss'
import * as resources from '../../store/models/resources'
import * as fromState from '../../store/fromState'
import * as coreActions from '../../store/actions/coreActions'
import * as sendRequest from '../../helpers/sendRequest'

export class OnboardingLoadingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showStepOne: false,
      showStepTwo: false,
      showStepThree: false,
      showStepFour: false
    }

    this.showStepOne = this.showStepOne.bind(this)
    this.showStepTwo = this.showStepTwo.bind(this)
    this.showStepThree = this.showStepThree.bind(this)
    this.showStepFour = this.showStepFour.bind(this)
    this.getAutoPopulateStatus = this.getAutoPopulateStatus.bind(this)
    this.redirectToSchoolPage = this.redirectToSchoolPage.bind(this)
    this.checkForResponse = this.checkForResponse.bind(this)

    setTimeout(this.showStepOne, 500)
  }

  showStepOne() {
    this.setState({ showStepOne: true })
    setTimeout(this.showStepTwo, 2000)
  }

  showStepTwo() {
    this.setState({ showStepTwo: true })
    this.intervalID = setInterval(
      this.checkForResponse,
      2000,
      this.showStepThree,
      false,
      6
    )
  }

  showStepThree() {
    this.setState({ showStepThree: true })
    this.intervalID = setInterval(
      this.checkForResponse,
      2000,
      this.showStepFour,
      false,
      8
    )
  }

  showStepFour() {
    this.setState({ showStepFour: true })
    this.intervalID = setInterval(
      this.checkForResponse,
      2000,
      this.redirectToSchoolPage,
      true
    )
  }

  checkForResponse(nextIntervalFunction, checkCompleted, completedStep = 0) {
    const resp = this.getAutoPopulateStatus()
    resp.then((body) => {
      // If there was an error, report to Rollbar
      if (
        body &&
        (body.error || (body.reponse && body.response.status === 'error'))
      ) {
        clearInterval(this.intervalID)
        window.Rollbar &&
          window.Rollbar.error(
            'Error in checking school autopopulation: ' + body.error.statusText
          )
        this.redirectToSchoolPage()
      }
      // If there is a response and either the process is up to the right completedStep
      // or the process has been completed, call the next function
      if (
        body &&
        body.response &&
        ((checkCompleted && body.response.status === 'completed') ||
          (!checkCompleted && body.response.completed > completedStep))
      ) {
        clearInterval(this.intervalID)
        nextIntervalFunction()
      }
    })
  }

  getAutoPopulateStatus() {
    return sendRequest.authSendRequest(
      'GET',
      `${window.API_SERVER}/api/autopopulate/${this.props.userID}/status`
    )
  }

  redirectToSchoolPage() {
    // Redirect to the page based on the user's preference from onboarding page
    const redirectPath =
      this.props.userPrefFreeTrialPath === userPreferences.CURRICULUM_PATH
        ? CURRICULUM_PATH_ROOT(this.props.realSchoolID)
        : SCHOOL_PATH_ROOT(this.props.demoSchoolID)
    this.props.history.push(redirectPath)
  }

  render() {
    const buildingYourSchoolLabel = i18n.t('labels.buildingYourSchool')
    const creatingYourAccountLabel = i18n.t('labels.creatingYourAccount')
    const addingDemoTeachersLabel = i18n.t('labels.addingDemoTeachers')
    const addingFinishingTouchesLabel = i18n.t('labels.addingFinishingTouches')
    const addingAssignmentsLabel = i18n.t('labels.addingAssignments')
    const stepOneClasses = classnames('onboarding-loading-step', {
      'step-visible': this.state.showStepOne
    })
    const stepTwoClasses = classnames('onboarding-loading-step', {
      'step-visible': this.state.showStepTwo
    })
    const stepThreeClasses = classnames('onboarding-loading-step', {
      'step-visible': this.state.showStepThree
    })
    const stepFourClasses = classnames('onboarding-loading-step', {
      'step-visible': this.state.showStepFour
    })
    return (
      <div className="onboarding-loading">
        <div className="onboarding-loading-animation">
          <Loading type="academy" />
        </div>
        <div className="onboarding-loading-header">
          {buildingYourSchoolLabel}
        </div>
        <div className="onboarding-loading-steps-container">
          <div className={stepOneClasses}>
            <ProgressCircle innerHeight={100} />
            <div className="onboarding-loading-step-label">
              {creatingYourAccountLabel}
            </div>
          </div>
          <div className={stepTwoClasses}>
            <ProgressCircle innerHeight={100} />
            <div className="onboarding-loading-step-label">
              {addingDemoTeachersLabel}
            </div>
          </div>
          <div className={stepThreeClasses}>
            <ProgressCircle innerHeight={100} />
            <div className="onboarding-loading-step-label">
              {addingAssignmentsLabel}
            </div>
          </div>
          <div className={stepFourClasses}>
            <ProgressCircle innerHeight={100} />
            <div className="onboarding-loading-step-label">
              {addingFinishingTouchesLabel}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    ...fromState.getFreeTrialSchoolIDs(state),
    userID: fromState.getUserProp(state, 'id'),
    userPrefFreeTrialPath: fromState.getUserPreference(
      state,
      userPreferences.FREE_TRIAL_PATH_CHOICE
    )
  }
}

const generateDataLoaders = () => [coreActions.schools.read.begin()]

const generateHasLoadedCheckers = () => ({
  hasLoaded: [[fromState.resourcesLoaded, [[resources.SCHOOLS]]]]
})

export default withDataLoading(
  withRouter(connect(mapStateToProps, null)(OnboardingLoadingContainer)),
  generateDataLoaders,
  generateHasLoadedCheckers
)
