import React from 'react'
import StudentClassOverviewPresenter from './studentClassOverviewPresenter'

class StudentClassOverviewContainer extends React.Component {
  render() {
    return <StudentClassOverviewPresenter {...this.props} />
  }
}

export default StudentClassOverviewContainer
