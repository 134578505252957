import {
  LDClient,
  LDUser,
  LDOptions,
  initialize
} from 'launchdarkly-js-client-sdk'
import { useEffect, useMemo, useState } from 'react'

import { Dictionary } from 'lodash'
import { Maybe } from '../utils/types'
import { useSelector } from 'react-redux'
import * as fromState from '../store/fromState'
interface LDConfig {
  key: string
  clientId: string
  hash: string
  env: string
}

declare global {
  interface Window {
    LD_CONFIG: LDConfig
  }
}

export enum ExternalFeature {
  HelpScoutBeacon = 'help-scout-beacon'
}

export enum PlatformFeature {
  KiddomLivePlatform = 'feature.live-class-and-audio-video'
}

export const AnyFeature = { ...ExternalFeature, ...PlatformFeature }

export type FeatureKey = ExternalFeature | PlatformFeature

export type Feature = {
  [key in FeatureKey]?: boolean
}

export default function useFeatures() {
  const [features, setFeatures] = useState<Feature>()
  const [client, setClient] = useState<Maybe<LDClient>>()
  const [connected, setConnected] = useState<boolean>(false)
  const userEmail = useSelector((state) => {
    return fromState.getUserProp(state, 'email')
  })
  const userId: number = useSelector((state) => {
    return fromState.getUserProp(state, 'id')
  })
  const userHash = useSelector((state) => {
    return fromState.getUserProp(state, 'user_hash')
  })
  const config: LDConfig = useMemo(() => window.LD_CONFIG || {}, [])
  const custom: Dictionary<any> = useMemo(() => {
    return {
      is_internal: userEmail?.endsWith('kiddom.co'),
      is_academy: true
    }
  }, [userEmail])

  useEffect(() => {
    const { clientId } = config

    const options: LDOptions = {
      hash: userHash
    }

    const userContext: LDUser = {
      key: `${userId}`,
      custom
    }

    const shouldSetClient = !client && userId

    try {
      shouldSetClient && setClient(initialize(clientId, userContext, options))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    client?.on('ready', () => {
      setConnected(true)
    })

    return () => {
      client?.close()
    }
  }, [config, custom, client, userId, setConnected, userHash])

  useEffect(() => {
    if (!connected) return

    const featuresDict: Feature = {}

    Object.values(AnyFeature).forEach((key) => {
      const variation = client ? client.variation(key, false) : false
      featuresDict[key] =
        typeof variation !== 'boolean'
          ? variation
          : variation || Boolean(client?.getUser()?.custom?.[key]) || false
    })

    setFeatures(featuresDict)
  }, [client, connected])

  return features
}
