import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './pill.scss'

export const Pill = ({
  text,
  icon,
  rightIcon,
  theme,
  className,
  ...others
}) => {
  delete others['data-spec']
  return (
    <div className={classnames('pill', theme, className)} {...others}>
      {icon}
      <span>{text}</span>
      {rightIcon}
    </div>
  )
}

Pill.defaultProps = {
  theme: 'dark'
}

Pill.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light', 'blue', 'gray'])
}

export default Pill
