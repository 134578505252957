import React from 'react'

import i18n from '../../helpers/i18n'
import Loading from '../kiddom/loading/loading'
import UserInfoHeader from '../ui/userInfoHeader/userInfoHeader'
import GuidanceTile from '../common/guidance/guidanceTile'
import withOptionalSidebar from '../hoc/withOptionalSidebar'

const StudentOverviewPresenter = (props) => {
  if (props.isLoading || props.needToFetchData) {
    return <Loading type="academy" />
  }
  const { student } = props
  const userHeaderProps = {
    id: student.get('id'),
    name: student.get('name'),
    avatar: student.get('avatar'),
    excludeSubtitle: true
  }

  const classesLabel = i18n.t('labels.classes')
  return (
    <div className="student-overview page">
      <UserInfoHeader {...userHeaderProps} />
      <h2>{classesLabel}</h2>
      <div className="student-class-cards card-container">
        {props.classCards}
      </div>
    </div>
  )
}

export default withOptionalSidebar(StudentOverviewPresenter, GuidanceTile)
