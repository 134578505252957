import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './button.scss'

export const Button = (props) => {
  const { buttonStyle, className, label, icon, mobileLabel, ...others } = props
  return (
    <button
      {...others}
      className={classnames('academy-button', buttonStyle, className)}
    >
      {icon}
      <span className="label">{label}</span>
      <span className="mobile-only">{mobileLabel}</span>
    </button>
  )
}

Button.propTypes = {
  buttonStyle: PropTypes.oneOf([
    'primary',
    'secondary',
    'text',
    'flat-green',
    'blue'
  ])
}

Button.defaultProps = {
  buttonStyle: 'primary',
  icon: null
}

export default Button
