import { stringifyArray } from './transformHelpers'
import { randomInt } from '../../helpers/utils'
import { gradeSymbol } from '../../helpers/gradeLevels'
import { AVATAR_FALLBACK_SRC, NOT_APPLICABLE } from '../../helpers/constants'

export const person = (data, schoolID = null) => ({
  id: (data.luser_id || data.id).toString(),
  avatar: data.image_url || AVATAR_FALLBACK_SRC,
  name: data.name,
  email: data.email,
  schoolID: schoolID
})

export const clazz = (data) => {
  return {
    id: data.class_id.toString(),
    name: data.class_alias,
    teacherID: data.teacher_id.toString(),
    gradeIDs: data.grade_ids
      ? data.grade_ids.map((x) => x.toString())
      : [NOT_APPLICABLE],
    grades: data.grade_ids
      ? data.grade_ids.map((x) => gradeSymbol(x))
      : [NOT_APPLICABLE],
    subjectIDs: stringifyArray(data.subject_ids),
    instructorIDs: stringifyArray(data.collaborator_ids)
  }
}

export const classStatistics = (statistics) => {
  const scoredPercent =
    statistics.number_of_graded_assignments /
    (statistics.number_of_assigments || 1)
  return {
    assignmentsAssigned: statistics.number_of_assigments, // this is NOT assigned, but created?
    assignmentsScored: statistics.number_of_graded_assignments,
    scoredPercent: Math.round(100 * scoredPercent),
    missingPercent:
      (scoredPercent && Math.round(100 * (1 - scoredPercent))) || 0,
    studentCount: statistics.number_of_students,
    masteryLevelPercent: statistics.mastery_percentage
  }
}

export const classes = (data) => (data.classes || []).map((c) => clazz(c))

export const classesStatistics = (data) => {
  const transformed = {}
  Object.keys(data.statistics).forEach((id) => {
    transformed[id] = classStatistics(data.statistics[id])
  })
  return transformed
}

// TODO - Randomized Hard Coded data for Demo Purposes Only
const randomNames = [
  'Victoria Vanderveer',
  'Bethanie Blanco',
  'Annie Armendariz',
  'Lizbeth Lopinto',
  'Carly Clubb',
  'Mara Medina',
  'Javier January',
  'Amparo Armstrong',
  'Desire Duhaime',
  'Adrien Austria',
  'Junie Julio',
  'Donna Damron',
  'Chet Canova',
  'Marisela Mcdow',
  'Herb Hao',
  'Juli Jefcoat',
  'Daniele Dixion',
  'Delena Duong',
  'Renate Rosamond',
  'Kristi Ketron'
]

let nameIndex = 0

export const transformSchool = (school) => {
  const principalName = randomNames[nameIndex]
  const teacherCount = randomInt(24, 96)
  const studentCount = randomInt(240, 1024)
  nameIndex++
  return {
    id: school.id.toString(),
    schoolName: school.name,
    districtID: school.district_id,
    county: school.county,
    state: school.state,
    city: school.city,
    admins: school.admins || [],
    // TODO - get prinicpals/add to /user/schools endpoint?
    // Randomize Data for Demos - replace when real data available
    principalName,
    teacherCount,
    studentCount,
    activeTeachers: randomInt(72, 96),
    studentAchievement: randomInt(65, 94),
    isDemo: school.is_demo
  }
}

export const transformSchoolList = (schoolList) =>
  (schoolList.school_list || []).map(transformSchool)

// subjects
/* recursive flatten subjects array based on children: 'branches' */
export const flattenSubjects = (subjects) => {
  const flatSubjects = []
  subjects.forEach((subject) => {
    const {
      subject_name,
      subject_id,
      parent_id: parentID,
      is_core,
      branches
    } = subject
    flatSubjects.push({
      name: subject_name,
      id: subject_id.toString(),
      parentID: parentID ? parentID.toString() : null,
      isCore: Boolean(is_core)
    })
    if (branches && branches.length) {
      flatSubjects.push(...flattenSubjects(branches))
    }
  })
  return flatSubjects
}

export const grades = (data) =>
  data.grades.map((gradeLevel) => ({
    id: gradeLevel.grade_level_id.toString(),
    // TODO(chris): Deprecate gradeLevelID
    gradeLevelID: gradeLevel.grade_level_id,
    gradeName: gradeLevel.grade_name,
    gradeOrdinality: gradeLevel.grade_oridinality
  }))

export const teachers = (data, schoolID) => {
  return data.teachers.map((teacher) => {
    return person(teacher, schoolID)
  })
}

export const student = (data) => ({
  ...person(data),
  childID: data.child_id.toString(),
  username: (data.email ? data.email : data.login_name) || ''
})

export const students = (data) => ({
  students: data.students.map((s) => student(s)),
  totalPages: data.total_pages
})

export const studentsBatch = (data) =>
  data.map((respStudent) => student(respStudent))

export const studentStatistics = (data, withRecentGrowth = false) => {
  const stats = {
    assignmentsSubmitted: data.submitted_assignments,
    assignmentsDue: data.assignments_due,
    assignmentsMissing: data.assignments_missing
  }
  if (withRecentGrowth) {
    stats.recentGrowth = data.recent_growth
  }
  return stats
}

export const studentsStatistics = (data) => {
  const transformed = {}
  Object.keys(data).forEach((id) => {
    transformed[id] = studentStatistics(data[id], true)
  })
  return transformed
}

export const schools = (data) => data.map(transformSchool)

export const subjects = (data) => flattenSubjects(data.subjects)
