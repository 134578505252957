import { sortSubjectsByNameFunc } from './subjects'

export const numCoursesInSubjectGroup = (subjectGroup) =>
  (subjectGroup.get('courseIDs') && subjectGroup.get('courseIDs').size) || 0

export const coursesWereFetchedForSubjectGroup = (subjectGroup) =>
  subjectGroup.get('courseIDs')

export const subjectGroupTitleAndSubjectNames = (subjectGroup) => {
  const subjectNames = subjectGroup.subjects.map((s) => s.name).sort()
  const title = subjectNames.join(subjectNames.length === 2 ? ' & ' : ', ')

  return { title, subjectNames }
}

export const sortSubjectGroups = (a, b) => {
  const subjectsForA = a.subjects.sort(sortSubjectsByNameFunc)
  const subjectsForB = b.subjects.sort(sortSubjectsByNameFunc)
  const numSubjectsForA = subjectsForA.length
  const numSubjectsForB = subjectsForB.length

  // Ordering is:
  //  Single core subjects
  //  Single non-core subjects
  //  Multi-subject sorted alphabetically
  if (numSubjectsForA === 1 && numSubjectsForB > 1) {
    return -1
  } else if (numSubjectsForA > 1 && numSubjectsForB === 1) {
    return 1
  } else if (numSubjectsForA === 1 && numSubjectsForB === 1) {
    const isFirstSubjectInACore = subjectsForA[0].isCore
    const isFirstSubjectInBCore = subjectsForB[0].isCore

    if (isFirstSubjectInACore && !isFirstSubjectInBCore) {
      return -1
    } else if (!isFirstSubjectInACore && isFirstSubjectInBCore) {
      return 1
    }

    const firstSubjectNameInA = subjectsForA[0].name
    const firstSubjectNameInB = subjectsForB[0].name
    const subjectCompare = firstSubjectNameInA.localeCompare(
      firstSubjectNameInB
    )
    if (subjectCompare !== 0) {
      return subjectCompare
    }

    // They have the same subjects. To make the function deterministic, just compare subject group IDs
    return a.id - b.id
  } else {
    return subjectsForA
      .map((s) => s.name)
      .join()
      .localeCompare(subjectsForB.map((s) => s.name).join())
  }
}
