import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'

import LoadingDots from '../common/loading/loadingDots'

import './userFileUploadProgress.scss'

const UserFileUploadProgress = (props) => (
  <div className="upload-progress-wrapper">
    <h4>{i18n.t('titles.uploading', { fileName: props.selectedFile })}</h4>
    <LoadingDots theme="dark" />
  </div>
)

UserFileUploadProgress.propTypes = {
  selectedFile: PropTypes.string.isRequired
}

export default UserFileUploadProgress
