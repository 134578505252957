// models
export const CLASSES = 'classes'
export const TOP_CLASSES = 'topClasses'
export const TEACHERS = 'teachers'
export const GRADES = 'grades'
export const INACTIVE_TEACHERS = 'inactiveTeachers'
export const UNACTIVATED_TEACHERS = 'unactivatedTeachers'
export const STUDENTS = 'students'
export const STUDENTS_BATCH = 'studentsBatch'
export const STUDENT_ACTIVITY_LISTS = 'studentActivityLists'
export const STUDENT = 'student'
export const STUDENT_ASSIGNMENT_STATISTICS = 'studentAssignmentStatistics'
export const STUDENT_PERFORMANCE = 'studentPerformance'
export const STUDENT_CLASSES_STATISTICS = 'studentClassesStatistics'
export const STUDENT_CLASS_PERFORMANCE = 'studentClassPerformance'
export const SCHOOLS = 'schools'
export const SUBJECTS = 'subjects'
export const SUBJECTS_AND_COURSES = 'subjectsAndCourses'
export const TEACHER_AGGREGATE = 'teacherAggregate'
export const USER = 'user'
export const DISTRICT = 'district'
export const REPORTING_METHODS = 'reportingMethods'
export const SCHOOL_SUBJECT_GROUPS = 'schoolSubjectGroups'
export const COURSES = 'courses'
export const SKILLS = 'skills'
export const INDIVIDUAL_SKILLS = 'individualSkills'
export const ASSETS = 'assets'
export const SKILL_GROUPS = 'skillGroups'
export const CURRICULUM = 'curriculum'
export const UNITS = 'units'

// user management
export const USER_MANAGEMENT = 'userManagement'
export const STUDENT_ACCOUNTS = 'studentAccounts'
export const TEACHER_ACCOUNTS = 'teacherAccounts'

// sis integration
export const SIS_INTEGRATION = 'sisIntegration'
export const SIS_CONFIGURATIONS = 'sisConfigurations'

// reports
export const TEACHER_ENGAGEMENT = 'teacherEngagement'
export const STUDENT_ACHIEVEMENT = 'studentAchievement'
export const TEACHER_REPORTS = 'teacherReports'

export const EVENT = 'event'
