import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Kiddoms
import i18n from '../../../helpers/i18n'
import * as fromState from '../../../store/fromState'
import ProgressBar from '../../charts/progressBar'
import { reportingMethodScaleName } from '../../../helpers/utils'
import { gradesLabel } from '../../../helpers/gradeLevels'
import SubjectIcon from '../../ui/subjectIcon'
import { neutralBkgndLight } from '../../../helpers/colors'
import { reportingMethodShape } from '../../../helpers/propShapes/chartDataShapes'
import { CLASSES, STUDENTS, TEACHERS } from '../../../store/models/resources'
import withToJS from '../../hoc/withToJS'
import Card from './card'
import './studentClassCard.scss'

class StudentClassCard extends React.Component {
  render() {
    const cardProps = {
      title: this.props.classLabel,
      subtitle: i18n.t('titles.teacherNameDotGrade', {
        teacherName: this.props.teacherName,
        gradeLabel: gradesLabel(this.props.grades)
      }),
      iconComponent: <SubjectIcon names={this.props.subjectNames} />,
      statsTitle: i18n.t('labels.assignments'),
      stats: [
        {
          stat: this.props.assignmentsSubmitted,
          label: i18n.t('labels.submitted')
        },
        {
          stat: this.props.assignmentsDue,
          label: i18n.t('labels.due')
        },
        {
          stat: this.props.assignmentsMissing,
          label: i18n.t('labels.missing')
        }
      ],
      className: 'StudentClassCard',
      onClick: this.props.onClick
    }

    const masteryLabel = reportingMethodScaleName(
      this.props.mastery,
      this.props.reportingMethod
    )
    return (
      <Card {...cardProps}>
        <div className="student-progress-container">
          <p className="mastery">{masteryLabel}</p>
          <ProgressBar
            progress={this.props.mastery}
            showLabel={false}
            height={24}
            backgroundColor={neutralBkgndLight}
            reportingMethod={this.props.reportingMethod}
          />
        </div>
      </Card>
    )
  }
}

StudentClassCard.propTypes = {
  classLabel: PropTypes.string.isRequired,
  teacherName: PropTypes.string.isRequired,
  grades: PropTypes.arrayOf(PropTypes.string).isRequired,
  subjectNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  assignmentsSubmitted: PropTypes.number.isRequired,
  assignmentsDue: PropTypes.number.isRequired,
  assignmentsMissing: PropTypes.number.isRequired,
  mastery: PropTypes.number.isRequired,
  reportingMethod: reportingMethodShape
}

const mapStateToProps = (state, ownProps) => {
  const clazz = fromState.getResourceByID(state, CLASSES, ownProps.classID)
  const student = fromState.getResourceByID(state, STUDENTS, ownProps.studentID)
  if (!student || !clazz) {
    return null
  }

  const subjectNames = fromState
    .mapSubjectsByID(state, clazz.get('subjectIDs'))
    .map((subject) => subject.get('name'))
  const teacher = fromState.getResourceByID(
    state,
    TEACHERS,
    clazz.get('teacherID')
  )
  const studentClass = student.get('classStatisticsByID').get(ownProps.classID)
  return {
    classLabel: clazz.get('name'),
    subjectNames,
    teacherName: teacher.get('name'),
    grades: clazz.get('grades'),
    assignmentsSubmitted: studentClass.get('assignmentsSubmitted'),
    assignmentsDue: studentClass.get('assignmentsDue'),
    assignmentsMissing: studentClass.get('assignmentsMissing'),
    mastery: studentClass.get('performance')
  }
}

const WrappedStudentCard = withToJS(StudentClassCard)
export default connect(mapStateToProps, null)(WrappedStudentCard)
