import { fromJS, Map, List } from 'immutable'
import * as reducerCreators from './reducerCreators'
import { mapArrayObjectsByKey } from '../../helpers/utils'
import {
  SCHOOL_SUBJECT_GROUPS,
  SUBJECTS_AND_COURSES
} from '../models/resources'
import * as actionIDs from '../actions/actionIDs'

export const initialState = Map({
  byID: Map(),
  fetching: Map(),
  error: Map()
})

const initSchoolSubjectGroups = (state, action) =>
  state.setIn(['fetching', 'bySchoolID', action.schoolID], true)

const getSchoolSubjectGroupsError = (state, action) => {
  const newState = state.setIn(
    ['fetching', 'bySchoolID', action.schoolID],
    false
  )
  return newState.setIn(['error', 'bySchoolID', action.schoolID], action.error)
}

const getSchoolSubjectGroupsSuccess = (state, action) => {
  let newState = state.setIn(['fetching', 'bySchoolID', action.schoolID], false)
  newState = newState.setIn(['error', 'bySchoolID', action.schoolID], null)
  return mapArrayObjectsByKey(
    newState, // store
    action.subjectGroups, // objectsList
    'id', // storeBy
    ['byID'] // storeAt
  )
}

const getSubjectsAndCoursesSuccess = (state, action) => {
  const subjects = []
  action.subjectsAndCourses.forEach((sac) => {
    const subj = sac.subject
    subj.courseIDs = List(sac.courses.map((course) => course.id))
    subjects.push(subj)
  })
  return mapArrayObjectsByKey(state, subjects, 'id', ['byID'])
}

const getCoursesSuccess = (state, action) => {
  return state.setIn(
    ['byID', action.schoolSubjectGroupID, 'courseIDs'],
    List(action.courses.map((course) => course.id))
  )
}

const createSubjectGroupOptions = {
  fetchingLocation: (state, action, fetching) => {
    let newState = state
    newState = newState.setIn(['error', 'byID', action.tempID], false)
    return newState.setIn(['fetching', 'byID', action.tempID], fetching)
  },
  errorLocation: (state, action, error) =>
    state.setIn(['error', 'byID', action.tempID], fromJS(error)),
  placementFunction: (state, action, tempObj = null) => {
    let newState = state
    const subjectGroup = tempObj || action.schoolSubjectGroup

    if (!tempObj) {
      newState = newState.removeIn('byID', action.tempID)
      newState = newState.setIn(['byID', subjectGroup.id], fromJS(subjectGroup))
      newState = newState.setIn(
        ['byID', subjectGroup.id, 'tempID'],
        action.tempID
      )
    }
    return newState
  },
  template: (state, action) => {
    return {
      id: action.tempID,
      type: action.subjectType,
      subjectIDs: [action.subjectID]
    }
  }
}

const subjectGroupsHandlers = reducerCreators.getHandlers(
  SCHOOL_SUBJECT_GROUPS,
  {
    [actionIDs.schoolSubjectGroups.read.initialize]: initSchoolSubjectGroups,
    [actionIDs.schoolSubjectGroups.read.success]: getSchoolSubjectGroupsSuccess,
    [actionIDs.schoolSubjectGroups.read.error]: getSchoolSubjectGroupsError
  }
)

const subjectAndCoursesHandlers = reducerCreators.getHandlers(
  SUBJECTS_AND_COURSES,
  {
    [actionIDs.subjectsAndCourses.read.success]: getSubjectsAndCoursesSuccess
  }
)

const createSubjectGroupHandlers = reducerCreators.createHandlers(
  SCHOOL_SUBJECT_GROUPS,
  {},
  createSubjectGroupOptions
)

const coursesHandlers = {
  [actionIDs.courses.read.success]: getCoursesSuccess
}

const handlers = Object.assign(
  {},
  subjectGroupsHandlers,
  subjectAndCoursesHandlers,
  createSubjectGroupHandlers,
  coursesHandlers
)

const subjectGroups = reducerCreators.reducerCreator(initialState, handlers)

export default subjectGroups
