import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { CLASS_URL, URL_WITH_GUIDANCE } from '../../routes/routes'
import {
  TEACHER_PROFILE_VIEWED,
  TEACHER_VIEWED_IN_CLASSROOM
} from '../../store/userPreferences'
import * as coreActions from '../../store/actions/coreActions'
import ClassCard from '../common/cards/classCard'
import * as fromState from '../../store/fromState'
import TeacherOverviewPresenter from './teacherOverviewPresenter'
import SubjectIcon from '../ui/subjectIcon'
import {
  CLASSES,
  REPORTING_METHODS,
  SUBJECTS,
  TEACHERS
} from '../../store/models/resources'
import { aggregateTeacherData } from '../../helpers/utils'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'

const WrappedCardPresenter = withToJS(ClassCard)
const WrappedSubjectIcon = withToJS(SubjectIcon)
const WrappedPresenter = withToJS(TeacherOverviewPresenter)

class TeacherOverviewContainer extends React.Component {
  cardClick = (classID) => {
    const classUrl = URL_WITH_GUIDANCE(CLASS_URL(classID))
    window.open(classUrl)
    if (this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(
        TEACHER_VIEWED_IN_CLASSROOM,
        this.props.shouldSetUserPref
      )
    }
  }

  generateCards = () => {
    const cards = []
    this.props.classes.forEach((clazz, index) => {
      const teacher = clazz.get('teacher') || {}
      const clazzProps = {
        subjects: clazz.get('subjects'),
        grades: clazz.get('grades'),
        assignmentsAssigned: clazz.get('assignmentsAssigned'),
        scoredPercent: clazz.get('scoredPercent'),
        missingPercent: clazz.get('missingPercent'),
        studentCount: clazz.get('studentCount'),
        masteryLevelPercent: clazz.get('masteryLevelPercent'),
        title: clazz.get('name') || teacher.name || ''
      }
      cards.push(
        <WrappedCardPresenter
          {...clazzProps}
          iconComponent={<WrappedSubjectIcon names={clazz.get('subjects')} />}
          schoolID={this.props.schoolID}
          onClick={this.cardClick.bind(this, clazz.get('id'))}
          history={this.props.history}
          key={`class-card-${index}`}
        />
      )
    })
    return cards
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(
        TEACHER_PROFILE_VIEWED,
        this.props.shouldSetUserPref
      )
      this.userPrefSet = true
    }
  }

  componentDidMount() {
    this.tryToSetUserPref()
  }

  componentDidUpdate() {
    this.tryToSetUserPref()
  }

  render() {
    return (
      <WrappedPresenter {...this.props} classCards={this.generateCards()} />
    )
  }
}

const buildTeacherData = (state, props) => {
  const teacherID = props.match.params.teacherID
  let teacher = fromState.getResourceByID(state, TEACHERS, teacherID)
  const subjects = fromState.getSubjects(state)
  const classes = fromState
    .getList(state, CLASSES)
    .filter(
      (c) =>
        fromState.getResourceByID(state, TEACHERS, c.get('teacherID')) &&
        c.get('instructorIDs').includes(teacherID)
    )
    .map((clazz) =>
      clazz.merge({
        subjects: fromState
          .mapSubjectsByID(state, clazz.get('subjectIDs'))
          .map((subject) => subject.get('name')),
        teacher: fromState.getResourceByID(
          state,
          TEACHERS,
          clazz.get('teacherID')
        )
      })
    )

  teacher = aggregateTeacherData(classes, teacher, subjects)

  return { teacher, classes }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const { stillFetching, haveAllData } = ownProps
  if (stillFetching || !haveAllData) {
    return { isLoading: true, schoolID }
  }
  const { teacher, classes } = buildTeacherData(state, ownProps)
  const renderSidebar =
    fromState.isUserFreeTrial(state) &&
    fromState.getSchoolIsDemo(state, schoolID)
  const shouldSetUserPref = fromState.isSchoolFreeTrialAndDemo(state, schoolID)
  return {
    schoolID,
    teacher,
    classes,
    renderSidebar,
    shouldSetUserPref,
    userPrefDataLoaded: fromState.userPrefDataLoaded(state)
  }
}

export const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    checkAndSetUserPref: (userPref, shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: userPref,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    }
  }
}

const generateDataLoaders = (props) => [
  coreActions.teacherAggregate.read.begin(props.match.params.schoolID)
]
const reloadProps = ['schoolID']

const generateLoadingProps = (props) => ({
  stillFetching: [
    [
      fromState.isFetchingResources,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ],
  haveAllData: [
    [
      fromState.resourcesLoaded,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ]
})

TeacherOverviewContainer.defaultProps = {
  classes: []
}

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(TeacherOverviewContainer),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
