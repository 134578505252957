import moment from 'moment'
import * as colors from '../../../helpers/colors'

function getNextDataPoint(currentDatasetIndex, dataIndex, dataSets) {
  if (currentDatasetIndex + 1 < dataSets.length) {
    return dataSets[currentDatasetIndex + 1].data[dataIndex].y
  } else {
    return 0
  }
}

const annotationModel = {
  borderWidth: 12,
  mode: 'vertical',
  type: 'box',
  xMin: null,
  xMax: null,
  yMax: null,
  yMin: null,
  xScaleID: 'x-axis-0',
  yScaleID: 'y-axis-0',
  color: colors.dataExceedingA,
  borderColor: 'transparent'
}

function generateAnnotations(amount) {
  const annotations = []
  for (let i = 0; i < amount; i++) {
    const annotation = Object.assign({}, annotationModel)
    annotation.color = colors.getReportingMethodColorsByIndex(amount, i)
    annotations.push(annotation)
  }
  return annotations
}

export function studentAchievementTooltipOptions(chartParentRef, numDataSets) {
  return {
    // xMin, xMax, yMin, yMax - set to null here, and set position when activiated in lineChart.js
    // COLOR -  is just stored, not used by chartsJS, we will refernce it to
    // the backgroundColor when the annotation is activated in lineChart.js
    annotation: {
      annotations: generateAnnotations(numDataSets)
    },
    tooltips: {
      enabled: false,
      mode: 'index',
      intersect: false,
      position: 'nearest',
      callbacks: {
        title: (tooltipItem, data) => {
          const currentIndex = tooltipItem[0].index
          const parsedDate = moment(data.dateObjects[currentIndex]).format(
            'MMMM Do'
          )
          if (parsedDate === 'Invalid date') {
            return data.labels[currentIndex]
          }
          return parsedDate
        },
        label: (tooltipItem, data) => {
          const label = data.datasets[tooltipItem.datasetIndex].label
          const nextLineValue = getNextDataPoint(
            tooltipItem.datasetIndex,
            tooltipItem.index,
            data.datasets
          )

          const amount = `<span class="data-value">${
            tooltipItem.yLabel - nextLineValue
          }</span>`
          return `${amount}% ${label}`
        }
      },
      custom: (tooltipModel) => {
        const chartRef =
          chartParentRef &&
          chartParentRef.current &&
          chartParentRef.current.chartRef
        // Tooltip Element
        let tooltipEl = document.getElementById('chartjs-tooltip')

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement('div')
          tooltipEl.id = 'chartjs-tooltip'
          tooltipEl.class = 'student-achievement-tooltip'
          document.body.appendChild(tooltipEl)
        }

        // Hide if no tooltip, also move out of the way,
        // so it doesn't block poitner events to the canvas
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0
          tooltipEl.style.left = 0
          tooltipEl.style.top = 0
          tooltipEl.style.zIndex = -10
          return
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform')
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign)
        } else {
          tooltipEl.classList.add('no-transform')
        }

        function getBody(bodyItem) {
          return bodyItem.lines
        }

        // Set Text
        if (tooltipModel.body) {
          const bodyLines = tooltipModel.body.map(getBody)

          let innerHtml = `<h5>${tooltipModel.title}</h5>`

          bodyLines.forEach(function (line) {
            innerHtml += '<div class="data-labels-container">'
            innerHtml += `<span class="data-label">${line}</span>`
            innerHtml += '</div>'
          })

          tooltipEl.innerHTML = innerHtml
        }

        // positioning
        tooltipModel.width = 252

        const canvas = chartRef.current.chartInstance.canvas
        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1
        tooltipEl.style.zIndex = 0
        tooltipEl.style.left =
          canvas.offsetLeft + tooltipModel.caretX + 25 + 'px'
        tooltipEl.style.top =
          canvas.offsetTop +
          // canvasContainer.offsetTop +
          32 +
          'px'
      }
    }
  }
}
