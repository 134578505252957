import React from 'react'
import classnames from 'classnames'

import './circledIcon.scss'

const CircledIcon = (props) => {
  const { className, ...others } = props

  return (
    <div className={classnames('circled-icon', className)} {...others}>
      {props.children}
    </div>
  )
}

export default CircledIcon
