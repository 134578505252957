import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'

import { getChartInstance } from '../../helpers/utils'
import './chartTooltip.scss'
import './lineChart.scss'

class LineChart extends React.Component {
  constructor(props) {
    super(props)
    this.chartRef = React.createRef()
  }

  handleMouseMove = (evt) => {
    const chart = getChartInstance(this.chartRef)
    if (!chart) {
      return
    }
    const xpoints = chart.getElementsAtXAxis(evt)
    const numDataPoints = this.props.chartData.labels.length
    xpoints.forEach((point, index) => {
      const annotation = chart.options.annotation.annotations[index]
      if (annotation) {
        const xPoint = point._index
        const yPoint = this.props.chartData.datasets[index]
        const nextYPoint = this.props.chartData.datasets[index + 1]

        annotation.backgroundColor = annotation.color
        annotation.xMin = xPoint - 0.1
        annotation.xMax = xPoint + 0.1
        // first
        if (xPoint === 0) {
          annotation.xMin = xPoint
          annotation.xMax = xPoint + 0.2
        }
        // last
        if (xPoint === numDataPoints - 1) {
          annotation.xMin = xPoint - 0.2
          annotation.xMax = xPoint
        }
        annotation.yMin = nextYPoint ? nextYPoint.data[xPoint].y : 0
        annotation.yMax = yPoint.data[xPoint].y
      }
    })
    chart.update()
  }

  handleMouseOut = (_evt) => {
    const chart = getChartInstance(this.chartRef)
    if (chart) {
      if (this.props.hasMouseMoveAnnotation) {
        this.resetAnnotations(chart.options.annotation.annotations)
      }
      this.resetToolTip()
      chart.update()
    }
  }

  resetAnnotations(annotations) {
    annotations.forEach((annotation) => {
      annotation.value = null
      annotation.xMin = 0
      annotation.xMax = 0
      annotation.yMin = 0
      annotation.yMax = 0
    })
  }

  resetToolTip() {
    const tooltip = document.querySelector('#chartjs-tooltip')
    if (tooltip) {
      Object.assign(tooltip.style, {
        top: '0px',
        left: '0px',
        zIndex: '-10',
        opacity: '0'
      })
    }
  }

  componentDidMount() {
    const chart = getChartInstance(this.chartRef)
    if (chart && this.props.hasMouseMoveAnnotation) {
      const canvas = chart && chart.canvas
      canvas.onmousemove = this.handleMouseMove
      canvas.onmouseout = this.handleMouseOut
    }
  }

  render() {
    const chartProps = {
      data: this.props.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0
          }
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-0',
              ticks: {
                max: 100,
                min: 1
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-0',
              type: 'linear',
              position: 'bottom',
              ticks: {
                stepSize: 1,
                suggestedMax: this.props.chartData.labels.length - 1,
                callback: (value, index) => {
                  return (
                    this.props.chartData && this.props.chartData.labels[index]
                  )
                }
              }
            }
          ]
        },
        legend: {
          position: 'top',
          labels: {
            usePointStyle: true,
            generatelegend: () => {}
          }
        }
      }
    }

    chartProps.options = Object.assign(chartProps.options, this.props.options)
    return (
      <div className="line-container">
        <Line ref={this.chartRef} {...chartProps} />
      </div>
    )
  }
}

LineChart.defaultProps = {
  hasMouseMoveAnnotation: false,
  options: {}
}

LineChart.propTypes = {
  hasMouseMoveAnnotation: PropTypes.bool,
  chartData: PropTypes.object.isRequired,
  options: PropTypes.object
}

export default LineChart
