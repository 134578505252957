import React from 'react'
import { connect } from 'react-redux'

// HOC to make it much easier to initiate data fetches at the right times as well as find out when the data you want
// has been fetched
// (param) WrappedComponent: container component
// (param) dataLoadersGenerator: function which takes in props and returns an array of actions to be dispatched when the
//                               component is mounted and when any of reloadProps changes.
// (param) loadingPropsSettersGenerator: function which takes in props and returns a structure that looks like:
//                                {
//                                  propertyKey1: [
//                                    [ <fromState function 1>, [fromState function1 args other than state] ],
//                                    [ <fromState function 2>, [fromState function2 args other than state] ],
//                                  ],
//                                }
//                                Each propertyKey will be passed into the wrapped component as a prop with a value of
//                                true if each fromState function returns true and false otherwise.
// (param) reloadProps: an array of property key strings or functions that should trigger a data reload. When specifying
//                      a function, it should take in props and return the value from the props. That value will be
//                      compared to an invocation of the function with the previous props and a reload will be triggered
//                      if those are not equal.
// (returns) 1 data-loading-enhanced component
const withDataLoading = (
  WrappedComponent,
  dataLoadersGenerator,
  loadingPropsSettersGenerator,
  reloadProps = []
) => {
  class withDataLoading extends React.Component {
    componentDidMount() {
      this.props.loadData()
    }

    componentDidUpdate(prevProps) {
      const shouldReload =
        reloadProps.findIndex((reloadProp) => {
          if (typeof reloadProp === 'function') {
            return reloadProp(prevProps) !== reloadProp(this.props)
          }

          return this.props[reloadProp] !== prevProps[reloadProp]
        }) >= 0

      if (shouldReload) {
        this.props.loadData()
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = (state, ownProps) => {
    state = state.parrot || state
    const returnProps = {}

    const propKeyToCheckers = loadingPropsSettersGenerator
      ? loadingPropsSettersGenerator(ownProps)
      : []

    Object.keys(propKeyToCheckers).forEach((propKey) => {
      const checkers = propKeyToCheckers[propKey]
      returnProps[propKey] =
        checkers.filter(([checker, extraArgs]) => checker(state, ...extraArgs))
          .length === checkers.length
    })

    return returnProps
  }

  const mapDispatchToProps = (dispatch, ownProps) => ({
    loadData: () => {
      dataLoadersGenerator(ownProps).forEach((actionCreator) =>
        dispatch(actionCreator)
      )
    }
  })

  return connect(mapStateToProps, mapDispatchToProps)(withDataLoading)
}

export default withDataLoading
