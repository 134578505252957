// Free Trial Onboarding Page
export const FREE_TRIAL_PATH_CHOICE = 'freeTrialPathChoice'
export const CURRICULUM_PATH = 'curriculumPath'
export const DASHBOARD_PATH = 'dashboardDemoPath'
// Free Trial Your School Step 1
export const FIRST_COURSE_SAVED = 'firstCourseSaved'
export const FIRST_COURSE_VIEWED = 'firstCourseViewed'
// Free Trial Demo School Step 1
export const TEACHER_DASHBOARD_VIEWED = 'teacherDashboardViewed'
export const TEACHER_PROFILE_VIEWED = 'teacherProfileViewed'
export const TEACHER_VIEWED_IN_CLASSROOM = 'teacherViewedInClassroom'
// Free Trial Demo School Step 2
export const STUDENT_DASHBOARD_VIEWED = 'studentDashboardViewed'
export const STUDENT_PROFILE_VIEWED = 'studentProfileViewed'
export const STUDENT_VIEWED_IN_CLASSROOM = 'studentViewedInClassroom'
// Free Trial Demo School Step 3
export const DEMO_SCHOOL_CURRICULUM_VIEWED = 'demoSchoolCurriculumViewed'
export const DEMO_SCHOOL_COURSE_VIEWED = 'demoSchoolCourseViewed'
export const DEMO_SCHOOL_PLANNER_VIEWED = 'demoSchoolPlannerViewed'
