import { fork, takeEvery } from 'redux-saga/effects'
import * as helperSagas from './helperSagas'
import * as adminSagas from './adminSagas'
import * as actionIDs from '../actions/actionIDs'

const adminSagaWatchers = [
  // basic resource fetches
  fork(
    helperSagas.resourceWatcher(
      actionIDs.inactiveTeachers.read,
      adminSagas.fetchInactiveTeachers
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.unactivatedTeachers.read,
      adminSagas.fetchUnactivatedTeachers
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentActivityLists.read,
      adminSagas.fetchStudentActivityLists
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.topClasses.read,
      adminSagas.fetchTopClasses
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentClassesStatistics.read,
      adminSagas.fetchStudentClassesStatistics
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.reportingMethods.read,
      adminSagas.fetchReportingMethods
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.teacherAggregate.read,
      adminSagas.fetchTeacherAggregate
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.teacherEngagement.read,
      adminSagas.fetchTeacherEngagement
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.studentAchievement.read,
      adminSagas.fetchStudentAchievement
    )
  ),
  fork(
    helperSagas.resourceWatcher(
      actionIDs.teacherReports.read,
      adminSagas.fetchTeacherReports
    )
  ),

  // composite resource fetches
  takeEvery(actionIDs.FETCH_STUDENT_CLASSES, adminSagas.fetchStudentClasses)
]

export default adminSagaWatchers
