import React from 'react'
import TeacherClassOverviewPresenter from './teacherClassOverviewPresenter'

class TeacherClassOverviewContainer extends React.Component {
  render() {
    return <TeacherClassOverviewPresenter {...this.props} />
  }
}

export default TeacherClassOverviewContainer
