import React from 'react'
import classnames from 'classnames'

import { ACCOUNT_TYPES } from '../../helpers/constants'

import './accountTypeTabs.scss'

const AccountTypeTabs = ({ selected, setAccountType }) => {
  const accountTypes = Object.values(ACCOUNT_TYPES).map((type) => {
    const className = classnames('tab', type)
    const underlineClasses = classnames('underline', {
      selected: selected === type
    })

    return (
      <div
        className={className}
        onClick={() => setAccountType(type)}
        key={type.role}
        data-spec={type.role}
      >
        {type.label}
        <div className={underlineClasses} />
      </div>
    )
  })
  return <div className="account-type-tabs">{accountTypes}</div>
}

export default AccountTypeTabs
