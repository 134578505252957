import Immutable from 'immutable'

import { mapArrayObjectsByKey } from '../../helpers/utils'
import * as actionIDs from '../actions/actionIDs'
import { CLASSES, TOP_CLASSES } from '../models/resources'
import * as reducerCreators from './reducerCreators'

export const initialState = Immutable.fromJS({
  byID: {},
  top: [],
  fetchingTop: false,
  fetching: false,
  didFetchSchoolID: Immutable.Map({}),
  errors: {
    top: null,
    classes: null
  }
})

export const getClassesSuccess = (state, action) => {
  let newState = state.set('fetching', false)
  newState = newState.setIn(['didFetchSchoolID', action.schoolID], true)

  newState = mapArrayObjectsByKey(newState, action.classes, 'id', ['byID'])
  const stats = action.statistics
  Object.keys(stats).forEach((id) => {
    const clazz = newState.getIn(['byID', id]).merge(stats[id])
    newState = newState.setIn(['byID', id], clazz)
    newState = newState.setIn(['byID', id, 'schoolID'], action.schoolID)
  })
  return newState
}

export const getTopClassesSuccess = (state, action) => {
  let newState = state.set('fetchingTop', false)
  if (action.classes) {
    newState = newState.set('top', action.classes)
  }
  return newState
}

const handlers = reducerCreators.getHandlers(CLASSES, {
  [actionIDs.classes.read.success]: getClassesSuccess,
  [actionIDs.classes.read.error]: (state, action) =>
    state.merge({ fetching: false, errors: { classes: action.error } })
})

const topHandlers = reducerCreators.getHandlers(TOP_CLASSES, {
  [actionIDs.topClasses.read.success]: getTopClassesSuccess,
  [actionIDs.topClasses.read.initialize]: (state) =>
    state.set('fetchingTop', true),
  [actionIDs.topClasses.read.error]: (state, action) =>
    state.merge({ fetchingTop: false, errors: { top: action.error } })
})

const classes = reducerCreators.reducerCreator(
  initialState,
  Object.assign(handlers, topHandlers)
)

export default classes
