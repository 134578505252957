import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { USER_MANAGEMENT_ROUTE } from './routes'

import UserManagementContainer from '../components/userManagement/userManagementContainer'

const UserManagementRouter = () => (
  <Switch>
    <Route
      exact
      path={USER_MANAGEMENT_ROUTE}
      component={UserManagementContainer}
    />
  </Switch>
)

export default UserManagementRouter
