import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import i18n from '../../helpers/i18n'
import Badge from '../common/badges/badges'

import './daysRemaining.scss'

export const FreeTrialDaysRemaining = (props) => {
  const numDaysLeft = moment(props.expirationDate).diff(moment().utc(), 'days')
  return (
    <div className="days-left">
      <Badge label={numDaysLeft} />
      <span className="normal-text">{daysLeftText(numDaysLeft)}</span>
      <span className="short-text">{shortDaysLeftText(numDaysLeft)}</span>
    </div>
  )
}

function daysLeftText(numDaysLeft) {
  return i18n.t('titles.daysLeftInFreeTrial', {
    count: numDaysLeft
  })
}

function shortDaysLeftText(numDaysLeft) {
  return i18n.t('titles.shortDaysLeftInFreeTrial', { count: numDaysLeft })
}

FreeTrialDaysRemaining.propTypes = {
  expirationDate: PropTypes.string.isRequired
}

export default FreeTrialDaysRemaining
