import PropTypes from 'prop-types'

const timeFrameShape = PropTypes.shape({
  start: PropTypes.string.isRequired,
  end: PropTypes.string,
  type: PropTypes.string.isRequired,
  lastDay: PropTypes.string,
  length: PropTypes.number
})

const teacherActivityShape = PropTypes.shape({
  timeFrame: timeFrameShape,
  assignments_graded: PropTypes.arrayOf(PropTypes.number),
  assignments_assigned: PropTypes.arrayOf(PropTypes.number),
  usage: PropTypes.arrayOf(PropTypes.number)
})

const studentAchievementShape = PropTypes.shape({
  timeFrame: timeFrameShape,
  developing: PropTypes.arrayOf(PropTypes.number),
  approaching: PropTypes.arrayOf(PropTypes.number),
  mastering: PropTypes.arrayOf(PropTypes.number),
  exceeding: PropTypes.arrayOf(PropTypes.number)
})

const contentUsageShape = PropTypes.shape({
  timeFrame: timeFrameShape,
  assignments_free: PropTypes.arrayOf(PropTypes.number),
  assignments_paid: PropTypes.arrayOf(PropTypes.number),
  assignments_total: PropTypes.arrayOf(PropTypes.number)
})

// only object, take out arrayOf option when done removign mock data for all charts
const oneOfChartDataShapes = PropTypes.oneOfType([
  PropTypes.objectOf(
    PropTypes.oneOfType([
      teacherActivityShape,
      studentAchievementShape,
      contentUsageShape
    ])
  )
])

export const chartDataShape = PropTypes.shape({
  year: oneOfChartDataShapes,
  term: oneOfChartDataShapes,
  month: oneOfChartDataShapes,
  week: oneOfChartDataShapes
})

export const timeframeTypesShape = PropTypes.oneOf([
  'week',
  'month',
  'term',
  'year'
])

export const reportingScale = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minScore: PropTypes.number.isRequired,
  maxScore: PropTypes.number.isRequired
})

export const reportingMethodShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  scales: PropTypes.arrayOf(reportingScale).isRequired
})
