import React from 'react'
import PropTypes from 'prop-types'

import Triangle from './triangle/triangle'
import { growthChangeGreen, growthChangeRed } from '../../helpers/colors'

const GrowthPercentage = (props) => {
  const { value } = props
  if (!value) {
    return (
      <span className="growth-container" data-spec="recent-growth-list-item">
        {' -- '}
      </span>
    )
  } else {
    const direction = value > 0 ? 'up' : 'down'
    const directionColor =
      direction === 'up' ? growthChangeGreen : growthChangeRed
    return (
      <span className="growth-container" data-spec="recent-growth-list-item">
        <Triangle width={14} direction={direction} color={directionColor} />
        {`${value}%`}
      </span>
    )
  }
}

GrowthPercentage.defaultProps = {
  value: 0
}

GrowthPercentage.propTypes = {
  value: PropTypes.number
}

export default GrowthPercentage
