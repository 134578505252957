const subjectNameToCSSClassMap = {
  English: 'english',
  Math: 'math',
  'Physical Education': 'pe',
  'Social Studies': 'socialStudies',
  'Fine Arts': 'fineArts',
  Science: 'science',
  'Languages Other Than English': 'otherLanguages',
  'Social Emotional Learning': 'sel',
  'Computer Science and Technology': 'compSci',
  'Special Education': 'sped',
  Other: 'other'
}

export const SEL_SUBJECT_ID = '10005'

export const subjectsToCSSClass = (subjects) => {
  let normalizedSubject = 'other'

  if (subjects && subjects.length === 1) {
    normalizedSubject = subjectNameToCSSClassMap[subjects[0]] || 'other'
  }

  return `subject-${normalizedSubject}`
}

export const sortSubjectsByNameFunc = (a, b) => {
  return a.name.localeCompare(b.name)
}

export const getSubjectsAsTitle = (subjects) =>
  subjects.sort().join(subjects.length === 2 ? ' & ' : ', ')

export const isParentSubject = (subject) => subject.get('parentID') === null

export const sortSubjects = (subjects) => {
  if (subjects) {
    subjects.sort((a, b) => {
      // Ordering is:
      //  core subjects
      //  non-core subjects
      const isACore = a.isCore
      const isBCore = b.isCore

      if (isACore && !isBCore) {
        return -1
      } else if (!isACore && isBCore) {
        return 1
      }

      const subjectCompare = a.name.localeCompare(b.name)
      if (subjectCompare !== 0) {
        return subjectCompare
      }

      // Subject names are equal. To make the function deterministic,
      // compare subject IDs
      return a.id - b.id
    })
  }

  return subjects
}
