import { Set as ImmutableSet } from 'immutable'

export const updateItemInSet = (set, item) => {
  const newSet = new Set(set)
  if (newSet.has(item)) {
    newSet.delete(item)
  } else {
    newSet.add(item)
  }
  return newSet
}

export const intersect = (a, b) =>
  new ImmutableSet([...a]).filter((x) => b.has(x))
