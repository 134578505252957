import React from 'react'
import PropTypes from 'prop-types'

import { reportingMethodShape } from '../../helpers/propShapes/chartDataShapes'
import * as colors from '../../helpers/colors'
import './progressBar.scss'

const partitionCount = 4
const fullPartition = 100 / partitionCount

const partitionedBar = (props) => {
  const { backgroundColor, height, reportingMethod } = props

  const progress = props.progress > 100 ? 100 : props.progress
  const color = colors.progressColor(progress, reportingMethod)
  const doneStyle = {
    background: color,
    height: `${height}px`
  }
  const emptyStyle = {
    background: backgroundColor,
    height: `${height}px`
  }
  const doneBarsCount = Math.floor(progress / fullPartition)
  const doneBars = [...Array(doneBarsCount).keys()].map((idx) => (
    <div
      className="partition done"
      key={idx}
      style={doneStyle}
      data-spec="done-bar"
    />
  ))

  const partialProgress = progress - doneBarsCount * fullPartition
  const donePartialStyle = { ...doneStyle, flexGrow: partialProgress }
  const pendingPartialStyle = {
    ...emptyStyle,
    flexGrow: fullPartition - partialProgress
  }
  const partialBar = partialProgress ? (
    <div className="partition partial-bar">
      <div
        className="partial partial-done"
        style={donePartialStyle}
        data-spec="partial-done-bar"
      />
      <div
        className="partial partial-pending"
        style={pendingPartialStyle}
        data-spec="partial-pending-bar"
      />
    </div>
  ) : null

  const emptyBarsCount = Math.floor((100 - progress) / fullPartition)
  const emptyBars = [...Array(emptyBarsCount).keys()].map((idx) => (
    <div
      className="partition empty"
      key={idx}
      style={emptyStyle}
      data-spec="empty-bar"
    />
  ))

  return (
    <div className="progress-partitioned-container">
      {doneBars}
      {partialBar}
      {emptyBars}
    </div>
  )
}

const ProgressBar = (props) => {
  const {
    progress,
    color,
    backgroundColor,
    showLabel,
    height,
    partitioned,
    reportingMethod
  } = props
  if (partitioned) {
    return partitionedBar(props)
  }
  const displayColor = reportingMethod
    ? colors.progressColor(progress, reportingMethod)
    : color
  const doneStyle = {
    flexGrow: progress,
    background: displayColor,
    height: `${height}px`
  }
  const pendingStyle = {
    flexGrow: 100 - progress,
    background: 'transparent',
    height: `${height}px`
  }
  const containerStyle = {
    background: backgroundColor
  }
  const progressLabel = progress + '%'
  const threshold = 80
  const overThreshold = progress >= threshold && showLabel
  const underThreshold = progress < threshold && showLabel
  return (
    <div className="progress-container" style={containerStyle}>
      <div className="done" style={doneStyle} data-spec="partial-done-bar">
        {overThreshold && props.showLabel && progressLabel}
      </div>
      <div
        className="pending"
        style={pendingStyle}
        data-spec="partial-pending-bar"
      >
        {underThreshold && props.showLabel && progressLabel}
      </div>
    </div>
  )
}

ProgressBar.defaultProps = {
  progress: 0,
  showLabel: true,
  backgroundColor: 'transparent',
  color: '#131313',
  height: 16
}

ProgressBar.propTypes = {
  progress: (props, propName, _componentName) => {
    if (props[propName] > 100) {
      const err = 'Progress Bar error: Progress exceeds 100%'
      window.Rollbar && window.Rollbar.error(err)
      return new Error(err)
    }
  },
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  showLabel: PropTypes.bool,
  height: PropTypes.number,
  reportingMethod: reportingMethodShape
}

export default ProgressBar
