import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { TEACHER_ID_PATH } from '../../routes/routes'
import {
  TEACHERS_VIEW_EVENT,
  SINGLE_TEACHER_VIEW_EVENT
} from '../../store/eventIDs'
import { TEACHER_DASHBOARD_VIEWED } from '../../store/userPreferences'
import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import * as fromState from '../../store/fromState'
import TeachersOverviewPresenter from './teachersOverviewPresenter'
import ClassCard from '../common/cards/classCard'
import withDataLoading from '../hoc/withDataLoading'
import withToJS from '../hoc/withToJS'
import {
  TEACHERS,
  CLASSES,
  SUBJECTS,
  REPORTING_METHODS
} from '../../store/models/resources'

const WrappedPresenter = withToJS(TeachersOverviewPresenter)
const WrappedCardPresenter = withToJS(ClassCard)

class TeachersOverviewContainer extends React.Component {
  componentDidMount() {
    this.props.trackSearch('')
    this.tryToSetUserPref()
  }

  componentDidUpdate() {
    this.tryToSetUserPref()
  }

  tryToSetUserPref() {
    if (!this.userPrefSet && this.props.userPrefDataLoaded) {
      this.props.checkAndSetUserPref(this.props.shouldSetUserPref)
      this.userPrefSet = true
    }
  }

  render() {
    const { topClasses, history } = this.props
    const cards =
      topClasses &&
      topClasses.map((clazz, index) => {
        const teacher = clazz.get('teacher') || {}
        const clazzProps = {
          icon: teacher.avatar || null,
          subjects: clazz.get('subjects'),
          grades: clazz.get('grades'),
          assignmentsAssigned: clazz.get('assignmentsAssigned'),
          scoredPercent: clazz.get('scoredPercent'),
          missingPercent: clazz.get('missingPercent'),
          studentCount: clazz.get('studentCount'),
          masteryLevelPercent: clazz.get('masteryLevelPercent'),
          title: clazz.get('name') || teacher.name || ''
        }
        return (
          <WrappedCardPresenter
            {...clazzProps}
            schoolID={this.props.schoolID}
            url={TEACHER_ID_PATH(this.props.schoolID, clazz.get('teacherID'))}
            history={history}
            key={`class-card-${index}`}
          />
        )
      })
    return <WrappedPresenter {...this.props} cards={cards} />
  }
}

export const mapStateToProps = (state, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  const { stillFetching, haveAllData } = ownProps
  if (stillFetching || !haveAllData) {
    return { isLoading: true, schoolID }
  }

  const fetchingTopClasses = fromState.isFetchingTopClasses(state)
  const topClassList = fromState.getTopClasses(state, schoolID)
  let topClasses
  if (topClassList && topClassList.size) {
    const aggregatedData = fromState.aggregateTeacherClassData(
      state,
      schoolID,
      topClassList
    )
    topClasses = aggregatedData.classes
  }
  const renderSidebar =
    fromState.isUserFreeTrial(state) &&
    fromState.getSchoolIsDemo(state, schoolID)

  const { teachers, classes } = fromState.aggregateTeacherClassData(
    state,
    schoolID
  )
  const shouldSetUserPref = fromState.isSchoolFreeTrialAndDemo(state, schoolID)

  return {
    schoolID,
    teachers,
    classes,
    topClasses,
    fetchingTopClasses,
    renderSidebar,
    shouldSetUserPref,
    userPrefDataLoaded: fromState.userPrefDataLoaded(state)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const schoolID = ownProps.match.params.schoolID
  return {
    trackSearch: (query) =>
      dispatch(
        coreActions.trackEvent.create.begin(TEACHERS_VIEW_EVENT, {
          schoolID,
          filter: {
            // new search will always be on page 0
            name: query
          }
        })
      ),
    trackSingleTeacherViewed: (id) =>
      dispatch(
        coreActions.trackEvent.create.begin(SINGLE_TEACHER_VIEW_EVENT, {
          schoolID,
          fromWhere: 'listAll',
          teacher: {
            id
          }
        })
      ),
    checkAndSetUserPref: (shouldSetUserPref) => {
      dispatch(
        coreActions.user.writePrefs.begin(
          [
            {
              key: TEACHER_DASHBOARD_VIEWED,
              value: 'true'
            }
          ],
          shouldSetUserPref
        )
      )
    }
  }
}

const generateDataLoaders = (props) => [
  coreActions.teacherAggregate.read.begin(props.match.params.schoolID),
  adminActions.topClasses.read.begin(props.match.params.schoolID)
]
const reloadProps = [(props) => props.match.params.schoolID]

const generateLoadingProps = (props) => ({
  stillFetching: [
    [
      fromState.isFetchingResources,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ],
  haveAllData: [
    [
      fromState.resourcesLoaded,
      [
        [TEACHERS, CLASSES, SUBJECTS, REPORTING_METHODS],
        props.match.params.schoolID
      ]
    ]
  ]
})

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(TeachersOverviewContainer),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
