import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Kiddoms
// import i18n from '../../../helpers/i18n'
import { SCHOOLS } from '../../../store/models/resources'
import * as fromState from '../../../store/fromState'
import * as coreActions from '../../../store/actions/coreActions'
// import ProgressBar from '../../charts/progressBar'
// import * as colors from '../../../helpers/colors'
import Card from './card'
// import { getGoodReportingLevel } from '../../../helpers/utils'
import withDataLoading from '../../hoc/withDataLoading'

class SchoolCard extends React.Component {
  renderCardContent() {
    return null
    /*
    if (!this.props.schoolName) {
      return null
    }
    const {
      teacherCount,
      studentCount,
      activeTeachers,
      studentAchievement,
      setReportingMethod
    } = this.props

    const teacherLabel = i18n.t('labels.numTeachers', { count: teacherCount })
    const studentLabel = i18n.t('labels.numStudents', { count: studentCount })
    const label1 = i18n.t('labels.percentAreEngaged', { activeTeachers })

    let reportingLabel = '...'
    if (setReportingMethod) {
      reportingLabel = getGoodReportingLevel(setReportingMethod)
    }
    const label2 = i18n.t('labels.percentAtLabelOrHigher', {
      percent: studentAchievement,
      label: reportingLabel
    })
    return (
      <div className="school-overview-container">
        <div className="section">
          <h3>{teacherLabel}</h3>
          <p>{label1}</p>
          <ProgressBar
            progress={activeTeachers}
            backgroundColor={colors.neutralBkgndLight}
            showLabel={false}
            height={16}
            partitioned
          />
        </div>
        <div className="section">
          <h3>{studentLabel}</h3>
          <p>{label2}</p>
          <ProgressBar
            progress={studentAchievement}
            backgroundColor={colors.neutralBkgndLight}
            showLabel={false}
            height={16}
            partitioned
          />
        </div>
      </div>
    )
    */
  }

  render() {
    const { schoolID } = this.props
    const schoolName = this.props.schoolName || 'Loading School...'
    const cardProps = {
      title: schoolName,
      subtitle: '',
      url: `/schools/${schoolID}`
    }

    return <Card {...cardProps}>{this.renderCardContent()}</Card>
  }
}

SchoolCard.propTypes = {
  schoolID: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {
  const { schoolID } = ownProps
  const school = fromState.getResourceByID(state, SCHOOLS, schoolID)
  let schoolData = {}
  if (school) {
    schoolData = {
      schoolName: school.get('schoolName'),
      teacherCount: school.get('teacherCount'),
      studentCount: school.get('studentCount'),
      activeTeachers: school.get('activeTeachers'),
      studentAchievement: school.get('studentAchievement')
    }
  }
  return {
    setReportingMethod: fromState.getSchoolSetReportingMethod(state, schoolID),
    ...schoolData
  }
}

const generateDataLoaders = (props) => [
  coreActions.reportingMethods.read.begin(props.schoolID)
]

const reloadProps = ['schoolID']
export default withDataLoading(
  connect(mapStateToProps)(SchoolCard),
  generateDataLoaders,
  () => [],
  reloadProps
)
