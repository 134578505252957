import React from 'react'
import { connect } from 'react-redux'
import withToJS from '../hoc/withToJS'
import SISMatchingPresenter from './sisMatchingPresenter'
import { withRoleCheckFor } from '../hoc/withRoleCheckFor'
import { getSISIntegration } from '../../store/fromState'
import * as sisIntegrationActions from '../../store/actions/sisIntegrationActions'

const WrappedPresenter = withToJS(SISMatchingPresenter)
const SISMatchingContainer = (props) => <WrappedPresenter {...props} />

const mapStateToProps = (state, ownProps) => ({
  districtID: ownProps.match.params.districtID,
  sisIntegration: getSISIntegration(state)
})

const mapDispatchToProps = (dispatch) => ({
  fetchSISMatches: (districtID) =>
    dispatch(sisIntegrationActions.sisSchoolMatches.read.begin(districtID)),
  updateSISMatches: (districtID, pairs) =>
    dispatch(
      sisIntegrationActions.sisSchoolMatches.update.begin(districtID, pairs)
    )
})

export default withRoleCheckFor(
  connect(mapStateToProps, mapDispatchToProps)(SISMatchingContainer),
  [],
  true
)
