import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import i18n from '../../../helpers/i18n'

import CloseIcon from '../../../images/icons/close.svg'
import WithVisibleStateToggle from '../../hoc/withVisibleStateToggle'
import './popup.scss'

export class Popup extends WithVisibleStateToggle {
  constructor(props) {
    super(props)
    this.state = {
      visible: this.props.initialState === 'open'
    }
    this.hidePopup = this.hidePopup.bind(this)
    this.togglePopup = this.togglePopup.bind(this)
  }

  hidePopup() {
    this.hideVisible()
    if (this.props.onHide) {
      this.props.onHide()
    }
  }

  togglePopup() {
    this.toggleVisible()
    if (this.props.onShow) {
      this.props.onShow()
    }
  }

  renderTrigger() {
    const { triggerElement, triggerRef } = this.props
    const triggerClasses = classnames('popup-trigger', {
      'popup-visible': this.state.visible
    })

    const buttonProps = {
      className: triggerClasses,
      onClick: this.togglePopup,
      'data-spec': 'trigger-element',
      ref: triggerRef
    }
    return (
      <button {...buttonProps}>{React.cloneElement(triggerElement)}</button>
    )
  }

  render() {
    const popupContainerClasses = classnames(
      'popup-container',
      this.props.className,
      {
        visible: this.state.visible
      }
    )

    const {
      position,
      alignment,
      isModalOnMobile,
      isActionSheet,
      isHover,
      withTail,
      closeOnClick,
      showClose,
      popupStyle
    } = this.props

    const popupClasses = classnames(
      'popup-trigger-container',
      `position-${position}`,
      `alignment-${alignment}`,
      {
        'modal-on-mobile': isModalOnMobile,
        'action-sheet': isActionSheet,
        'is-hover': isHover,
        tail: withTail
      }
    )

    const spaceClasses = classnames('space-after', `position-${position}`)
    return (
      <div className={popupContainerClasses}>
        {isHover && <div className={spaceClasses} />}
        {this.renderTrigger()}
        <div className={popupClasses} data-spec="popup-ui">
          <div className="popup-backdrop" onClick={this.hidePopup} />
          <div
            style={popupStyle}
            className="popup"
            onClick={closeOnClick ? this.hidePopup : undefined}
          >
            {showClose && (
              <CloseIcon
                className="close"
                title={i18n.t('labels.close')}
                onClick={this.hidePopup}
              />
            )}

            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

Popup.defaultProps = {
  position: 'bottom',
  alignment: 'center',
  isModalOnMobile: false,
  isActionSheet: false,
  closeOnClick: true,
  withTail: true,
  initialState: 'close'
}

Popup.propTypes = {
  position: PropTypes.oneOf(['top', 'bottom', 'right', 'left']),
  alignment: PropTypes.oneOf(['start', 'center', 'end']),
  isModalOnMobile: PropTypes.bool,
  isActionSheet: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  triggerElement: PropTypes.element,
  withTail: PropTypes.bool,
  initialState: PropTypes.oneOf(['open', 'close'])
}

export default Popup
