import React from 'react'
import PropTypes from 'prop-types'

// Kiddoms
import i18n from '../../../helpers/i18n'
import { TEACHER_ID_PATH } from '../../../routes/routes'
import Card from './card'

class TeacherCard extends React.Component {
  generateSubtitle() {
    const subjects =
      (this.props.teacher.get('subjects') &&
        this.props.teacher.get('subjects').join(', ')) ||
      i18n.t('labels.noSubjects')
    const gradesLabel =
      this.props.teacher.get('grades') &&
      this.props.teacher.get('grades').join(', ')
    return `${subjects} • ${gradesLabel}`
  }

  render() {
    const { teacher, schoolID, onClick } = this.props

    const url = TEACHER_ID_PATH(schoolID, teacher.get('id'))

    const cardProps = {
      icon: teacher.get('avatar'),
      title: teacher.get('name'),
      url,
      subtitle: this.generateSubtitle(),
      onClick
    }

    return <Card {...cardProps} />
  }
}

TeacherCard.propTypes = {
  subjects: PropTypes.arrayOf(PropTypes.string),
  grades: PropTypes.arrayOf(PropTypes.string)
}

export default TeacherCard
