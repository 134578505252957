import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { matchPath, withRouter } from 'react-router-dom'

import i18n from '../../helpers/i18n'
import { AVATAR_FALLBACK_SRC, FEATURE_ACCESS_ID } from '../../helpers/constants'
import * as fromState from '../../store/fromState'
import * as coreActions from '../../store/actions/coreActions'
import * as adminActions from '../../store/actions/adminActions'
import { SCHOOLS } from '../../store/models/resources'
import * as routes from '../../routes/routes'

import UserActions from './userActions'
import FreeTrialSchoolToggleContainer from '../freeTrial/freeTrialSchoolToggleContainer'
import Subheader from './subheader'
import withDataLoading from '../hoc/withDataLoading'
import {
  dateRangeModel,
  generateTimeframeIDFromDates
} from '../../helpers/chartDateHelpers'
import { LinkToKiddomClassroom } from './linkToKiddomClassroom'

import './header.scss'
import './freeTrialHeader.scss'

const AcademyIcon = '../../../../../../../images/illustrations/academy.png'

const schoolLogoAltText = i18n.t('imageAlt.schoolLogo')

const showMinimizedHeader = (path) => {
  const isActive =
    matchPath(path, routes.CURRICULUM_COURSE_ROUTE) ||
    matchPath(path, routes.USER_MANAGEMENT_ROUTE) ||
    matchPath(path, routes.SIS_INTEGRATION_ROUTE)
  return !!isActive
}

const HeaderTitle = (props) => {
  if (props.isUserInFreeTrial && !!props.schoolID) {
    return <FreeTrialSchoolToggleContainer schoolID={props.schoolID} />
  }
  return (
    <h2 data-spec="header-title" className="header-title">
      {props.title}
    </h2>
  )
}

export class Header extends React.Component {
  constructor(props) {
    super(props)
    const inSchool = !!props.schoolID
    this.state = {
      inSchool,
      headerMinimized: inSchool,
      subheaderMinimized: props.isSubheaderMinimized || false
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.schoolID !== this.props.schoolID) {
      const inSchool = !!this.props.schoolID
      this.setState({ inSchool, headerMinimized: inSchool })
    }
    if (prevProps.isSubheaderMinimized !== this.props.isSubheaderMinimized) {
      this.setState({ subheaderMinimized: this.props.isSubheaderMinimized })
    }
  }

  handleScroll() {
    if (this.props.isSubheaderMinimized) {
      return
    }
    if (window.scrollY === 0) {
      if (this.state.inSchool) {
        this.setState({ subheaderMinimized: false })
      } else {
        this.setState({ headerMinimized: false })
      }
    } else if (window.scrollY > 125) {
      this.setState({ headerMinimized: true, subheaderMinimized: true })
    }
  }

  render() {
    const {
      isViewingDemoSchool,
      sticky,
      stickyMobile,
      schoolName,
      schools,
      schoolID,
      districtID,
      userIcon,
      history,
      isUserInFreeTrial,
      subheaderTitle,
      useSchoolSubheader
    } = this.props
    const headerClassnames = classnames('header-container header-transform', {
      unstuck: !sticky,
      'unstuck-mobile': !stickyMobile,
      'header-container--minimized': this.state.headerMinimized,
      'is-demo': isViewingDemoSchool,
      'free-trial': isUserInFreeTrial,
      'subheader--minimized': this.state.subheaderMinimized
    })
    const spaceholderClassnames = classnames('spaceholder', {
      'spaceholder--withSubheader':
        this.state.inSchool && !this.state.subheaderMinimized,
      district: !schoolID,
      'spaceholder--minimized': this.state.headerMinimized
    })
    const headerClass = classnames('header', {
      district: !schoolName
    })
    const singleSchool = schools && schools.length === 1
    const inSchool = singleSchool ? false : this.state.inSchool
    return (
      <div className={headerClassnames}>
        <div className={spaceholderClassnames} />
        <div className={headerClass}>
          <div className="headlines">
            <div className="headlines-left">
              <div className="icon-container">
                <img
                  src={AcademyIcon}
                  data-spec="school-icon"
                  alt={schoolLogoAltText}
                  className="icon"
                />
              </div>
              <HeaderTitle
                title={this.props.title}
                isUserInFreeTrial={this.props.isUserInFreeTrial}
                schoolID={schoolID}
              />
            </div>
            {schoolID && <LinkToKiddomClassroom />}
            <UserActions
              userIcon={userIcon}
              inSchool={inSchool}
              history={history}
              isUserInFreeTrial={isUserInFreeTrial}
              showAllSchoolButton
            />
          </div>
          <Subheader
            useSchoolSubheader={useSchoolSubheader}
            districtID={districtID}
            schoolID={schoolID}
            schoolName={schoolName}
            minimized={this.state.subheaderMinimized}
            disableDashboardLinks={this.props.disableDashboardLinks}
            subheaderTitle={subheaderTitle}
          />
        </div>
      </div>
    )
  }
}

Header.defaultProps = {
  sticky: true,
  stickyMobile: true,
  title: ''
}

Header.propTypes = {
  title: PropTypes.string,
  userIcon: PropTypes.string,
  isUserInFreeTrial: PropTypes.bool,
  schoolName: PropTypes.string,
  sticky: PropTypes.bool,
  stickyMobile: PropTypes.bool,
  subheaderTitle: PropTypes.string,
  useSchoolSubheader: PropTypes.bool
}

// check route for schools ep, get name from state
// if district dont pass it in
export const mapStateToProps = (state, ownProps) => {
  const districtName = fromState.getDistrictName(state)
  const districtID = fromState.getDistrictID(state)

  const schoolID = ownProps.match.params.schoolID
  const schoolName =
    (!!schoolID && fromState.getSchoolName(state, schoolID)) || undefined
  const disableDashboardLinks =
    schoolID && !fromState.hasTeacherActivityChartData(state, schoolID)
  const isUserInFreeTrial = fromState.userInFeature(
    state,
    FEATURE_ACCESS_ID.fullAcademy
  )
  const demoSchoolID = fromState.getFreeTrialSchoolIDs(state).demoSchoolID
  const isViewingDemoSchool =
    isUserInFreeTrial && ownProps.match.params.schoolID === demoSchoolID
  return {
    title: districtName,
    districtID,
    userIcon: fromState.getUserProp(state, 'image_url') || AVATAR_FALLBACK_SRC,
    schoolName,
    schoolID,
    schools: fromState.getList(state, SCHOOLS),
    isSubheaderMinimized: showMinimizedHeader(ownProps.location.pathname),
    disableDashboardLinks,
    isUserInFreeTrial,
    isViewingDemoSchool
  }
}

// props to fetch chart data if we haven't already,
// only needs to get first month in teacherActivity
const defaultEnd = dateRangeModel.month.lastXRange.end
const defaultStart = dateRangeModel.month.lastXRange.start
const defaultTimeframeID = generateTimeframeIDFromDates(
  defaultStart,
  defaultEnd
)

const generateDataLoaders = (props) => {
  const schoolID = props.match.params.schoolID
  const actions = [coreActions.schools.read.begin()]
  if (schoolID) {
    actions.push(
      adminActions.teacherEngagement.read.begin(
        schoolID,
        'month',
        defaultTimeframeID
      )
    )
  }
  return actions
}

const generateLoadingProps = (_props) => ({
  isLoading: [[fromState.isFetching, [SCHOOLS]]]
})

const reloadProps = ['districtID', 'schoolID']

export default withRouter(
  withDataLoading(
    connect(mapStateToProps, null)(Header),
    generateDataLoaders,
    generateLoadingProps,
    reloadProps
  )
)
