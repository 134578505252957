import React from 'react'
import PropTypes from 'prop-types'

import TriangleIcon from '../../../images/icons/dropdown.svg'
import './triangle.scss'

export default function Triangle(props) {
  const { color, width, direction } = props
  const classname = `triangle ${direction}`
  const iconStyle = {
    color
  }
  const containerStyle = {
    width: width + 'px',
    height: width + 'px'
  }
  return (
    <div className={classname} style={containerStyle} data-spec="triangle-ui">
      <TriangleIcon style={iconStyle} data-spec="triangle-svg" />
    </div>
  )
}

Triangle.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  width: PropTypes.number
}
