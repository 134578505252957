import React from 'react'
import PropTypes from 'prop-types'

import i18n from '../../helpers/i18n'
import { teacherItemShape } from '../../helpers/propShapes/listViewShapes'
import ListView from '../ui/listView/listView'
import StickyContainer from '../ui/stickyContainer/stickyContainer'
import { TEACHER_ID_PATH } from '../../routes/routes'

import './teacherListView.scss'

class TeacherListView extends React.Component {
  constructor(props) {
    super(props)

    this.containerRef = React.createRef()
  }

  renderPreHeadingRow() {
    const assignmentsLabel = i18n.t('labels.assignments')
    return (
      <div
        className="list-row pre-headers"
        key="pre-headers"
        data-spec="header-row"
      >
        <div className="list-cell" />
        <div className={'list-cell lg'} />
        <div className={'list-cell lg'} />
        <div className={'list-cell md'} />
        <div className={'list-cell lg wide'} data-spec="heading-assignments">
          {assignmentsLabel}
        </div>
        <div className={'list-cell chart-section md'} />
      </div>
    )
  }

  renderHeadings() {
    const nameLabel = i18n.t('labels.nameSubject')
    const classesLabel = i18n.t('labels.classes')
    const gradesLabel = i18n.t('labels.grades')
    const numStudentsLabel = i18n.t('labels.students')
    const assignedLabel = i18n.t('labels.assigned')
    const scoredLabel = i18n.t('labels.scored')
    const masterylabel = i18n.t('labels.studentMastery')

    return (
      <div className="list-row headers" key="headers" data-spec="header-row">
        <div className="list-cell">{nameLabel}</div>
        <div className={'list-cell lg'}>{classesLabel}</div>
        <div className={'list-cell lg'}>{gradesLabel}</div>
        <div className={'list-cell md'}>{numStudentsLabel}</div>
        <div className={'list-cell sub-header lg'}>{assignedLabel}</div>
        <div className={'list-cell sub-header lg'}>{scoredLabel}</div>
        <div className={'list-cell chart-section md'}>{masterylabel}</div>
      </div>
    )
  }

  render() {
    const listStyles = {
      name: '',
      classCount: 'lg',
      grades: 'lg',
      numStudents: 'md',
      assignments: 'lg',
      assignmentsAssigned: 'lg',
      assignmentsScored: 'lg',
      studentMastery: 'md'
    }

    const basicDataKeys = [
      'classCount',
      'grades',
      'numStudents',
      'assignmentsAssigned',
      'assignmentsScored'
    ]

    const { items, schoolID, onClickRow } = this.props
    return (
      <div
        ref={this.containerRef}
        className="list-view-container teacher-list-view-container"
        data-spec="teacher-listview-ui"
      >
        <StickyContainer containerRef={this.containerRef}>
          {this.renderPreHeadingRow()}
          {this.renderHeadings()}
        </StickyContainer>
        <ListView
          items={items}
          listStyles={listStyles}
          basicDataKeys={basicDataKeys}
          rowLink={(teacherID) => TEACHER_ID_PATH(schoolID, teacherID)}
          onClickRow={onClickRow}
          stackedBar
          showMastery
        />
      </div>
    )
  }
}

TeacherListView.defaultProps = {
  items: []
}

TeacherListView.propTypes = {
  items: PropTypes.arrayOf(teacherItemShape),
  schoolID: PropTypes.string.isRequired
}

export default TeacherListView
