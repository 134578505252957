import React from 'react'
import { connect } from 'react-redux'

import SISConfigurationPresenter from './sisConfigurationPresenter'
import { withDistrictAdminRoleCheck } from '../hoc/withRoleCheckFor'
import withDataLoading from '../hoc/withDataLoading'
import * as fromState from '../../store/fromState'
import * as coreActions from '../../store/actions/coreActions'
import * as sisIntegrationActions from '../../store/actions/sisIntegrationActions'
import {
  SCHOOLS,
  GRADES,
  SUBJECTS,
  SIS_CONFIGURATIONS
} from '../../store/models/resources'

import { getError, getList, getSISConfigurations } from '../../store/fromState'

export const SISConfigurationContainer = (props) => {
  const {
    districtID,
    hasLoaded,
    configurations,
    schools,
    grades,
    subjects,
    updateConfigurations,
    sisError
  } = props
  if (!hasLoaded) {
    return null
  }

  const selectedSchoolIDs = configurations.school_settings
    .filter((s) => s.is_sync_enabled)
    .map((s) => s.school_id)

  const sisSyncedSchoolIDs = configurations.school_settings.map((s) =>
    s.school_id.toString()
  )

  // we want to filter out the schools in kiddom but not synced from sis.
  const idSet = new Set(sisSyncedSchoolIDs)
  const kiddomSchools = []
  schools
    .filter((s) => idSet.has(s.get('id')))
    .forEach((school) =>
      kiddomSchools.push({
        id: parseInt(school.get('id'), 10),
        name: school.get('schoolName')
      })
    )

  const gradeLevels = []
  grades.forEach((g) =>
    gradeLevels.push({
      id: parseInt(g.get('id'), 10),
      name: g.get('gradeName')
    })
  )

  const coreSubjects = []
  subjects
    .filter((s) => s.get('isCore'))
    .forEach((s) =>
      coreSubjects.push({ id: parseInt(s.get('id'), 10), name: s.get('name') })
    )

  return (
    <SISConfigurationPresenter
      districtID={districtID}
      schools={kiddomSchools}
      grades={gradeLevels}
      subjects={coreSubjects}
      selectedSchoolIDs={selectedSchoolIDs}
      selectedGradeIDs={configurations.grades}
      selectedSubjectIDs={configurations.subjects}
      schedule={configurations.schedule}
      setConfigurations={updateConfigurations}
      sisError={sisError}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const districtID = ownProps.match.params.districtID
  const configurations = getSISConfigurations(state)
  const sisError = getError(state, 'sisIntegration')
  return {
    districtID: districtID,
    configurations: configurations,
    schools: getList(state, SCHOOLS),
    grades: getList(state, GRADES),
    subjects: getList(state, SUBJECTS),
    sisError: sisError
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateConfigurations: (schedule, schoolIDs, gradeIDs, subjectIDs) =>
    dispatch(
      sisIntegrationActions.updateSISConfigurations.begin(
        ownProps.match.params.districtID,
        schedule,
        schoolIDs,
        gradeIDs,
        subjectIDs
      )
    )
})

const generateDataLoaders = (props) => [
  sisIntegrationActions.fetchSISConfigurations.begin(
    props.match.params.districtID
  ),
  coreActions.schools.read.begin(),
  coreActions.grades.read.begin(),
  coreActions.subjects.read.begin()
]

const generateHasLoadedCheckers = () => ({
  isFetching: [
    [
      fromState.isFetchingResources,
      [[SCHOOLS, SIS_CONFIGURATIONS, GRADES, SUBJECTS]]
    ]
  ],
  hasLoaded: [
    [
      fromState.resourcesLoaded,
      [[SIS_CONFIGURATIONS, SCHOOLS, GRADES, SUBJECTS]]
    ]
  ]
})

export default withDistrictAdminRoleCheck(
  withDataLoading(
    connect(mapStateToProps, mapDispatchToProps)(SISConfigurationContainer),
    generateDataLoaders,
    generateHasLoadedCheckers
  )
)
